import { useMutation } from '@tanstack/vue-query';
import { createRole } from '@/features/Roles/api';
import type { RoleData } from '@/features/Roles/types';

export function useCreateRole() {
  const { data, isPending, isSuccess, mutate, mutateAsync } = useMutation({
    mutationFn: async (payload: RoleData) => {
      return createRole(payload);
    },
    gcTime: 0,
  });

  return {
    mutate,
    mutateAsync,
    data,
    isPending,
    isSuccess,
  };
}
