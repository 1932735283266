<template>
  <AuthPageWrapper data-testid="auth-2fa">
    <template #title> Authentication code </template>
    <form
      class="flex flex-col grow"
      @submit="onSubmit"
    >
      <FormInput
        v-model="code"
        :attrs="codeAttrs"
        :is-errored="isCodeErrored"
        class="mb-4"
        name="code"
        type="code"
      >
        <template #label>Two-factor code</template>
        <template #error>
          {{ errors.code }}
        </template>
        <template #help>
          Enter the 6-digit code from your two-factor authentication app or use the 8-digit backup code.
        </template>
      </FormInput>
      <div class="mt-auto">
        <Button
          :is-loading="isLoading"
          data-testid="button-login"
          is-full-width
          type="submit"
        >
          Verify
        </Button>
      </div>
    </form>
  </AuthPageWrapper>
</template>

<script lang="ts" setup>
import zod from 'zod';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { toTypedSchema } from '@vee-validate/zod';

import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';

import { useSimpleAction } from '@/composables';

import AuthPageWrapper from '../components/AuthPageWrapper.vue';
import { AuthService } from '../service';
import { useFormData } from '@/composables/useFormData';
import { AUTH_ERROR_CODES, type AuthLoginPayload } from '../types';
import type { GenericError } from '@/types/exceptions';
import { ERROR_CODE_TO_MESSAGE } from '../constants';
import { useErrorHandling } from '@/composables/useErrorHandling';
import { setAuthenticated } from '@/services/auth';

const router = useRouter();

const { defineField, errors, meta, handleSubmit, setFieldError } = useFormData({
  data: {
    code: '',
  },
  validator: toTypedSchema(
    zod.object({
      code: zod.string().min(1, 'Please fill the code'),
    }),
  ),
});

const [code, codeAttrs] = defineField('code');

const isCodeErrored = computed(() => meta.value.touched && !!errors.value.code);

const { handleError } = useErrorHandling({
  list: AUTH_ERROR_CODES,
  messages: ERROR_CODE_TO_MESSAGE,
  callback: (message, code) => {
    if (message === AUTH_ERROR_CODES['2FA_NOT_SET']) {
      router.replace({ path: '/auth/2fa' });
    }
    if (message === AUTH_ERROR_CODES['2FA_OFF']) {
      router.replace({ path: '/auth/login' });
    }

    setFieldError('code', 'Wrong code. Try again.');
  },
});

const onSubmit = handleSubmit((values: Pick<AuthLoginPayload, 'code'>) => action(values));
const { isLoading, action } = useSimpleAction(async (payload: Pick<AuthLoginPayload, 'code'>) => {
  try {
    const resultPayload = {
      code: payload.code,
    };

    await AuthService.twoFASet(resultPayload);

    setAuthenticated();
    router.replace({ path: '/' });
  } catch (e: unknown) {
    handleError(e as GenericError);
  }
});
</script>
