import type { Ref } from 'vue';
import type { AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/vue-query';
import { updateLabel, createLabel } from '@/features/Labels/api';
import type { LabelTranslate } from '@/features/Labels/types';

interface MutationParams {
  label: LabelTranslate;
  labelId?: string | number;
}

interface UseMutationResult {
  mutate: unknown;
  isPending: Ref<boolean>;
}

interface MutateLabel {
  onSuccess: (label: LabelTranslate) => void;
  onError: (error: AxiosResponse) => void;
}

export const useUpdateLabel = ({ onSuccess, onError }: MutateLabel): UseMutationResult => {
  const { isPending, mutate } = useMutation({
    mutationFn: async ({ label, labelId }: MutationParams) => {
      if (labelId) {
        return await updateLabel(labelId, label);
      }

      return await createLabel(label);
    },
    onSuccess,
    onError,
  });

  return {
    mutate,
    isPending,
  };
};
