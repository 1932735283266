<template>
  <template v-if="!dashboardStore.state.isLoadingDashboardType">
    <BasicDashboard v-if="dashboardStore.state.dashboardType === DashboardTab.ARTICLES" />
    <ArtEditorDashboard v-else />
  </template>
</template>
<script setup lang="ts">
import BasicDashboard from '@/features/Dashboard/BasicDashboard.vue';
import { useDashboardStore } from '@/features/Dashboard/stores/dashboardStore';
import { DASHBOARD_STATE_NAME, DashboardTab } from '@/features/Dashboard/types';
import ArtEditorDashboard from '@/features/Dashboard/ArtEditorDashboard.vue';
import { computed, onMounted, watch } from 'vue';
import { ROLES } from '@/types';
import { getLocalStorageItem } from '@/services/localStorage';
import { useUserStore } from '@/stores/user.store';

const dashboardStore = useDashboardStore();

const userStore = useUserStore();

const user = computed(() => userStore.state);

watch(
  [user.value],
  () => {
    const value = getLocalStorageItem<DashboardTab>(DASHBOARD_STATE_NAME);
    if (value) {
      dashboardStore.setDashboardType(value);
      return;
    }

    if (user.value) {
      if (user.value?.roles?.includes(ROLES.ROLE_ART_EDITOR)) {
        dashboardStore.setDashboardType(DashboardTab.COVERS);
      } else {
        dashboardStore.setDashboardType(DashboardTab.ARTICLES);
      }
    }
  },
  { immediate: true },
);

onMounted(() => {
  const value = getLocalStorageItem<DashboardTab>(DASHBOARD_STATE_NAME);
  if (value) {
    dashboardStore.setDashboardType(value);
    return;
  }
});
</script>
