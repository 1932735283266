import { extractData, http, makeVoid } from '@/http';
import type {
  AuthLoginRequest,
  FetchUserResponse,
  AuthResetPasswordRequest,
  AuthCreatePasswordPayload,
  UserInvitationConfirmResponse,
  UserInvitationConfirmPayload,
  ValidateTokenPayload,
  ValidateTokenResponse,
  AuthLoginResponse,
  TwoFASetRequest,
  TwoFASetResponse,
  TwoFACheckResponse,
} from '../types';

export const login = (postBody: AuthLoginRequest) =>
  http.post<AuthLoginResponse>('/auth/login', postBody).then(extractData);

export const logout = () => http.post<void>('/auth/logout');

export const twoFACheck = () => {
  return http.get<TwoFACheckResponse>('/auth/2fa/set').then(extractData);
};

export const twoFASet = (postBody: TwoFASetRequest) =>
  http.post<TwoFASetResponse>('/auth/2fa/check', postBody).then(extractData);

export const resetPassword = (postBody: AuthResetPasswordRequest) =>
  http.post<void>('/auth/reset-password', postBody).then(makeVoid);

export const createPassword = (postBody: AuthCreatePasswordPayload) =>
  http.post<void>('/auth/set-password', postBody).then(makeVoid);

export const fetchUser = () => http.get<FetchUserResponse>('/auth/me').then(extractData);

export const validateToken = ({ token }: ValidateTokenPayload) =>
  http.get<ValidateTokenResponse>(`/auth/validate-token/${token}`).then(extractData);

export const userInvitationConfirm = (postBody: UserInvitationConfirmPayload) =>
  http
    .post<UserInvitationConfirmResponse>('/auth/invite-confirm', postBody, { withCredentials: true })
    .then(extractData);
