import { useQuery } from '@tanstack/vue-query';
import type { CategoryFetchParam } from '@/features/Categories/types';
import { fetchCategory } from '@/features/Categories/api';
import { CATEGORY_RESOURCE } from '@/features/Categories/constants';

export const useFetchCategory = (params: CategoryFetchParam, enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: [CATEGORY_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload = queryKey[1] as CategoryFetchParam;

      const result = await fetchCategory(payload);

      return result;
    },

    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
    enabled,
  });

  return {
    data,
    isLoading,
  };
};
