<template>
  <div class="flex text-imperium-fg-strong items-center whitespace-nowrap cursor-pointer">
    <Avatar
      :id="props.id"
      :img="props.avatar"
      :label="initials"
      :size="avatarSize"
    />
    <div
      v-if="withFullName"
      class="ps-2.5 max-w-full pr-8"
    >
      <div
        data-testid="name-surname"
        class="truncate block text-sm font-semibold line-clamp-1 hover:underline"
      >
        {{ `${name} ${surname}` }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Avatar from '@/components/Avatar.vue';
import { SIZES } from '@/types';
import { computed } from 'vue';
import { getInitials } from '@/utils/string/initials';

const props = withDefaults(
  defineProps<{
    avatar?: string;
    name: string;
    surname?: string;
    id: string | number;
    withFullName: boolean;
    avatarSize?: string;
  }>(),
  {
    avatar: '',
    name: '',
    surname: '',
    id: '',
    withFullName: true,
    avatarSize: SIZES.XSMALL,
  },
);

const initials = computed(() =>
  getInitials({
    name: props.name,
    surname: props.surname,
  }),
);
</script>
