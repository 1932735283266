<template>
  <MediaTable
    is-choosing-media
    @choose="(value) => emits('choose', value)"
  />
</template>
<script setup lang="ts">
import MediaTable from '@/features/Media/components/MediaTable.vue';

const emits = defineEmits<{
  (event: 'choose', value: { id: number; url: string }): void;
}>();
</script>
