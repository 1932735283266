import { useForm } from 'vee-validate';

export const useFormData = ({ data, validator }: any) => {
  const {
    errors,
    values,
    defineField,
    setFieldError,
    meta,
    handleSubmit,
    validate,
    setFieldValue,
    setValues,
    resetField,
    setErrors,
    setTouched,
    resetForm,
  } = useForm({
    validationSchema: validator,
    initialValues: data,
  });

  return {
    defineField,
    errors,
    values,
    meta,
    setValues,
    setFieldValue,

    validate,
    resetField,

    handleSubmit,
    resetForm,
    setFieldError,
    setErrors: (errors: Record<string, string>) => {
      setErrors(errors);
      setTouched(true);
    },
  };
};
