import { useQuery } from '@tanstack/vue-query';
import { fetchArticles } from '../api';
import { type ArticlesFetchRequest } from '../types';
import { type ComputedRef } from 'vue';

import { ARTICLES_RESOURCE } from '../constants';

export const useFetchArticles = (params: ComputedRef<ArticlesFetchRequest>, onSuccess = null) => {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: [ARTICLES_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: ArticlesFetchRequest = queryKey[1] as ArticlesFetchRequest;

      const result = await fetchArticles(payload);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    staleTime: 0,
    gcTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
  });

  return {
    data,
    isLoading,
    isFetched,
  };
};
