import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { FiltersState } from '@/features/Roles/types';
import { ROLE_FIELD_ID } from '@/features/Roles/constants';
import { SortStatus } from '@/types';

export type State = FiltersState;

export const useRolesFilters = defineStore('rolesPageFilters', () => {
  const state = ref<State>({
    sort: {
      [ROLE_FIELD_ID]: SortStatus.DESC,
    },
    searchQuery: '',
  });

  const setSorts = (sort) => {
    state.value = { ...state.value, sort: { ...sort } } as FiltersState;
  };

  const setSearchQuery = (searchQuery: string) => {
    state.value = { ...state.value, searchQuery };
  };

  return {
    state,
    setSorts,
    setSearchQuery,
  };
});
