<template>
  <component
    :is="component"
    class=""
    :class="
      buttonClass({
        color: props.color,
        wFull: props.isFullWidth,
        isActive: props.isActive,
        size: props.size,
        onSurface: isOnSurface,
        rounded: props.rounded,
      })
    "
    :disabled="props.isLoading || props.isDisabled"
    :type="props.type"
  >
    <template v-if="!props.isLoading">
      <div
        class="flex items-center justify-center"
        :class="!props.isFullWidth && 'w-max'"
      >
        <template v-if="isLeftIcon">
          <slot name="leftIcon" />
        </template>
        <div
          v-if="props.leadingLabel"
          class="ml-1 opacity-60"
        >
          {{ props.leadingLabel }}
        </div>
        <div
          v-if="!isLabel && isRightIcon && isLeftIcon"
          class="w-2"
        />
        <div
          v-if="isLabel && !isLabelSlotEmpty"
          :class="{ 'px-1': props.visualType !== BUTTON_TYPE.PLAIN }"
        >
          <slot />
        </div>
        <template v-if="isRightIcon">
          <slot name="rightIcon" />
        </template>
      </div>
    </template>
    <template v-else>
      <Spinner />
      <span class="sr-only"> Loading... </span>
    </template>
  </component>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import Spinner from '@/components/Spinner.vue';
import { BUTTON_ON_SURFACE, BUTTON_ROUNDED, BUTTON_TYPE, SIZES, TOGGLE_BUTTON_COLOR } from '@/types';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    size?: SIZES;
    rounded?: BUTTON_ROUNDED;
    isOnSurface?: BUTTON_ON_SURFACE;
    color?: TOGGLE_BUTTON_COLOR;
    visualType?: BUTTON_TYPE;

    leadingLabel?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isFullWidth?: boolean;
    isActive?: boolean;
    isLink?: boolean;
    type?: string;
  }>(),
  {
    size: SIZES.MEDIUM,
    rounded: BUTTON_ROUNDED.LARGE,
    isOnSurface: BUTTON_ON_SURFACE.GRAY,
    color: TOGGLE_BUTTON_COLOR.WHITE,

    leadingLabel: '',
    isDisabled: false,
    isLoading: false,
    isFullWidth: false,
    isActive: false,
    isLink: false,
    type: 'button',
  },
);

const slots = useSlots();
const isLabel = computed(() => !!slots.default);
const isLeftIcon = computed(() => !!slots.leftIcon);
const isRightIcon = computed(() => !!slots.rightIcon);

const isLabelSlotEmpty = computed(() => {
  const defaultSlot = slots.default ? slots.default() : [];
  return defaultSlot.length === 0 || (defaultSlot.length === 1 && defaultSlot[0].children?.length === 0);
});

const buttonClass = tv({
  base: 'font-semibold text-center text-imperium-fg-base',
  variants: {
    size: {
      [SIZES.LARGE]: 'button-lg',
      [SIZES.MEDIUM]: 'button-md',
      [SIZES.SMALL]: 'button-sm min-h-8 min-w-8',
      [SIZES.XSMALL]: 'button-xs',
    },
    rounded: {
      [BUTTON_ROUNDED.LARGE]: 'button-rounded-lg',
      [BUTTON_ROUNDED.BASE]: 'button-rounded-base',
      [BUTTON_ROUNDED.NONE]: 'button-rounded-none',
      [BUTTON_ROUNDED.FULL]: 'button-rounded-full',
    },
    wFull: {
      true: 'w-full',
    },
  },
  compoundVariants: [
    {
      color: TOGGLE_BUTTON_COLOR.WHITE,
      onSurface: BUTTON_ON_SURFACE.GRAY,
      isActive: false,
      class: 'hover:text-imperium-fg-strong bg-transparent',
    },
    {
      color: TOGGLE_BUTTON_COLOR.WHITE,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      isActive: false,
      class: 'hover:bg-imperium-bg-3 hover:text-imperium-fg-strong bg-transparent',
    },
    {
      color: TOGGLE_BUTTON_COLOR.WHITE,
      isActive: true,
      onSurface: BUTTON_ON_SURFACE.GRAY,
      class: 'bg-white text-imperium-fg-strong cursor-default',
    },
    {
      color: TOGGLE_BUTTON_COLOR.WHITE,
      isActive: true,
      onSurface: BUTTON_ON_SURFACE.DEFAULT,
      class: 'bg-imperium-bg-3 text-imperium-fg-strong cursor-default',
    },
  ],
});

const component = computed(() => (props.isLink ? 'RouterLink' : 'button'));
</script>
