<script setup lang="ts">
import LongArrowUp from '@/assets/icons/long-arrow-up.svg?component';
import { ref, watchEffect } from 'vue';
import { useWindowScroll } from '@vueuse/core';

const SCROLL_OFFSET_TOP = 1000;

const { y } = useWindowScroll();

const isButtonVisible = ref<boolean>(false);

watchEffect(() => {
  if (y.value > SCROLL_OFFSET_TOP) {
    isButtonVisible.value = true;
  }
  if (y.value < SCROLL_OFFSET_TOP) {
    isButtonVisible.value = false;
  }
});

const moveToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
</script>

<template>
  <button
    v-if="isButtonVisible"
    class="fixed bottom-8 z-10 right-8 w-12 h-12 border border-imperium-border-clear bg-white rounded-full p-3 flex justify-center"
    @click="moveToTop"
  >
    <LongArrowUp class="text-imperium-fg-base" />
  </button>
</template>
