<template>
  <div class="flex flex-col space-y-2">
    <div
      class="rounded-lg flex items-center justify-center h-[100px] 3xl:h-[150px] w-full bg-center bg-cover media-image rounded-t-lg"
    />
    <div class="flex flex-wrap rounded-b-lg">
      <div class="text-sm text-imperium-fg-strong mr-1 mb-1 cursor-default">Last usage</div>
      <LastUsage :last-usage="props.lastUsage"></LastUsage>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import LastUsage from '@/components/LastUsage.vue';

const props = defineProps<{
  src: string;
  lastUsage: null | string;
}>();

const image = computed(() => `url(${props.src})`);
</script>
<style lang="scss" scoped>
.media-image {
  background-image: v-bind(image);
}
</style>
