<template>
  <div
    v-if="isCheckingTokenLoading"
    class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
  >
    <div class="h-10 w-10">
      <LoaderIcon class="w-10 h-10" />
    </div>
  </div>
  <div v-else>
    <div v-if="user?.id">
      <UserInvitationSetPassword :email="user.email" />
    </div>
    <div v-else>
      <UserInvitationExpired />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useSimpleAction } from '@/composables';

import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { AuthService } from '../service';
import type { ValidateTokenPayload } from '@/features/Auth/types';
import UserInvitationSetPassword from '@/features/Auth/components/UserInvitation/UserInvitationSetPassword.vue';
import type { User } from '@/features/Users/types';
import UserInvitationExpired from '@/features/Auth/components/UserInvitation/UserInvitationExpired.vue';

const user = ref<User>({});

const { isLoading: isCheckingTokenLoading, action: checkingTokenAction } = useSimpleAction(
  async (payload: ValidateTokenPayload) => {
    try {
      if (payload.token) {
        const data = await AuthService.validateToken(payload);

        user.value = data.user;
      } else {
        throw new Error('No token in url');
      }
    } catch (e: any) {
      console.warn(e);
    }
  },
);

onMounted(() => {
  const route = useRoute();
  const token = route.params.token;
  checkingTokenAction({ token });
});
</script>
