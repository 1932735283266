import type { Ref } from 'vue';
import type { AxiosResponse } from 'axios';

import { useMutation } from '@tanstack/vue-query';
import { updateCategory, createCategory } from '@/features/Categories/api';
import type { CategoryTranslate, CategoryTranslateFormPayload } from '@/features/Categories/types';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';

interface MutationParams {
  category: CategoryTranslateFormPayload;
  categoryId?: string | number;
}

interface UseMutationResult {
  mutate: unknown;
  isPending: Ref<boolean>;
}

interface MutateCategory {
  onSuccess: (category: CategoryTranslate) => void;
  onError: (error: AxiosResponse) => void;
}

export const useUpdateCategory = ({ onSuccess, onError }: MutateCategory): UseMutationResult => {
  const { isPending, mutate } = useMutation({
    mutationFn: async ({ category, categoryId }: MutationParams) => {
      if (categoryId) {
        return await updateCategory(categoryId, category);
      }

      return await createCategory({ ...category, languageId: DEFAULT_LANGUAGE_ID });
    },
    onSuccess,
    onError,
  });

  return {
    mutate,
    isPending,
  };
};
