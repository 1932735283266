<template>
  <div class="flex gap-2">
    <div
      v-for="socialNetwork of props.socialNetworks"
      :key="socialNetwork.name"
    >
      <a
        :href="socialNetwork.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button
          class="flex items-center"
          :data-testid="`social-network-${socialNetwork.name}`"
          :visual-type="BUTTON_TYPE.GHOST"
        >
          <template #leftIcon>
            <Component
              :is="icons[socialNetwork.name]"
              class="w-5 h-5"
            />
          </template>
        </Button>
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import TwitterIcon from '@/assets/icons/twitter.svg?component';
import FacebookIcon from '@/assets/icons/facebook.svg?component';
import TelegramIcon from '@/assets/icons/telegram.svg?component';
import type { Component } from 'vue';
import Button from '@/components/Button.vue';
import { BUTTON_TYPE } from '@/types';

const props = defineProps<{
  socialNetworks: { name: string; url: string }[];
}>();

const icons: Record<string, Component> = {
  telegram: TelegramIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
};
</script>
