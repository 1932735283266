<template>
  <Toast
    :id="props.id"
    :message="props.message"
    :title="props.title"
    :theme="THEMES.DANGER"
  >
    <Button
      v-if="props.buttonText"
      data-testid="user-edit-button-toast"
      :visual-type="BUTTON_TYPE.PRIMARY"
      :color="props.buttonColor"
      :size="SIZES.SMALL"
      @click="$emit('close-toast')"
    >
      {{ props.buttonText }}
    </Button>
  </Toast>
</template>
<script setup lang="ts">
import Toast from '@/components/Toast.vue';
import Button from '@/components/Button.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES, THEMES } from '@/types';

const props = defineProps<{
  id: string;
  message: string;
  buttonColor: BUTTON_COLOR;
  title: string;
  buttonText: string;
}>();
</script>
