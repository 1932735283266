<template>
  <FormInput
    class="mb-4"
    :model-value="props.articleUrl"
    :name="props.name"
    type="text"
    :size="props.size"
    :attrs="props.articleUrlAttrs"
    :is-readonly="props.isReadonly"
    :is-disabled="props.isDisabled"
    @update:model-value="onUrlChange"
  >
    <template #label>Article URL</template>

    <template #prefix>
      <ChainIcon class="w-5 h-5 text-imperium-fg-muted" />
    </template>

    <template #suffix>
      <EditIcon class="w-5 h-5 text-imperium-fg-muted" />
    </template>

    <template #help>
      <a
        v-if="postUrl"
        :href="postUrl"
        target="_blank"
        class="border-b-2 border-imperium-ds-primary-base no-underline cursor-pointer"
      >
        cointelegraph.com/news/{{ props.articleUrl }}
      </a>
      <span
        v-else
        class="break-all"
        >cointelegraph.com/news/{{ props.articleUrl }}</span
      >
    </template>
  </FormInput>
</template>

<script lang="ts" setup>
import { computed, watchEffect } from 'vue';

import FormInput from '@/components/FormInput.vue';

import EditIcon from '@/assets/icons/edit-icon.svg?component';
import ChainIcon from '@/assets/icons/chain.svg?component';

import { convertStringToKebabCase } from '@/utils/url';
import { SIZES } from '@/types';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';

const props = withDefaults(
  defineProps<{
    articleTitle: string;
    articleUrl: string;
    name: string;
    articleUrlAttrs?: Record<string, unknown>;
    size: SIZES;
    isReadonly: boolean;
    hasPost: boolean;
    isDisabled?: boolean;
  }>(),
  {
    size: SIZES.SMALL,
    isReadonly: true,
    isDisabled: false,
  },
);

const articleStore = useArticleStore();

const postUrl = computed(() => articleStore.state.postUrl ?? '');

const emits = defineEmits<{
  (event: 'update:model-value', value: string | null): void;
  (event: 'update:article-url', value: string | null): void;
}>();

const onUrlChange = (val: string) => {
  if (props.isReadonly || props.hasPost) {
    return;
  }
  emits('update:article-url', convertStringToKebabCase(val) || null);
};

watchEffect(() => {
  if (!props.hasPost) {
    emits('update:article-url', convertStringToKebabCase(props.articleUrl) || null);
  }
});
</script>
