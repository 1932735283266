<template>
  <TableHeadRow
    :size="SIZES.MEDIUM"
    :bordered="TABLE_BORDERED.BOTTOM"
    :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
  >
    <TableHeadCell
      :size="SIZES.SMALL"
      :is-sticky="!isLargeDesktop"
      is-uppercase
    >
      <SortColumnHeader
        id="id"
        label="Id"
        :value="filtersStore.state.sort.id"
        @change-sort="updateFilters({ filterName: 'id', value: $event })"
      />
    </TableHeadCell>

    <TableHeadCell
      :size="!isLargeDesktop ? SIZES.LARGE : SIZES.XLARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.DOUBLE : TABLE_SCALE.TRIPLE"
      :is-sticky="!isLargeDesktop"
      :sticky-offset-size="SIZES.SMALL"
      is-uppercase
    >
      <SortColumnHeader
        id="user"
        label="User"
        :value="filtersStore.state.sort.name"
        @change-sort="updateFilters({ filterName: 'name', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      class="cursor-default"
      is-full-width
      is-uppercase
    >
      <span class="hidden xl:block"> User role </span>

      <span class="block xl:hidden"> User </span>
    </TableHeadCell>
    <TableHeadCell
      :size="SIZES.LARGE"
      :scale="TABLE_SCALE.DOUBLE"
      is-uppercase
    >
      <SortColumnHeader
        id="createdAt"
        label="Invitation date"
        :value="filtersStore.state.sort.createdAt"
        @change-sort="updateFilters({ filterName: 'createdAt', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      :size="!isLargeDesktop ? SIZES.MEDIUM : SIZES.LARGE"
      :scale="!isLargeDesktop ? TABLE_SCALE.QUADRUPLE : TABLE_SCALE.DOUBLE"
      is-uppercase
    >
      <SortColumnHeader
        id="status"
        label="User status"
        :value="filtersStore.state.sort.status"
        @change-sort="updateFilters({ filterName: 'status', value: $event })"
      />
    </TableHeadCell>
    <TableHeadCell
      class="cursor-default"
      is-full-width
      is-uppercase
    >
      Language access
    </TableHeadCell>
    <TableHeadCell
      :size="SIZES.SMALL"
      :scale="TABLE_SCALE.TRIPLE"
      class="cursor-default"
      is-uppercase
    >
      Action
    </TableHeadCell>
  </TableHeadRow>
</template>
<script setup lang="ts">
import { inject } from 'vue';
import { useUserFilters } from '@/features/Users/stores/filters.store';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import { TableHeadRow, TableHeadCell } from '@/components/Table';
import { SIZES, TABLE_ON_SURFACE, type SortStatus, TABLE_BORDERED, TABLE_SCALE } from '@/types';

const isTablet = inject<boolean>('isTablet');
const isLargeDesktop = inject<boolean>('isLargeDesktop');

const filtersStore = useUserFilters();

const updateFilters = ({ filterName, value }: { filterName: string; value: SortStatus }) => {
  filtersStore.setSorts({
    [filterName]: value,
  });
};
</script>
