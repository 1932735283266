import { useQuery } from '@tanstack/vue-query';
import { CONDITIONS_QUERY } from '@/features/Conditions/constants';
import { fetchConditions } from '@/features/Conditions/api';

export const useFetchConditions = () => {
  const { data, isLoading } = useQuery({
    queryKey: [CONDITIONS_QUERY],

    queryFn: async () => {
      return await fetchConditions();
    },
  });

  return { data, isLoading };
};
