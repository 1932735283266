import type { Permission } from '@/features/Permissions/types';
import { addRolePermission, updateRolePermission, deleteRolePermission } from '@/features/Roles/api';
import { ROLE_PERMISSION_RESPONSE_CODE } from '@/features/Roles/types';

interface RolePermissionsInterface {
  addPermission(id: number, permission: Permission): void;
  updatePermission(id: number, permission: Permission): void;
  deletePermission(id: number, permission: Permission): void;
}

export const useRolePermissions = (): RolePermissionsInterface => {
  const addPermission = async (id: number, permission: Permission) => {
    try {
      await addRolePermission(id, permission);
    } catch (e) {
      if (e.data.errorCode === ROLE_PERMISSION_RESPONSE_CODE.DUPLICATE) {
        await updateRolePermission(id, permission);
      }
    }
  };

  const updatePermission = async (id: number, permission: Permission) => {
    try {
      await updateRolePermission(id, permission);
    } catch (e) {
      if (e.data.errorCode === ROLE_PERMISSION_RESPONSE_CODE.NOT_FOUND) {
        await addRolePermission(id, permission);
      }
    }
  };

  const deletePermission = async (id: number, permissionId: number) => {
    await deleteRolePermission(id, permissionId);
  };

  return {
    addPermission,
    updatePermission,
    deletePermission,
  };
};
