<template>
  <div
    id="collaborative-editor-link-preview"
    class="hidden shadow-imperium-base duration-500 sticky z-[100] bottom-10 h-16 w-[544px] bg-imperium-bg-5 px-3 py-4 rounded m-auto"
  >
    <div class="flex space-x-2 justify-between items-center">
      <div class="flex space-x-2 items-center">
        <div class="bg-imperium-bg-2 w-8 h-8 flex items-center justify-center rounded">
          <WorldIcon class="w-5 h-5 text-imperium-fg-base" />
        </div>
        <div
          ref="titleString"
          id="collaborative-editor-link-preview-text"
          class="ml-2 text-imperium-fg-strong text-sm max-w-[420px] max-h-[50px] truncate"
        />
      </div>

      <Button
        :visual-type="BUTTON_TYPE.TERTIARY"
        class="ml-2"
        :size="SIZES.SMALL"
      >
        <template #leftIcon>
          <a
            href=""
            target="_blank"
            id="collaborative-editor-link-preview-link"
          >
            <LinkIcon
              v-if="titleString?.innerHTML.includes('https://')"
              class="w-5 h-5 text-imperium-fg-base"
            />
            <LinkIcon
              v-else
              class="w-5 h-5 text-imperium-fg-base"
            />
          </a>
        </template>
      </Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import WorldIcon from '@/assets/icons/world.svg?component';
import LinkIcon from '@/assets/icons/open-link.svg?component';
import Button from '@/components/Button.vue';
import { BUTTON_TYPE, SIZES } from '@/types';

const titleString = ref<HTMLElement | null>(null);
</script>
