import { extractData, http } from '@/http';
import type { RolesFetchRequest, RolesFetchResponse, Role } from './types';
import type { Permission } from '@/features/Permissions/types';

export const fetchRoles = (params: RolesFetchRequest): Promise<RolesFetchResponse> =>
  http.get<RolesFetchResponse>('/roles', { params, useAuth: true }).then(extractData);

export const fetchRole = (id: string): Promise<RolesFetchResponse> =>
  http.get<RolesFetchResponse>(`/roles/${id}`, { useAuth: true }).then(extractData);

export const createRole = (payload: RoleCreateRequest): Promise<RoleCreateResponse> =>
  http.post<RoleCreateResponse>('/roles', payload).then(extractData);

export const partialUpdateRole = (id: number, params: RoleUpdateRequest): Promise<RoleUpdateResponse> =>
  http.patch<RoleUpdateResponse>(`/roles/${id}`, { params, useAuth: true }).then(extractData);

export const updateRole = (id: number, params: Role): Promise<Role> =>
  http.patch<Role>(`/roles/${id}`, params, { useAuth: true }).then(extractData);

// Role permissions

export const addRolePermission = (id: number, payload: Permission): Promise<Role> =>
  http.post(`/roles/${id}/permissions`, payload).then(extractData);

export const updateRolePermission = (id: number, payload: Permission): Promise<Role> =>
  http.patch(`/roles/${id}/permissions`, payload).then(extractData);

export const deleteRolePermission = (id: number, permissionId: number): Promise<Role> =>
  http.delete(`/roles/${id}/permissions/${permissionId}`).then(extractData);
