import { ref, onBeforeUnmount, type Ref } from 'vue';

interface CurrentTime {
  currentTimeUTC: Ref<string>;
  currentLocalTime: Ref<Date>;
}

export const useCurrentTime = (): CurrentTime => {
  const currentTimeUTC = ref(
    new Date().toLocaleString('en', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    }),
  );
  const currentLocalTime = ref(new Date());
  const updateCurrentTime = () => {
    currentTimeUTC.value = new Date().toLocaleString('en', {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
    });
    currentLocalTime.value = new Date();
  };
  const updateTimeInterval = setInterval(updateCurrentTime, 1000);

  onBeforeUnmount(() => {
    clearInterval(updateTimeInterval);
  });

  return {
    currentTimeUTC,
    currentLocalTime,
  };
};
