import { ref } from 'vue';
import { useConfirmDialog } from '@vueuse/core';
import { freezeBody, unfreezeBody } from '@/utils/dom';

const openModalsCount = ref<number>(0);

export const useModal = () => {
  const { isRevealed, reveal, confirm, cancel, onReveal, onConfirm, onCancel } = useConfirmDialog();

  onReveal(() => {
    openModalsCount.value = openModalsCount.value + 1;
    freezeBody();
  });

  onConfirm(() => {
    openModalsCount.value = openModalsCount.value > 0 ? openModalsCount.value - 1 : 0;

    if (openModalsCount.value === 0) {
      unfreezeBody();
    }
  });

  onCancel(() => {
    openModalsCount.value = openModalsCount.value > 0 ? openModalsCount.value - 1 : 0;

    if (openModalsCount.value === 0) {
      unfreezeBody();
    }
  });

  return {
    isRevealed,

    reveal,
    confirm,
    cancel,
  };
};
