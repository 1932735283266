<template>
  <div class="w-10 sticky top-14 pt-14 right-0 pb-2 bottom-0 h-full flex flex-col gap-6 z-50">
    <Tooltip
      :key="`tooltip-info`"
      width="unset"
      name="metadata-info"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.INFO"
          @click="() => onNavigationClick(METADATA_SECTIONS.INFO)"
        >
          <template #leftIcon>
            <InfoIcon class="w-6 h-6 text-imperium-fg-base" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Common Information</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-team`"
      width="unset"
      name="metadata-team"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.COVER"
          @click="() => onNavigationClick(METADATA_SECTIONS.COVER)"
        >
          <template #leftIcon>
            <ImageIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Cover</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-team`"
      width="unset"
      name="metadata-team"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.TEAM"
          @click="() => onNavigationClick(METADATA_SECTIONS.TEAM)"
        >
          <template #leftIcon>
            <UsersIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Team</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-schedule`"
      width="unset"
      name="metadata-schedule"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.SCHEDULE"
          @click="() => onNavigationClick(METADATA_SECTIONS.SCHEDULE)"
        >
          <template #leftIcon>
            <ClockIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Date & Schedule</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-taxonomy`"
      width="unset"
      name="metadata-taxonomy"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.TAXONOMY"
          @click="() => onNavigationClick(METADATA_SECTIONS.TAXONOMY)"
        >
          <template #leftIcon>
            <LabelIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Taxonomy</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-socials`"
      width="unset"
      name="metadata-socials"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.SOCIALS"
          @click="() => onNavigationClick(METADATA_SECTIONS.SOCIALS)"
        >
          <template #leftIcon>
            <TwitterIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Social Networks</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-seo`"
      width="unset"
      name="metadata-seo"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.SEO"
          @click="() => onNavigationClick(METADATA_SECTIONS.SEO)"
        >
          <template #leftIcon>
            <TradeIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">SEO</div>
      </template>
    </Tooltip>

    <Tooltip
      :key="`tooltip-settings`"
      width="unset"
      name="metadata-settings"
      placement="left"
    >
      <template #content>
        <Button
          :visual-type="BUTTON_TYPE.GHOST"
          :size="SIZES.SMALL"
          :is-active="activeAnchor === METADATA_SECTIONS.SETTINGS"
          @click="() => onNavigationClick(METADATA_SECTIONS.SETTINGS)"
        >
          <template #leftIcon>
            <EqualizerIcon class="w-6 h-6" />
          </template>
        </Button>
      </template>
      <template #tooltip>
        <div class="text-sm whitespace-nowrap">Article Settings</div>
      </template>
    </Tooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, type Ref } from 'vue';
import { useElementVisibility, watchPausable, useScroll, useWindowScroll } from '@vueuse/core';
import { useScrollEnd } from '@/composables/useScrollEnd';

import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import InfoIcon from '@/assets/icons/info-circle.svg?component';
import LabelIcon from '@/assets/icons/label.svg?component';
import UsersIcon from '@/assets/icons/users.svg?component';
import ImageIcon from '@/assets/icons/img.svg?component';
import ClockIcon from '@/assets/icons/clock.svg?component';
import TradeIcon from '@/assets/icons/trade.svg?component';
import TwitterIcon from '@/assets/icons/twitter.svg?component';
import EqualizerIcon from '@/assets/icons/equalizer.svg?component';

import { SIZES, BUTTON_TYPE } from '@/types';

import { METADATA_SECTIONS, FIELD_TO_SECTION_MAP } from './constants';

type WatchableAnchor = Record<
  string,
  {
    element: HTMLElement;
    isVisible: Ref<boolean>;
  }
>;

const props = defineProps<{
  area: HTMLElement;
}>();

const { onScrollEnd } = useScrollEnd();
const { onScrollEnd: onAreaScrollEnd } = useScrollEnd(props.area);

const watchableAnchors = ref<WatchableAnchor>({});
const selectedAnchor = ref<string | undefined>();
const scrollableArea = computed(() => props.area);

const { y: windowY } = useWindowScroll();
const { y: areaY } = useScroll(scrollableArea);

const activeAnchor = computed<string | void>(() => {
  if (selectedAnchor.value) {
    return selectedAnchor.value;
  }

  const entries = Object.entries(watchableAnchors.value);
  const visible = entries.filter((entry) => !!entry[1].isVisible) || [];
  const mediana = Math.floor(visible.length / 2);

  if (entries && visible) {
    const first = visible[0];
    const last = visible[visible.length - 1];

    if (first && entries[0][0] === first[0] && visible.length <= 2) {
      return first[0];
    }

    if (last && entries[entries.length - 1][0] === last[0] && visible.length <= 3) {
      return last[0];
    }

    if (visible[mediana]) {
      return visible[mediana][0];
    }
  }
});

const { pause, resume } = watchPausable([windowY, areaY], () => {
  if (selectedAnchor.value) {
    selectedAnchor.value = undefined;
  }
});

const scrollToAnchor = (anchor: string) => {
  selectedAnchor.value = anchor;

  watchableAnchors.value[anchor].element.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });

  pause();
};

const scrollToField = (field: string) => {
  const anchor = FIELD_TO_SECTION_MAP[field];

  if (!anchor) {
    return;
  }

  scrollToAnchor(anchor);
};

const onNavigationClick = (anchor: string) => {
  scrollToAnchor(anchor);
};
onScrollEnd(resume);
onAreaScrollEnd(resume);

onMounted(() => {
  Object.values(METADATA_SECTIONS).forEach((section) => {
    const anchorElement = document.querySelector(`[data-anchor="${section}"]`);

    if (anchorElement) {
      watchableAnchors.value[section] = {
        element: anchorElement as HTMLElement,
        isVisible: useElementVisibility(anchorElement as HTMLElement),
      };
    }
  });
});

defineExpose({
  scrollToField,
});
</script>
