<template>
  <div
    class="flex font-semibold flex-col text-imperium-fg-muted"
    @click="emits('click')"
  >
    <CaretUp
      class="h-2 w-2"
      :class="props.value === SortStatus.ASC && 'text-imperium-fg-strong'"
    />
    <CaretDown
      class="h-2 w-2"
      :class="props.value === SortStatus.DESC && 'text-imperium-fg-strong'"
    />
  </div>
</template>

<script setup lang="ts">
import CaretUp from '@/assets/icons/caret-up.svg?component';
import CaretDown from '@/assets/icons/caret-down.svg?component';
import { SortStatus } from '@/types';

const props = withDefaults(
  defineProps<{
    value: SortStatus;
  }>(),
  {
    value: SortStatus.DISABLE,
  },
);

const emits = defineEmits<{
  (event: 'click'): void;
}>();
</script>
