<template>
  <div class="relative gap-1 group flex flex-wrap">
    <template
      v-for="(flag, index) in localFlags"
      :key="index"
    >
      <Badge
        :id="`article-flag-${flag}`"
        :label="flag"
        :size="SIZES.XSMALL"
        :theme="setBadgeTheme(flag)"
        :rounded="BADGE_ROUNDED.LARGE"
        :is-hoverable="false"
        :is-closable="false"
        is-readonly
      />
    </template>
    <Badge
      v-if="props.withTooltip && props.tooltipLabel"
      :id="`article-flag-${flag}`"
      :label="props.tooltipLabel"
      :size="SIZES.XSMALL"
      :theme="BADGE_THEMES.DEFAULT"
      :rounded="BADGE_ROUNDED.LARGE"
      :is-hoverable="false"
      :is-closable="false"
      is-readonly
      class="hidden group-hover:inline-flex absolute whitespace-nowrap z-[100] -translate-x-full"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref, watchEffect } from 'vue';
import Badge from '@/components/Badge.vue';
import { BADGE_ROUNDED, BADGE_THEMES, SIZES } from '@/types';
import { ARTICLE_FLAGS } from '@/features/Articles/constants';
import { type Article } from '@/features/Articles/types';

const FLAG_TO_BADGE_COLOR_MAP = {
  [ARTICLE_FLAGS.BREAKING]: BADGE_THEMES.DEFAULT,
  [ARTICLE_FLAGS.NEEDS_PROFREADING]: BADGE_THEMES.DEFAULT,
  [ARTICLE_FLAGS.NEEDS_ORIGINAL_COVER]: BADGE_THEMES.DEFAULT,
  [ARTICLE_FLAGS.SCHEDULED]: BADGE_THEMES.DEFAULT,
  [ARTICLE_FLAGS.REPUBLISHED]: BADGE_THEMES.DEFAULT,
  [ARTICLE_FLAGS.EMBARGOED]: BADGE_THEMES.RED,
};

const props = defineProps<{
  flag?: ARTICLE_FLAGS;
  article?: Article;
  withTooltip?: boolean;
  tooltipLabel?: string;
}>();

const isScheduled = computed<boolean>(() => {
  const currentDate = new Date();
  const publishDate = props?.article?.publishedAt ? new Date(props.article.publishedAt) : currentDate;

  return publishDate.getTime() > currentDate.getTime();
});

const localFlags: ref<Array<string>> = computed(() => {
  const result = [];
  const article = props.article;
  if (!article) return;
  if (!article && props.flag) result.push(props.flag);

  if (article.embargoUntil) result.push(ARTICLE_FLAGS.EMBARGOED);
  if (article.needsProofreading) result.push(ARTICLE_FLAGS.NEEDS_PROFREADING);
  if (article.isOriginalContent) result.push(ARTICLE_FLAGS.ORIGINAL_CONTENT);
  if (isScheduled.value) result.push(ARTICLE_FLAGS.SCHEDULED);
  if (article.canBeRepublished) result.push(ARTICLE_FLAGS.REPUBLISHED);
  if (article.isBreaking) result.push(ARTICLE_FLAGS.BREAKING);

  return result;
});

const setBadgeTheme = (flag: string) => {
  return FLAG_TO_BADGE_COLOR_MAP[flag];
};

onBeforeMount(() => {
  if (!props.flag && !props.article) {
    throw new Error('Provide flag or article');
  }
});
</script>
