<template>
  <Button
    v-if="!props.isRevealed"
    :visual-type="BUTTON_TYPE.TERTIARY"
    @click="emits('reveal')"
  >
    <template #leftIcon>
      <PlusIcon class="w-4 h-4" />
    </template>

    <slot name="label"> Reveal </slot>
  </Button>

  <template v-else>
    <Teleport
      v-if="props.to"
      :to="props.to"
    >
      <slot />
    </Teleport>

    <slot v-else />
  </template>
</template>

<script lang="ts" setup>
import { BUTTON_TYPE } from '@/types';
import Button from '@/components/Button.vue';
import PlusIcon from '@/assets/icons/plus.svg?component';

const props = withDefaults(
  defineProps<{
    isRevealed?: boolean;
    to?: HTMLElement;
  }>(),
  {
    isRevealed: false,
    to: undefined,
  },
);
const emits = defineEmits<{
  (event: 'reveal'): void;
}>();
</script>
