import { ref, computed } from 'vue';
import { type SelectItem } from '@/components/FormSelect.vue';
import type { ArticleBadgesFetchRequest } from '../types';
import { useFetchBadges } from '@/features/ArticleLayout/queries';

export const useBadges = (filter?: { category?: number }) => {
  const badgesPayload = ref<ArticleBadgesFetchRequest>({ languageId: 1 });

  if (filter?.category) {
    badgesPayload.value.category = filter.category;
  }

  const { data: badgesTranslates, refetch: queryReFetch } = useFetchBadges(badgesPayload);

  const badges = computed<SelectItem[]>(() => {
    if (!badgesTranslates.value?.items?.length) {
      return [];
    }

    return badgesTranslates.value.items.map((badgeTranslate) => ({
      id: badgeTranslate.badge.id,
      label: badgeTranslate.title,
    }));
  });

  const refetch = (newCategory?: number) => {
    if (newCategory !== undefined) {
      badgesPayload.value.category = newCategory;
      queryReFetch();
    }
  };

  return {
    badges,
    refetch,
  };
};
