<template>
  <RouterLink
    :to="props.to"
    class="flex items-center p-2 rounded-lg hover:bg-gray-100 group w-full"
    :class="{
      'text-imperium-fg-base hover:bg-imperium-bg-3/50': !isActiveLink,
      'text-imperium-fg-strong bg-imperium-bg-3': isActiveLink,
    }"
    :data-testid="`menu-item-${props.name}`"
  >
    <component
      :is="props.meta.icon"
      v-if="props.meta?.icon"
      class="w-5 h-5"
    />

    <span class="flex-1 ms-2 whitespace-nowrap">
      {{ label }}
    </span>
  </RouterLink>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();

interface Meta {
  [key: string]: any;
}

const props = defineProps<{
  to: string;
  name: string;
  label: string;
  meta: Meta;
}>();

const isActiveLink = computed(() => {
  return route.matched.some((matchedRoute) => matchedRoute.name === props.name);
});
</script>
