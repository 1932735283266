import type { Permission } from '@/features/Permissions/types';
import { SortStatus } from '@/types';
import {
  ROLE_FIELD_ID,
  ROLE_FIELD_NAME,
  ROLE_FIELD_DESCRIPTION,
  ROLE_FIELD_SLUG,
  ROLE_FIELD_PERMISSIONS,
} from '@/features/Roles/constants';

export interface Role {
  [ROLE_FIELD_ID]: string;
  [ROLE_FIELD_NAME]: string;
  [ROLE_FIELD_SLUG]: string;
  [ROLE_FIELD_DESCRIPTION]: string;
  rolePermissions?: Permission[];
  permissions?: {
    empty: boolean;
    keys: number[];
    values: string[];
    iterator: string;
    conditions?: string[];
  };
  rolePermission?: Permission;
}
export interface RoleData {
  [ROLE_FIELD_ID]?: string;
  [ROLE_FIELD_NAME]: string;
  [ROLE_FIELD_SLUG]?: string;
  [ROLE_FIELD_DESCRIPTION]: string;
  [ROLE_FIELD_PERMISSIONS]: Permission[];
}

export interface RolesFetchRequest {
  page: number;
  itemsPerPage: number;
  searchQuery?: string;
}

export interface RolesFetchResponse {
  items: Role[];
  page: number;
  itemsPerPage: number;
  itemsTotal: number;
}

export interface FiltersState {
  searchQuery: string;
  sort: {
    id: SortStatus;
    name: SortStatus;
  };
}

export interface RoleSortOptions {
  [ROLE_FIELD_ID]?: 'asc' | 'desc';
  [ROLE_FIELD_NAME]?: 'asc' | 'desc';
}

export type Filters = FiltersState;

export enum PERMISSION_SELECTED_OPTION {
  VIEW = 'view',
  EDIT = 'edit',
  HIDE = 'hide',
  CONDITIONS = 'conditions',
}

export enum ROLE_PERMISSION_RESPONSE_CODE {
  NOT_FOUND = 'NOT_FOUND',
  DUPLICATE = 'DUPLICATE',
}
