import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Document } from '@/features/ArticleLayout/types';
import { useArticleUpdatedAt } from '@/stores/article.store';
import type { DocumentFetchResponse, DocumentPatchResponse } from '@/features/ArticleLayout/types';
import type { SelectItem } from '@/components/FormSelect.vue';
import { getTags } from '@/features/ArticleLayout/helpers/article';
import { CoverDeadline } from '@/features/Dashboard/types';

interface ArticleState extends Document {}

const defaultState = {
  authorId: null,
  title: '',
  dek: '',
  fulltext: '',

  // Metadata
  slug: null,
  assignment: '',
  needsOriginalArtwork: false,
  writerId: null,
  editorId: null,
  copyEditorId: null,
  coverImageDeadLineAt: null,
  coverDeadline: null,
  publishedAt: null,
  updatedAt: null,
  embargoUntil: null,
  categoryId: null,
  badgeId: null,
  superTags: [],
  tags: [],

  twitterPost: '',
  twitterExcludeRss: false,
  isExcludedFromTelegram: false,

  seoMetaKeyWords: '',
  seoMetaDescription: '',

  isBreakingNews: false,
  isShowingInMarkets: false,
  excludeFromAllRss: false,
  hideFromHotStories: false,
  hideFromMainPage: false,
  isPromoPost: false,
  hasPost: false,
  needsProofreading: false,
  canBeRepublished: false,
  isOriginalContent: false,

  // Cover
  coverArt: null,
  youtubeUrl: null,

  postUrl: '',

  words: 0,
  characters: 0,
  coverSetBy: 'No',
  approvedBy: 'No',
};

export const useArticleStore = defineStore('useArticleStore', () => {
  const state = ref<ArticleState>({ ...defaultState });

  const updatedAtStore = useArticleUpdatedAt();

  const setArticleData = (articleData: Document) => {
    const keysToSkip = ['tags', 'superTags', 'publishedAt', 'updatedAt', 'coverArt'];

    Object.keys(articleData).forEach((key) => {
      if (keysToSkip.includes(key)) {
        return;
      }

      if (key in state.value) {
        state.value[key] = articleData.hasOwnProperty(key) ? articleData[key] : state.value[key];
      }
    });

    if (articleData.publishedAt) {
      const publishDate = new Date(articleData.publishedAt);
      state.value.publishedAt = publishDate.toISOString();
    }

    state.value.updatedAt = updatedAtStore.state;

    if (articleData.tags) {
      state.value.tags = getTags({
        tags: articleData.tags || state.value.tags || [],
        isSuper: false,
      });

      state.value.superTags = getTags({
        tags: articleData.tags || state.value.tags || [],
        isSuper: true,
      });
    }

    if (articleData.coverArt?.id) {
      state.value.coverArt = articleData.coverArt.id;
    } else if (articleData.coverArt === null) {
      state.value.coverArt = ''; // Remove coverArt value, if it's null
    }
  };

  const setArticleAdditionalData = (data: DocumentFetchResponse | DocumentPatchResponse, users) => {
    state.value.words = data.words || 0;
    state.value.characters = data.characters || 0;

    const approvedByUser = users.value?.find((item: SelectItem) => item.id === data.approvedBy);
    const coverSetByUser = users.value?.find((item: SelectItem) => item.id === data.coverSetBy);

    state.value.approvedBy = approvedByUser ? approvedByUser.label : 'No';
    state.value.coverSetBy = coverSetByUser ? coverSetByUser.label : 'No';
  };

  const cleanArticleData = () => {
    state.value = { ...defaultState };
  };

  return {
    state,
    setArticleData,
    setArticleAdditionalData,
    cleanArticleData,
  };
});
