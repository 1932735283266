import { type SortField } from '@/components/Table/Filters/SortDropdown.vue';
import { DocumentStatus } from '@/types';
export const ARTICLES_RESOURCE = 'articles-resource';

export const enum QUICK_FILTERS {
  EMBARGOED = 'embargoed',
  PUBLISHED = 'published',
  SCHEDULED = 'scheduled',
  OTHER = 'other',
}

export const enum FILTERS_DATE {
  NOW = 'now',
}

export const enum ARTICLE_FLAGS {
  BREAKING = 'B',
  EMBARGOED = 'E',
  NEEDS_PROFREADING = 'PR',
  // NEEDS_ORIGINAL_COVER = 'OC',
  ORIGINAL_CONTENT = 'OC',
  SCHEDULED = 'S',
  REPUBLISHED = 'R',
}

export const FILTERS_BY_PRESET_MAP = {
  [QUICK_FILTERS.EMBARGOED]: {
    flag: [ARTICLE_FLAGS.EMBARGOED],
  },
  [QUICK_FILTERS.PUBLISHED]: {
    status: [DocumentStatus.PUBLISHED],
  },
  [QUICK_FILTERS.SCHEDULED]: {
    status: [DocumentStatus.PUBLISHED],
    'filter[published][from]': FILTERS_DATE.NOW,
  },
};

export const FILTERS_BY_FLAG_MAP = {
  [ARTICLE_FLAGS.EMBARGOED]: [
    {
      isEmbargoed: true,
    },
  ],
  [ARTICLE_FLAGS.BREAKING]: [
    {
      breaking: true,
    },
  ],
  [ARTICLE_FLAGS.NEEDS_PROFREADING]: [
    {
      needsProofreading: true,
    },
  ],
};

export const SORT_FIELDS: SortField[] = [
  {
    value: 'id',
    label: 'ID',
  },
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'coverImageDeadLineAt',
    label: 'Deadline',
  },
  {
    value: 'embargoUntil',
    label: 'Embargo',
  },
  {
    value: 'publishedAt',
    label: 'Published',
  },
  {
    value: 'createdAt',
    label: 'Created',
  },
  {
    value: 'updatedAt',
    label: 'Updated at',
  },
  {
    value: 'words',
    label: 'Words',
  },
  {
    value: 'characters',
    label: 'Characters',
  },
];

export const enum ARTICLE_COLUMNS {
  FLAGS = 'flags',
  STATUS = 'status',
  EMBARGO = 'embargo',
  DEADLINE = 'deadline',
  PUBLISHED = 'published',
  EDITOR = 'editor',
  WRITER = 'writer',
  CATEGORY = 'category',
  WORDS = 'words',

  CREATED = 'created',
  UPDATED = 'updated',
  // temporary removed
  // VIEWS = 'views',
  // SHARES = 'shares',
  COPY_EDITOR = 'copy-editor',
  AUTHOR = 'author',
  CREATOR = 'creator',
  CHARACTERS = 'characters',
  LANGUAGE = 'language',
}

interface MAPPED_COLUMN {
  column: string;
  withUnderline: boolean;
  label: string;
}

export const MAPPED_ARTICLE_COLUMNS: readonly MAPPED_COLUMN[] = [
  {
    column: ARTICLE_COLUMNS.FLAGS,
    withUnderline: false,
    label: 'Flags',
  },
  {
    column: ARTICLE_COLUMNS.STATUS,
    withUnderline: true,
    label: 'Status',
  },
  {
    column: ARTICLE_COLUMNS.EMBARGO,
    withUnderline: false,
    label: 'Embargo',
  },
  {
    column: ARTICLE_COLUMNS.DEADLINE,
    withUnderline: false,
    label: 'Cover deadline',
  },
  {
    column: ARTICLE_COLUMNS.PUBLISHED,
    withUnderline: false,
    label: 'Published',
  },
  {
    column: ARTICLE_COLUMNS.CREATED,
    withUnderline: false,
    label: 'Created',
  },
  {
    column: ARTICLE_COLUMNS.UPDATED,
    withUnderline: true,
    label: 'Updated at',
  },

  // temporary removed according to https://ctmain.slack.com/archives/C04JSK57DU1/p1723033881244309
  // {
  //   column: ARTICLE_COLUMNS.VIEWS,
  //   withUnderline: false,
  //   label: 'Views',
  // },
  // {
  //   column: ARTICLE_COLUMNS.SHARES,
  //   withUnderline: true,
  //   label: 'Shares',
  // },
  {
    column: ARTICLE_COLUMNS.EDITOR,
    withUnderline: false,
    label: 'Editor',
  },
  {
    column: ARTICLE_COLUMNS.WRITER,
    withUnderline: false,
    label: 'Writer',
  },
  {
    column: ARTICLE_COLUMNS.COPY_EDITOR,
    withUnderline: false,
    label: 'Copy editor',
  },
  {
    column: ARTICLE_COLUMNS.AUTHOR,
    withUnderline: false,
    label: 'Public author',
  },
  {
    column: ARTICLE_COLUMNS.CREATOR,
    withUnderline: true,
    label: 'Creator',
  },
  {
    column: ARTICLE_COLUMNS.CATEGORY,
    withUnderline: true,
    label: 'Category',
  },
  {
    column: ARTICLE_COLUMNS.WORDS,
    withUnderline: false,
    label: 'Words',
  },
  {
    column: ARTICLE_COLUMNS.CHARACTERS,
    withUnderline: true,
    label: 'Characters',
  },
  {
    column: ARTICLE_COLUMNS.LANGUAGE,
    withUnderline: true,
    label: 'Language',
  },
];
