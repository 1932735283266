import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { MenuItem } from '@/features/CollaborativeEditor/plugins/menu';
import { createApp, h, ref } from 'vue';
import EditImageDataModal from '@/features/UploadImage/modals/EditImageDataModal.vue';
import { TextSelection } from 'prosemirror-state';
import updateImageInFigure from '@/features/CollaborativeEditor/helpers/updateImageInFigure';
import { Node } from 'prosemirror-model';
import type { EditorView } from 'prosemirror-view';

export const IMAGE_ATTR_ALT = 'alt';
export const IMAGE_ATTR_NAME = 'name';
export const IMAGE_ATTR_TITLE = 'title';
export const IMAGE_ATTR_SRC = 'src';
export const IMAGE_ATTR_LINK = 'img_link';
export const IMAGE_ATTR_LINK_TITLE = 'img_link_title';
export const IMAGE_ATTR_LINK_NEW_TAB = 'img_link_new_tab';
export const IMAGE_ATTR_LINK_NOFOLLOW = 'img_link_nofollow';

export const ImageAttributes = [
  IMAGE_ATTR_ALT,
  IMAGE_ATTR_NAME,
  IMAGE_ATTR_TITLE,
  IMAGE_ATTR_SRC,
  IMAGE_ATTR_LINK,
  IMAGE_ATTR_LINK_NEW_TAB,
  IMAGE_ATTR_LINK_TITLE,
  IMAGE_ATTR_LINK_NOFOLLOW,
] as const;

type ImageBase = {
  [IMAGE_ATTR_ALT]: string;
  [IMAGE_ATTR_TITLE]: string;
  [IMAGE_ATTR_NAME]: string;
  [IMAGE_ATTR_SRC]?: string;
  [IMAGE_ATTR_LINK]: string | null;
  [IMAGE_ATTR_LINK_TITLE]: string | null;
  [IMAGE_ATTR_LINK_NEW_TAB]: boolean | null;
  [IMAGE_ATTR_LINK_NOFOLLOW]: boolean | null;
};

type ImageAttribute = (typeof ImageAttributes)[number];
export type ImageAttributesObject = Partial<Record<ImageAttribute, string | boolean | null>>;

export type EditorImageType = ImageBase;

const modalData = ref<{ node: any } & ImageAttributesObject>({
  src: '',
  alt: '',
  title: '',
  name: '',
  node: null,
  img_link: null,
  img_link_title: null,
  img_link_new_tab: null,
  img_link_nofollow: null,
});

let editImagePromiseResolve: (value: EditorImageType) => void;

const showEditModal = ref<boolean>(false);

export const openEditModal = () => {
  showEditModal.value = true;

  return new Promise<EditorImageType>((resolve) => {
    editImagePromiseResolve = resolve;
  });
};

export const setEditingImageAttributes = (attrs: ImageAttributesObject, node: Node) => {
  modalData.value = { ...attrs, node };
};

const closeEditModal = () => {
  showEditModal.value = false;
};

export const imagesDataHelper = (): InstallableBlock[] => {
  const itemEdit = new MenuItem({
    title: 'Image edit',
    label: 'Image edit',
    render: () => {
      const template = `<div class="w-0 h-0" id="edit-image-data-button"></div>`;
      return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
    },
    enable() {
      return true;
    },
    run(state, _, view: EditorView) {
      const { dispatch } = view;
      const { tr, doc, schema } = state;

      const applySettings = (settings: EditorImageType) => {
        const modified = updateImageInFigure(tr, dispatch, doc, schema, modalData.value, settings);
        return modified; // Return false if no node was found or updated
      };

      openEditModal().then((settings: EditorImageType) => {
        applySettings(settings);
      });
    },
  });

  const itemCaption = new MenuItem({
    title: 'Image caption',
    label: 'Image caption',
    render: () => {
      const template = `<div class="w-0 h-0" id="edit-caption-image-data-button"></div>`;
      return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
    },
    enable() {
      return true;
    },
    run(state, _, view) {
      const { dispatch } = view;
      const { tr, doc, schema, selection } = state;
      const { from } = selection;
      const src = modalData.value.src;

      let foundFigurePos: number | null = null;

      // Check all nodes for finding figure with image
      doc.descendants((node, pos) => {
        if (node.type === schema.nodes.figure) {
          if (node.content?.content.length) {
            const imageNode = node.child(0);
            if (imageNode && imageNode.type === schema.nodes.img && imageNode.attrs.src === src) {
              foundFigurePos = pos;
              return false;
            }
          }
        }
        return true;
      });

      if (foundFigurePos !== null) {
        const figureNode = doc.nodeAt(foundFigurePos);
        if (!figureNode) return false;

        let figcaptionNode: any | null = null;
        let figcaptionPos: number | null = null;

        // Check if figcaption is inside the figure
        figureNode.forEach((child, offset) => {
          if (child.type === schema.nodes.figcaption) {
            figcaptionNode = child;
            figcaptionPos = foundFigurePos! + offset + 1;
          }
        });

        // If figcaption does not exist, create one
        if (!figcaptionNode) {
          const newFigcaptionNode = schema.nodes.figcaption.create(
            null,
            schema.text('Edit caption here or remove text'),
          );
          tr.insert(foundFigurePos! + figureNode.nodeSize - 1, newFigcaptionNode);
          figcaptionPos = foundFigurePos! + figureNode.nodeSize; // Update position after inserting new figcaption
          figcaptionNode = newFigcaptionNode; // Assign the newly created figcaption node
        }

        // Set focus
        const pos = tr.doc.resolve(figcaptionPos! + figcaptionNode.content.size); // Position at the end of figcaption content
        const newSelection = TextSelection.create(tr.doc, pos.pos, pos.pos);

        tr.setSelection(newSelection);
        if (dispatch) dispatch(tr.scrollIntoView());
      }
    },
  });

  const editApp = createApp({
    setup() {
      return () =>
        h(EditImageDataModal, {
          isVisible: showEditModal.value,
          onClose: closeEditModal,
          onUpdate: (settings: EditorImageType) => {
            closeEditModal();
            editImagePromiseResolve(settings);
          },
          attrs: { ...modalData.value },
        });
    },
  });

  editApp.mount('#prosemirror-edit-image-modal');

  return [
    {
      key: 'imageDataHelper',
      item: itemEdit,
    },
    {
      key: 'imageCaptionHelper',
      item: itemCaption,
    },
  ];
};
