<script setup lang="ts">
import { useCurrentTime } from './useCurrentTime';
import { computed } from 'vue';

const useTime = useCurrentTime();
const timeUTC = computed(() => useTime.currentTimeUTC.value);
const localTime = computed(() =>
  new Date(useTime.currentLocalTime.value).toLocaleTimeString('en-us', { timeZoneName: 'short' }),
);
</script>

<template>
  <div class="relative group">
    <p class="text-[10px] leading-4 sm:text-xs bg-white rounded-full p-0.5 sm:py-1 sm:px-2 font-semibold">
      {{ timeUTC }} UTC
    </p>
    <p
      class="invisible md:group-hover:visible absolute top-7 whitespace-nowrap -left-2/3 md:left-0 md:-translate-x-1/4 text-[10px] leading-4 sm:text-xs bg-white rounded p-0.5 sm:py-1 sm:px-2"
    >
      Local time {{ localTime }}
    </p>
  </div>
</template>
