import { useQuery } from '@tanstack/vue-query';
import { users as fetchUsers } from '../api';
import type { UsersFetchPayload } from '../types';
import type { ComputedRef } from 'vue';

import { USERS_RESOURCE } from '../constants';

export const useFetchUsers = (params: ComputedRef<UsersFetchPayload>) => {
  const { data, isLoading } = useQuery({
    queryKey: [USERS_RESOURCE, params],

    queryFn: ({ queryKey }) => {
      const payload: UsersFetchPayload = queryKey[1] as UsersFetchPayload;

      return fetchUsers(payload);
    },
    staleTime: Infinity,
    refetchInterval: false,
  });

  return {
    data,
    isLoading,
  };
};
