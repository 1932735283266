<template>
  <section
    class="bg-imperium-bg-sub-base p-4 sm:rounded-2xl mt-4 relative"
    :class="[disabledClasses]"
  >
    <div class="flex gap-2">
      <div
        class="w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full bg-imperium-bg-3 text-xs font-semibold"
      >
        2
      </div>
      <div class="flex flex-col">
        <h3 class="font-semibold text-base leading-8">Role permissions 👮🏻</h3>
        <p class="text-imperium-fg-muted text-xs">
          Select the functions within the CMS that this role can edit or view.
        </p>
      </div>
    </div>
    <div class="mt-4">
      <FormInput
        :model-value="props.search"
        name="permissions-search"
        @input="emit('onSearchPermissions', $event.target.value)"
      >
        <template #prefix>
          <Search class="text-imperium-fg-muted" />
        </template>
      </FormInput>

      <RolesFormPermissionsTable
        v-if="props.permissions && props.permissions.length"
        :permissions="props.permissions"
        :is-loading="isPermissionsLoading"
        class="mt-4"
        @onPermissionUpdate="(value) => emit('onPermissionUpdate', value)"
      />
    </div>
  </section>
</template>
<script setup lang="ts">
import FormInput from '@/components/FormInput.vue';
import Search from '@/assets/icons/search.svg?component';
import RolesFormPermissionsTable from '@/features/Roles/components/RoleForm/RolesFormPermissionsTable.vue';
import type { Permission } from '@/features/Permissions/types';
import { computed } from 'vue';

interface Props {
  permissions: Permission[];
  isPermissionsLoading: boolean;
  search: string;
  isDisabled?: boolean;
}
const props = defineProps<Props>();
const emit = defineEmits<{
  onSearchPermissions: [value: string];
  onPermissionUpdate: [value: Permission];
}>();

const disabledClasses = computed(() =>
  props.isDisabled
    ? 'pointer-events-none after:absolute after:input-primary-disabled after:opacity-[0.5] after:sm:rounded-2xl after:top-0 after:bottom-0 after:right-0 after:left-0'
    : '',
);
</script>
