<template>
  <div
    class="relative w-full"
    :class="{
      'max-w-sm': !props.isFullWidth,
    }"
    :data-testid="`datepicker-${props.name}`"
  >
    <FormInput
      ref="triggerElement"
      :model-value="formattedDate"
      :name="props.name"
      :size="props.size"
      :placeholder="props.placeholder"
      :is-disabled="props.isDisabled"
      is-readonly
      @click="toggle"
    >
      <template #suffix>
        <ArrowDownIcon
          v-if="!formattedDate"
          class="absolute w-5 h-5 z-10 -right-1 text-imperium-fg-muted cursor-pointer pointer-events-none"
        />

        <CloseIcon
          v-else
          class="absolute w-5 h-5 z-10 -right-1 text-imperium-fg-muted cursor-pointer"
          data-testid="datepicker-reset"
          @click="onResetDate"
        />
      </template>
    </FormInput>

    <input
      ref="inputElement"
      datepicker
      class="invisible block absolute h-px p-0"
      :disabled="props.isDisabled"
      :data-testid="`datepicker-input-${props.name}`"
      @changeDate="onDateChange"
      @changeView="onViewChange"
    />

    <div
      ref="wrapperElement"
      class="z-[111]"
      :class="{
        [$style.datepicker]: true,
        [$style['timepicker-hidden']]: isTimepickerHidden,
      }"
    />

    <template ref="template">
      <Timepicker
        class="timepicker"
        :data-testid="`datepicker-time-${props.name}`"
        :model-value="time"
        @update:model-value="onTimeChange"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, ref, watch, watchEffect } from 'vue';
import { onClickOutside } from '@vueuse/core';
// @ts-expect-error No proper types for that
import { Datepicker as DatepickerType } from 'vanillajs-datepicker';

import ArrowDownIcon from '@/assets/icons/arrow-down.svg?component';
import CloseIcon from '@/assets/icons/close.svg?component';

import { useElementExistence } from '@/composables/useElementExistence';
import FormInput from '@/components/FormInput.vue';

import Timepicker from './Timepicker.vue';
import { DATEPICKER_VIEWS, type TimepickerItem } from './types';
import { SIZES } from '@/types';

const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    name: string;
    placeholder?: string;
    isDefaultDate?: boolean;
    isFullWidth?: boolean;
    isDisabled?: boolean;
    size?: SIZES;
  }>(),
  {
    placeholder: 'Select date',
    isDefaultDate: false,
    isFullWidth: false,
    isDisabled: false,
    size: SIZES.MEDIUM,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string | null): void;
}>();

const isLargeDesktop = inject('isLargeDesktop');
const Datepicker: DatepickerType = inject('Datepicker');

const template = ref();
const datepicker = ref<typeof Datepicker | null>(null);
const triggerElement = ref<HTMLElement | null>(null);
const inputElement = ref<HTMLElement | null>(null);
const wrapperElement = ref<HTMLDivElement | null>(null);

const { element: datepickerElement } = useElementExistence('.datepicker', wrapperElement);

const time = ref<TimepickerItem>();
const currentView = ref<DATEPICKER_VIEWS>(DATEPICKER_VIEWS.DAYS);
const formattedDate = ref<string>('');

const isDatePickerActive = ref<boolean>(false);
const isTimepickerHidden = computed(() => currentView.value !== DATEPICKER_VIEWS.DAYS);

onClickOutside(
  wrapperElement,
  () => {
    isDatePickerActive.value = false;
  },
  {
    ignore: [triggerElement],
  },
);

const toggle = () => {
  if (isDatePickerActive.value) {
    datepicker.value.hide();

    isDatePickerActive.value = false;
  } else {
    datepicker.value.show();

    isDatePickerActive.value = true;
  }
};

const formatDate = (date: Date) => {
  let hours = date.getHours() % 12;
  const half = date.getHours() >= 12 ? 'PM' : 'AM';
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });

  hours = hours === 0 ? 12 : hours;

  return `${month} ${date.getDate()}, ${date.getFullYear()} ${hours}:${minutes} ${half}`;
};

const updateDisplayValue = (selectedDate: Date) => {
  selectedDate.setHours(time.value?.hours || 0);
  selectedDate.setMinutes(time.value?.minutes || 0);
  formattedDate.value = formatDate(selectedDate);
};

const handleDateChange = (selectedDate: Date) => {
  const currentDate = new Date();
  currentDate.setTime(currentDate.getTime() + 1 * 60 * 60 * 1000);
  currentDate.setMinutes(0);
  selectedDate.setHours(currentDate.getHours());

  if (time.value) {
    currentDate.setMinutes(time.value.minutes);
    selectedDate.setHours(time.value.hours);
  } else {
    time.value = {
      hours: currentDate.getHours(),
      minutes: currentDate.getMinutes(),
    };
  }

  if (selectedDate) {
    updateDisplayValue(selectedDate);
    emits('update:modelValue', selectedDate.toISOString());
  }
};

const onDateChange = (e: CustomEvent) => {
  const selectedDate: Date = e.detail.date;

  if (!selectedDate) {
    return;
  }

  handleDateChange(selectedDate);
};

const onTimeChange = (newTime: TimepickerItem) => {
  time.value = newTime;
  let selectedDate = datepicker.value.getDate();

  if (!selectedDate) {
    selectedDate = new Date();
    datepicker.value.setDate([selectedDate]);
  }

  updateDisplayValue(selectedDate);
  emits('update:modelValue', selectedDate.toISOString());

  isDatePickerActive.value = false;
};

const onViewChange = (e: CustomEvent) => {
  const viewId: DATEPICKER_VIEWS = e.detail.viewId;

  currentView.value = viewId;
};

const handleResetDate = () => {
  time.value = {
    hours: 0,
    minutes: 0,
  };

  datepicker.value.setDate(null, {
    clear: true,
  });

  formattedDate.value = '';
};

const onResetDate = (e: MouseEvent) => {
  handleResetDate();

  emits('update:modelValue', null);
  e.preventDefault();
};

watchEffect(() => {
  if (!datepickerElement.value || !wrapperElement.value) {
    return;
  }

  // HACK: Because Datepicker don't support time selection, we need to inject
  // component inside pre-defined layout when it's ready. We also can't modify
  // layout that library create.
  const datepickerArea = wrapperElement.value.querySelector('.datepicker-picker');

  if (datepickerArea && template.value.childNodes.length) {
    datepickerArea?.appendChild(template.value.childNodes[0]);
  }
});

const setInitialValue = (initialValue: string | null) => {
  if (!datepicker.value) {
    return;
  }

  if (!initialValue) {
    handleResetDate();
    return;
  }

  const selectedDate = datepicker.value.getDate();

  if (!selectedDate || (selectedDate && initialValue !== selectedDate.toISOString())) {
    const newDate = new Date(initialValue);
    time.value = {
      hours: newDate.getHours(),
      minutes: newDate.getMinutes(),
    };
    datepicker.value.setDate(newDate);

    handleDateChange(newDate);
  }
};

onMounted(() => {
  if (datepicker.value) {
    return;
  }

  datepicker.value = new Datepicker(inputElement.value, {
    autohide: false,
    orientation: isLargeDesktop ? 'bottom auto' : 'bottom left',
    container: wrapperElement.value,
    showOnClick: false,
    showOnFocus: false,
    nextArrow: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.06993 3.09717C8.93965 2.96761 8.72842 2.96761 8.59813 3.09717L8.07915 3.61329C7.94887 3.74285 7.94887 3.95291 8.07915 4.08248L10.8606 6.85532L11.8333 7.4L10.6797 7.33646H2.33361C2.14936 7.33646 2 7.485 2 7.66823L2 8.33177C2 8.515 2.14936 8.66354 2.33361 8.66354H10.6797L11.8333 8.6L10.8606 9.14468L8.07915 11.9175C7.94887 12.0471 7.94887 12.2572 8.07915 12.3867L8.59813 12.9028C8.72842 13.0324 8.93965 13.0324 9.06993 12.9028L14 8L9.06993 3.09717Z" fill="currentColor"/>
      </svg>
    `,
    prevArrow: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.93007 3.09717C7.06035 2.96761 7.27158 2.96761 7.40187 3.09717L7.92085 3.61329C8.05113 3.74285 8.05113 3.95291 7.92085 4.08248L5.13937 6.85532L4.16667 7.4L5.32031 7.33646H13.6664C13.8506 7.33646 14 7.485 14 7.66823V8.33177C14 8.515 13.8506 8.66354 13.6664 8.66354H5.32031L4.16667 8.6L5.13937 9.14468L7.92085 11.9175C8.05113 12.0471 8.05113 12.2572 7.92085 12.3867L7.40187 12.9028C7.27158 13.0324 7.06035 13.0324 6.93007 12.9028L2 8L6.93007 3.09717Z" fill="currentColor"/>
      </svg>
    `,
  });

  if (props.isDefaultDate) {
    const currentDate = new Date();
    datepicker.value.setDate([currentDate]);
  } else {
    setInitialValue(props.modelValue);
  }
});

watch(
  () => props.modelValue,
  (newValue: string | null) => {
    setInitialValue(newValue);
  },
  { immediate: true },
);
</script>

<style lang="scss" module>
.datepicker {
  // HACK: We need to define styles via @apply because Datepicker library don't support customization
  :global(.datepicker-picker) {
    @apply relative;
  }

  :global(.datepicker-dropdown) {
    @apply z-[110];
  }

  :global(.datepicker-view) {
    @apply w-[282px];
  }

  :global(.datepicker-footer) {
    display: none;
  }

  :global(.datepicker-controls) {
    @apply h-[48px] pt-3 flex items-center justify-between;

    :global(button) {
      @apply text-imperium-fg-muted;
    }

    :global(.view-switch) {
      @apply text-xs font-semibold text-imperium-fg-base;
    }
  }

  :global(.days-of-week span) {
    @apply text-xs text-imperium-fg-muted font-semibold py-2 px-1;
  }

  :global(.datepicker-cell) {
    @apply rounded-lg text-xs text-imperium-ds-base-black font-normal py-2 px-3 [&:not(.selected):not(.focused)]:hover:bg-imperium-ds-primary-weak [&:not(.day)]:h-[32px];

    &:global(.focused) {
      @apply bg-transparent hover:text-imperium-ds-base-black;
    }

    &:global(.selected) {
      @apply bg-imperium-bg-base hover:text-imperium-ds-base-black;
    }
  }
}

.timepicker-hidden {
  :global(.timepicker) {
    @apply hidden;
  }

  :global(.datepicker-picker) {
    @apply pr-0;
  }
}
</style>
