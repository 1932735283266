<template>
  <div class="flex gap-2 bg-imperium-bg-sub-base rounded-lg shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] px-2 sm:px-4 py-3">
    <slot name="icon" />

    <div class="flex flex-col gap-1">
      <span class="text-imperium-fg-base font-semibold text-base leading-none whitespace-nowrap">
        <slot />
      </span>

      <span class="text-xs text-imperium-fg-muted">
        <slot name="time" />
      </span>
    </div>
  </div>
</template>
