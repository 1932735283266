<template>
  <ModalHolder
    data-testid="publish-embargoed-article-modal"
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    :type="MODAL_TYPES.POPUP"
    @close="emits('close')"
  >
    <div class="flex flex-col justify-center items-center">
      <ExclamationShapesIcon class="w-20 h-20 text-imperium-bg-5 mb-4" />

      <div class="text-imperium-fg-base text-base font-normal text-center">
        This article is embargoed.
        <br />
        Are you sure you want to publish it right now?
      </div>
    </div>

    <div class="flex items-center gap-4 justify-center pt-5">
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        data-testid="button-cancel"
        @click="emits('close')"
      >
        Cancel
      </Button>

      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :color="BUTTON_COLOR.RED"
        :is-loading="false"
        data-testid="button-cta"
        @click="emits('publish')"
      >
        Yes, publish now
      </Button>
    </div>
  </ModalHolder>
</template>

<script setup lang="ts">
import ExclamationShapesIcon from '@/assets/icons/exclamation-shapes.svg?component';

import Button from '@/components/Button.vue';

import ModalHolder from '@/components/ModalHolder.vue';

import { BUTTON_TYPE, SIZES, BUTTON_COLOR, MODAL_TYPES } from '@/types';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'publish'): void;
  (event: 'close'): void;
}>();
</script>
