// @see https://vue-toastification.maronato.dev/
import type { ToastOptionsAndRequiredContent, PluginOptions } from 'vue-toastification/src/types';
import { TYPE } from 'vue-toastification';

export const filterBeforeCreate = (
  toast: ToastOptionsAndRequiredContent,
  toasts: ToastOptionsAndRequiredContent[],
): ToastOptionsAndRequiredContent | false => {
  const hasSameErrorToast = toasts.some((t: ToastOptionsAndRequiredContent): boolean => {
    if (t.type !== TYPE.ERROR) return false;

    // Ensure `t.content` and `toast.content` are objects with `props` before accessing `props.id`
    const tId = typeof t.content === 'object' && 'props' in t.content ? t.content.props.id : undefined;
    const toastId = typeof toast.content === 'object' && 'props' in toast.content ? toast.content.props.id : undefined;

    if (!tId || !toastId) return false;

    return tId === toastId;
  });

  if (hasSameErrorToast) {
    return false;
  }

  // Otherwise, allow the toast
  return toast;
};

export const toastConfig: PluginOptions = {
  maxToasts: 10,
  icon: false,
  closeButton: false,
  closeOnClick: false,
  filterBeforeCreate,
};
