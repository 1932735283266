import { defineStore } from 'pinia';
import { ref, shallowRef } from 'vue';

export const useArticleUpdatedAt = defineStore('useArticleUpdatedAt', () => {
  const state = shallowRef<string>('');
  const connection = shallowRef<boolean>(true);
  const isErrored = shallowRef<boolean>(false);

  const setArticleUpdatedAt = (date: string): void => {
    state.value = date;
  };
  const setConnection = (conn: boolean): void => {
    connection.value = conn;
  };

  const setErroredStatus = (errored: boolean): void => {
    isErrored.value = errored;
  };

  return {
    setConnection,
    setArticleUpdatedAt,
    setErroredStatus,
    state,
    connection,
    isErrored,
  };
});
