import type { NavigationGuard } from 'vue-router';
import { UserService } from '@/services/user';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { toRaw } from 'vue';
import { isAuthenticated } from '@/services/auth';
import { useLanguagesStore } from '@/stores/languages.store';
import { LanguagesService } from '@/services/languages';

export const authGuard: NavigationGuard = async (to, from, next) => {
  const redirectedFrom = !isAuthenticated() && to.redirectedFrom ? to.redirectedFrom.fullPath : null;

  if (!isAuthenticated() && redirectedFrom) {
    sessionStorage.setItem('prevPath', redirectedFrom);
  }

  if (!to.meta.auth) {
    next();
    return;
  }

  if (!isAuthenticated()) {
    next({ name: 'Login' });
    return;
  }

  // refresh permissions after page reloaded
  if (isAuthenticated()) {
    const userPermissions = useUserPermissions();
    const userState = useUserStore();
    const languagesStore = useLanguagesStore();

    if (!toRaw(userPermissions.state) || !userState.state) {
      const user = await UserService.fetchUser();
      userPermissions.setUserPermissions(user.permissions);
      userPermissions.setUserId(user.id);
      userState.setUserState(user);
    }

    if (!languagesStore.state) {
      const languages = await LanguagesService.fetchLanguages();
      languagesStore.setLanguages(languages);
    }
  }

  if (isAuthenticated() && sessionStorage.getItem('prevPath')) {
    next({ path: sessionStorage.getItem('prevPath') });
    sessionStorage.clear();
    return;
  } else if (isAuthenticated()) {
    next();
    return;
  }

  next({ name: 'Login' });
  return;
};
