<template>
  <div
    class="rounded-lg flex justify-between bg-imperium-ds-primary-trace text-imperium-fg-strong text-base p-2 min-h-[54px]"
  >
    <div
      ref="chiefMessage"
      data-testid="dashboard-chief-message"
      class="w-full pr-14"
    >
      {{ t('dashboard.chief-message.label') }} [Content goes here]
    </div>

    <Button
      class="absolute top-2 right-2 !p-2 !mr-0"
      data-testid="dashboard-chief-message-edit-button"
      @click="onEditChiefMessageClick"
    >
      <template #rightIcon>
        <EditIcon class="w-5 h-5" />
      </template>
    </Button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import Button from '@/components/Button.vue';
import EditIcon from '@/assets/icons/edit-icon.svg?component';

const emits = defineEmits<{
  (event: 'edit'): void;
}>();

const { t } = useI18n();

const chiefMessage = ref<HTMLElement | null>(null);

const onEditChiefMessageClick = () => {
  if (!chiefMessage.value) {
    return;
  }

  emits('edit');
};
</script>
