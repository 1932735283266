<template>
  <div class="relative">
    <div
      :class="{
        'flex space-x-2 mb-0': props.labelPosition === INPUT_LABEL_POSITION.LEFT,
      }"
    >
      <label
        v-if="isLabel"
        :class="
          labelClass({
            size: props.size,
            labelPosition: props.labelPosition,
            isDisabled: props.isDisabled,
            isErrored: props.isErrored,
            isSuccess: props.isSuccess,
          })
        "
        :style="{ 'min-width': props.labelWidth ? props.labelWidth : 'auto' }"
      >
        <slot name="label" />
        <template v-if="props.isRequired">*</template>
      </label>
      <div class="w-full">
        <button
          :id="`${props.name}-multi-dropdown`"
          ref="triggerElement"
          :class="
            inputClass({
              rounded: props.rounded,
              size: props.size,
              visualType: props.visualType,
              isErrored: props.isErrored,
              isDisabled: props.isDisabled,
              isSuccess: props.isSuccess,
            })
          "
          :data-testid="`${props.name}-multi-dropdown`"
          class="border relative w-full pr-10"
          type="button"
        >
          <div>
            <div v-if="!props.isDisabled">
              <FormInput
                v-model="searchString"
                :is-disabled="isDisabled"
                :is-errored="false"
                :name="props.name"
                :placeholder="props.placeholder"
                :size="props.size"
                :visual-type="INPUT_TYPE.INVISIBLE"
                class="w-full"
                type="text"
                @blur="onFocused(false)"
                @focus="onFocused(true)"
              />
            </div>

            <div
              v-if="currentValues?.length"
              class="p-2 flex flex-wrap gap-1"
              :class="{
                'truncate w-full': props.singleLine,
              }"
            >
              <div
                v-for="value of currentValues"
                :key="value.id"
                :class="{
                  'w-[inherit]': props.singleLine,
                }"
              >
                <Tag
                  :id="value.id"
                  :is-disabled="isDisabled"
                  :is-closable="!props.isDisabled"
                  :label="value.label"
                  :single-line="props.singleLine"
                  @click="onRemoveValue(value.id)"
                />
              </div>
            </div>
          </div>

          <div
            :class="{
              'right-3 top-2': props.size === SIZES.MEDIUM && !props.isDisabled,
              'right-3 top-2.5': props.size === SIZES.MEDIUM && props.isDisabled,
              'right-2 top-1.5': props.size === SIZES.SMALL,
            }"
            class="absolute z-10 text-imperium-fg-muted flex"
          >
            <ArrowDown
              :class="
                arrowClass({
                  size: props.size,
                  isDisabled: props.isDisabled,
                })
              "
              class="text-imperium-fg-muted"
            />
          </div>
        </button>

        <div
          ref="dropdownElement"
          :data-testid="`${props.name}-multi-dropdown-menu`"
          class="z-20 divide-y hidden bg-imperium-bg-sub-base rounded-lg shadow w-full"
        >
          <div
            :aria-labelledby="`${props.name}Button`"
            class="p-2 flex flex-wrap"
          >
            <div
              v-for="value in showedValues"
              :key="value.id"
              class="mb-1 mr-1"
              @click="onSelect(value)"
            >
              <Tag
                :id="value.id"
                :is-closable="false"
                :single-line="props.singleLine"
                :label="value.label"
              />
            </div>
            <p
              v-if="!showedValues.length"
              class="pt-2 px-3 pb-3 overflow-y-auto dark:text-gray-300 scrollbar-thin text-sm font-medium text-gray-900"
            >
              No options
            </p>
          </div>
        </div>

        <div
          v-if="isErrored"
          class="mt-1"
          :class="
            labelClass({
              size: props.size,
              isErrored: props.isErrored,
            })
          "
        >
          <slot name="error" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type Ref, ref, watch, useSlots, watchEffect } from 'vue';
import { Dropdown, type DropdownInterface } from 'flowbite';

import { useDropdown } from '@/composables/useDropdown';

import Tag from '@/components/Tag.vue';
import FormInput from './FormInput.vue';

import ArrowDown from '@/assets/icons/arrow-down.svg?component';
import { INPUT_LABEL_POSITION, INPUT_ROUNDED, INPUT_TYPE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';

export interface MultidropdownItem {
  id: number;
  label: string;
}

const props = withDefaults(
  defineProps<{
    name: string;
    modelValue: number[];
    values: MultidropdownItem[];

    attrs?: Record<string, unknown>;
    placeholder?: string;
    isDisabled?: boolean;
    // in case you need selected value be in a single line
    singleLine?: boolean;

    isRequired?: boolean;
    isErrored?: boolean;
    isSuccess?: boolean;
    returnFullValue?: boolean;
    isHandleSearchInput?: boolean;

    size?: SIZES;
    labelPosition?: INPUT_LABEL_POSITION;
    labelWidth?: string;
    rounded?: INPUT_ROUNDED;
    visualType?: INPUT_TYPE;
  }>(),
  {
    placeholder: 'Search',

    isDisabled: false,
    isRequired: false,
    isErrored: false,
    isSuccess: false,
    returnFullValue: false,
    isHandleSearchInput: false,

    size: SIZES.MEDIUM,
    labelPosition: INPUT_LABEL_POSITION.TOP,
    rounded: INPUT_ROUNDED.DEFAULT,
    visualType: INPUT_TYPE.PRIMARY,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: number[] | MultidropdownItem[]): void;
  (event: 'search', value: string): void;
}>();

const slots = useSlots();
const isLabel = computed(() => !!slots.label);

const currentValues = ref<MultidropdownItem[]>([]);
const searchString = ref<string>('');

const isFocused = ref<boolean>(false);

watch([searchString], () => {
  emits('search', searchString.value);
});

watchEffect(() => {
  if (props.isHandleSearchInput) {
    currentValues.value = props.modelValue;
  } else {
    currentValues.value = props.values?.filter((item) => props.modelValue.includes(item.id));
  }
});

const arrowClass = tv({
  base: 'text-imperium-fg-muted',
  variants: {
    size: {
      [SIZES.SMALL]: 'w-5 h-5',
      [SIZES.MEDIUM]: 'w-6 h-6',
    },
  },
});

const labelClass = tv({
  base: 'inline-block block input-meta-text-default w-fit',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-meta-text-sm',
      [SIZES.MEDIUM]: 'input-meta-text-md',
    },
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isSuccess: {
      true: 'input-meta-text-success',
    },
    isDisabled: {
      true: 'input-meta-text-disabled',
    },
    isInfo: {
      true: 'input-meta-helper-text',
    },
    labelPosition: {
      [INPUT_LABEL_POSITION.TOP]: 'mb-2',
      [INPUT_LABEL_POSITION.LEFT]: 'mt-2.5 text-right',
    },
  },
});

const inputClass = tv({
  base: 'block w-full text-ellipsis',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-meta-text-sm min-h-8',
      [SIZES.MEDIUM]: 'input-meta-text-md min-h-10',
    },
    rounded: {
      [INPUT_ROUNDED.DEFAULT]: 'input-rounded-default',
      [INPUT_ROUNDED.FULL]: 'input-rounded-full',
    },
    visualType: {
      [INPUT_TYPE.PRIMARY]: 'input-primary',
      [INPUT_TYPE.PLAIN]: 'input-plain',
    },
  },

  compoundVariants: [
    {
      isDisabled: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-disabled',
    },
    {
      isDisabled: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-disabled',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-success',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-success',
    },
  ],
});

const dropdownElement = ref<HTMLElement | null>(null);
const triggerElement = ref<HTMLElement | null>(null);

const dropdown: Ref<Dropdown> = useDropdown({
  dropdownElement: dropdownElement,
  triggerElement: triggerElement,
  id: props.name,
  options: {
    onShow: (tooltip: DropdownInterface) => {
      if (props.isDisabled || !showedValues.value?.length) {
        tooltip.hide();
      }
    },
  },
});

const showedValues = computed(() => {
  if (!props.values) {
    return [];
  }

  const filteredValues = props.values?.filter(
    (item: MultidropdownItem) => !currentValues.value.map((currentItem) => currentItem.id).includes(item.id),
  );

  if (props.isHandleSearchInput) {
    return filteredValues;
  }

  if (searchString.value) {
    return filteredValues.filter((item: MultidropdownItem) =>
      item.label.toLowerCase().includes(searchString.value.toLowerCase()),
    );
  }

  return filteredValues;
});

const onRemoveValue = (id: number) => {
  currentValues.value = currentValues.value.filter((item) => item.id !== id);

  const tags = props.returnFullValue ? currentValues.value : currentValues.value.map((item) => item.id);
  emits('update:modelValue', tags);
};

const onSelect = ({ id, label }: MultidropdownItem) => {
  searchString.value = '';
  dropdown?.value?.hide();
  currentValues.value.push({ id, label });
  const tags = props.returnFullValue ? currentValues.value : currentValues.value.map((item) => item.id);
  emits('update:modelValue', tags);
};

const onFocused = (value: boolean) => {
  if (!props.isDisabled) {
    isFocused.value = value;
  }
};
</script>
