<template>
  <article
    data-testid="dashboard-table-activity"
    class="bg-imperium-bg-sub-base shadow-imperium-base rounded-2xl w-full sm:w-auto"
  >
    <header class="flex items-center gap-2 px-2 sm:px-5 py-4">
      <StatsIcon :theme="THEMES.SECONDARY">
        <ClockArrowIcon class="w-5 h-5" />
      </StatsIcon>

      <h3 class="text-gray-900 font-semibold text-md sm:text-xl leading-none">
        {{ t('dashboard.tables.activity') }}
      </h3>
      <Button
        to=""
        data-testid="dashboard-table-activity-button"
        :visual-type="BUTTON_TYPE.GHOST"
        :size="SIZES.SMALL"
        class="ml-auto whitespace-nowrap"
        is-link
      >
        {{ t('common.view-all', { count: 130 }) }}
      </Button>
    </header>

    <div class="relative overflow-x-auto">
      <div class="px-2 sm:px-5 py-2 border-t">
        <Table>
          <TableBody>
            <TableRow
              v-for="(row, index) in props.values"
              :key="`table-01-${index}`"
              class="last:border-b-0 group"
              :bordered="TABLE_BORDERED.BOTTOM"
              :striped="TABLE_STRIPED.STRIPED"
            >
              <TableCell
                class="grid grid-flow-row group-hover:bg-imperium-bg-4"
                is-full-width
              >
                <div class="flex flex-col xs:flex-row gap-0 xs:gap-2 mb-1 whitespace-nowrap overflow-hidden">
                  <span>{{ row.author }}</span>
                  <span class="text-imperium-fg-subtle">{{ row.action }}</span>
                  <span class="text-ellipsis overflow-hidden block min-w-[80px]">{{ row.title }}</span>
                </div>

                <div class="text-imperium-fg-subtle">{{ row.changed }}</div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import ClockArrowIcon from '@/assets/icons/file-success.svg?component';
import Button from '@/components/Button.vue';
import { Table, TableBody, TableRow, TableCell } from '@/components/Table';
import { THEMES, SIZES, BUTTON_TYPE, TABLE_BORDERED, TABLE_STRIPED } from '@/types';

import StatsIcon from './StatsIcon.vue';

const props = defineProps<{
  values: Record<string, string>[];
}>();

const { t } = useI18n();
</script>
