<template>
  <div class="flex">
    <div
      v-for="tab in tabs"
      :key="tab.id"
    >
      <div
        :class="{
          'border-b-2 border-b-imperium-bg-base cursor-default text-imperium-fg-strong': tab.id === activeTabId,
          'cursor-pointer text-imperium-fg-base': tab.id !== activeTabId,
        }"
        class="h-10 p-2 font-semibold transition"
        @click="onChangeTab(tab.id)"
      >
        {{ tab.title }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import type { NavTab } from '@/types';

const props = defineProps<{
  tabs: NavTab[];
}>();

const activeTabId = ref<string>();

defineExpose({
  changeActiveTab: (id: string) => (activeTabId.value = id),
});

const emits = defineEmits<{
  (event: 'choose', id: string): void;
}>();

watchEffect(() => {
  if (!activeTabId.value) {
    activeTabId.value = props.tabs[0].id;
    emits('choose', activeTabId.value);
  }
});

const onChangeTab = (id: string) => {
  emits('choose', id);
  activeTabId.value = id;
};
</script>
