import { userReset2FA } from '@/features/Users/api';
import { type MutateFunction, useMutation } from '@tanstack/vue-query';
import type { UserReset2FAResponse } from '@/features/Users/types';
import type { GenericError } from '@/types/exceptions';
import type { Ref } from 'vue';
import type { AUTH_ERROR_CODES } from '@/features/Auth/types';

export function useResetTwoFa(
  onSuccess?: (data: UserReset2FAResponse) => void,
  onError?: (data: GenericError) => void,
): {
  reset2FAMutate: (
    ...options: Parameters<
      MutateFunction<
        UserReset2FAResponse,
        Error & { data: { errorCode: AUTH_ERROR_CODES; errorMessage: string } },
        number,
        unknown
      >
    >
  ) => void;
  isError: Ref<boolean>;
  isPending: Ref<boolean>;
  error: Ref<null> | Ref<Error & { data: { errorCode: AUTH_ERROR_CODES; errorMessage: string } }>;
  isSuccess: Ref<boolean>;
} {
  const {
    isPending,
    isError,
    error,
    isSuccess,
    mutate: reset2FAMutate,
  } = useMutation({
    mutationFn: (userId: number) => userReset2FA(userId),
    onSuccess,
    onError,
  });

  return {
    isPending,
    isError,
    error,
    isSuccess,
    reset2FAMutate,
  };
}
