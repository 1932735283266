import { computed, watch } from 'vue';
import { useArticleUpdatedAt } from '@/stores/article.store';
import { useToast } from '@/composables';

export const useOfflineEditor = () => {
  const articleUpdatedAt = useArticleUpdatedAt();
  const toast = useToast();

  const handleOfflineEditor = (newConnectionVal: boolean, oldConnectionVal: boolean): void => {
    if (newConnectionVal === oldConnectionVal) return;
    if (oldConnectionVal && !newConnectionVal) {
      toast.error({
        id: 'ERROR_EDITOR_IS_OFFLINE',
        title: 'Refresh the page',
        message:
          'We’ve temporarily lost connection to the article’s text. Please refresh the page to continue working; your last edits have been saved.',
      });
    }
  };

  const isEditorOffline = computed(() => {
    return !articleUpdatedAt.connection;
  });

  watch(() => articleUpdatedAt.connection, handleOfflineEditor);

  return {
    isEditorOffline,
  };
};
