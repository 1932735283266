import { useQuery } from '@tanstack/vue-query';
import { fetchBadges } from '../api';
import { type ArticleBadgesFetchRequest } from '../types';
import { type Ref } from 'vue';

import { ARTICLE_BADGES_RESOURCE } from '../constants';

export const useFetchBadges = (params: Ref<ArticleBadgesFetchRequest>) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [ARTICLE_BADGES_RESOURCE, params],
    queryFn: ({ queryKey }) => {
      const payload: ArticleBadgesFetchRequest = queryKey[1] as ArticleBadgesFetchRequest;
      if (queryKey[1].category) {
        payload['filter[categoryId]'] = queryKey[1].category;
        delete payload.category;
      }
      return fetchBadges(payload);
    },
    staleTime: Infinity,
    refetchInterval: false,
  });

  return {
    data,
    isLoading,
    refetch,
  };
};
