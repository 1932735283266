import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import { LABEL_FIELD_NAME, LABEL_FIELD_DESCRIPTION } from '@/features/Labels/constants';

const labelFormSchema = zod.object({
  [LABEL_FIELD_NAME]: zod.string().min(1, { message: 'This is required' }),
  [LABEL_FIELD_DESCRIPTION]: zod.string().min(1, { message: 'This is required' }),
});

export const labelFormTypedSchema: unknown = toTypedSchema(labelFormSchema);
