const ENTER_KEYCODE = 13;

export const preventLinebreaks = (evt: KeyboardEvent) => {
  if (evt.which === ENTER_KEYCODE) {
    evt.preventDefault();
  }
};

export const clearHtmlEntities = (evt: ClipboardEvent) => {
  evt.preventDefault();
  var text = evt.clipboardData?.getData('text/plain') || '';
  document.execCommand('insertText', false, text.replace(/(\r\n|\n|\r)/gm, ''));
};
