import HighlightIcon from '@/assets/icons/editor/highlight.svg?raw';

import { markItem } from '@/features/CollaborativeEditor/utils/blocks';
import type { MarkType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';

export const buildHighlight = (mark: MarkType): InstallableBlock => {
  const underlineIconNode = new DOMParser().parseFromString(HighlightIcon, 'text/html').body.firstElementChild;

  return {
    key: 'toggleHighlight',
    item: markItem(mark, {
      title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.HIGHLIGHT),
      icon: {
        dom: underlineIconNode as Node,
      },
    }),
  };
};
