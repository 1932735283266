<template>
  <div>
    <div class="flex gap-2 my-4 items-center justify-end">
      <Button
        :color="BUTTON_COLOR.DEFAULT"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
      >
        Cancel
      </Button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        type="submit"
        @click="onSubmit"
      >
        <template v-if="isEditing"> Save </template>
        <template v-else> Save a new label </template>
      </Button>
    </div>

    <section class="bg-imperium-bg-sub-base p-4 sm:rounded-2xl">
      <TitleHelper
        title="General settings"
        badge-number="1"
        description="Make this category eassily indentifiable"
      />
      <div class="flex mt-4 full-width gap-4">
        <div class="flex flex-col basis-1/2">
          <FormInput
            v-model="title"
            :attrs="titleAttrs"
            :is-errored="!!errors[LABEL_FIELD_NAME]"
          >
            <template #label> Label name * </template>
            <template #error>{{ errors[LABEL_FIELD_NAME] }}</template>
          </FormInput>
          <FormSelect
            model-value=""
            class="mt-4"
            name="category-select"
          >
            <template #label> Category * </template>
            <template #help>
              <span class="text-imperium-fg-muted text-xs">https://cointelegraph.com/category/news</span>
            </template>
          </FormSelect>
          <p class="text-xs mt-1 text-imperium-fg-muted">The label will only be visible in the assigned category.</p>
        </div>

        <div class="flex flex-col basis-1/2">
          <FormTextarea
            v-model="description"
            :attrs="descriptionAttrs"
            :is-errored="!!errors[LABEL_FIELD_DESCRIPTION]"
            name="label_description"
            min-height="280px"
          >
            <template #label> Label description * </template>
            <template #error> {{ errors[LABEL_FIELD_DESCRIPTION] }}</template>
          </FormTextarea>
          <p class="text-xs mt-1 text-imperium-fg-muted">
            Brief description of the label's main differences between other labels.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
// TODO: WHAT CANCEL BUTTON SHOULD DO
import TitleHelper from '@/components/TitleHelper.vue';
import FormInput from '@/components/FormInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import FormSelect from '@/components/FormSelect.vue';
import { labelFormTypedSchema } from '@/features/Labels/validators';
import { useFormData, useToast } from '@/composables';
import { computed, onMounted } from 'vue';
import { LabelTranslate } from '@/features/Labels/types';
import { LABEL_FIELD_DESCRIPTION, LABEL_FIELD_ID, LABEL_FIELD_NAME } from '@/features/Labels/constants';
import { useRouter } from 'vue-router';
import { useUpdateLabel } from '@/features/Labels/queries/useMutateLabel';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';

interface Props {
  data: LabelTranslate | null;
}

const props = withDefaults(defineProps<Props>(), {
  data: null,
});

const toast = useToast();
const router = useRouter();

const { defineField, resetForm, handleSubmit, errors } = useFormData({
  data: props.data,
  validator: labelFormTypedSchema,
});

const isEditing = computed(() => !!props.data);

const { mutate } = useUpdateLabel({
  onSuccess: (label) => {
    toast.success({
      id: 'LABEL_DATA_SAVE_SUCCESS',
      message: 'Data was saved',
    });

    if (!isEditing.value) {
      router.push({
        name: 'labels-edit',
        params: {
          id: label[LABEL_FIELD_ID],
        },
      });
    }
  },
  onError: ({ data }) => {
    const { errorMessage, errorCode } = data;

    toast.error({
      id: errorCode,
      message: errorMessage,
    });
  },
});

const [title, titleAttrs] = defineField(LABEL_FIELD_NAME);
const [description, descriptionAttrs] = defineField(LABEL_FIELD_DESCRIPTION);

const onSubmit = handleSubmit((values) => {
  mutate({ label: values, labelId: props?.data?.[LABEL_FIELD_ID] || null });
});

onMounted(async () => {
  if (props.data) {
    resetForm({ values: props.data ?? {} });
  }
});
</script>
