import { isIOS } from '@vueuse/core';
import { isMac } from '@/utils';

export enum ProseMirrorMenuLabel {
  H2 = 'H2',
  H3 = 'H3',
  B = 'B',
  I = 'I',
  U = 'U',
  STRIKETHROUGH_TEXT = 'STRIKETHROUGH_TEXT',
  CLEAR_FORMATTING = 'CLEAR_FORMATTING',
  UNDO = 'UNDO',
  REDO = 'REDO',
  BULLET_LIST = 'BULLET_LIST',
  NUMBERED_LIST = 'NUMBERED_LIST',
  INSERT_LINK = 'INSERT_LINK',
  HIGHLIGHT = 'HIGHLIGHT',
  NON_BREAKABLE = 'NON_BREAKABLE',
}

enum ProseMirrorMenuTooltipMacTitles {
  H2 = 'Heading 2 (⌘+Option+2)',
  H3 = 'Heading 3 (⌘+Option+3)',
  B = 'Bold (⌘B)',
  I = 'Italic (⌘I)',
  U = 'Underline text (⌘U)',
  CLEAR_FORMATTING = 'Clear formatting (⌘\\ or ⌘+Space)',
  STRIKETHROUGH_TEXT = 'Strikethrough text (⌘+Shift+X)',
  UNDO = 'Undo (⌘Z)',
  REDO = 'Redo (⌘Y)',
  BULLET_LIST = 'Bulleted list (⌘+Shift+8)',
  NUMBERED_LIST = 'Numbered list (⌘+Shift+7)',
  INSERT_LINK = 'Insert link (⌘K)',
  NON_BREAKABLE = '⌘J',
  HIGHLIGHT = 'Highlight',
}

enum ProseMirrorMenuTooltipWindowsTitles {
  H2 = 'Heading 2 (Ctrl+Option+2)',
  H3 = 'Heading 3 (Ctrl+Option+3)',
  B = 'Bold (Ctrl+B)',
  I = 'Italic (Ctrl+I)',
  U = 'Underline text (Ctrl+U)',
  CLEAR_FORMATTING = 'Clear formatting (Ctrl+\\ or Ctrl+Space)',
  STRIKETHROUGH_TEXT = 'Strikethrough text (Ctrl+Shift+X)',
  UNDO = 'Undo (Ctrl+Z)',
  REDO = 'Redo (Ctrl+Y)',
  BULLET_LIST = 'Bulleted list (Ctrl+Shift+8)',
  NUMBERED_LIST = 'Numbered list (Ctrl+Shift+7)',
  INSERT_LINK = 'Insert link (Ctrl+K)',
  NON_BREAKABLE = 'Ctrl+J',
  HIGHLIGHT = 'Highlight',
}

export const getProseMirrorMenuTitle = (menuName: ProseMirrorMenuLabel) => {
  return isMac() ? ProseMirrorMenuTooltipMacTitles[menuName] : ProseMirrorMenuTooltipWindowsTitles[menuName];
};
