import type { AuthorByIdFetchRequest } from '../types';
import { useQuery } from '@tanstack/vue-query';
import { ARTICLE_TAGS_RESOURCE } from '@/features/ArticleLayout/constants';
import { fetchAuthorById } from '@/features/ArticleLayout/api';
import { type Ref } from 'vue';
export const useFetchAuthorById = (params: Ref<AuthorByIdFetchRequest>) => {
  const { data, isLoading } = useQuery({
    queryKey: [ARTICLE_TAGS_RESOURCE, params],
    queryFn: ({ queryKey }) => {
      const payload: AuthorByIdFetchRequest = queryKey[1] as AuthorByIdFetchRequest;

      return fetchAuthorById(payload.authorId, payload.languageId);
    },

    staleTime: Infinity,
    refetchInterval: false,
  });

  return {
    data,
    isLoading,
  };
};
