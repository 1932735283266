<template>
  <Dropdown
    id="article-columns"
    placement="bottom-start"
  >
    <template #button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        @click="emits('toggle')"
      >
        <template #leftIcon>
          <SettingsIcon class="w-6 h-6 text-imperium-fg-base" />
        </template>
        <ArrowDownIcon class="w-5 h-5 text-imperium-fg-base" />
      </Button>
    </template>

    <template #menu>
      <div class="w-96 p-4 flex gap-2">
        <div class="flex flex-col gap-4 w-full">
          <div class="flex gap-4">
            <Button
              :size="SIZES.SMALL"
              :visual-type="BUTTON_TYPE.BASE"
              :color="BUTTON_COLOR.BASE"
              is-full-width
              @click="emits('reset')"
            >
              Reset to default
            </Button>
            <Button
              :size="SIZES.SMALL"
              :visual-type="BUTTON_TYPE.BASE"
              :color="BUTTON_COLOR.BASE"
              is-full-width
              @click="onSelectAll"
            >
              Select all
            </Button>
          </div>
          <div class="flex flex-col flex-wrap gap-2 max-h-[340px]">
            <template
              v-for="(column, index) in MAPPED_ARTICLE_COLUMNS"
              :key="index"
            >
              <FormCheckbox
                :model-value="filtersStore.isColumnSelected(column.column)"
                name="show-flags"
                :size="SIZES.SMALL"
                class="w-1/2"
                @update:model-value="emits('select', column.column)"
              >
                <span class="text-base capitalize">{{ column.label }}</span>
              </FormCheckbox>
              <div
                v-if="column.withUnderline"
                class="h-px bg-imperium-border-weak mb-1 w-1/2"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import Dropdown from '@/components/Dropdown.vue';
import Button from '@/components/Button.vue';

import SettingsIcon from '@/assets/icons/settings.svg?component';
import ArrowDownIcon from '@/assets/icons/arrow-down.svg?component';

import { SIZES, BUTTON_COLOR, BUTTON_TYPE } from '@/types';
import { useArticlesFilters } from '@/features/Articles/stores/filters.store';
import { ARTICLE_COLUMNS, MAPPED_ARTICLE_COLUMNS } from '../constants';

import FormCheckbox from '@/components/FormCheckbox.vue';
import { computed } from 'vue';

const emits = defineEmits<{
  (event: 'toggle'): void;
  (event: 'select', column: ARTICLE_COLUMNS): void;
  (event: 'reset'): void;
}>();

const filtersStore = useArticlesFilters();

const filterColumns = computed(() => filtersStore.enabledColumns);

const allColumnsSelected = computed<boolean>(
  () =>
    Object.keys(ARTICLE_COLUMNS).length === Object.keys(filterColumns.value).length &&
    Object.values(filterColumns.value).every((val) => val),
);
const onSelectAll = () => {
  if (allColumnsSelected.value) return;

  for (const column in ARTICLE_COLUMNS) {
    if (!filterColumns.value[column.toLowerCase()]) {
      emits('select', ARTICLE_COLUMNS[column]);
    }
  }
};
</script>
