import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Language } from '@/types';

export const useLanguagesStore = defineStore(
  'useLanguagesStore',
  () => {
    const state = ref<Language[]>();

    const setLanguages = (languages: Languages[]): void => {
      state.value = languages;
    };

    const clearLanguages = (): void => {
      state.value = null;
    };

    return {
      state,
      setLanguages,
      clearLanguages,
    };
  },
  { persist: true },
);
