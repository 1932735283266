import { isMac } from '@/utils';

let keyDownFunction: (event: any) => void;
let keyUpFunction: (event: any) => void;

const clickOnMenuButton = (id: string) => {
  const menuButton = document.getElementById(id);
  menuButton?.click();
};

export const addMenuHotKeysListener = () => {
  let isCommandPressed = false;
  let isOptionPressed = false;
  let isShiftPressed = false;

  document.addEventListener(
    'keydown',
    (keyDownFunction = (event) => {
      if (event.key === 'Meta') {
        isCommandPressed = true;
      }
      if (event.key === 'Alt') {
        isOptionPressed = true;
      }
      if (event.key === 'Shift') {
        isShiftPressed = true;
      }

      if (isCommandPressed) {
        // Link
        if (event.key === 'K' || event.key === 'k') {
          clickOnMenuButton('add-link-button');
        }
      }

      if (isCommandPressed && isShiftPressed) {
        // Strike
        if (event.key === 'X' || event.key === 'x') {
          clickOnMenuButton('strike-text-button');
        }

        // Bullet list
        if (event.key === '7') {
          clickOnMenuButton('add-ordered-list-button');
        }

        // Ordered list
        if (event.key === '8') {
          clickOnMenuButton('add-list-button');
        }
      }

      if (isCommandPressed && isOptionPressed) {
        // Heading 2
        if (event.key === '2' || isMac() ? event.key === '™' : event.key === '@') {
          clickOnMenuButton('make-head2-button');
        }

        // Heading 3
        if (event.key === '3' || isMac() ? event.key === '£' : event.key === '#') {
          clickOnMenuButton('make-head3-button');
        }
      }
    }),
  );

  document.addEventListener(
    'keyup',
    (keyUpFunction = (event) => {
      if (event.key === 'Meta') {
        isCommandPressed = false;
      }
      if (event.key === 'Alt') {
        isOptionPressed = false;
      }
      if (event.key === 'Shift') {
        isShiftPressed = false;
      }
    }),
  );
};

export const removeMenuHotKeysListeners = () => {
  document.removeEventListener('keydown', keyDownFunction);
  document.removeEventListener('keyup', keyUpFunction);
};
