<script setup lang="ts">
import { CoverDeadline, CoverDeadlineOptions } from '@/features/CreateArticle/types';
import { useUserPermissions } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import { useI18n } from 'vue-i18n';
import FormSelect from '@/components/FormSelect.vue';
import { watchEffect } from 'vue';
import { SIZES } from '@/types';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    isBreakingNews: boolean;
  }>(),
  {},
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: number | string): void;
}>();

const { documentMetadataCoverDeadlineView, documentMetadataCoverDeadlineEdit } = useUserPermissions();

const { metadataCoverCondition } = useUserConditions();

const { t } = useI18n();

watchEffect(() => {
  if (props.isBreakingNews) {
    emits('update:modelValue', CoverDeadline['10_MINUTES']);
  }
});
</script>

<template>
  <FormSelect
    v-if="documentMetadataCoverDeadlineView"
    :model-value="props.modelValue"
    is-required
    name="coverDeadlineId"
    class="mb-2"
    :size="SIZES.SMALL"
    :is-disabled="props.isBreakingNews || (!documentMetadataCoverDeadlineEdit)"
    :values="CoverDeadlineOptions"
    @update:model-value="(value) => emits('update:modelValue', value)"
  >
    <template #label>
      {{ t('article.cover-deadline') }}
    </template>
  </FormSelect>
</template>
