/**
 * Counts the number of words in a given text.
 *
 * @param text - The text to count words in. Should be a non-null string.
 * @returns The number of words in the text. Returns 0 if the text is empty or contains only whitespace.
 */
export const getCountOfWords = (text: string): number => {
  const trimmedText: string = text?.trim() ?? '';

  if (trimmedText === '') {
    return 0;
  }

  const words: string[] = trimmedText.split(/\s+/);

  return words.length;
};
