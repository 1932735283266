<template>
  <ModalHolder
    data-testid="unpublish-article-modal"
    :is-visible="props.isVisible"
    :type="MODAL_TYPES.MODAL"
    @close="emits('close')"
  >
    <template #title> Ready to publish? </template>

    <div class="flex flex-col justify-stretch items-stretch mt-4">
      <div class="mb-3 text-imperium-fg-base">Pay special attention to all important settings.</div>
      <ArticleUrlInput
        :article-url="articleStore.state?.slug || ''"
        :article-title="articleStore.state?.title || ''"
        name="publishArticleSlug"
        :size="SIZES.MEDIUM"
        :is-disabled="slugCanBeEdit"
        :has-post="slugCanBeEdit"
        :is-readonly="slugCanBeEdit"
        @update:article-url="onUrlChange"
      />

      <Toast
        id="publish-modal-disclaimer"
        message="Once the article is published, the URL slug becomes permanent and cannot be changed. Make sure to finalize any adjustments to the slug prior to publication."
        :theme="THEMES.WARNING"
        :is-closable="false"
        is-full-width
      />

      <div class="flex flex-wrap gap-2 mt-3">
        <Button
          v-if="!isEmbargoMode"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="isEmbargoMode = true"
        >
          <template #leftIcon>
            <PlusIcon class="w-4 h-4" />
          </template>

          Embargo
        </Button>

        <div
          v-else
          class="w-full"
        >
          <span class="inline-block text-sm mb-2">Embargo</span>
          <FormDatepicker
            name="embargoedUntil"
            class="mb-2"
            placeholder="Not selected"
            :model-value="embargoedUntil"
            :size="SIZES.SMALL"
            is-full-width
            @update:model-value="(value) => (embargoedUntil = value)"
          />
        </div>

        <Toast
          v-if="isEmbargoedOnly"
          id="publish-modal-embargo"
          message="This article is embargoed. Be sure you want to publish it now."
          :theme="THEMES.DANGER"
          :is-closable="false"
          is-full-width
        />

        <Button
          v-if="!isScheduledMode"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="isScheduledMode = true"
        >
          <template #leftIcon>
            <PlusIcon class="w-4 h-4" />
          </template>

          Schedule publication
        </Button>

        <div
          v-else
          class="w-full"
        >
          <span class="inline-block text-sm mb-2">Schedule publication</span>
          <FormDatepicker
            name="publishedAt"
            class="mb-2"
            placeholder="Not selected"
            :model-value="publishedAt"
            :size="SIZES.SMALL"
            is-full-width
            @update:model-value="(value) => (publishedAt = value)"
          />
        </div>
      </div>
    </div>

    <div class="flex items-center gap-4 justify-stretch pt-5">
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.TERTIARY"
        data-testid="button-cancel"
        is-full-width
        @click="emits('close')"
      >
        Cancel
      </Button>

      <Button
        v-if="isArticleWillScheduled"
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-loading="false"
        data-testid="button-cta"
        is-full-width
        @click="emits('schedule', articleUrl, embargoedUntil, publishedAt)"
      >
        Schedule publication
      </Button>

      <Button
        v-else
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-loading="false"
        data-testid="button-cta"
        is-full-width
        @click="emits('publish', articleUrl)"
      >
        Publish now
      </Button>
    </div>
  </ModalHolder>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, inject, type Ref } from 'vue';

import PlusIcon from '@/assets/icons/plus.svg?component';

import Button from '@/components/Button.vue';
import Toast from '@/components/Toast.vue';
import ModalHolder from '@/components/ModalHolder.vue';

import FormDatepicker from '@/components/FormDatepicker';

import ArticleUrlInput from '../ArticleUrlInput.vue';

import { BUTTON_TYPE, SIZES, MODAL_TYPES, THEMES } from '@/types';

import { useCurrentDocument } from '../../composables';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';
import FormInput from '@/components/FormInput.vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    publishedAt: string;
    isPublishNow?: boolean;
  }>(),
  {
    isVisible: false,
    isPublishNow: false,
  },
);

const emits = defineEmits<{
  (event: 'schedule', articleUrl: string, embargoedUntil: string | null, publishedAt: string | null): void;
  (event: 'publish', articleUrl: string): void;
  (event: 'close'): void;
}>();

const { data: articleData } = useCurrentDocument();
const articleStore = useArticleStore();

const articleUrl = ref<string>('');
const publishedAt = ref<string | null>(null);
const embargoedUntil = ref<string | null>(null);

const isEmbargoMode = ref<boolean>(false);
const isScheduledMode = ref<boolean>(false);
const isEmbargoedOnly = computed<boolean>(
  () => isEmbargoMode.value && !!embargoedUntil.value && !isScheduledMode.value,
);

const slugCanBeEdit = computed<boolean>(() => articleData?.value?.hasPost ?? false);

const isArticleWillScheduled = computed<boolean>(() => {
  const currentDate = new Date();
  const publishDate = publishedAt.value ? new Date(publishedAt.value) : currentDate;

  return isScheduledMode.value && publishDate.getTime() > currentDate.getTime();
});

const articleTitle = inject<string>('articleTitle');
const injectedArticleUrl = inject<Ref<string>>('articleUrl');

const onUrlChange = (val: string) => {
  articleStore.setArticleData({ slug: val });
};

watch(
  () => injectedArticleUrl,
  (val) => {
    if (val?.value !== articleUrl?.value) {
      articleUrl.value === val?.value || '';
    }
  },
  { deep: true, immediate: true },
);
watch(
  articleData,
  (currentArticleData) => {
    if (!currentArticleData) {
      return;
    }

    if (currentArticleData.publishedAt !== publishedAt.value) {
      publishedAt.value = !props.isPublishNow ? currentArticleData.publishedAt : null;

      const currentDate = new Date();
      const publishDate = publishedAt.value ? new Date(publishedAt.value) : currentDate;

      if (!isScheduledMode.value && publishDate.getTime() > currentDate.getTime()) {
        isScheduledMode.value = true;
      }
    }

    if (currentArticleData.embargoUntil !== embargoedUntil.value) {
      embargoedUntil.value = currentArticleData.embargoUntil ?? null;

      if (!isEmbargoMode.value) {
        isEmbargoMode.value = true;
      }
    }
  },
  { immediate: true, deep: true },
);

onMounted(() => {
  publishedAt.value = !props.isPublishNow ? props.publishedAt : null;
  if (props.isPublishNow) {
    isScheduledMode.value = false;
  } else {
    isScheduledMode.value = !!props.publishedAt;
  }
});
</script>
