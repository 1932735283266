import { computed } from 'vue';
import { useUserPermissions } from '@/stores/user.store';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';

export const useUserConditions = () => {
  const articleStore = useArticleStore();

  const {
    getUserId,
    documentStatusToFinishTextCondition,
    documentStatusToFinishArtworkCondition,
    documentStatusToPublishCondition,
    documentMetadataTextCondition,
    documentMetadataCoverDeadlineCondition,
    documentMetadataEmbargoCondition,
    documentMetadataHideFromMainPageCondition,
    documentMetadataShowInMarketsCondition,
    documentMetadataPromoPostCondition,
    documentMetadataNoIndexCondition,
  } = useUserPermissions();

  const finishTextCondition = computed<boolean>(() => {
    return (
      articleStore.state.writerId && documentStatusToFinishTextCondition && articleStore.state.writerId === getUserId()
    );
  });

  const artworkCondition = computed<boolean>(() => {
    return (
      articleStore.state.writerId &&
      documentStatusToFinishArtworkCondition &&
      articleStore.state.writerId === getUserId()
    );
  });
  const publishCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentStatusToPublishCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });
  const metadataCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId && documentMetadataTextCondition && articleStore.state.writerId === getUserId()) ??
      false
    );
  });
  const metadataCoverCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentMetadataCoverDeadlineCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });
  const metadataEmbargoCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentMetadataEmbargoCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });
  const hideFromMainCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentMetadataHideFromMainPageCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });
  const showInMarketCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentMetadataShowInMarketsCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });
  const promoPostCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentMetadataPromoPostCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });

  const editNoIndexCondition = computed<boolean>(() => {
    return (
      (articleStore.state.writerId &&
        documentMetadataNoIndexCondition &&
        articleStore.state.writerId === getUserId()) ??
      false
    );
  });

  return {
    finishTextCondition,
    artworkCondition,
    publishCondition,
    metadataCondition,
    metadataCoverCondition,
    metadataEmbargoCondition,
    hideFromMainCondition,
    showInMarketCondition,
    promoPostCondition,
    editNoIndexCondition,
  };
};
