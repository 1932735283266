<template>
  <div class="relative">
    <FormInput
      :id="`${name}-password-input`"
      ref="passwordInput"
      :model-value="props.modelValue"
      class="mb-4 relative"
      :is-errored="props.isErrored"
      :attrs="props.attrs"
      :name="props.name"
      :type="isPasswordShown ? 'text' : 'password'"
      @update:model-value="onInputChange"
    >
      <template #label>
        <slot name="label" />
      </template>

      <template #prefix>
        <slot name="icon" />
      </template>

      <template #suffix>
        <div
          v-if="isPasswordShown"
          :data-testid="`${name}-button-hide-password`"
          @click="
            () => {
              passwordInput?.caretToEnd();
              isPasswordShown = false;
            }
          "
        >
          <EyeIcon
            :id="`${name}-icon-eye`"
            class="w-6 h-6 z-10 text-imperium-fg-muted cursor-pointer"
          />
        </div>
        <div
          v-else
          :data-testid="`${name}-button-show-password`"
          @click="
            () => {
              passwordInput?.caretToEnd();
              isPasswordShown = true;
            }
          "
        >
          <EyeClosedIcon
            :id="`${name}-icon-eye-close`"
            class="w-6 h-6 text-imperium-fg-muted cursor-pointer"
          />
        </div>
      </template>
      <template #error>
        <slot name="error" />
      </template>
    </FormInput>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

import FormInput from '@/components/FormInput.vue';
import EyeIcon from '@/assets/icons/eye.svg?component';
import EyeClosedIcon from '@/assets/icons/eye-cross.svg?component';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    name: string;
    attrs?: Record<string, unknown>;
    isErrored?: boolean;
    isRequired?: boolean;
  }>(),
  {
    isErrored: false,
    isRequired: false,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const isPasswordShown = ref<boolean>(false);
const passwordInput = ref<typeof FormInput>();

const onInputChange = (value: string) => {
  emits('update:modelValue', value);
};
</script>
