import type {
  CategoryTranslateForm,
  CategoryTranslateFormPayload,
  CategoryTranslate,
} from '@/features/Categories/types';
import { CATEGORY_API_CREATE_FIELD_LABELS, CATEGORY_FIELD_LABELS } from '@/features/Categories/constants';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';

interface KeyValueMap {
  [key: string]: unknown; // Keys are strings, and values are numbers
}

export const transformFormToCategoryPayload = (form: CategoryTranslateForm): CategoryTranslateFormPayload => {
  const formCopy: KeyValueMap = { ...form };

  if (formCopy[CATEGORY_FIELD_LABELS]) {
    formCopy[CATEGORY_API_CREATE_FIELD_LABELS] = formCopy[CATEGORY_FIELD_LABELS];
    delete formCopy[CATEGORY_FIELD_LABELS];
  }

  formCopy.languageId = DEFAULT_LANGUAGE_ID;

  return formCopy as unknown as CategoryTranslateFormPayload;
};

export const transformCategoryResponseToForm = (category: CategoryTranslate): CategoryTranslateForm => {
  const categoryCopy: KeyValueMap = { ...category };

  if (Array.isArray(categoryCopy[CATEGORY_FIELD_LABELS])) {
    categoryCopy[CATEGORY_FIELD_LABELS] = categoryCopy[CATEGORY_FIELD_LABELS].map(({ id }) => id) as number[];
  } else {
    categoryCopy[CATEGORY_FIELD_LABELS] = [];
  }

  return categoryCopy as unknown as CategoryTranslateForm; // Use unknown for casting
};
