<template>
  <div
    :class="
      colorClass({
        state: state,
      })
    "
  >
    {{ date }}
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { tv } from 'tailwind-variants';
import { dateBeautifyPeriodFormCurrentDate } from '@/utils/date';

const enum State {
  NEVER = 'never',
  OLD = 'old',
  NEW = 'new',
}

const props = defineProps<{
  lastUsage: null | string;
}>();

const state = ref<State>(State.NEVER);

const date = computed((): string => {
  if (!props.lastUsage) {
    state.value = State.NEVER;
    return 'Never';
  }

  const result = dateBeautifyPeriodFormCurrentDate(props.lastUsage);
  if (result.includes('day') || result.includes('hour') || result.includes('minute') || result.includes('second')) {
    state.value = State.NEW;
  } else {
    state.value = State.OLD;
  }

  return result;
});

const colorClass = tv({
  base: 'font-semibold text-sm cursor-default',
  variants: {
    state: {
      [State.NEVER]: 'text-imperium-fg-strong',
      [State.OLD]: 'text-imperium-ds-green-strong',
      [State.NEW]: 'text-imperium-ds-red-strong',
    },
  },
});
</script>
