import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useSimpleAction } from '@/composables';
import { useToast } from '@/composables/useToast';
import { MediaService } from '@/features/Media/service/media';
import type { Media } from '@/features/Media/types';
import { type MediaListRequest } from '@/features/Media/types';
import { useMediaPagination } from '@/features/Media/stores/pagination.store';
import { useMediaFilters } from '@/features/Media/stores/filters.store';

interface MediaState {
  media: Media[];
  isMediaLoading: boolean;
  editedImage?: number | null;
}

export const useMediaList = defineStore('mediaPage', () => {
  const state = ref<MediaState>({
    media: [],
    isMediaLoading: false,
  });

  const toast = useToast();

  const paginationStore = useMediaPagination();
  const filtersStore = useMediaFilters();

  const { action } = useSimpleAction(async () => {
    try {
      state.value.isMediaLoading = true;
      const resultPayload: MediaListRequest = {
        pagination: true,
        page: paginationStore.state.page,
        itemsPerPage: paginationStore.state.limit,
      };

      if (filtersStore.state.searchQuery) {
        resultPayload['filter[search]'] = filtersStore.state.searchQuery;
      }

      for (const key in filtersStore.state.sort) {
        if (filtersStore.state.sort[key]) {
          resultPayload[`order[${key}]`] = filtersStore.state.sort[key];
        }
      }

      const { items, itemsPerPage, page, total } = await MediaService.media(resultPayload);

      state.value.media = items;
      paginationStore.state = {
        limit: itemsPerPage,
        total,
        page,
      };

      state.value.isMediaLoading = false;
    } catch (err) {
      state.value.isMediaLoading = false;
      toast.errorTemporary({ id: 'ERROR_FETCH_MEDIA_LIST', message: 'Something went wrong, please try later' });
      console.error('error', err);
      throw err;
    }
  });

  const cleanEditingMedia = () => {
    state.value.editedImage = null;
  };

  return {
    fetchMediaList: action,
    cleanEditingMedia,
    state,
  };
});
