<template>
  <div>
    <UserFiltersRow class="mb-2 w-full" />
    <div class="relative overflow-x-auto">
      <div
        v-if="usersStore.isUsersLoading"
        class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
      >
        <div class="h-10 w-10">
          <LoaderIcon class="w-10 h-10" />
        </div>
      </div>
      <div
        v-else
        class="min-w-[926px] relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <Table
          :is-sticky="!isLargeDesktop"
          :is-rounded="!isMobile"
        >
          <TableHead>
            <UsersTableHeader />
          </TableHead>
          <TableBody v-if="paginationStore.state.total">
            <UserRow
              v-for="user in usersStore.state.users"
              :key="user.id"
              :user="user"
              @deactivate="onShowDeactivateModal(user.id)"
              @re-invite="onReInvite(user.id)"
              @restore="onShowRestoreModal(user.id)"
              @edit="onOpenEditModal(user.id)"
              @reset-2fa="onReset2FAOpenModal({ userId: user.id, userName: user.name })"
            />
          </TableBody>
        </Table>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No users found</div>
        </div>
        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
    <UserInfoModal
      v-if="!!userModalId"
      :id="userModalId"
      :is-opened="isRevealed"
      @close="onCloseUserInfoModal"
      @restore="onShowRestoreModal(userModalId as number)"
      @deactivate="onShowDeactivateModal(userModalId as number)"
    />
    <Teleport
      v-if="deactivateModalId"
      to="#modals"
    >
      <UserDeactivateModal
        :is-visible="deactivateModalId"
        :user-name="deactivateModalUserName"
        @close="onCloseDeactivateModal"
        @deactivate="onDeactivate"
      />
    </Teleport>
    <Teleport
      v-if="restoreModalId"
      to="#modals"
    >
      <UserRestoreModal
        :is-visible="restoreModalId"
        :user-name="restoreModalUserName"
        @close="onCloseRestoreModal"
        @restore="onRestore"
      />
    </Teleport>
    <Teleport
      v-if="isShowConfirmReset2fa && modalUser?.userId"
      to="#modals"
    >
      <UserReset2FAModal
        :key="`modal-user-${modalUser.userId || 0}`"
        :user-name="modalUser.userName"
        :is-visible="isShowConfirmReset2fa"
        :is-loading="isResetting2FA"
        @close="onCloseResetModal"
        @confirm="onConfirmModal"
      />
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import { inject, onMounted, ref, watch } from 'vue';
import isEqual from 'lodash/isEqual';
import { useUserFilters } from '@/features/Users/stores/filters.store';
import { usePagination } from '@/features/Users/stores/pagination.store';
import { useUsersPage } from '@/features/Users/stores/users.store';
import UserRow from '@/features/Users/components/UsersTable/UserRow/UserRow.vue';
import UsersTableHeader from '@/features/Users/components/UsersTable/UsersTableHeader.vue';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import UserFiltersRow from '@/features/Users/components/UsersTable/UsersFilters/UsersFiltersRow.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';
import UserInfoModal from '@/features/Users/components/UserInfo/UserInfoModal.vue';
import UserDeactivateModal from '@/features/Users/components/modals/UserDeactivateModal.vue';
import { useToast } from '@/composables/useToast';
import type { User } from '@/features/Users/types';
import { useSimpleAction } from '@/composables';
import { BUTTON_COLOR, UserStatuses } from '@/types';
import UserRestoreModal from '@/features/Users/components/modals/UserRestoreModal.vue';
import StatusUserToast from '@/features/Users/components/toasts/StatusUserToast.vue';
import { UsersService } from '@/features/Users/service';

import { Table, TableBody, TableHead } from '@/components/Table';
import { useModal } from '@/composables/useModal';
import UserReset2FAModal from '@/features/Users/components/modals/UserReset2FAModal.vue';
import { useReset2faModal } from '@/features/Users/composables/reset-2fa-modal';

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');
const isLargeDesktop = inject<boolean>('isLargeDesktop');

const userModalId = ref<boolean | number>(false);
const deactivateModalId = ref<boolean | number>(false);
const deactivateModalUserName = ref<null | string>(null);
const restoreModalId = ref<boolean | number>(false);
const restoreModalUserName = ref<null | string>(null);

const toast = useToast();

const filtersStore = useUserFilters();
const paginationStore = usePagination();
const usersStore = useUsersPage();
const { isShowConfirmReset2fa, modalUser, isResetting2FA, onCloseResetModal, onReset2FAOpenModal, onConfirmModal } =
  useReset2faModal();
const { reveal, cancel, confirm, isRevealed } = useModal();

watch(
  () => [filtersStore.state, paginationStore.state],
  (newValue, oldValue) => {
    const isFiltersEqual = isEqual(newValue, oldValue);
    if (!isFiltersEqual) {
      usersStore.fetchUsersList({ filtersStore, paginationStore });
    }
  },
  { deep: true },
);

const { action: changeStatusAction } = useSimpleAction(async ({ id, status }: { id: number; status: UserStatuses }) => {
  try {
    await UsersService.edit(id, {
      status,
    });
    toast.success({
      id: 'SUCCESS_CHANGE_STATUS_USER',
      message: `${
        status === UserStatuses.DEACTIVATED ? deactivateModalUserName.value : restoreModalUserName.value
      } ${status === UserStatuses.DEACTIVATED ? 'was deactivated' : 'was restored'}`,
    });
    onCloseDeactivateModal();
    onCloseRestoreModal();
    await usersStore.fetchUsersList({ filtersStore, paginationStore });
    await usersStore.fetchEditingUser(id);
  } catch (err) {
    const isDeactivated = status === UserStatuses.DEACTIVATED;
    toast.errorTemporary(
      {
        id: 'ERROR_CHANGE_STATUS_USER',
        message: 'Something went wrong, please try again later',
        buttonColor: isDeactivated ? BUTTON_COLOR.DEFAULT : BUTTON_COLOR.RED,
        title: isDeactivated ? 'Deactivate user failed' : 'Restore user failed',
      },
      StatusUserToast,
    );
    throw err;
  }
});

const { action: inviteAction } = useSimpleAction(async ({ id }: { id: number }) => {
  try {
    const user = usersStore.state.users.find((item) => item.id === id);
    if (user) {
      await UsersService.userInvitation({
        userName: user.name,
        email: user.email,
        roles: user.roles.map((item) => item.id),
        languages: user.languages.map((item) => item.id),
      });

      toast.success({
        id: 'SUCCESS_RE_INVITE_USER',
        message: `Re-invite user ${user?.name} successfully`,
      });
      await usersStore.fetchUsersList({ filtersStore, paginationStore });
    } else {
      throw new Error();
    }
  } catch (err) {
    toast.errorTemporary({
      id: 'ERROR_RE_INVITE_USER',
      message: 'Something went wrong, please try again later',
    });
    throw err;
  }
});

const onShowDeactivateModal = (userId: number) => {
  deactivateModalId.value = userId;
  deactivateModalUserName.value = usersStore.state.users.find((item: User) => item.id === userId)?.name || '';
  reveal();
};

const onDeactivate = () => {
  changeStatusAction({
    id: deactivateModalId?.value as number,
    status: UserStatuses.DEACTIVATED,
  });
};

const onCloseDeactivateModal = () => {
  deactivateModalId.value = false;
  deactivateModalUserName.value = null;
  reveal();
};

const onShowRestoreModal = (userId: number) => {
  restoreModalId.value = userId;
  restoreModalUserName.value = usersStore.state.users.find((item: User) => item.id === userId)?.name || '';
};

const onCloseRestoreModal = () => {
  restoreModalId.value = false;
  restoreModalUserName.value = null;
  reveal();
};

const onRestore = () => {
  changeStatusAction({
    id: restoreModalId?.value as number,
    status: UserStatuses.ACTIVE,
  });
};

const onOpenEditModal = (userId: number | boolean): void => {
  reveal();
  userModalId.value = userId;
};

const onCloseUserInfoModal = (): void => {
  cancel();
  userModalId.value = false;
};

const onReInvite = (userId: number) => {
  inviteAction({
    id: userId,
  });
};

onMounted(() => usersStore.fetchUsersList({ filtersStore, paginationStore }));
</script>
