import { extractData, http } from '@/http';
import type {
  CreatePermissionPayload,
  PermissionsFetchRequest,
  PermissionsFetchResponse,
  UpdatePermissionPayload,
} from '@/features/Permissions/types';

export const fetchPermissions = (params: PermissionsFetchRequest): Promise<PermissionsFetchResponse> =>
  http.get<PermissionsFetchResponse>('/permissions', { params, useAuth: true }).then(extractData);

export const updatePermission = (params: UpdatePermissionPayload): Promise<UpdatePermissionPayload> =>
  http
    .patch<PermissionsFetchResponse>(`/permissions/${params.id}`, {
      slug: params.slug,
      name: params.name,
      useAuth: true,
    })
    .then(extractData);

export const createPermission = (slug: CreatePermissionPayload) =>
  http
    .post('/permissions', slug, {
      useAuth: true,
    })
    .then(extractData);
