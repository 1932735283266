<template>
  <Badge
    :id="props.labelId"
    :label="props.labelTitle"
    :theme="BADGE_THEMES.DEFAULT"
    :size="SIZES.XSMALL"
    :rounded="BADGE_ROUNDED.LARGE"
  />
</template>
<script setup lang="ts">
import Badge from '@/components/Badge.vue';
import { BADGE_ROUNDED, BADGE_THEMES, SIZES } from '@/types';

interface Props {
  labelTitle: string;
  labelId: string;
}

const props = withDefaults(defineProps<Props>(), {
  labelTitle: null,
  labelId: null,
});
</script>
