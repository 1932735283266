<template>
  <article
    data-testid="dashboard-table-authors"
    class="bg-imperium-bg-sub-base shadow-imperium-base rounded-2xl w-full sm:w-auto"
  >
    <header class="flex items-center gap-2 px-2 sm:px-5 py-4">
      <StatsIcon :theme="THEMES.SECONDARY">
        <UsersIcon class="w-5 h-5" />
      </StatsIcon>

      <h3 class="text-gray-900 font-semibold text-md sm:text-xl leading-none">
        {{ t('dashboard.tables.authors') }}
      </h3>
      <Button
        to=""
        data-testid="dashboard-table-activity-button"
        :visual-type="BUTTON_TYPE.GHOST"
        :size="SIZES.SMALL"
        class="ml-auto whitespace-nowrap"
        is-link
      >
        {{ t('common.view-all', { count: 120 }) }}
      </Button>
    </header>

    <div class="relative overflow-x-auto">
      <Table
        class="min-w-[580px] sm:min-w-0"
        is-rounded
      >
        <TableHead>
          <TableHeadRow
            class="min-h-[48px] h-[48px] uppercase"
            :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
          >
            <TableHeadCell is-uppercase>
              {{ t('dashboard.tables.columns.writer') }}
            </TableHeadCell>
            <TableHeadCell is-uppercase>
              {{ t('dashboard.tables.columns.published-articles') }}
            </TableHeadCell>
            <TableHeadCell is-uppercase>
              {{ t('dashboard.tables.columns.original') }}
            </TableHeadCell>
          </TableHeadRow>
        </TableHead>

        <TableBody>
          <TableRow
            v-for="(row, index) in props.values"
            :key="`table-02-${index}`"
            :bordered="TABLE_BORDERED.BOTTOM"
            :striped="TABLE_STRIPED.STRIPED"
            class="group"
          >
            <TableCell class="group-hover:bg-imperium-bg-4">
              <div class="flex items-center gap-2 whitespace-nowrap">
                <div
                  class="rounded-full bg-imperium-bg-4 w-8 h-8 flex items-center justify-center font-semibold text-xs"
                >
                  {{
                    row.author
                      .split(' ')
                      .map((word) => word[0])
                      .join('')
                  }}
                </div>

                {{ row.author }}
              </div>
            </TableCell>
            <TableCell
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              class="group-hover:bg-imperium-bg-4"
            >
              {{ row.published }} <span class="hidden sm:inline-block">articles</span>
            </TableCell>
            <TableCell
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              class="group-hover:bg-imperium-bg-4"
            >
              {{ row.original }} <span class="hidden sm:inline-block">articles</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import UsersIcon from '@/assets/icons/file-success.svg?component';
import Button from '@/components/Button.vue';
import { Table, TableHead, TableBody, TableHeadRow, TableRow, TableHeadCell, TableCell } from '@/components/Table';
import {
  THEMES,
  SIZES,
  BUTTON_TYPE,
  TABLE_VISUAL_TYPE,
  TABLE_BORDERED,
  TABLE_ON_SURFACE,
  TABLE_STRIPED,
} from '@/types';

import StatsIcon from './StatsIcon.vue';

const props = defineProps<{
  values: Record<string, string>[];
}>();

const { t } = useI18n();
</script>
