import { ref } from 'vue';
import { defineStore } from 'pinia';

interface TwoFAStore {
  codes?: string[] | null;
  qr?: string;
}

export const useTwoFactorAuthStore = defineStore('TwoFactorAuthStore', () => {
  const state = ref<TwoFAStore>({
    codes: null,
    qr: '',
  });

  const setState = ({ codes, qr }: TwoFAStore) => {
    state.value = {
      codes: codes || state.value.codes,
      qr: qr || state.value.qr,
    };
  };

  const cleanState = () => {
    state.value = {};
  };

  return {
    state,
    setState,
    cleanState,
  };
});
