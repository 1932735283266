import type { RoleData } from '@/features/Roles/types';
import { roleSchema } from '@/features/Roles/model/roleModel';
import { ROLE_FIELD_DESCRIPTION, ROLE_FIELD_NAME, ROLE_FIELD_PERMISSIONS } from '@/features/Roles/constants';
import { useFormData } from '@/composables';
import { toTypedSchema } from '@vee-validate/zod';
import { computed } from 'vue';

export function useFormRoleEdit(initialRole: RoleData | null) {
  const { defineField, errors, meta, handleSubmit, values, setFieldValue, setFieldError } = useFormData({
    data: {
      [ROLE_FIELD_NAME]: initialRole?.[ROLE_FIELD_NAME] || '',
      [ROLE_FIELD_DESCRIPTION]: initialRole?.[ROLE_FIELD_DESCRIPTION] || '',
      [ROLE_FIELD_PERMISSIONS]: initialRole?.[ROLE_FIELD_PERMISSIONS] || [],
    },
    validator: toTypedSchema(roleSchema),
  });

  // You can define individual fields if needed
  const [name, nameAttr] = defineField(ROLE_FIELD_NAME);
  const [description, descriptionAttr] = defineField(ROLE_FIELD_DESCRIPTION);

  // Handle form validation
  const validateForm = async () => {
    const isValid = await handleSubmit(async () => {
      return true; // Form is valid
    })();

    return isValid;
  };

  const nameIsErrored = computed(() => !!errors.value[ROLE_FIELD_NAME]);
  const descriptionIsErrored = computed(() => !!errors.value[ROLE_FIELD_DESCRIPTION]);

  return {
    name,
    nameAttr,
    description,
    descriptionAttr,
    nameIsErrored,
    descriptionIsErrored,

    errors,
    meta,
    validateForm,
    handleSubmit,
    setFieldValue,
    setFieldError,
  };
}
