import {
  login,
  resetPassword,
  createPassword,
  fetchUser,
  userInvitationConfirm,
  validateToken,
  twoFACheck,
  twoFASet,
  logout,
} from '../api/auth';
import type {
  AuthLoginPayload,
  AuthResetPasswordPayload,
  AuthCreatePasswordPayload,
  UserInvitationConfirmPayload,
  UserInvitationConfirmResponse,
  ValidateTokenPayload,
  ValidateTokenResponse,
  AuthLoginResponse,
  TwoFACheckResponse,
  TwoFASetRequest,
  TwoFASetResponse,
} from '../types';
import { removeLocalStorageItem } from '@/services/localStorage';
import { DASHBOARD_STATE_NAME, DashboardTab } from '@/features/Dashboard/types';
import { setAuthenticated } from '@/services/auth';

export const AuthService = {
  login: (payload: AuthLoginPayload): Promise<AuthLoginResponse> => login(payload),
  twoFACheck: (): Promise<TwoFACheckResponse> => twoFACheck(),
  twoFASet: (payload: TwoFASetRequest): Promise<TwoFASetResponse> => twoFASet(payload),
  resetPassword: (payload: AuthResetPasswordPayload) => resetPassword(payload),
  createPassword: (payload: AuthCreatePasswordPayload) => createPassword(payload),
  validateToken: (payload: ValidateTokenPayload): Promise<ValidateTokenResponse> => validateToken(payload),
  userInvitationConfirm: (payload: UserInvitationConfirmPayload): Promise<UserInvitationConfirmResponse> =>
    userInvitationConfirm(payload),
  fetchUser,
  logout: async (isNeedGoToLogin: boolean = true) => {
    try {
      await logout();
      removeLocalStorageItem(DASHBOARD_STATE_NAME);
      setAuthenticated(false);
      if (isNeedGoToLogin) {
        window.location.href = '/auth/login';
      }
    } catch (e) {
      // there should be an error catching
    }
  },
};
