export const BASE_API_PATH = '/api';
export const BASE_SSE_PATH = '/sse';
export const BASE_CRDT_PATH = '/crdt';

export const apiGatewayUrl: string = import.meta.env?.PROD ? import.meta.env.VITE_API_GATEWAY_URL : BASE_API_PATH;
export const colladitorWs: string = import.meta.env?.PROD ? import.meta.env.VITE_COLLABORATION_WS : BASE_CRDT_PATH;

export const sseGatewayUrl = import.meta.env?.PROD ? import.meta.env.VITE_APP_SSE_GATEWAY : BASE_SSE_PATH;

// Sentry
export const sentryDsn = import.meta.env?.VITE_APP_SENTRY_DSN;
export const deploymentEnv = import.meta.env?.VITE_APP_DEPLOYMENT_ENV;
export const release = import.meta.env?.VITE_APP_RELEASE || '1.0.0';
export const tracePropagationTargets = (import.meta.env?.VITE_SENTRY_TRACES_PROPAGATION_TARGETS || '')
  .split(',')
  .map((target: string): RegExp | string => {
    target = target.trim();

    if (target.startsWith('/') && target.endsWith('/')) {
      const pattern = target.slice(1, -1); // Remove the starting and ending slashes
      return new RegExp(pattern); // Convert to a RegExp object
    }

    // Otherwise, return the string target as is
    return target;
  });
export const tracesSampleRate = parseFloat(import.meta.env?.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.5);
