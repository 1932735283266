<script setup lang="ts">
import { useActiveArticleStore } from '@/stores/users.store';
import UserInfo from '@/features/Users/components/UsersTable/UserRow/UserInfo.vue';
import { computed, inject } from 'vue';
import { SIZES } from '@/types';
import Tooltip from '@/components/Tooltip.vue';

const MAX_AVATARS_LENGTH = 5;

const usersOnPageStore = useActiveArticleStore();
const isMobile = inject('isMobile');

const users = computed(() => usersOnPageStore.state);

const totalUsers = computed(() => users.value.length);
const avatars = computed(() => {
  if (totalUsers.value > MAX_AVATARS_LENGTH) {
    return users.value.slice(0, MAX_AVATARS_LENGTH - 1);
  } else {
    return users.value.slice(0, MAX_AVATARS_LENGTH);
  }
});

const totalNotShowedUsers = computed(() => totalUsers.value - (MAX_AVATARS_LENGTH - 1));

const getUserSurname = (name: string): string => {
  const splitedName = name.split(' ');
  return splitedName[1];
};
</script>

<template>
  <div
    v-if="totalUsers > 1 && !isMobile"
    class="flex gap-1"
  >
    <div
      v-for="user in avatars"
      :key="user.id"
      class="group sticky"
    >
      <Tooltip
        :key="`user-avatar-info`"
        name="user-avatar-name"
        width="unset"
        placement="bottom"
      >
        <template #content>
          <UserInfo
            :id="user.id"
            :avatar-size="SIZES.SMALL"
            :name="user.name"
            :surname="getUserSurname(user.name)"
            :with-full-name="false"
          />
        </template>
        <template #tooltip>
          <p class="font-semibold whitespace-nowrap text-imperium-fg-base text-xs bg-white">
            {{ user.name }}
          </p>
        </template>
      </Tooltip>
    </div>
    <div
      v-if="totalUsers > MAX_AVATARS_LENGTH"
      class="text-center flex items-center justify-center uppercase"
    >
      <p class="bg-white p-2 rounded-full text-sm text-black font-semibold">+{{ totalNotShowedUsers }}</p>
    </div>
  </div>
  <div
    v-else-if="isMobile && totalUsers > 1"
    class="text-center flex items-center justify-center uppercase text-imperium-fg-strong whitespace-nowrap cursor-pointer"
  >
    <div>
      <p
        class="bg-white p-2 rounded-full text-sm text-black font-semibold w-8 h-[32px] text-center flex items-center justify-center uppercase"
      >
        {{ totalUsers }}
      </p>
    </div>
  </div>
</template>
