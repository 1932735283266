class CorrelationService {
  private correlationId: string | null = null;

  getCorrelationId() {
    if (!this.correlationId) {
      this.correlationId = this.createCorrelationId();
    }

    return this.correlationId;
  }

  createCorrelationId() {
    return Math.random().toString(36).substr(2, 9);
  }
}

export default new CorrelationService();
