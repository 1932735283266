import { extractData, http } from '@/http';
import type {
  ImageSetAttributesRequest,
  ImageSetAttributesResponse,
  ImageUploadRequest,
  ImageUploadResponse,
} from '@/features/Media/types/api';

export const imageSetAttributes = (
  id: number,
  patchBody: ImageSetAttributesRequest,
): Promise<ImageSetAttributesResponse> =>
  http.patch<ImageSetAttributesResponse>(`/gallery-images/${id}`, patchBody, { useAuth: true }).then(extractData);

export const imageUpload = (postBody: ImageUploadRequest): Promise<ImageUploadResponse> =>
  http
    .postForm<any>(`/gallery-images`, postBody, {
      useAuth: true,
    })
    .then(extractData);
