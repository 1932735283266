import { AuthException } from './exceptions/AuthException';
import { AccessRestrictedException } from './exceptions/AccessRestrictedException';
import type { Router } from 'vue-router';

export const initAuthErrorsHandling = (router: Router) => {
  const handleCriticalException = (e: Error) => {
    if (e instanceof AccessRestrictedException) {
      router.push({ name: 'UserTemporarilyBlocked' });
    }
  };

  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    if (event.reason instanceof AuthException) {
      handleCriticalException(event.reason);
    }
  });

  window.addEventListener('error', (event: ErrorEvent) => {
    if (event.error instanceof AuthException) {
      handleCriticalException(event.error);
    }
  });
};
