<template>
  <div class="flex flex-col">
    <div class="flex mb-4">
      <Button
        :size="SIZES.MEDIUM"
        data-testid="create-new-role-modal-show"
        class="ml-auto"
        @click="goToRoleCreate"
      >
        <template #leftIcon>
          <PlusIcon class="w-5 h-5" />
        </template>

        New role
      </Button>
    </div>

    <RolesTable @redirect="goToRoleEdit" />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import Button from '@/components/Button.vue';
import { SIZES } from '@/types';

import PlusIcon from '@/assets/icons/plus.svg?component';
import RolesTable from '@/features/Roles/components/RolesTable.vue';

const router = useRouter();

const goToRoleEdit = (roleId: number) => {
  router.push({
    name: 'roles-edit',
    params: {
      id: roleId,
    },
  });
};

const goToRoleCreate = () => {
  router.push({
    name: 'roles-create',
  });
};
</script>
