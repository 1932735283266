<template>
  <div>
    <Teleport to="#modals">
      <ModalHolder
        :is-visible="props.isVisible"
        :size="SIZES.MEDIUM"
        data-testid="upload-image-modal"
        @close="onClose"
      >
        <template #title>
          <div class="px-2 py-1">Upload image</div>
        </template>
        <UploadImageContent
          :image-loading-state="imageLoadingState"
          :media-id="mediaId"
          @update-state="onUpdateState"
          @update-media-id="onUpdateMediaId"
          @close="onClose"
        />
      </ModalHolder>
    </Teleport>
  </div>
</template>
<script lang="ts" setup>
import ModalHolder from '@/components/ModalHolder.vue';
import { ImageLoadingState } from '@/features/UploadImage/types';

import { SIZES } from '@/types';

import UploadImageContent from '@/features/UploadImage/components/UploadImageContent.vue';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
}>();

const imageLoadingState = ref<ImageLoadingState>(ImageLoadingState.CHOOSE);
const mediaId = ref<number | null>(null);

const onClose = () => {
  emits('close');
  imageLoadingState.value = ImageLoadingState.CHOOSE;
};

const onUpdateState = (value: ImageLoadingState) => {
  imageLoadingState.value = value;
};

const onUpdateMediaId = (value: number | null) => {
  mediaId.value = value;
};
</script>
