<template>
  <div
    class="w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-lg"
    :class="{
      'bg-imperium-ds-primary-strong text-white': props.theme === THEMES.WARNING,
      'bg-imperium-ds-red-strong text-white': props.theme === THEMES.DANGER,
      'bg-imperium-ds-red-muted text-white': props.theme === THEMES.INFO,
      'bg-imperium-ds-green-strong text-white': props.theme === THEMES.SUCCESS,
      'bg-imperium-bg-4 text-imperium-fg-base': props.theme === THEMES.SECONDARY,
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { THEMES } from '@/types';

const props = defineProps<{
  theme: THEMES;
}>();
</script>
