<template>
  <SegmentedControlTabs
    id="dashboard-control-tabs"
    class="ml-auto"
    :values="dashboardTabsValues"
    :current-tab="dashboardStore.state.dashboardType"
    :color="SEGMENT_CONTROL_TABS_COLOR.GRAY_5"
    :size="SIZES.SMALL"
    @change-tab="setCurrentTab"
  >
    <template
      v-if="!props.isMobile"
      #articles
    >
      Articles
    </template>
    <template #articles-leftIcon> <ArticlesIcon class="w-4 h-4" /> </template>
    <template
      v-if="!props.isMobile"
      #covers
    >
      Covers
    </template>
    <template #covers-leftIcon> <ImageIcon class="w-4 h-4" /> </template>
  </SegmentedControlTabs>
</template>
<script setup lang="ts">
import SegmentedControlTabs from '@/components/SegmentedControlTabs.vue';
import ArticlesIcon from '@/assets/icons/articles.svg?component';
import ImageIcon from '@/assets/icons/img.svg?component';
import { SEGMENT_CONTROL_TABS_COLOR, SIZES } from '@/types';
import { DashboardTab } from '@/features/Dashboard/types';
import { useDashboardStore } from '@/features/Dashboard/stores/dashboardStore';

const props = withDefaults(
  defineProps<{
    isMobile?: boolean;
  }>(),
  {
    isMobile: false,
  },
);

const dashboardStore = useDashboardStore();
const dashboardTabsValues = [DashboardTab.ARTICLES, DashboardTab.COVERS];

const setCurrentTab = (value: DashboardTab) => {
  dashboardStore.setDashboardType(value);
};
</script>
