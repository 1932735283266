<template>
  <div class="select-none text-imperium-fg-muted text-xs">
    {{ wordCount }} {{ wordLabel }} / {{ characterCount }} {{ characterLabel }}
  </div>
</template>
<script setup lang="ts">
import { toRef } from 'vue';
import { useWordCount } from '@/features/WordCount/composables/useWordCount';

interface Props {
  text: string;
}

const props = defineProps<Props>();

const text = toRef(props, 'text');

const { wordCount, wordLabel, characterCount, characterLabel } = useWordCount(text);
</script>
