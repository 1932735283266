import LinkIcon from '@/assets/icons/editor/link.svg?raw';
import { MenuItem } from '@/features/CollaborativeEditor/plugins/menu';
import type { MarkType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { createApp, h, ref } from 'vue';
import LinkModal from '@/features/CollaborativeEditor/components/LinkModal.vue';
import { NodeSelection, TextSelection } from 'prosemirror-state';
import { schema } from '@/features/CollaborativeEditor/schema';
import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';
import { markActive } from '@/features/CollaborativeEditor/utils/blocks';
import { onHideLinkToast, removeListenersForLinksFocus } from '@/features/CollaborativeEditor/helpers/linkPreview';
import { disableMenuLinkItem } from '@/features/CollaborativeEditor/utils/state';

interface LinkData {
  title?: string;
  text?: string;
  link: string;
  isEdit?: boolean;
  isReset?: boolean;
  isNofollow?: string;
  isInNewTab?: string;
}

const linkData = ref<LinkData>({
  text: '',
  link: '',
  isEdit: false,
  isReset: false,
  isNofollow: '',
  isInNewTab: '',
});

const showInsertModal = ref<boolean>(false);
let insertLinkPromiseResolve: (value: LinkData) => void;

export const setEditingLinkAttributes = ({ text, link, isEdit, title, isNofollow, isInNewTab }: LinkData) => {
  linkData.value = { text, link, isEdit, title, isNofollow, isInNewTab };
};

const openInsertModal = async () => {
  showInsertModal.value = true;
  onHideLinkToast();

  return new Promise<LinkData>((resolve) => {
    insertLinkPromiseResolve = resolve;
  });
};

const closeInsertModal = () => {
  showInsertModal.value = false;
};

export const buildLink = (mark: MarkType): InstallableBlock => {
  const linkIconNode = new DOMParser().parseFromString(LinkIcon, 'text/html').body.firstElementChild;
  linkIconNode.setAttribute('id', 'add-link-data-button');
  const item = new MenuItem({
    title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.INSERT_LINK),
    icon: {
      dom: linkIconNode as Node,
    },
    render: () => {
      const buttonContainer = document.createElement('div');
      buttonContainer.id = 'add-link-button';
      buttonContainer.className = 'ProseMirror-icon';

      if (linkIconNode) {
        buttonContainer.appendChild(linkIconNode.cloneNode(true));
      }

      return buttonContainer;
    },
    active(state) {
      return markActive(state, mark);
    },
    enable(state) {
      return (
        !state.selection.empty && disableMenuLinkItem(state.selection.$head!.path, state.selection?.node?.type?.name)
      );
    },
    run(state, _, view) {
      const { selection, doc } = state;
      let selectedText = '';
      let selectedNode = null;

      if (selection instanceof TextSelection) {
        selectedText = state.doc.textBetween(selection.from, selection.to, ' ');
      } else if (selection instanceof NodeSelection) {
        selectedNode = selection.node;
      }

      const { from, to } = state.selection;
      let href = '';
      let title: string | null = null;
      let target = '';
      let rel = '';
      let markFrom = null;
      let markTo = null;

      state.doc.nodesBetween(from, to, (node, pos) => {
        const markIndex = node.marks.findIndex((mark) => mark.type === schema.marks.a);
        if (markIndex >= 0) {
          href = node.marks[markIndex].attrs.href;
          target = node.marks[markIndex].attrs.target;
          rel = node.marks[markIndex].attrs.rel;
          title = node.marks[markIndex].attrs.title;
          selectedText = node.text;
          markFrom = pos;
          markTo = pos + node.nodeSize;
          return false;
        }
      });

      if (!title || title === 'null') {
        title = href;
      }

      linkData.value = {
        link: href,
        text: selectedText,
        isEdit: !!href,
        isInNewTab: target,
        isNofollow: rel,
        title,
      };

      const { dispatch } = view;

      const applyTransaction = ({ link: newLink, isReset, title, isNofollow, isInNewTab }: LinkData) => {
        if (isReset) {
          dispatch(state.tr.removeMark(markFrom, markTo, schema.marks.a));

          // get link text for removing listeners
          let text = '';
          doc.nodesBetween(markFrom, markTo, (node, pos, parent, index) => {
            if (node.isText) {
              text += node.text.slice(Math.max(0, markFrom - pos), Math.min(node.text.length, markTo - pos));
            }
          });

          removeListenersForLinksFocus(text);
          return;
        }
        const props: {
          href: string;
          title: string;
        } = {
          href: newLink,
        };
        if (title) {
          props.title = title;
        }

        dispatch(
          state.tr.addMark(
            markFrom || from,
            markTo || to,
            schema.marks.a.create({
              ...props,
              target: isInNewTab ? '_blank' : '_self',
              rel: isNofollow ? 'nofollow noopener' : '',
            }),
          ),
        );
      };

      openInsertModal().then(({ link, isReset, title, isNofollow, isInNewTab }) => {
        applyTransaction({ link, isReset, isNofollow, title, isInNewTab });
      });
    },
  });

  const app = createApp({
    setup() {
      return () =>
        h(LinkModal, {
          isVisible: showInsertModal.value,
          data: linkData.value,
          onClose: closeInsertModal,
          onReset: (data) => {
            closeInsertModal();
            insertLinkPromiseResolve({ isReset: true, ...data });
          },
          onInsert: (data) => {
            closeInsertModal();
            insertLinkPromiseResolve({ isReset: false, ...data });
          },
        });
    },
  });

  app.mount('#prosemirror-add-link-modal');

  return {
    key: 'toggleLink',
    item,
  };
};
