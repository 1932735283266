<template>
  <AuthPageWrapper
    data-testid="auth-2fa"
    class="py-2"
  >
    <template #title> Two-factor authentication</template>

    <p class="font-normal text-lg text-imperium-fg-strong">
      In order to secure your account, you should use a two-factor authentication.
    </p>

    <p class="mt-5 font-normal text-lg text-imperium-fg-muted">1. Download or open an authentication app.</p>

    <p class="mt-5 font-normal text-lg text-imperium-fg-muted">2. Scan the QR code with your authenticator app.</p>

    <div class="mt-5 flex space-y-8 flex-col sm:space-y-0 sm:flex-row sm:space-x-8">
      <div class="flex-shrink-0 relative m-auto sm:m-0">
        <FrameImage class="text-imperium-fg-weak" />
        <div class="top-5 left-5 absolute">
          <QrcodeVue
            class="w-[120px] h-[120px]"
            :value="twoFAStore.state.qr"
            :size="120"
          />
        </div>
      </div>
      <div class="m-0 border rounded-l border-b-imperium-fg-weak h-[160px] w-full flex items-center justify-center">
        <div class="grid grid-cols-2 gap-1">
          <div
            v-for="code in twoFAStore.state.codes"
            :key="code"
            class="text-xs font-semibold font-medium text-imperium-fg-strong"
          >
            {{ code }}
          </div>
        </div>
      </div>
    </div>

    <p class="mt-5 font-normal text-lg text-imperium-fg-muted">
      3. Enter the six-digit code from the application or use the 8-digit backup code.
    </p>
    <form
      class="flex flex-col grow mt-5"
      @submit="onSubmit"
    >
      <FormInput
        v-model="code"
        :attrs="codeAttrs"
        :is-errored="isCodeErrored"
        class="mb-4"
        name="code"
        type="code"
      >
        <template #label>Two-factor code</template>
        <template #error>
          {{ errors.code }}
        </template>
        <template #help>
          Enter the 6-digit code from your two-factor authentication app or use the 8-digit backup code.
        </template>
      </FormInput>
      <div class="mt-auto">
        <Button
          :is-loading="isLoading"
          data-testid="button-login"
          is-full-width
          type="submit"
        >
          Submit
        </Button>
      </div>
    </form>
  </AuthPageWrapper>
</template>

<script lang="ts" setup>
import zod from 'zod';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { toTypedSchema } from '@vee-validate/zod';

import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';

import { useSimpleAction } from '@/composables';

import FrameImage from '@/assets/img/frame.svg?component';
import AuthPageWrapper from '../components/AuthPageWrapper.vue';
import { AuthService } from '../service';
import { useFormData } from '@/composables/useFormData';
import { AUTH_ERROR_CODES, type AuthLoginPayload } from '../types';
import type { GenericError } from '@/types/exceptions';
import { ERROR_CODE_TO_MESSAGE } from '../constants';
import { useErrorHandling } from '@/composables/useErrorHandling';
import { useTwoFactorAuthStore } from '@/features/Auth/stores/twoFactorAuthStore';
import QrcodeVue from 'qrcode.vue';

const router = useRouter();

const twoFAStore = useTwoFactorAuthStore();

if (!twoFAStore.state.qr || !twoFAStore.state.codes) {
  router.replace({ path: '/auth/login' });
}

const { defineField, errors, meta, handleSubmit, setFieldError } = useFormData({
  data: {
    code: '',
  },
  validator: toTypedSchema(
    zod.object({
      code: zod.string().min(1, 'Please fill the code'),
    }),
  ),
});

const [code, codeAttrs] = defineField('code');

const isCodeErrored = computed(() => meta.value.touched && !!errors.value.code);

const { handleError } = useErrorHandling({
  list: AUTH_ERROR_CODES,
  messages: ERROR_CODE_TO_MESSAGE,
  callback: (message, code) => {
    if (code === AUTH_ERROR_CODES['2FA_OFF']) {
      router.replace({ path: '/auth/login' });
    }
    if (code === AUTH_ERROR_CODES.ACCESS_DENIED) {
      router.replace({
        name: 'UserTemporarilyBlocked',
      });
      return;
    }

    setFieldError('code', 'Wrong code. Try again.');
  },
});

const onSubmit = handleSubmit((values: Pick<AuthLoginPayload, 'code'>) => action(values));
const { isLoading, action } = useSimpleAction(async (payload: Pick<AuthLoginPayload, 'code'>) => {
  try {
    const resultPayload = {
      code: payload.code,
    };
    const tokens = await AuthService.twoFASet(resultPayload);

    router.replace({ path: '/auth/2fa-success' });
    twoFAStore.cleanState();
  } catch (e: unknown) {
    handleError(e as GenericError);
  }
});
</script>
