import { useMutation } from '@tanstack/vue-query';
import { type DocumentPatchPayload } from '@/types';
import { patchDocument } from '../api';

interface DocumentMutateRequest {
  documentId: number;
  language: number;
  values: DocumentPatchPayload;
}

export const useMutateDocument = () => {
  const { isPending, isError, isSuccess, mutate, mutateAsync } = useMutation({
    mutationFn: ({ documentId, language, values }: DocumentMutateRequest) =>
      patchDocument(documentId, language, values),
  });

  return {
    isPending,
    isError,
    isSuccess,
    mutate,
    mutateAsync,
  };
};
