<template>
  <div
    class="gap-2 flex cursor-pointer font-semibold text-xs uppercase"
    :class="{
      'text-imperium-fg-strong': props.value,
      'whitespace-nowrap': props.isWrap === false,
    }"
    :visual-type="BUTTON_TYPE.INVISIBLE"
    :data-testid="`sort-button-${id}`"
    @click="onChangeStatus"
  >
    {{ label }}

    <SortButton
      :value="props.value"
      class="self-center"
    />
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue';
import SortButton from '@/components/Table/Filters/SortButton.vue';
import { BUTTON_TYPE, SortStatus } from '@/types';

const props = withDefaults(
  defineProps<{
    id: string;
    label: string;
    value: SortStatus;
    isWrap?: boolean;
  }>(),
  {
    isWrap: false,
    value: SortStatus.DISABLE,
  },
);

const emits = defineEmits<{
  (event: 'change-sort', status: SortStatus): void;
}>();

const onChangeStatus = () => {
  let result;
  switch (props.value) {
    case SortStatus.DISABLE:
      result = SortStatus.ASC;
      break;
    case SortStatus.ASC:
      result = SortStatus.DESC;
      break;
    case SortStatus.DESC:
      result = SortStatus.DISABLE;
      break;
  }

  emits('change-sort', result);
};
</script>
