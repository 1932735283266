import { AUTH_ERROR_CODES } from './types';

export const enum HTTP_STATUSES {
  BAD_REQUEST = 400,
  UNATHORIZED = 401,
  FORBIDDEN = 403,
}

export const ERROR_CODE_TO_MESSAGE = {
  [AUTH_ERROR_CODES.INVALID_LOGIN_CREDENTIALS]: 'Email or password are incorrect',
  [AUTH_ERROR_CODES.ACCESS_DENIED]: 'Too many login attempts',
  [AUTH_ERROR_CODES.NOT_FOUND]: 'User not found',
};
