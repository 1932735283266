import { ref, computed, type Ref } from 'vue';

export const useElementExistence = (selector: string, parent?: Ref<HTMLElement | null>) => {
  const isElementExist = ref<boolean>(false);
  const element = ref<Element | null>(null);

  const observer = new MutationObserver((mutations) => {
    const startNode = parent && parent.value ? parent.value : document;

    const observedElement: HTMLElement = startNode.querySelector(selector) as HTMLElement;
    if (observedElement) {
      observer.disconnect();

      isElementExist.value = true;
      element.value = observedElement;
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });

  return {
    isElementExist: computed(() => isElementExist.value),
    element: computed(() => element.value),
  };
};
