<template>
  <section class="bg-imperium-bg-sub-base p-4 sm:rounded-2xl">
    <div class="flex gap-2">
      <div
        class="w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full bg-imperium-bg-3 text-xs font-semibold"
      >
        1
      </div>
      <div class="flex flex-col">
        <h3 class="font-semibold text-base leading-8">Role name and description 👨🏼‍🎨</h3>
        <p class="text-imperium-fg-muted text-xs">Make this role easily identifiable.</p>
      </div>
    </div>
    <div class="flex gap-2 mt-4">
      <div class="basis-1/2">
        <FormInput
          :model-value="props.name"
          :attrs="props.nameAttr"
          :is-errored="nameErrored"
          placeholder="Enter name"
          @update:modelValue="emit('update:name', $event)"
        >
          <template #label>
            <span class="text-sm font-semibold">Role name *</span>
          </template>
          <template #error>
            {{ errors.name }}
          </template>
        </FormInput>
      </div>
      <div class="basis-1/2">
        <FormTextarea
          :model-value="props.description"
          name="role-description"
          min-height="112px"
          placeholder="Enter description"
          :attrs="props.descriptionAttr"
          :is-errored="descriptionErrored"
          @update:modelValue="emit('update:description', $event)"
        >
          <template #label>
            <span class="text-sm font-semibold">Role description *</span>
          </template>
          <template #error>
            {{ errors.description }}
          </template>
        </FormTextarea>
        <p class="text-imperium-fg-muted text-xs mt-2">
          Brief description of the role's main responsibilities and the main differences between other roles. with a
          link
        </p>

        <div class="flex gap-2 my-4 items-center justify-end">
          <Button
            :color="BUTTON_COLOR.DEFAULT"
            :size="SIZES.MEDIUM"
            data-testid="restore-modal-close-button"
            :visual-type="BUTTON_TYPE.TERTIARY"
            @click="emit('onCancel')"
          >
            Cancel
          </Button>
          <Button
            :size="SIZES.MEDIUM"
            :visual-type="BUTTON_TYPE.PRIMARY"
            type="submit"
            data-testid="restore-modal-restore-button"
            @click="emit('onSave')"
          >
            <template v-if="props.isEdit"> Save Changes </template>
            <template v-if="!props.isEdit"> Save a new role </template>
          </Button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import FormInput from '@/components/FormInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';
import Button from '@/components/Button.vue';

interface Props {
  isEdit?: boolean;
  name: string;
  description: string;
  nameAttr: Record<string, unknown>;
  descriptionAttr: Record<string, unknown>;
  nameErrored: boolean;
  descriptionErrored: boolean;
  errors: object;
}

const props = withDefaults(defineProps<Props>(), {
  isEdit: false,
});

const emit = defineEmits<{
  onSave: [void];
  onCancel: [void];
  'update:name': [value: string];
  'update:description': [value: string];
}>();
</script>
