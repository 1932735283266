<template>
  <Toast
    :id="props.id"
    message="Please try again later"
    title="User restoration failed"
    :theme="THEMES.DANGER"
  >
    <Button
      data-testid="user-edit-button-toast"
      :visual-type="BUTTON_TYPE.PRIMARY"
      :size="SIZES.SMALL"
      @click="$emit('close-toast')"
    >
      Restore user again
    </Button>
  </Toast>
</template>
<script setup lang="ts">
import Toast from '@/components/Toast.vue';
import Button from '@/components/Button.vue';
import { BUTTON_TYPE, SIZES, THEMES } from '@/types';

const props = defineProps<{
  id: string;
}>();
</script>
