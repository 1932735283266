<template>
  <div>
    <div
      v-if="isLoading"
      class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
    >
      <div class="h-10 w-10">
        <LoaderIcon class="w-10 h-10" />
      </div>
    </div>
    <Table
      v-if="!isLoading"
      class="max-w-full relative overflow-x-auto"
    >
      <TableHead>
        <TableHeadRow
          :size="SIZES.MEDIUM"
          :bordered="TABLE_BORDERED.BOTTOM"
        >
          <TableHeadCell
            :size="SIZES.SMALL"
            :scale="TABLE_SCALE.DOUBLE"
            is-uppercase
            is-full-width
          >
            Permission slug
          </TableHeadCell>
          <TableHeadCell
            :size="SIZES.MEDIUM"
            :scale="TABLE_SCALE.DOUBLE"
            is-uppercase
            :align="TABLE_ALIGN.CENTER"
          >
            Edit
          </TableHeadCell>
          <TableHeadCell
            :size="SIZES.MEDIUM"
            :scale="TABLE_SCALE.DOUBLE"
            is-uppercase
            :align="TABLE_ALIGN.CENTER"
          >
            View
          </TableHeadCell>
          <TableHeadCell
            :size="SIZES.MEDIUM"
            :scale="TABLE_SCALE.DOUBLE"
            is-uppercase
            :align="TABLE_ALIGN.CENTER"
          >
            Hide
          </TableHeadCell>
          <TableHeadCell
            :size="SIZES.SMALL"
            :scale="TABLE_SCALE.DOUBLE"
            is-uppercase
            is-full-width
            :align="TABLE_ALIGN.RIGHT"
          >
            Conditions
          </TableHeadCell>
        </TableHeadRow>
      </TableHead>

      <TableBody v-if="!!mappedPermissions?.length">
        <TableRow
          v-for="permission in mappedPermissions"
          :key="`permission-${permission.id}`"
          :size="SIZES.MEDIUM"
          :bordered="TABLE_BORDERED.BOTTOM"
          class="cursor-pointer"
        >
          <TableCell
            :scale="TABLE_SCALE.DOUBLE"
            :vertical-align="TABLE_VALIGN.TOP"
            is-uppercase
            is-full-width
            class="truncate"
          >
            <span class="text-imperium-fg-muted">{{ permission.slug }}</span>
          </TableCell>
          <TableCell
            :scale="TABLE_SCALE.DOUBLE"
            :vertical-align="TABLE_VALIGN.TOP"
            is-uppercase
            :align="TABLE_ALIGN.CENTER"
          >
            <RadioButton
              :model-value="permission.selectedOption"
              value="edit"
              :name="`permission-${permission.slug}`"
              @change="() => updatePermission(permission, PERMISSION_SELECTED_OPTION.EDIT)"
            />
          </TableCell>
          <TableCell
            :scale="TABLE_SCALE.DOUBLE"
            :vertical-align="TABLE_VALIGN.TOP"
            is-uppercase
            :align="TABLE_ALIGN.CENTER"
          >
            <RadioButton
              :model-value="permission.selectedOption"
              value="view"
              :name="`permission-${permission.slug}`"
              @change="() => updatePermission(permission, PERMISSION_SELECTED_OPTION.VIEW)"
            />
          </TableCell>
          <TableCell
            :scale="TABLE_SCALE.DOUBLE"
            :vertical-align="TABLE_VALIGN.TOP"
            is-uppercase
            :align="TABLE_ALIGN.CENTER"
          >
            <RadioButton
              :model-value="permission.selectedOption"
              value="hide"
              :name="`permission-${permission.slug}`"
              @change="() => updatePermission(permission, PERMISSION_SELECTED_OPTION.HIDE)"
            />
          </TableCell>
          <TableCell
            :scale="TABLE_SCALE.DOUBLE"
            :vertical-align="TABLE_VALIGN.TOP"
            is-uppercase
            :align="TABLE_ALIGN.LEFT"
          >
            <FormMultiDropdown
              name="conditions"
              :model-value="permission.conditions"
              class="mb-2"
              :label-position="INPUT_LABEL_POSITION.TOP"
              :size="SIZES.SMALL"
              :values="mappedConditions"
              single-line
              @update:model-value="
                (value) => updatePermission(permission, PERMISSION_SELECTED_OPTION.CONDITIONS, value)
              "
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
</template>
<script setup lang="ts">
import { INPUT_LABEL_POSITION, SIZES, TABLE_ALIGN, TABLE_BORDERED, TABLE_SCALE, TABLE_VALIGN } from '@/types';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow, TableRow } from '@/components/Table';
import RadioButton from '@/components/RadioButton.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';

import { type SelectItem } from '@/components/FormSelect.vue';
import { type Permission } from '@/features/Permissions/types';
import { computed } from 'vue';
import FormMultiDropdown from '@/components/FormMultiDropdown.vue';
import { PERMISSION_SELECTED_OPTION } from '@/features/Roles/types';
import { useFetchConditions } from '@/features/Conditions/queries/useFetchConditions';
import type { Condition } from '@/features/Conditions/types';
import { useRolePermissions } from '@/features/Roles/composable/useRolePermissions';
import { useRoute } from 'vue-router';

interface Props {
  isLoading: boolean;
  permissions: Permission[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  onPermissionUpdate: [value: Permission];
}>();

const conditions = useFetchConditions();
const rolePermissions = useRolePermissions();

const route = useRoute();

const roleIdFromRoute = computed(() => route.params.id);

const mappedConditions = computed(() => {
  if (!conditions.data.value) return [];

  return (
    conditions.data?.value.map((condition: Condition) => {
      return {
        id: condition.slug,
        label: condition.description,
      };
    }) ?? []
  );
});

const mappedPermissions = computed(() => {
  if (!props.permissions) return [];

  return (
    props.permissions.map((permission: Permission) => {
      return {
        ...permission,
        selectedOption: getSelectedOption(permission),
      };
    }) ?? []
  );
});

const getSelectedOption = (permission: Permission): string => {
  if (permission?.hide) return PERMISSION_SELECTED_OPTION.HIDE;

  if (permission?.edit) return PERMISSION_SELECTED_OPTION.EDIT;

  return PERMISSION_SELECTED_OPTION.VIEW;
};

const updatePermission = (permission: Permission, selectedOption: PERMISSION_SELECTED_OPTION, condition?: string) => {
  if (selectedOption === PERMISSION_SELECTED_OPTION.EDIT) {
    onPermissionUpdate({
      ...permission,
      edit: true,
      hide: false,
      conditions: [...permission.conditions],
    });
    rolePermissions.updatePermission(roleIdFromRoute.value, {
      id: permission.id,
      edit: true,
    });
  }
  if (selectedOption === PERMISSION_SELECTED_OPTION.VIEW) {
    onPermissionUpdate({
      ...permission,
      view: true,
      hide: false,
      edit: false,
      conditions: [...permission.conditions],
    });
    rolePermissions.addPermission(roleIdFromRoute.value, {
      id: permission.id,
      edit: false,
    });
  }
  if (selectedOption === PERMISSION_SELECTED_OPTION.HIDE) {
    onPermissionUpdate({
      ...permission,
      hide: true,
      conditions: [],
    });
    rolePermissions.deletePermission(roleIdFromRoute.value, permission.id);
  }

  if (condition && selectedOption === PERMISSION_SELECTED_OPTION.CONDITIONS) {
    onPermissionUpdate({
      ...permission,
      edit: true,
      hide: false,
      conditions: condition,
    });
    rolePermissions.updatePermission(roleIdFromRoute.value, {
      id: permission.id,
      edit: true,
      conditions: condition,
    });
  }
};

const onPermissionUpdate = (value: Permission) => {
  emit('onPermissionUpdate', value);
};
</script>
