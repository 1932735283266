import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import {
  CATEGORY_FIELD_DESCRIPTION,
  CATEGORY_FIELD_NAME,
  CATEGORY_FIELD_REMOVE_CATEGORY,
  CATEGORY_FIELD_SHOW_ON_WEBSITE,
  CATEGORY_FIELD_URL,
  CATEGORY_FIELD_META_DESCRIPTION,
  CATEGORY_FIELD_META_KEYWORDS,
  CATEGORY_FIELD_META_TITLE,
  CATEGORY_FIELD_ENABLED,
  CATEGORY_FIELD_LABELS,
} from '@/features/Categories/constants';

const categoryFormSchema = zod.object({
  [CATEGORY_FIELD_NAME]: zod.string().min(1, { message: 'This is a mandatory field.' }),
  [CATEGORY_FIELD_DESCRIPTION]: zod.string().min(1, { message: 'This is a mandatory field.' }),
  [CATEGORY_FIELD_URL]: zod.string().min(1, { message: 'This is a mandatory field.' }),
  [CATEGORY_FIELD_REMOVE_CATEGORY]: zod.boolean().default(false),
  [CATEGORY_FIELD_SHOW_ON_WEBSITE]: zod.boolean().default(true),
  [CATEGORY_FIELD_ENABLED]: zod.boolean().default(true),
  [CATEGORY_FIELD_META_TITLE]: zod.string().min(1, { message: 'This is a mandatory field.' }),
  [CATEGORY_FIELD_META_DESCRIPTION]: zod.string().min(1, { message: 'This is a mandatory field.' }),
  [CATEGORY_FIELD_META_KEYWORDS]: zod.string().optional(),
  [CATEGORY_FIELD_LABELS]: zod.array(zod.number()).min(1, { message: 'This is a mandatory field.' }),
});

export const categoriesFormSchema: unknown = toTypedSchema(categoryFormSchema);
