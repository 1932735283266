import BoldIcon from '@/assets/icons/editor/bold.svg?raw';

import { markItem } from '@/features/CollaborativeEditor/utils/blocks';
import type { MarkType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';

export const buildBold = (mark: MarkType): InstallableBlock => {
  const boldIconNode = new DOMParser().parseFromString(BoldIcon, 'text/html').body.firstElementChild;
  const item = markItem(mark, {
    render: () => {
      const buttonContainer = document.createElement('div');
      buttonContainer.id = 'bold-text-button';
      buttonContainer.className = 'ProseMirror-icon';

      if (boldIconNode) {
        buttonContainer.appendChild(boldIconNode.cloneNode(true));
      }

      return buttonContainer;
    },
    title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.B),
    icon: {
      dom: boldIconNode as Node,
    },
  });

  return {
    key: 'toggleStrong',
    item,
  };
};
