<template>
  <div
    :data-testid="`tag-${props.id}`"
    :class="{
      // Themes (color)
      'bg-imperium-bg-1 border border-imperium-border-base text-imperium-fg-subtle inline-flex items-center font-semibold rounded':
        props.isDisabled && props.theme === THEMES.PRIMARY,
      'bg-imperium-bg-3 border border-imperium-bg-4 inline-flex items-center text-imperium-fg-base font-semibold rounded':
        !props.isDisabled && props.theme === THEMES.PRIMARY,

      'px-1 py-0 text-xs': props.size === SIZES.SMALL,
      'px-2 py-1 text-sm': props.size === SIZES.MEDIUM,

      'cursor-default': props.isClosable || props.isDisabled,
      'cursor-pointer hover:bg-imperium-bg-4': !props.isClosable && !props.isDisabled && props.isInteractive,
      'truncate !block': props.singleLine,
    }"
  >
    {{ props.label }}
    <button
      v-if="props.isClosable"
      type="button"
      :data-testid="`tag-cancel-button-${props.id}`"
      class="inline-flex cursor-pointer items-center ml-1 p-0.5 text-sm text-imperium-fg-muted bg-transparent rounded-3xl hover:bg-imperium-bg-4"
      :class="{
        'absolute right-2': props.singleLine,
      }"
      :data-dismiss-target="`tag-${props.id}`"
      aria-label="Remove"
      @click="click"
    >
      <Close class="w-4 h-4" />
      <span class="sr-only">Remove tag</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import Close from '@/assets/icons/close.svg?component';
import { SIZES, THEMES, VARIANTS } from '@/types';

const props = withDefaults(
  defineProps<{
    label: string;
    id: string;
    isDisabled?: boolean;
    isClosable?: boolean;
    isInteractive?: boolean;
    theme?: THEMES;
    variant?: VARIANTS;
    size?: SIZES;
    // in case you need selected value be in a single line
    singleLine?: boolean;
  }>(),
  {
    isClosable: true,
    isDisabled: false,
    isInteractive: true,
    theme: THEMES.PRIMARY,
    variant: VARIANTS.SOLID,
    size: SIZES.MEDIUM,
  },
);

const emits = defineEmits<{
  (event: 'click'): void;
}>();

const click = (event: Event) => {
  event.stopPropagation();
  emits('click');
};
</script>
