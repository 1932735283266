export const ACTIVITY_TABLE = [
  {
    changed: '1 minutes ago',
    title: 'Bitcoin faces ‘sell the news event’ before 2024 BTC price all-time high',
    author: 'Aaron Wood',
    action: 'changed status to Ready to review',
    type: 'change',
  },
  {
    changed: '16 minutes ago',
    title: 'Bitcoin faces ‘sell the news event’ before 2024 BTC price all-time high',
    author: 'Aaron Wood',
    action: 'commented',
    type: 'comment',
  },
  {
    changed: '16 minutes ago',
    title: 'Bitcoin ETFs hype stalled by due diligence — Bloomberg',
    author: 'Aaron Wood',
    action: 'approved art cover',
    type: 'cover',
  },
  {
    changed: '20 minutes ago',
    title: 'Bitcoin ETFs hype stalled by due diligence — Bloomberg',
    author: 'Aaron Wood',
    action: 'set art cover',
    type: 'cover',
  },
  {
    changed: '1 hour ago',
    title: 'New Articles',
    author: 'Aaron Wood',
    action: 'created New Article',
    type: 'create',
  },
];

export const AUTHORS_TABLE = [
  {
    author: 'Aaron Wood',
    published: '20',
    original: '20',
  },
  {
    author: 'William Suberg',
    published: '17',
    original: '17',
  },
  {
    author: 'Tom Mitchelhill',
    published: '17',
    original: '17',
  },
  {
    author: 'Arijit Sarkar',
    published: '14',
    original: '14',
  },
  {
    author: 'Ezra Reguerra',
    published: '14',
    original: '14',
  },
];

export const SCHEDULED_TABLE = [
  {
    scheduled: 'Today, 3:32 PM',
    title: 'BlackRock and Fidelity Bitcoin ETFs reach top 10 in January flows',
    author: 'Ezra Reguerra',
    category: 'Latest News',
    words: '387',
  },
  {
    scheduled: 'Today, 8:50 PM',
    title: 'Bitcoin faces ‘sell the news event’ before 2024 BTC price all-time high',
    author: 'William Suberg',
    category: 'Market Analysis',
    words: '955',
  },
  {
    scheduled: 'Feb 10, 9:59 AM',
    title: 'Nigerian analysts refute claims of crypto role in Bureau De Change shutdown',
    author: 'Prashant Jha',
    category: 'Market Analysis',
    words: '1048',
  },
];
