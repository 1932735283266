<template>
  <nav
    class="w-full mx-auto px-4 py-2 sm:px-6 sm:py-3 flex"
    data-testid="breadcrumbs"
  >
    <template
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
    >
      <div
        v-if="breadcrumb.name"
        class="inline-flex items-center"
        :class="{ truncate: index === breadcrumbs.length - 1 }"
      >
        <RouterLink
          :to="breadcrumb.to"
          class="ms-1 text-sm truncate pointer-events-none"
          :class="{
            'text-imperium-fg-base': breadcrumb.active,
            'text-imperium-fg-muted': !breadcrumb.active,
            'ms-1 text-base font-semibold text-imperium-fg-base': breadcrumbs.length - 1 === index,
            'hover:underline pointer-events-auto': index < breadcrumbs.length - 1,
          }"
          :data-testid="`breadcrumbs-${breadcrumb.name}`"
        >
          <span v-if="index < breadcrumbs.length - 1">{{ t(`breadcrumbs.${breadcrumb.name}`) }}</span>
          <span v-else>{{ getCrumbName(breadcrumb.name) }}</span>
        </RouterLink>

        <CaretRight
          v-if="breadcrumbs.length - 1 !== index"
          class="rtl:rotate-180 w-2 h-2 text-imperium-fg-subtle ml-2 mr-1 mt-0.5"
        />
      </div>
    </template>
  </nav>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import CaretRight from '@/assets/icons/caret-right.svg?component';

import type { LinkEntity } from '@/types';
import { useCurrentDocument } from '@/features/ArticleLayout/composables';

const { t } = useI18n();
const { data: articleData } = useCurrentDocument();

const route = useRoute();

const isArticlePage = computed(() => route.name === 'article');

const getCrumbName = (name: string): string => {
  if (!articleData || !articleData.value || !articleData.value.title) return t(`breadcrumbs.${name}`);

  return isArticlePage.value ? articleData.value.title : t(`breadcrumbs.${name}`);
};

const breadcrumbs = computed<LinkEntity[]>(() => {
  const crumbs = [
    {
      name: 'home',
      to: '/',
      active: false,
    },
  ];
  if (route.matched.length) {
    const mappedMatchedRoutes = route.matched
      .map((matchedRoute) => ({
        name: matchedRoute.name?.toString() || '',
        to: matchedRoute.path,
        active: matchedRoute.path === route.fullPath,
      }))
      .filter((crumb) => crumb.name);

    mappedMatchedRoutes.forEach((crumb) => {
      crumbs.push(crumb);
    });
    return crumbs;
  }

  return crumbs;
});
</script>
