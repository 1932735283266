<template>
  <div class="space-y-4 flex-col">
    <div
      v-for="item of list"
      :key="item.value"
    >
      <FormCheckbox
        :id="item.value"
        :name="item.value"
        :model-value="currentValue.has(item.value)"
        @update:model-value="update(item.value)"
      >
        {{ item.label }}
      </FormCheckbox>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import FormCheckbox from '@/components/FormCheckbox.vue';

export interface ListItem {
  label: string;
  value: string;
}

const props = withDefaults(
  defineProps<{
    value: string[];
    list: ListItem[];
  }>(),
  {
    value: () => [],
    list: () => [],
  },
);

const currentValue = ref<Set<string>>(new Set<string>(props.value));

watchEffect(() => {
  currentValue.value = new Set(props.value);
});

const update = (itemValue: string) => {
  if (currentValue.value.has(itemValue)) {
    currentValue.value.delete(itemValue);
  } else {
    currentValue.value.add(itemValue);
  }

  emits('update', Array.from(currentValue.value));
};

const emits = defineEmits<{
  (event: 'update', value: string[]): void;
}>();
</script>
