<template>
  <div class="flex flex-col h-full justify-between">
    <div class="flex flex-col">
      <div class="flex text-imperium-fg-strong items-center whitespace-nowrap cursor-pointer">
        <Avatar
          :id="props.userData?.id"
          :img="props.userData?.avatar"
          :label="initials"
          :size="SIZES.SMALL"
        />
        <div class="pl-2 max-w-full flex flex-col">
          <div
            data-testid="name-surname"
            class="truncate block text-base font-semibold text-imperium-fg-base line-clamp-1"
          >
            {{ props.userData?.name }}
          </div>
          <div
            data-testid="email"
            class="truncate block text-xs line-clamp-1 text-imperium-fg-muted"
          >
            {{ props.userData?.email }}
          </div>
        </div>
      </div>
      <div
        class="mt-3 text-sm font-normal text-imperium-fg-base"
        data-testid="user-view-invitation-date"
      >
        Invitation date: {{ createdAt }}
      </div>
      <div class="mt-4">
        <div class="grid grid-cols-[130px,1fr] gap-4">
          <div class="font-semibold text-sm flex items-center">
            <div>User role:</div>
          </div>
          <div
            class="font-normal"
            data-testid="user-view-roles"
          >
            {{ rolesList }}
          </div>
          <div class="font-semibold text-sm flex items-center">
            <div>User status:</div>
          </div>
          <div class="font-normal">
            <UserStatus
              :name="`status-${userData.id}`"
              :status="userData.status"
            />
          </div>
          <div
            class="font-semibold text-sm flex items-center"
            data-testid="user-view-languages"
          >
            <div>Language access:</div>
          </div>
          <div class="font-normal">{{ languagesList }}</div>
          <div
            v-if="socialNetworks.length"
            class="font-semibold text-sm flex items-center"
          >
            <div>Social networks:</div>
          </div>
          <div
            v-if="socialNetworks.length"
            class="font-normal"
          >
            <UserSocialNetworks :social-networks="socialNetworks" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-2">
      <Button
        v-if="props.userData.status !== UserStatuses.DEACTIVATED && changeUserStatusView"
        :color="BUTTON_COLOR.PRIMARY"
        :is-disabled="props.userData.status !== UserStatuses.ACTIVE || !changeUserStatusEdit"
        :size="SIZES.MEDIUM"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        data-testid="deactivate-user-button"
        :visual-type="BUTTON_TYPE.SECONDARY"
        @click="emits('deactivate')"
      >
        Deactivate
      </Button>
      <Button
        v-else-if="changeUserStatusView"
        :color="BUTTON_COLOR.PRIMARY"
        :size="SIZES.MEDIUM"
        data-testid="restore-user-button"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :visual-type="BUTTON_TYPE.SECONDARY"
        @click="emits('restore')"
      >
        Restore
      </Button>
      <Button
        :size="SIZES.MEDIUM"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-disabled="!changeUserStatusEdit"
        is-full-width
        data-testid="edit-user-button"
        @click="emits('edit')"
      >
        Edit
      </Button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, type Language, type Role, SIZES, UserStatuses } from '@/types';
import Avatar from '@/components/Avatar.vue';
import Button from '@/components/Button.vue';
import { computed } from 'vue';
import { getInitials } from '@/utils/string/initials';
import UserStatus from '@/features/Users/components/UsersTable/UserRow/UserStatus.vue';
import { TOTAL_LANGUAGES } from '@/constants';
import UserSocialNetworks from '@/features/Users/components/UserInfo/UserSocialNetworks.vue';
import type { User } from '@/features/Users/types';
import { useUserPermissions } from '@/stores/user.store';

const props = defineProps<{
  userData: User;
}>();

const emits = defineEmits<{
  (event: 'edit'): void;
  (event: 'deactivate'): void;
  (event: 'restore'): void;
}>();

const { changeUserStatusView, changeUserStatusEdit } = useUserPermissions();

const initials = computed(() =>
  getInitials({
    name: props.userData?.name?.split(' ')[0] || '',
    surname: props.userData?.name?.split(' ')[1] || '',
  }),
);

const createdAt = computed(() => {
  const date = new Date(props.userData.createdAt);
  const month = date.toLocaleString('default', { month: 'short' });
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
});

const rolesList = computed(() => props.userData?.roles?.map((value: Role) => value.name).join(', '));
const languagesList = computed(() => {
  if (props.userData?.languages?.length === TOTAL_LANGUAGES) {
    return 'All languages';
  }

  return props?.userData?.languages
    ?.map((value: Language) => (value.title === 'English' ? 'Global' : value.title))
    .join(', ');
});

const socialNetworks = computed(() => {
  const result = [];
  if (props.userData.twitter) {
    result.push({ name: 'twitter', url: `https://x.com/${props.userData.twitter}` });
  }
  if (props.userData.telegram) {
    result.push({ name: 'telegram', url: `https://t.me/${props.userData.telegram}` });
  }
  if (props.userData.facebook) {
    result.push({ name: 'facebook', url: `https://www.facebook.com/profile.php?id=${props.userData.facebook}` });
  }

  return result;
});
</script>
