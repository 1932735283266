import { useQuery } from '@tanstack/vue-query';
import type { RolesFetchRequest, RolesFetchResponse } from '../types';
import type { ComputedRef } from 'vue';
import { ROLE_RESOURCE } from '@/features/Roles/constants';
import { fetchRole } from '@/features/Roles/api';

type OnSuccessCallback = (result: RolesFetchResponse) => void;

interface useFetchRoleParams {
  id: string | number;
}
export const useFetchRole = (params: ComputedRef<useFetchRoleParams>, onSuccess: OnSuccessCallback | null = null) => {
  const { data, isLoading } = useQuery({
    queryKey: [ROLE_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: RolesFetchRequest = queryKey[1] as useFetchRoleParams;

      if (!payload.id) return null;

      const result = await fetchRole(payload.id);

      if (onSuccess) {
        onSuccess(result);
      }

      return result;
    },

    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
  });

  return {
    data,
    isLoading,
  };
};
