import { useUserPermissions } from '@/stores/user.store';
import { toRaw } from 'vue';

interface AccessGranted {
  view: boolean;
  edit: boolean;
}
export const isAccessGranted = (place: string): AccessGranted | null => {
  const state = useUserPermissions().state;

  if (!(toRaw(state) && !!toRaw(state).length)) return null;

  return {
    view: !!toRaw(state).find((val) => val.slug === place),
    edit: !!toRaw(state).find((val) => val.slug === place && val.edit),
  };
};
