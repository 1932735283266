<template>
  <div class="relative">
    <label>
      <span
        v-if="isLabel"
        :class="
          labelClass({
            size: props.size,
            labelPosition: props.labelPosition,
            isDisabled: props.isDisabled,
            isErrored: props.isErrored,
            isSuccess: props.isSuccess,
          })
        "
      >
        <div class="flex justify-between">
          <div>
            <slot name="label" />
            <template v-if="props.isRequired">*</template>
          </div>
          <div
            v-if="props.max"
            class="select-none text-imperium-fg-muted"
            :class="props.size === SIZES.SMALL ? 'text-xs' : 'text-sm'"
          >
            {{ props.modelValue?.length || 0 }}/{{ props.max }}
          </div>
        </div>
      </span>

      <div class="relative">
        <textarea
          ref="textareaInput"
          v-model="inputValue"
          v-bind="props.attrs"
          :name="props.name"
          :placeholder="props.placeholder"
          :class="
            inputClass({
              size: props.size,
              rounded: props.rounded,
              visualType: props.visualType,
              isErrored: props.isErrored,
              isSuccess: props.isSuccess,
              isPrefix: isPrefix,
              isSuffix: isSuffix,
            })
          "
          :disabled="props.isDisabled"
          class="py-1.5"
          :required="props.isRequired"
          :maxlength="props.max || ''"
          :data-testid="`textarea-${props.name}`"
          @input="onInputChange"
        />
      </div>

      <div
        v-if="isHelp"
        :data-testid="`input-${props.name}-help`"
        :class="
          labelClass({
            size: props.size,
            isInfo: true,
          })
        "
        class="mt-1"
      >
        <slot name="help" />
      </div>

      <div
        v-if="props.isErrored && isError"
        class="mt-1"
        :class="
          labelClass({
            size: props.size,
            isErrored: props.isErrored,
          })
        "
        :data-testid="`input-${props.name}-error`"
      >
        <slot name="error" />
      </div>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, useSlots, watchEffect } from 'vue';
import { INPUT_ROUNDED, INPUT_TYPE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';
import noRussian, { hasCyrillic } from '@/helpers/no-russian';

const TEXTAREA_HEIGHT = {
  [SIZES.SMALL]: '34px',
  [SIZES.MEDIUM]: '40px',
};

const props = withDefaults(
  defineProps<{
    modelValue: string;
    name: string;
    placeholder?: string;
    max?: number;
    attrs?: Record<string, unknown>;
    minHeight?: string;

    isDisabled?: boolean;
    isSuccess?: boolean;
    isErrored?: boolean;
    isRequired?: boolean;

    size?: SIZES;
    rounded?: INPUT_ROUNDED;
    visualType?: INPUT_TYPE;
  }>(),
  {
    isDisabled: false,
    isErrored: false,
    isRequired: false,
    isSuccess: false,
    minHeight: '34px',

    size: SIZES.MEDIUM,
    rounded: INPUT_ROUNDED.DEFAULT,
    visualType: INPUT_TYPE.PRIMARY,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const slots = useSlots();

const inputValue = ref<string>(props.modelValue);
const textareaInput = ref<HTMLTextAreaElement>();

const isHelp = computed(() => !!slots.help);
const isLabel = computed<boolean>(() => !!slots.label);
const isError = computed<boolean>(() => !!slots.error);

const modelValueLength = computed<number>(() => props.modelValue?.length ?? 0);

const labelClass = tv({
  base: 'inline-block block input-meta-text-default mb-2 w-full',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-meta-text-sm',
      [SIZES.MEDIUM]: 'input-meta-text-md',
    },
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isSuccess: {
      true: 'input-meta-text-success',
    },
    isDisabled: {
      true: 'input-meta-text-disabled',
    },
    isInfo: {
      true: 'input-meta-helper-text',
    },
  },
});

const inputClass = tv({
  base: `break-words resize-none block w-full scrollbar-thin`,
  variants: {
    size: {
      [SIZES.SMALL]: `input-sm h-[${TEXTAREA_HEIGHT[SIZES.SMALL]}]`,
      [SIZES.MEDIUM]: `input-md h-[${TEXTAREA_HEIGHT[SIZES.MEDIUM]}]`,
    },
    rounded: {
      [INPUT_ROUNDED.DEFAULT]: 'input-rounded-default',
      [INPUT_ROUNDED.FULL]: 'input-rounded-full',
    },
    visualType: {
      [INPUT_TYPE.PRIMARY]: 'input-primary',
      [INPUT_TYPE.PLAIN]: 'input-plain',
      // For multiselect
      [INPUT_TYPE.INVISIBLE]: 'input-invisible',
    },
    isPrefix: {
      true: 'pl-10',
    },
    isSuffix: {
      true: 'pr-10',
    },
  },

  compoundVariants: [
    {
      isErrored: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-success',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-success',
    },
  ],
});

const setTextAreaHeight = (textarea: HTMLTextAreaElement) => {
  const height = props.minHeight
    ? props.minHeight
    : props.size === SIZES.SMALL
      ? TEXTAREA_HEIGHT[SIZES.SMALL]
      : TEXTAREA_HEIGHT[SIZES.MEDIUM];
  textarea.style.height = height;
  textarea.style.height =
    Math.min(textarea.scrollHeight || props.minHeight, 5 * parseFloat(getComputedStyle(textarea).lineHeight)) + 'px';
};

const onInputChange = (e: Event) => {
  setTextAreaHeight(textareaInput.value);
  if (hasCyrillic((e.target as HTMLInputElement).value) && (e.target as HTMLInputElement).value.length > 1) {
    inputValue.value = props.modelValue;
  } else {
    const validValue = noRussian((e.target as HTMLInputElement).value);
    emits('update:modelValue', validValue);
    inputValue.value = validValue;
  }
};

watchEffect(() => {
  if (textareaInput.value) {
    nextTick(() => {
      setTextAreaHeight(textareaInput.value);
    });
  }
  inputValue.value = props.modelValue;
});
</script>
