import Cookies from 'js-cookie';

interface SetKeyOptions {
  expires: Date;
}

const attributes = { path: '/', sameSite: 'None', secure: true };

// @ts-expect-error Cookies have any type
const cookieStore = Cookies.withAttributes(attributes);

export const CookieStoreService = {
  get(key: string, defaultValue?: string | number) {
    return cookieStore.get(key) || defaultValue;
  },
  set(key: string, value: string, options?: SetKeyOptions) {
    return cookieStore.set(key, value, options);
  },
  remove(key: string) {
    // @ts-expect-error Cookies have any type
    return cookieStore.remove(key);
  },
};
