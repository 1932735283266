<template>
  <tr>
    <td
      v-for="(column, colIndex) in columnsArray"
      :key="colIndex"
      class="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
    >
      <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  columns: number;
}

const props = withDefaults(defineProps<Props>(), {
  columns: 10,
});

const columnsArray = computed(() => {
  return Array.from({ length: props.columns });
});
</script>
