import { type User } from './types';
import { type ROLES } from '@/types';
import { type SelectItem } from '@/components/FormSelect.vue';

export const mapUsersToSelectItemByRole = (users: User[], filterRole: ROLES): SelectItem[] => {
  const result: SelectItem[] = [];

  if (!users || !users.length) {
    return [];
  }

  users.map((user) => {
    if (~user.roles.findIndex((role) => role?.slug === filterRole)) {
      result.push({
        id: user.id,
        label: user.name,
      });
    }
  });

  return result;
};

export const mapUsersToSelectItem = (users: User[]): SelectItem[] => {
  if (!users || !users.length) {
    return [];
  }

  return users.map((user) => ({
    id: user.id,
    label: user.name,
  }));
};
