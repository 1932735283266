<template>
  <div class="flex gap-2">
    <div
      class="w-8 min-w-8 h-8 min-h-8 flex items-center justify-center rounded-full bg-imperium-bg-3 text-xs font-semibold"
    >
      {{ props.badgeNumber }}
    </div>
    <div class="flex flex-col">
      <h3 class="font-semibold text-base leading-8">{{ props.title }}</h3>
      <p class="text-imperium-fg-muted text-xs">{{ props.description }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
interface Props {
  badgeNumber: string;
  title: string;
  description: string;
}
const props = defineProps<Props>();
</script>
