<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Date & Schedule</h3>

    <div v-if="documentMetadataScheduledView">
      <span class="inline-block text-xs mb-2">Schedule publication</span>
      <FormDatepicker
        :is-disabled="!documentMetadataScheduledEdit"
        name="publish"
        class="mb-2"
        placeholder="Not selected"
        :model-value="publish"
        :size="SIZES.SMALL"
        :attrs="publishAttrs"
        @update:model-value="(value) => emits('update:publish', value)"
      />
    </div>

    <div v-if="documentMetadataEmbargoView">
      <span class="inline-block text-xs mb-2">Embargo</span>
      <FormDatepicker
        name="embargo"
        :is-disabled="!documentMetadataEmbargoEdit"
        class="mb-2"
        placeholder="Not selected"
        :size="SIZES.SMALL"
        :model-value="embargo"
        :attrs="embargoAttrs"
        @update:model-value="(value) => emits('update:embargo', value)"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import FormDatepicker from '@/components/FormDatepicker';
import { SIZES } from '@/types';
import { useUserPermissions } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';

const props = defineProps<{
  deadlineAttrs: Record<string, unknown>;
  publish: string;
  publishAttrs: Record<string, unknown>;
  embargo: string;
  embargoAttrs: Record<string, unknown>;
  writer: number;
}>();

const emits = defineEmits<{
  (event: 'update:deadline', value: string): void;
  (event: 'update:publish', value: string): void;
  (event: 'update:embargo', value: string): void;
}>();

const {
  documentMetadataEmbargoView,
  documentMetadataEmbargoEdit,
  documentMetadataScheduledView,
  documentMetadataScheduledEdit,
} = useUserPermissions();

const { metadataEmbargoCondition } = useUserConditions();
</script>
