export * from './inputs';
export * from './user';
export * from './roles';
export * from './languages';
export * from './document';

export enum PromiseValues {
  'FULFILLED' = 'fulfilled',
  'PENDING' = 'pending',
  REJECTED = 'rejected',
}

export interface LinkEntity {
  name: string;
  to?: string;
  active?: boolean;
  accessGranted?: boolean;

  meta?: Record<string, unknown>;
  position?: 'top' | 'bottom';
}

interface Child extends LinkEntity {
  target?: string;
}

export interface LinkEntityWithNestedLinks extends LinkEntity {
  children?: Child[];
}

export interface EditCoworkerMessagePayload {
  message: string;
}

export enum THEMES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GHOST = 'ghost',

  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
  INFO = 'info',
}

// TODO: should be removed
export const enum VARIANTS {
  SOLID = 'solid',
  INVISIBLE = 'invisible',
  REGULAR = 'regular',

  MUTED = 'muted',
}

export enum SIZES {
  XSMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
}

export const enum BUTTON_TYPE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GHOST = 'ghost',
  PLAIN = 'plain',
  INVISIBLE = 'invisible',
  BASE = 'base',
}

export const enum BUTTON_COLOR {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  GREEN = 'green',
  RED = 'red',
  BASE = 'gold',
}

export const enum BUTTON_ON_SURFACE {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  GRAY = 'gray',
}

export const enum BUTTON_ROUNDED {
  NONE = 'none',
  BASE = 'base',
  LARGE = 'lg',
  FULL = 'full',
}

export const enum INPUT_TYPE {
  PRIMARY = 'primary',
  PLAIN = 'plain',
  INVISIBLE = 'invisible',
}

export const enum INPUT_LABEL_POSITION {
  TOP = 'top',
  LEFT = 'left',
}

export const enum INPUT_ROUNDED {
  DEFAULT = 'default',
  FULL = 'full',
}

export const enum AVATAR_ROUNDED {
  NONE = 'none',
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
  FULL = 'full',
}

export const enum TABLE_BORDERED {
  NONE = 'none',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const enum TABLE_ON_SURFACE {
  DEFAULT = 'default',
  PRIMARY = 'primary',
}

export const enum TABLE_STRIPED {
  NONE = 'none',
  STRIPED = 'striped',
}

export const enum TABLE_PADDINGS {
  NONE = 'none',
  START = 'start',
  END = 'end',
  BOTH = 'both',
}

export const enum TABLE_PADDINGS_VERTICAL {
  NONE = 'none',
  BOTH = 'both',
}

export const enum TABLE_ALIGN {
  LEFT = 'left',
  CENTER = 'middle',
  RIGHT = 'right',
}

export const enum TABLE_VALIGN {
  TOP = 'top',
  MIDDLE = 'middle',
}

export const enum TABLE_VISUAL_TYPE {
  DEFAULT = 'default',
  SUBTLE = 'subtle',
}

export const enum TABLE_SCALE {
  NORMAL = 1,
  DOUBLE = 2,
  TRIPLE = 3,
  QUADRUPLE = 4,
}

export const enum MODAL_TYPES {
  MODAL = 'modal',
  POPUP = 'popup',
}

export const enum BADGE_THEMES {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  GREEN = 'green',
  RED = 'red',
}

export const enum BADGE_VISUAL_TYPE {
  DEFAULT = 'default',
  WEAK = 'weak',
}

export const enum BADGE_ROUNDED {
  DEFAULT = 'default',
  FULL = 'full',
  LARGE = 'large',
  NONE = 'none',
}

export const enum SEGMENT_CONTROL_TABS_COLOR {
  GRAY_5 = 'gray_5',
  GRAY_3 = 'gray_3',
  DEFAULT = 'default',
}
export const enum SEGMENT_CONTROL_TABS_WIDTH {
  STRETCH = 'stretch',
  CONTENT = 'content',
}

export const enum TOGGLE_BUTTON_COLOR {
  WHITE = 'white',
}
