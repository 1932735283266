export const dateBeautifyPeriodFormCurrentDate = (date: string) => {
  if (!date) {
    return '';
  }

  let resultDate: string;

  const now = new Date();
  const past = new Date(date);

  if (past > now) {
    return past.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  }

  const ms = now - past;
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 31) {
    // Format data to "Oct 09, 2019"
    resultDate = past.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
  } else if (days > 0) {
    resultDate = `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    resultDate = `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    resultDate = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    resultDate = `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }

  return resultDate;
};

export const getFormattedDate = (datestring: string) => {
  const date = new Date(datestring);
  const month = date.toLocaleString('default', { month: 'short' });

  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getFormattedTime = (datestring: string) => {
  const date = new Date(datestring);

  const hours = date.getHours() % 12;
  const half = date.getHours() >= 12 ? 'PM' : 'AM';
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes} ${half}`;
};
