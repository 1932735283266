<template>
  <div>
    <div class="flex mb-1 w-full gap-3 flex-nowrap items-center justify-between">
      <Search
        class="md:max-w-[300px]"
        placeholder="Search for users"
        :value="searchQuery"
        @update:modelValue="(value) => updateSearchQuery(value)"
      />
      <div class="flex max-h-10 flex-nowrap w-fit">
        <UserFilters v-if="isFiltersVisible" />
        <InviteUserButton v-if="inviteUserView" />
      </div>
    </div>
    <UsersFilterChips
      v-if="isFiltersVisible"
      class="mb-4"
      :filters="filtersStore.getActiveFiltersForChips()"
      @remove-item="removeFilter"
      @remove-all="removeAllFilters"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, inject } from 'vue';
import Search from '@/components/Table/Filters/Search.vue';
import { useUserFilters } from '@/features/Users/stores/filters.store';
import { type FiltersState } from '@/features/Users/types';
import UsersFilterChips from '@/features/Users/components/UsersTable/UsersFilters/UsersFilterChips.vue';
import InviteUserButton from '@/features/Users/components/UsersTable/InviteUserButton.vue';
import UserFilters from '@/features/Users/components/UsersTable/UsersFilters/UserFilters.vue';
import { useUserPermissions } from '@/stores/user.store';

const filtersStore = useUserFilters();

const isFiltersVisible = inject('isUserFiltersVisible');

const searchQuery = computed(() => filtersStore.state?.searchQuery || '');

const { inviteUserView } = useUserPermissions();

const updateSearchQuery = (searchQuery: string) => {
  filtersStore.setSearchQuery(searchQuery);
};

const updateFilters = <T,>({ filterName, value }: { filterName: string; value: T }) => {
  filtersStore.setFilters({
    [filterName]: value,
  });
};

const removeFilter = (filter: string) => {
  const [filterName, filterValue] = filter.split('.');
  const updatedFilters = (filtersStore.state[filterName as keyof FiltersState] as string[] | null)?.filter(
    (item) => item !== filterValue,
  );

  updateFilters({ filterName, value: updatedFilters });
};

const removeAllFilters = () => {
  filtersStore.removeAllFiltersFromChips();
};
</script>
