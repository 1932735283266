import { computed } from 'vue';
import { useFetchUsers } from '@/features/Users/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItemByRole } from '@/features/Users/utils';
import { ROLES } from '@/types';
import { useFetchRoles } from '@/features/Roles/queries';

export const useEditors = () => {
  const { data: roles } = useFetchRoles();

  const fetchUserParams = computed<UsersFetchPayload>(() => ({
    page: 1,
    itemsPerPage: 1000,
  }));
  const { data: users } = useFetchUsers(fetchUserParams);

  const editors = computed<SelectItem[]>(() => {
    if (!roles.value?.items?.length || !users.value?.items?.length) {
      return [];
    }

    return mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR);
  });

  const copyEditors = computed<SelectItem[]>(() => {
    if (!roles.value?.items?.length || !users.value?.items?.length) {
      return [];
    }

    return mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_COPY_EDITOR);
  });

  return {
    editors,
    copyEditors,
  };
};
