<template>
  <Toast
    :id="props.id"
    :message="props.message"
    :title="props.title"
    :theme="THEMES.DANGER"
  >
    <Button
      :size="SIZES.SMALL"
      data-testid="button-cta"
      class="w-[214px]"
      is-full-width
      @click="$emit('close-toast')"
    >
      Add a new permission again
    </Button>
  </Toast>
</template>

<script setup lang="ts">
import Toast from '@/components/Toast.vue';
import { SIZES, THEMES } from '@/types';
import Button from '@/components/Button.vue';

const props = defineProps<{
  id: string;
  message: string;
  title: string;
}>();
</script>
