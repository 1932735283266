import { languages } from '@/api/languages';
import type { Language } from '@/types/languages';

export const LanguagesService = {
  fetchLanguages: async (): Promise<Language[]> => {
    const data = await languages();
    const engIndex = data.items.findIndex((item) => item.id === 1);
    data.items[engIndex].title = 'Global';
    return data.items;
  },
};
