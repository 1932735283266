<template>
  <div class="flex items-center gap-2 pr-2">
    <template v-if="isNew">
      <Button
        v-if="documentStatusToWorkView"
        :is-disabled="!documentStatusToWorkEdit"
        @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
      >
        Start work
      </Button>
    </template>

    <template v-if="isInProgress">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="emits('update:modelValue', DocumentStatus.NEW)"
      >
        Stop work
      </Button>

      <Button
        v-if="documentStatusToFinishView"
        :is-disabled="!documentStatusToFinishEdit"
        @click="emits('update:modelValue', DocumentStatus.READY_FOR_ART_EDITOR)"
      >
        Finish text
      </Button>
    </template>

    <template v-if="isReadyForArtwork">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
      >
        Back to text
      </Button>

      <Button
        :is-disabled="!documentStatusToFinishArtworkEdit"
        @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
      >
        Finish artwork
      </Button>
    </template>
    <template v-if="isCopyEditorAndNeedsProofreading && isApproveButtonVisible">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="onApproveByCopyEditor"
      >
        Approve
      </Button>
    </template>

    <template v-if="isReadyToReview">
      <Button
        v-if="documentStatusToRejectTextView"
        :is-disabled="!documentStatusToRejectTextEdit"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="emits('update:modelValue', DocumentStatus.TEXT_REJECTED)"
      >
        Reject text
      </Button>

      <Button
        v-if="documentStatusToFinishArtworkView"
        :is-disabled="!documentStatusToFinishArtworkEdit"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="emits('update:modelValue', DocumentStatus.IMAGE_REJECTED)"
      >
        Reject artwork
      </Button>

      <Button
        v-if="documentStatusToFinishReviewView"
        :is-disabled="!documentStatusToFinishReviewEdit"
        @click="emits('update:modelValue', DocumentStatus.READY_TO_PUBLISH)"
      >
        Finish review
      </Button>
    </template>

    <template v-if="isTextRejected && documentStatusToFinishView">
      <Button
        :is-disabled="!documentStatusToFinishEdit"
        @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
      >
        Finish text
      </Button>
    </template>

    <template v-if="isArtworkRejected && documentStatusToFinishArtworkEdit">
      <Button
        :is-disabled="!documentStatusToFinishArtworkEdit"
        @click="emits('update:modelValue', DocumentStatus.READY_TO_REVIEW)"
      >
        Finish artwork
      </Button>
    </template>

    <template v-if="isReadyToPublish">
      <Button
        v-if="documentStatusToPublishView"
        :is-disabled="!documentStatusToPublishEdit"
        @click="revealPublish"
      >
        Publish
      </Button>
    </template>

    <template v-if="isPublished">
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="revealUnpublish"
      >
        Unpublish
      </Button>

      <Button
        v-if="documentStatusToUpdateView"
        :is-disabled="!documentStatusToUpdateEdit"
        @click="revealUpdate"
      >
        Update
      </Button>

      <Button
        v-if="documentStatusToPublishView && isArticleScheduled"
        :is-disabled="!documentStatusToPublishEdit"
        @click="revealPublish"
      >
        Publish now
      </Button>
    </template>

    <template v-if="isUnpublished">
      <Button
        v-if="documentStatusToBackToWorkView"
        :is-disabled="!documentStatusToBackToWorkEdit"
        :visual-type="BUTTON_TYPE.PRIMARY"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :color="BUTTON_COLOR.PRIMARY"
        @click="emits('update:modelValue', DocumentStatus.IN_PROGRESS)"
      >
        Back to work
      </Button>

      <Button
        v-if="documentStatusToPublishView"
        :is-disabled="!documentStatusToPublishEdit"
        @click="revealPublish"
      >
        Publish
      </Button>
    </template>

    <Teleport to="#modals">
      <component
        :is="isArticleEmbargoed && !isArticleScheduled ? PublishEmbargoedModal : PublishModal"
        v-if="isPublishRevealed"
        :is-visible="isPublishRevealed"
        :published-at="props.publishedAt"
        :is-publish-now="isPublishNow"
        @close="cancelPublish"
        @schedule="onConfirmSchedule"
        @publish="onConfirmPublish"
      />

      <UnpublishModal
        :is-visible="isUnpublishRevealed"
        :is-scheduled="isArticleScheduled"
        @close="cancelUnpublish"
        @confirm="onConfirmUnpublish"
      />

      <UpdateModal
        :is-visible="isUpdateRevealed"
        :is-published="isPublished && !isArticleScheduled"
        :can-be-republished="props.canBeRepublished"
        @close="cancelUpdate"
        @update="onConfirmUpdate"
      />
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Button from '@/components/Button.vue';
import { useModal } from '@/composables/useModal';

import UnpublishModal from './modals/UnpublishModal.vue';
import PublishModal from './modals/PublishModal.vue';
import PublishEmbargoedModal from './modals/PublishEmbargoedModal.vue';
import UpdateModal from './modals/UpdateModal.vue';

import { useCurrentDocument } from '../composables';

import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, DocumentStatus, ROLES } from '@/types';
import { useUserPermissions, useUserStore } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';
import { useUser } from '@/queries/useUser';
import { useArticleStore } from '@/features/ArticleLayout/stores/article.store';

const props = defineProps<{
  modelValue: DocumentStatus;
  publishedAt: string;
  hasPost: boolean;
  canBeRepublished: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', status: DocumentStatus): void;
  (event: 'publish', articleUrl: string): void;
  (event: 'schedule', articleUrl: string, embargoedUntil: string | null, publishedAt: string | null): void;
  (event: 'update', isUpdatePublicationDate: boolean): void;
  (event: 'approve', field: string, value: boolean): void;
}>();

const userStore = useUserStore();

const { data: articleData } = useCurrentDocument();
const articleStore = useArticleStore();

const user = computed(() => userStore.state);

const isCopyEditorAndNeedsProofreading = computed(
  () => user.value?.roles?.includes(ROLES.ROLE_COPY_EDITOR) && articleStore.state.needsProofreading,
);

const {
  documentStatusToFinishView,
  documentStatusToFinishEdit,
  documentStatusToFinishArtworkView,
  documentStatusToFinishArtworkEdit,
  documentStatusToBackToWorkView,
  documentStatusToBackToWorkEdit,
  documentStatusToRejectTextView,
  documentStatusToRejectTextEdit,
  documentStatusToFinishReviewView,
  documentStatusToFinishReviewEdit,
  documentStatusToPublishView,
  documentStatusToPublishEdit,
  documentStatusToUpdateView,
  documentStatusToUpdateEdit,
  documentStatusToWorkView,
  documentStatusToWorkEdit,
} = useUserPermissions();

const {
  reveal: revealUnpublish,
  cancel: cancelUnpublish,
  confirm: confirmUnpublish,
  isRevealed: isUnpublishRevealed,
} = useModal();

const {
  reveal: revealPublish,
  cancel: cancelPublish,
  confirm: confirmPublish,
  isRevealed: isPublishRevealed,
} = useModal();

const { reveal: revealUpdate, cancel: cancelUpdate, confirm: confirmUpdate, isRevealed: isUpdateRevealed } = useModal();

const { finishTextCondition, artworkCondition, publishCondition } = useUserConditions();

const isArticleScheduled = computed(() => {
  const currentDate = new Date();
  const publishDate = props.publishedAt ? new Date(props.publishedAt) : currentDate;

  return publishDate.getTime() > currentDate.getTime();
});

const isApproveButtonVisible = computed(
  () =>
    props.modelValue === DocumentStatus.PUBLISHED ||
    props.modelValue === DocumentStatus.READY_TO_REVIEW ||
    props.modelValue === DocumentStatus.READY_FOR_ART_EDITOR ||
    props.modelValue === DocumentStatus.READY_TO_PUBLISH ||
    isArticleScheduled.value,
);

const isArticleEmbargoed = computed(() => !!articleData.value?.embargoUntil);

const isNew = computed(() => props.modelValue === DocumentStatus.NEW);
const isInProgress = computed(() => props.modelValue === DocumentStatus.IN_PROGRESS);
const isReadyForArtwork = computed(() => props.modelValue === DocumentStatus.READY_FOR_ART_EDITOR);
const isReadyToPublish = computed(() => props.modelValue === DocumentStatus.READY_TO_PUBLISH);
const isReadyToReview = computed(() => props.modelValue === DocumentStatus.READY_TO_REVIEW);
const isPublished = computed(() => props.modelValue === DocumentStatus.PUBLISHED);
const isUnpublished = computed(() => props.modelValue === DocumentStatus.UNPUBLISHED);
const isTextRejected = computed(() => props.modelValue === DocumentStatus.TEXT_REJECTED);
const isArtworkRejected = computed(() => props.modelValue === DocumentStatus.IMAGE_REJECTED);

const isPublishNow = computed(() => {
  return isPublished.value && isArticleScheduled.value;
});

const onConfirmSchedule = (articleUrl: string, embargoedUntil: string | null, publishedAt: string | null) => {
  confirmPublish();
  emits('schedule', articleUrl, embargoedUntil, publishedAt);
};

const onConfirmPublish = (articleUrl: string) => {
  confirmPublish();
  emits('publish', articleUrl);
};

const onConfirmUnpublish = () => {
  confirmUnpublish();
  emits('update:modelValue', DocumentStatus.UNPUBLISHED);
};

const onConfirmUpdate = (isUpdatePublicationDate: boolean) => {
  confirmUpdate();
  emits('update', isUpdatePublicationDate);
};

const onApproveByCopyEditor = (): void => {
  emits('approve', 'needsProofreading', false);
};
</script>
