import { Plugin } from 'prosemirror-state';
import { schema } from '@/features/CollaborativeEditor/schema';

export const noImagesPlugin = new Plugin({
  props: {
    handlePaste(view, event, slice) {
      let containsImage = false;
      slice.content.descendants((node) => {
        if (node.type.name === schema.nodes.img.name) {
          containsImage = true;
        }
      });
      if (containsImage) {
        event.preventDefault();
        return true;
      }
      return false;
    },
  },
});
