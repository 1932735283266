import { useQuery } from '@tanstack/vue-query';
import { fetchCategories } from '../api';
import { type ArticleCategoriesFetchRequest } from '../types';
import { type Ref } from 'vue';

import { ARTICLE_CATEGORIES_RESOURCE } from '../constants';

export const useFetchCategories = (params: Ref<ArticleCategoriesFetchRequest>) => {
  const { data, isLoading } = useQuery({
    queryKey: [ARTICLE_CATEGORIES_RESOURCE, params],

    queryFn: ({ queryKey }) => {
      const payload: ArticleCategoriesFetchRequest = queryKey[1] as ArticleCategoriesFetchRequest;

      return fetchCategories(payload);
    },

    staleTime: Infinity,
    refetchInterval: false,
  });

  return {
    data,
    isLoading,
  };
};
