<template>
  <div
    class="h-[156px] border-dotted border border-imperium-border-strong"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="onFileDrop"
    @click="openFileDialog"
  >
    <div
      v-if="!isDragOver"
      class="h-[156px] flex flex-col items-center justify-center cursor-pointer"
    >
      <Button
        :visual-type="BUTTON_TYPE.SECONDARY"
        :color="BUTTON_COLOR.PRIMARY"
        :is-disabled="!uploadMediaEdit"
        :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
        :size="SIZES.SMALL"
      >
        Add image
      </Button>
      <div
        v-if="!isMobile"
        class="text-sm font-semibold text-imperium-fg-muted mt-1"
      >
        or drag and drop
      </div>
      <div class="text-sm font-normal text-imperium-fg-muted mt-3 text-center">
        <p>Max size per image: 1 MB. Min width: 600px.</p>
        <p>Accepted formats: jpeg, jpg, png.</p>
      </div>
    </div>
    <div
      v-else
      class="h-[156px] flex flex-col items-center justify-center cursor-pointer text-imperium-fg-subtle"
    >
      <PictureIcon class="w-10 h-10" />
    </div>

    <input
      ref="fileInput"
      class="h-px w-px hidden"
      type="file"
      @change="onFileChange"
      @click="onClick"
    />
  </div>
</template>
<script setup lang="ts">
import { inject, ref } from 'vue';
import Button from '@/components/Button.vue';
import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_TYPE, SIZES } from '@/types';
import { Input } from 'postcss';
import PictureIcon from '@/assets/icons/picture.svg?component';
import { useUserPermissions } from '@/stores/user.store';

const emits = defineEmits<{
  (event: 'upload', url: FormData): void;
}>();

const { uploadMediaEdit } = useUserPermissions();
const isDragOver = ref<boolean>(false);
const fileInput = ref<Input | null>(null);

const isMobile = inject('isMobile');

const dragOver = () => {
  isDragOver.value = true;
};

const dragLeave = () => {
  isDragOver.value = false;
};

const onFileDrop = (event: any) => {
  const files = event.dataTransfer.files;
  handleFiles(files);
  isDragOver.value = false;
};

const onFileChange = (event: any) => {
  const files = event.target.files;
  handleFiles(files);
};

const handleFiles = (files: FormData[]) => {
  emits('upload', files[0]);
};

const openFileDialog = () => {
  fileInput.value?.click();
};

const onClick = () => {
  fileInput.value.value = null;
};
</script>
