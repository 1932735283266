<template>
  <div class="flex flex-col gap-1">
    <div class="flex justify-end">
      <WordCount :text="modelValue" />
    </div>
    <div
      ref="titleRef"
      :data-testid="`input-${name}`"
      v-bind="props.attrs"
      :name="props.name"
      :placeholder="props.placeholder"
      :type="props.type"
      class="article-title-input leading-normal resize-none overflow-hidden"
      :class="
        inputClass({
          isErrored: props.isErrored,
        })
      "
      :required="props.isRequired"
      :readonly="props.isReadonly"
      rows="1"
      contenteditable
      @input="onInputChange"
    />

    <div
      v-if="props.isErrored && isError"
      :data-testid="`input-${props.name}-error`"
      :class="
        labelClass({
          isErrored: true,
        })
      "
      class="mt-1"
    >
      <slot name="error" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSlots, computed, ref, onMounted, onUnmounted, watchEffect } from 'vue';
import { tv } from 'tailwind-variants';
import { preventLinebreaks, clearHtmlEntities } from '@/utils/string/keyboard';
import noRussian, { hasCyrillic } from '@/helpers/no-russian';
import WordCount from '@/features/WordCount/components/WordCount.vue';
import { useFocus } from '@vueuse/core/index';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    isErrored: boolean;
    attrs?: Record<string, unknown>;
    name?: string;
    type?: string;
    isRequired: boolean;
    placeholder?: string;
    isReadonly?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    type: 'text',
    isErrored: false,
    isRequired: false,
    isReadonly: false,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

const slots = useSlots();

const titleRef = ref<HTMLDivElement | undefined>();
const localText = ref<string>(props.modelValue || '');

const isError = computed(() => !!slots.error);

const { focused } = useFocus(titleRef);

watchEffect(() => {
  if (props.modelValue !== localText.value && !focused.value) {
    localText.value = props.modelValue;
  }

  if (titleRef.value && !focused.value) {
    titleRef.value.textContent = localText.value;
  }
});

const labelClass = tv({
  base: 'inline-block block input-meta-text-default input-meta-text-sm',
  variants: {
    isErrored: {
      true: 'input-meta-text-errored',
    },
  },
});

const inputClass = tv({
  base: 'rounded-lg border-0 ring-0 focus:ring-0 block w-full text-ellipsis text-[33px] text-imperium-fg-strong font-semibold p-0',
  variants: {
    isErrored: {
      true: 'input-primary-errored',
    },
    isDisabled: {
      true: 'pointer-events-none bg-imperium-bg-5 text-imperium-fg-muted',
    },
  },
});

onMounted(() => {
  if (!titleRef.value) {
    return;
  }

  titleRef.value.addEventListener('keypress', preventLinebreaks);
  titleRef.value.addEventListener('paste', clearHtmlEntities);
});

onUnmounted(() => {
  if (titleRef.value) {
    titleRef.value.removeEventListener('keypress', preventLinebreaks);
    titleRef.value.removeEventListener('paste', clearHtmlEntities);
  }
});

const onInputChange = (e: Event) => {
  if (
    hasCyrillic((e.target as HTMLInputElement).textContent) &&
    (e.target as HTMLInputElement).textContent.length > 1
  ) {
    localText.value = noRussian((e.target as HTMLInputElement).value);
  } else {
    const validValue = noRussian((e.target as HTMLInputElement).textContent);
    emits('update:modelValue', validValue);
    localText.value = validValue;
    titleRef.value.textContent = validValue;
  }
};
</script>

<style lang="scss" scoped>
.article-title-input[placeholder]:empty:before {
  content: attr(placeholder);
  opacity: 0.5;
}
</style>
