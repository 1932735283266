<template>
  <span class="text-xs text-imperium-fg-muted">
    Showing
    <span
      data-testid="showing-pages-start"
      class="font-semibold text-imperium-fg-strong"
    >
      {{ props.offset + 1 }} </span
    >-<span
      data-testid="showing-pages-end"
      class="font-semibold text-imperium-fg-strong"
    >
      {{ props.offset + props.limit }}
    </span>
    of
    <span
      data-testid="pages-count"
      class="font-semibold text-imperium-fg-strong"
    >
      {{ props.itemsCount }}
    </span>
    records
  </span>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    limit: number;
    offset: number;
    itemsCount: number;
  }>(),
  {
    limit: 10,
    offset: 0,
    itemsCount: 0,
  },
);
</script>
