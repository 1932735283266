import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useLayoutAppearance = () => {
  const route = useRoute();

  const isMainLayoutVisible = computed(() => !route.path.includes('auth'));

  // Features
  const isRegionDropdownVisible = computed(() => route.name === 'dashboard');
  const isDashboardSwitcherVisible = computed(() => route.name === 'dashboard');

  return {
    isMainLayoutVisible,

    isRegionDropdownVisible,
    isDashboardSwitcherVisible,
  };
};
