export const DASHBOARD_STATE_NAME = 'dashboardState';

export const enum DashboardTab {
  ARTICLES = 'articles',
  COVERS = 'covers',
}

export enum CoverDeadline {
  '10M' = '10m',
  '30M' = '30m',
  '24H' = '24h',
}

export enum ArtEditorDashboardTable {
  '10MIN' = '10min',
  '30MIN' = '30min',
  '24HOURS' = '24hours',
  'PENDING_APPROVAL' = 'pending_approval',
  'PUBLISHED' = 'published',
  'ALL_ARTICLES' = 'all_articles',
}
