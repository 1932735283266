<template>
  <div>
    <div class="relative">
      <div
        v-if="isLoading"
        class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
      >
        <div class="h-10 w-10">
          <LoaderIcon />
        </div>
      </div>

      <div
        v-else
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :is-rounded="!isMobile"
            :is-sticky="!isExtraLargeDesktop"
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :is-sticky="true"
                  is-uppercase
                >
                  <SortColumnHeader
                    :id="LABEL_FIELD_ID"
                    label="Id"
                    :value="filtersStore.state.sort[LABEL_FIELD_ID]"
                    @change-sort="updateFilters({ filterName: LABEL_FIELD_ID, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    :id="LABEL_FIELD_NAME"
                    label="Label"
                    :value="filtersStore.state.sort[LABEL_FIELD_NAME]"
                    @change-sort="updateFilters({ filterName: LABEL_FIELD_NAME, value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Category
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Label
                </TableHeadCell>

                <TableHeadCell
                  :size="SIZES.XLARGE"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                  is-full-width
                >
                  Label Description
                </TableHeadCell>
              </TableHeadRow>
            </TableHead>

            <TableBody v-if="!!roles.items.length">
              <TableRow
                v-for="role in roles.items"
                :key="role[ROLE_FIELD_ID]"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                @click="emits('redirect', role[ROLE_FIELD_ID])"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :is-sticky="true"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ role[ROLE_FIELD_ID] }}</span>
                </TableCell>

                <TableCell
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  {{ role[ROLE_FIELD_NAME] || '—' }}
                </TableCell>

                <TableCell
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                  is-full-width
                >
                  {{ role[ROLE_FIELD_DESCRIPTION] || '—' }}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No roles found</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page || 1"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, computed } from 'vue';

import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { useRolesPagination } from '@/features/Roles/stores/pagination.store';
import { useRolesFilters } from '@/features/Roles/stores/filters.store';

import Pagination from '@/components/Table/Pagination/Pagination.vue';

import { TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_STRIPED, TABLE_SCALE, SIZES, SortStatus, TABLE_VALIGN } from '@/types';
import { Table, TableBody, TableHead, TableCell, TableHeadCell, TableRow, TableHeadRow } from '@/components/Table';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import { ROLE_FIELD_DESCRIPTION, ROLE_FIELD_ID, ROLE_FIELD_NAME } from '@/features/Roles/constants';
import { LABEL_FIELD_ID, LABEL_FIELD_NAME } from '@/features/Labels/constants';
import { useFetchLabels } from '@/features/Labels/queries';
import type { LabelsFetchRequest, LabelsFetchResponse } from '@/features/Labels/types';
import { DEFAULT_LANGUAGE_ID } from '@/constants/queries';

const emits = defineEmits<{
  (event: 'redirect', roleId: number): void;
}>();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');
const isExtraLargeDesktop = inject<boolean>('isExtraLargeDesktop');

const filtersStore = useRolesFilters();
const paginationStore = useRolesPagination();

onMounted(() => {
  filtersStore.setSorts({
    [ROLE_FIELD_ID]: SortStatus.DESC,
  });
});

const rolesSort = computed(() => {
  const sortFields = filtersStore.state.sort;

  if (!Object.keys(sortFields)[0]) {
    return;
  }

  if (!Object.values(sortFields)[0]) {
    return;
  }

  return {
    value: Object.keys(sortFields)[0],
    direction: Object.values(sortFields)[0],
  };
});

const labelsPayload = computed<LabelsFetchRequest>(() => {
  const payload = {
    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,
    pagination: true,
    searchQuery: filtersStore.state.searchQuery || undefined,
    languageId: DEFAULT_LANGUAGE_ID,
  };

  if (rolesSort.value) {
    payload[`order[${rolesSort.value.value}]`] = rolesSort.value.direction;
  }

  return payload;
});

const { data: roles, isLoading } = useFetchLabels(labelsPayload, (result: LabelsFetchResponse) => {
  if (!result) {
    return;
  }

  paginationStore.setState({
    page: result.page,
    total: result.total,
  });
});

const updateFilters = ({ filterName, value }: { filterName: string; value: SortStatus }) => {
  filtersStore.setSorts({
    [filterName]: value ? value : SortStatus.ASC,
  });
};
</script>
