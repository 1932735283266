import { wrapItem } from '@/features/CollaborativeEditor/plugins/menu';
import type { NodeType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import ArticleWidgetIcon from '@/assets/icons/editor/article-widget.svg?raw';
import { ref, createApp, h } from 'vue';
import InsertArticleWidgetModal from '@/features/CollaborativeEditor/components/InsertArticleWidgetModal.vue';
import {
  createParagraphNear,
  deleteSelection,
  selectNodeBackward,
  selectParentNode,
  splitBlock,
  wrapIn,
} from 'prosemirror-commands';
import type { Post } from '@/features/Posts/types';
import { disableMenuItem } from '@/features/CollaborativeEditor/utils/state';

const showInsertModal = ref<boolean>(false);

let insertArticleWidgetPromiseResolve: (value: Post) => void;

const openInsertModal = async () => {
  showInsertModal.value = true;

  return new Promise<Post>((resolve) => {
    insertArticleWidgetPromiseResolve = resolve;
  });
};

const closeInsertModal = () => {
  showInsertModal.value = false;
};

export const buildArticleWidget = (node: NodeType): InstallableBlock => {
  const app = createApp({
    setup() {
      return () =>
        h(InsertArticleWidgetModal, {
          isVisible: showInsertModal.value,
          onClose: closeInsertModal,
          onInsert: (payload: Post) => {
            insertArticleWidgetPromiseResolve(payload);
            closeInsertModal();
          },
        });
    },
  });

  app.mount('#prosemirror-add-related-article-widget-modal');

  return {
    key: 'makeArticleWidget',
    item: wrapItem(node, {
      enable(state) {
        return disableMenuItem(state.selection.$head!.path, state.selection?.node?.type?.name);
      },
      render: () => {
        const label = 'Article Widget';

        const template = `<div>${ArticleWidgetIcon}<div>${label}</div></div>`;
        return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
      },
      run(state, _, view) {
        const applyTransaction = (payload: Post) => {
          const { $from, $to } = state.selection;

          if ($from.depth === $to.depth) {
            const nearestElement = $to.path[$to.path.length - 3];

            if (nearestElement && nearestElement?.content.size === 0) {
              deleteSelection(view.state, view.dispatch);
            } else {
              // XXX: not a bug. Need to split twicely.
              splitBlock(view.state, view.dispatch);
              splitBlock(view.state, view.dispatch);
              selectNodeBackward(view.state, view.dispatch);
            }
          } else {
            deleteSelection(view.state, view.dispatch);
          }

          wrapIn(node, {
            id: payload.id,
            languageId: 1,
            author: payload.author,
            title: payload.title,
            publishedAt: payload.publishedAt,
            imageUrl: payload.imageUrl,
          })(view.state, view.dispatch);

          selectParentNode(view.state, view.dispatch);
          selectParentNode(view.state, view.dispatch);
          createParagraphNear(view.state, view.dispatch, view);
        };

        openInsertModal().then((payload: Post) => {
          applyTransaction(payload);
        });
      },
    }),
  };
};
