import { ref } from 'vue';
import { defineStore } from 'pinia';
import { CATEGORY_FIELD_ID, CATEGORY_FIELD_NAME } from '@/features/Categories/constants';
import { SortStatus } from '@/types';

export type SortsSupport = typeof CATEGORY_FIELD_ID | typeof CATEGORY_FIELD_NAME;

export interface SortInterface {
  [CATEGORY_FIELD_ID]?: SortStatus;
  [CATEGORY_FIELD_NAME]?: SortStatus;
}

export interface SortStoreValue {
  filterName: SortsSupport;
  value: SortStatus;
}

export interface State {
  searchQuery: string;
  filters: {
    [CATEGORY_FIELD_NAME]?: string;
  };
  sort: SortInterface;
}

export const useCategoryTranslatesFilters = defineStore('categoryTranslationsPageFilters', () => {
  const state = ref<Partial<State>>({
    sort: {},
    searchQuery: '',
  });

  const setSorts = (sort: SortInterface) => {
    state.value = { ...state.value, sort: { ...sort } } as State;
  };

  const setSearchQuery = (searchQuery: string) => {
    state.value = { ...state.value, searchQuery };
  };

  return {
    state,
    setSorts,
    setSearchQuery,
  };
});
