<template>
  <AuthPageWrapper data-testid="auth-2fa-success">
    <template #title> Two-factor authentication </template>

    <Toast
      id="auth-2fa-success"
      message="You have been set up your authentication app method."
      :theme="THEMES.SUCCESS"
      :is-closable="false"
      is-full-width
    />
    <p class="mt-2 font-normal text-lg text-imperium-fg-muted">
      The next time you log in, you'll need to use an authenticator app to access your account.
    </p>
    <Button
      data-testid="button-continue"
      is-full-width
      class="mt-5"
      @click="onSuccess"
    >
      Continue
    </Button>
  </AuthPageWrapper>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';

import Button from '@/components/Button.vue';

import AuthPageWrapper from '../components/AuthPageWrapper.vue';
import { THEMES } from '@/types';
import Toast from '@/components/Toast.vue';
import { setAuthenticated } from '@/services/auth';

const router = useRouter();

const onSuccess = () => {
  router.replace({ path: '/' });
  setAuthenticated();
};
</script>
