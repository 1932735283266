import { createApp, h, ref } from 'vue';
import type { NodeType } from 'prosemirror-model';

import { MenuItem } from '@/features/CollaborativeEditor/plugins/menu';
import { EmbedType, type InstallableBlock } from '@/features/CollaborativeEditor/types';
import InsertEmbedModal from '@/features/CollaborativeEditor/components/InsertEmbedModal.vue';

import EmbedAddIcon from '@/assets/icons/editor/embed.svg?raw';
import {
  getBuzzsproutPodcastId,
  getInstagramId,
  getTwitterId,
  getVimeoId,
  getYoutubeId,
} from '@/features/CollaborativeEditor/helpers/embed';
import { useToast } from '@/composables';
import { disableMenuItem } from '@/features/CollaborativeEditor/utils/state';

const showInsertModal = ref<boolean>(false);

let insertEmbedPromiseResolve: (value: { url: string; type: EmbedType }) => void;

const openInsertModal = async () => {
  showInsertModal.value = true;

  return new Promise<{ url: string; type: EmbedType }>((resolve) => {
    insertEmbedPromiseResolve = resolve;
  });
};

const closeInsertModal = () => {
  showInsertModal.value = false;
};

export const buildEmbed = (node: NodeType): InstallableBlock => {
  const EmbedAddIconNode = new DOMParser().parseFromString(EmbedAddIcon, 'text/html').body.firstElementChild;

  const toast = useToast();

  const item = new MenuItem({
    title: 'Insert embed',
    icon: {
      dom: EmbedAddIconNode as Node,
    },
    enable(state) {
      return disableMenuItem(state.selection.$head!.path, state.selection?.node?.type?.name);
    },
    run(state, _, view) {
      const { dispatch } = view;
      const { schema, selection } = state;
      const { from } = selection;

      const applyTransaction = ({ url, type }: { url: string; type: EmbedType }) => {
        let id;
        let embedNode;
        let resultURL = url;

        switch (type) {
          case EmbedType.TWITTER:
            id = getTwitterId(url);
            embedNode = schema.nodes.twitter.create({ embed_id: id });
            break;
          case EmbedType.YOUTUBE:
            id = getYoutubeId(url);
            resultURL = `https://www.youtube.com/embed/${id}`;
            embedNode = schema.nodes.youtube.create({ src: resultURL, embed_id: id });
            break;
          case EmbedType.VIMEO:
            id = getVimeoId(url);
            resultURL = `https://player.vimeo.com/video/${id}`;
            embedNode = schema.nodes.vimeo.create({ src: resultURL, embed_id: id });
            break;
          case EmbedType.BUZZSPROUT:
            // for example: https://www.buzzsprout.com/2072690?client_source=large_player&iframe=true
            id = getBuzzsproutPodcastId(url);
            resultURL = `https://www.buzzsprout.com/${id}?client_source=large_player&amp;iframe=true`;
            embedNode = schema.nodes.buzzsprout_podcast.create({ src: resultURL, embed_id: id });
            break;
          case EmbedType.INSTAGRAM:
            id = getInstagramId(url);
            resultURL = `https://www.instagram.com/p/${id}/embed`;
            embedNode = schema.nodes.instagram.create({ src: resultURL, embed_id: id });
            break;
        }

        if (!id) {
          toast.errorTemporary({
            id: 'ERROR_INSERT_EMBED',
            message: 'Embed link is invalid',
          });
          return;
        }

        if (embedNode) {
          const transaction = state.tr.insert(from, embedNode);
          dispatch(transaction);
        }
      };

      openInsertModal().then(({ url, type }) => {
        applyTransaction({ url, type });
      });
    },
  });

  const app = createApp({
    setup() {
      return () =>
        h(InsertEmbedModal, {
          isVisible: showInsertModal.value,
          onClose: closeInsertModal,
          onInsert: ({ url, type }) => {
            closeInsertModal();
            insertEmbedPromiseResolve({ url, type });
          },
        });
    },
  });

  app.mount('#prosemirror-add-embed-modal');

  return {
    key: 'insertEmbed',
    item,
  };
};
