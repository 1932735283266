<template>
  <div class="relative">
    <label
      v-if="isLabel"
      class="w-fit mb-2"
      :class="
        labelClass({
          size: props.size,
          labelPosition: props.labelPosition,
          isDisabled: props.isDisabled,
          isErrored: props.isErrored,
          isSuccess: props.isSuccess,
        })
      "
    >
      <slot name="label" />

      <template v-if="props.isRequired">*</template>
    </label>

    <Dropdown
      :id="props.id || `form-select-${props.name}`"
      ref="dropdownElement"
      :classes="{
        button:
          'relative text-white focus:ring-imperium-ds-primary-strong focus:border-imperium-ds-primary-strong focus:outline-none rounded-lg text-sm inline-flex items-center w-full',
        dropdown: 'z-[101] hidden bg-white rounded-lg shadow w-full',
      }"
      @open="isDropdownVisible = true"
      @close="isDropdownVisible = false"
    >
      <template #button>
        <div
          v-show="!isDropdownVisible"
          class="pr-5"
          :class="
            inputClass({
              size: props.size,
              rounded: props.rounded,
              visualType: props.visualType,
              isErrored: props.isErrored,
              isDisabled: props.isDisabled,
              isSuccess: props.isSuccess,
            })
          "
        >
          <div
            v-if="isLeftIcon"
            class="absolute left-3 inset-y-0 flex items-center pointer-events-none"
          >
            <slot name="icon" />
          </div>

          <p
            v-if="!selectedValue"
            :class="{
              'pl-8': isLeftIcon && props.size === SIZES.SMALL,
              'pl-10': isLeftIcon && props.size === SIZES.MEDIUM,
            }"
            class="text-imperium-fg-muted"
          >
            {{ props.placeholder }}
          </p>

          <p
            v-else
            :class="{
              'pl-8': isLeftIcon && props.size === SIZES.SMALL,
              'pl-10': isLeftIcon && props.size === SIZES.MEDIUM,
            }"
            class="text-imperium-fg-input truncate"
          >
            {{ selectedValue.label }}
          </p>
        </div>

        <FormInput
          v-show="isDropdownVisible"
          ref="searchInputElement"
          :model-value="search"
          type="text"
          :name="`form-select-search-${props.name}`"
          class="w-full"
          :placeholder="props.modelValue ? 'Search...' : props.placeholder"
          :is-errored="false"
          :size="props.size"
          @update:model-value="onSearch"
        >
          <template
            v-if="isLeftIcon"
            #prefix
          >
            <slot name="icon" />
          </template>
          <template #suffix><slot name="suffix" /></template>
        </FormInput>
      </template>

      <template
        v-if="displayableValues.length"
        #menu
      >
        <ul
          v-if="displayableValues.length"
          class="max-h-48 pt-2 px-3 pb-3 overflow-y-auto text-sm text-gray-700 scrollbar-thin"
        >
          <li
            v-for="(value, index) in displayableValues"
            :key="value.id || `${props.name}${index}`"
            class="cursor-pointer"
            @click="() => onSelect(value)"
          >
            <div
              class="flex items-center ps-2 rounded hover:bg-imperium-bg-4"
              :class="{
                ['bg-imperium-bg-3']: checkItemActive(value),
              }"
            >
              <label
                v-if="!isDropdownItemSlot"
                class="w-full py-2 ms-2 text-sm truncate font-medium text-gray-900 rounded dark:text-gray-300"
              >
                {{ value.label }}
              </label>
              <slot
                v-else
                name="dropdownItem"
                :item="value.label"
              />
            </div>
          </li>
        </ul>
      </template>

      <template
        v-if="props.isErrored && isError"
        #error
      >
        <div
          class="mt-1"
          :class="
            labelClass({
              size: props.size,
              isErrored: props.isErrored,
            })
          "
        >
          <slot name="error" />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, useSlots, watch } from 'vue';

import Dropdown from '@/components/Dropdown.vue';

import FormInput from './FormInput.vue';
import { INPUT_ROUNDED, INPUT_TYPE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';

export interface SelectItem {
  id: number | string;
  label: string;
}

const props = withDefaults(
  defineProps<{
    id?: string;
    name: string;
    modelValue: number;
    values: SelectItem[];
    searchValue?: string;

    attrs?: Record<string, unknown>;
    placeholder?: string;

    isRequired?: boolean;
    isErrored?: boolean;
    isDisabled?: boolean;
    isSuccess?: boolean;
    autofocus?: boolean;

    size?: SIZES;
    rounded?: INPUT_ROUNDED;
    visualType?: INPUT_TYPE;
  }>(),
  {
    placeholder: 'Not selected',

    isErrored: false,
    isRequired: false,
    isDisabled: false,
    isSuccess: false,

    size: SIZES.MEDIUM,
    rounded: INPUT_ROUNDED.DEFAULT,
    visualType: INPUT_TYPE.PRIMARY,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: number | SelectItem): void;
  (event: 'search', value: string): void;
}>();

const slots = useSlots();

const search = ref<string>('');

const isDropdownVisible = ref<boolean>(false);

const dropdownElement = ref<typeof Dropdown | null>(null);
const searchInputElement = ref<HTMLElement | null>(null);

const isLabel = computed(() => !!slots.label);
const isDropdownItemSlot = computed(() => !!slots.dropdownItem);
const isLeftIcon = computed(() => !!slots.icon);
const isError = computed(() => !!slots.error);

const displayableValuesRef = ref<SelectItem[] | null>(null);
const displayableValues = computed({
  get() {
    return displayableValuesRef.value ? displayableValuesRef.value : props.values;
  },
  set(newValues) {
    displayableValuesRef.value = newValues;
  },
});
const selectedValue = computed(() => {
  return props.values.find((element) => element.id === props.modelValue || element.id === props.modelValue?.id);
});

const labelClass = tv({
  base: 'inline-block block input-meta-text-default',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-meta-text-sm',
      [SIZES.MEDIUM]: 'input-meta-text-md',
    },
    isErrored: {
      true: 'input-meta-text-errored',
    },
    isSuccess: {
      true: 'input-meta-text-success',
    },
    isDisabled: {
      true: 'input-meta-text-disabled',
    },
    isInfo: {
      true: 'input-meta-helper-text',
    },
  },
});

const inputClass = tv({
  base: 'block w-full text-ellipsis',
  variants: {
    size: {
      [SIZES.SMALL]: 'input-sm',
      [SIZES.MEDIUM]: 'input-md pt-[7.5px] pb-[7.5px]',
    },
    rounded: {
      [INPUT_ROUNDED.DEFAULT]: 'input-rounded-default',
      [INPUT_ROUNDED.FULL]: 'input-rounded-full',
    },
    visualType: {
      [INPUT_TYPE.PRIMARY]: 'input-primary',
      [INPUT_TYPE.PLAIN]: 'input-plain',
    },
  },

  compoundVariants: [
    {
      isDisabled: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-disabled',
    },
    {
      isDisabled: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-disabled',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PRIMARY,
      class: 'input-primary-success',
    },
    {
      isErrored: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-errored',
    },
    {
      isSuccess: true,
      visualType: INPUT_TYPE.PLAIN,
      class: 'input-plain-success',
    },
  ],
});

const checkItemActive = (value: SelectItem): boolean => {
  return props.modelValue === value.id;
};

const onSelect = (value: SelectItem): void => {
  search.value = value.label;
  displayableValues.value = props.values;
  emits('update:modelValue', value);
  dropdownElement.value?.close();
};

const onSearch = (value: string): void => {
  search.value = value;
  emits('search', search.value);
  displayableValues.value =
    props.values.filter((element) => {
      return element.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    }) || [];
};

watch(isDropdownVisible, (value: boolean) => {
  if (!value) {
    return;
  }

  nextTick(() => searchInputElement.value?.focus());
});

watch(
  () => props.autofocus,
  (value: boolean) => {
    if (value) {
      isDropdownVisible.value = true;
    }
  },
);

watch(
  () => props.values,
  (newValues: SelectItem[]) => {
    displayableValuesRef.value = newValues;
  },
  { immediate: true, deep: true },
);
watch(
  () => props.searchValue,
  (newValue: string) => {
    search.value = newValue;
  },
  { immediate: true },
);
</script>
