import { useQuery } from '@tanstack/vue-query';
import { fetchAuthors } from '../api';
import { type ArticleAuthorsFetchRequest } from '../types';
import { type Ref } from 'vue';

import { ARTICLE_TAGS_RESOURCE } from '../constants';

export const useFetchAuthors = (params: Ref<ArticleAuthorsFetchRequest>) => {
  const { data, isLoading } = useQuery({
    queryKey: [ARTICLE_TAGS_RESOURCE, params],
    queryFn: ({ queryKey }) => {
      const payload: ArticleAuthorsFetchRequest = queryKey[1] as ArticleAuthorsFetchRequest;

      return fetchAuthors(payload);
    },

    staleTime: Infinity,
    refetchInterval: false,
  });

  return {
    data,
    isLoading,
  };
};
