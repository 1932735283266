import UnderlineIcon from '@/assets/icons/editor/strike.svg?raw';

import { markItem } from '@/features/CollaborativeEditor/utils/blocks';
import type { MarkType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';

export const buildStrike = (mark: MarkType): InstallableBlock => {
  const strikeIconNode = new DOMParser().parseFromString(UnderlineIcon, 'text/html').body.firstElementChild;

  return {
    key: 'toggleStrike',
    item: markItem(mark, {
      title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.STRIKETHROUGH_TEXT),
      render: () => {
        const buttonContainer = document.createElement('div');
        buttonContainer.id = 'strike-text-button';
        buttonContainer.className = 'ProseMirror-icon';

        if (strikeIconNode) {
          buttonContainer.appendChild(strikeIconNode.cloneNode(true));
        }

        return buttonContainer;
      },
      icon: {
        dom: strikeIconNode as Node,
      },
    }),
  };
};
