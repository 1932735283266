import { computed, type Ref } from 'vue';
import { getCountOfWords } from '@/utils/string/counter';
import { pluralize } from '@/utils/string/pluralize';

export function useWordCount(text: Ref<string>) {
  const wordCount = computed(() => getCountOfWords(text.value));
  const characterCount = computed(() => text.value?.length ?? 0);
  const wordLabel = computed(() => pluralize(wordCount.value, 'word'));
  const characterLabel = computed(() => pluralize(characterCount.value, 'character'));

  return {
    wordCount,
    wordLabel,
    characterCount,
    characterLabel,
  };
}
