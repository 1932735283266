export const isMac = (): boolean => {
  const userAgent = window.navigator.userAgent;

  if (userAgent.indexOf('Mac') !== -1) {
    return true;
  } else if (userAgent.indexOf('Win') !== -1) {
    return false;
  } else {
    return false;
  }
};
