import { defineStore } from 'pinia';
import { ref } from 'vue';

// Temporary according to https://jira.cointelegraph.com/browse/CMS-634
// Later need to use Article store in case we will set all article data into article store

interface Tag {
  id: number | string;
  label: string;
}

export const useArticleTagsStore = defineStore('useArticleTagsStore', () => {
  const state = ref<string[]>(['']);

  const setTags = (tags: Tag[]) => {
    state.value = tags.map((tag) => tag.label);
  };

  return {
    state,
    setTags,
  };
});
