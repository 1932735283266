import Toast from '@/components/Toast.vue';
import { TYPE, useToast as useToastification } from 'vue-toastification';
import { THEMES } from '@/types';
import type { Component } from 'vue';

interface ToastProps {
  id: string;
  message?: string;
  theme?: THEMES;
  title?: string;
  isImageVisible?: boolean;
  isClosable?: boolean;
}

type BuildToastArgs = {
  props: ToastProps;
  type: TYPE;
  component?: Component;
  timeout?: number | false;
  discardingPreventDuplicates?: boolean;
};

export const useToast = () => {
  const toast = useToastification();

  const clearAll = () => toast.clear();

  const buildToast = ({ props, type, component = Toast, timeout = 5000 }: BuildToastArgs) => {
    toast(
      {
        component,
        props,
        listeners: {
          click: (event: Event) => {
            return event;
          },
        },
      },
      {
        timeout,
        type,
      },
    );
  };

  const error = (props: ToastProps, component: Component = Toast) => {
    const theme = THEMES.DANGER;
    const type = TYPE.ERROR;

    buildToast({
      props: { ...props, theme },
      type,
      timeout: false,
      component,
    });
  };

  const errorTemporary = (props: ToastProps, component: Component = Toast) => {
    const theme = THEMES.DANGER;
    const type = TYPE.ERROR;

    buildToast({
      props: { ...props, theme },
      type,
      component,
    });
  };

  const success = (props: ToastProps, component: Component = Toast) => {
    const theme = THEMES.SUCCESS;
    const type = TYPE.SUCCESS;

    buildToast({
      props: { ...props, theme },
      type,
      component,
    });
  };

  const warning = (props: ToastProps, component: Component = Toast) => {
    const theme = THEMES.WARNING;
    const type = TYPE.WARNING;

    buildToast({
      props: { ...props, theme },
      type,
      component,
    });
  };

  const info = (props: ToastProps, component: Component = Toast) => {
    const theme = THEMES.INFO;
    const type = TYPE.INFO;

    buildToast({
      props: { ...props, theme },
      type,
      component,
    });
  };

  return {
    errorTemporary,
    error,
    success,
    warning,
    info,
    clearAll,
  };
};
