<template>
  <div
    class="relative w-full"
    :data-testid="`datepicker-range-${props.name}`"
  >
    <div
      class="flex gap-6"
      ref="datepickerRangeRef"
    >
      <FormInput
        ref="startDateRef"
        :name="`${props.name}-from`"
        :size="props.size"
        is-readonly
        is-disabled
      >
        <template #label> From </template>
        <template #suffix>
          <CalendarIcon class="absolute top-[7px] right-[-4px] text-imperium-fg-muted" />
        </template>
      </FormInput>

      <FormInput
        ref="endDateRef"
        :name="`${props.name}-to`"
        :size="props.size"
        is-readonly
        is-disabled
      >
        <template #label> To </template>
        <template #suffix>
          <CalendarIcon class="absolute top-[7px] right-[-4px] text-imperium-fg-muted" />
        </template>
      </FormInput>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, useCssModule, watch, watchEffect } from 'vue';
import { DateRangePicker } from 'vanillajs-datepicker';

import CalendarIcon from '@/assets/icons/calendar.svg?component';

import FormInput from '@/components/FormInput.vue';

import { SIZES } from '@/types';

export interface RangeValues {
  start?: string;
  end?: string;
}

const props = withDefaults(
  defineProps<{
    modelValue: RangeValues | null;
    name: string;
    size?: SIZES;
  }>(),
  {
    isDefaultDate: false,
    size: SIZES.SMALL,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: RangeValues | null): void;
}>();

const $style = useCssModule();

const datepickerRange = ref<typeof DateRangePicker | null>(null);

const datepickerRangeRef = ref<HTMLElement | null>(null);
const startDateRef = ref<HTMLInputElement | null>(null);
const endDateRef = ref<HTMLInputElement | null>(null);

const startDate = ref<string | null>(null);
const endDate = ref<string | null>(null);

const handleDateChange = () => {
  startDate.value = datepickerRange.value?.dates[0] ? new Date(datepickerRange.value?.dates[0]).toISOString() : null;
  endDate.value = datepickerRange.value?.dates[1] ? new Date(datepickerRange.value?.dates[1]).toISOString() : null;

  if (startDate || endDate) {
    emits('update:modelValue', {
      start: startDate.value,
      end: endDate.value,
    });
  } else {
    emits('update:modelValue', null);
  }
};

const handleResetDate = () => {
  datepickerRange.value.datepickers[0].setDate([], { clear: true });
  datepickerRange.value.datepickers[1].setDate([], { clear: true });

  startDate.value = null;
  endDate.value = null;
};

const setInitialValue = (initialValue: RangeValues | null) => {
  if (!datepickerRange.value) {
    return;
  }

  if (!initialValue || (!initialValue?.start && !initialValue?.end)) {
    handleResetDate();
    return;
  }

  if (initialValue.start) {
    datepickerRange.value.datepickers[0].setDate(new Date(initialValue.start));
  }
  if (initialValue.end) {
    datepickerRange.value.datepickers[1].setDate(new Date(initialValue.end));
  }
};

watch(
  () => props.modelValue,
  () => {
    setInitialValue(props.modelValue);
  },
  { deep: true, immediate: true },
);

onMounted(() => {
  if (datepickerRange.value) {
    return;
  }

  datepickerRange.value = new DateRangePicker(datepickerRangeRef.value, {
    autohide: false,
    format: 'M d, yyyy',
    nextArrow: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.06993 3.09717C8.93965 2.96761 8.72842 2.96761 8.59813 3.09717L8.07915 3.61329C7.94887 3.74285 7.94887 3.95291 8.07915 4.08248L10.8606 6.85532L11.8333 7.4L10.6797 7.33646H2.33361C2.14936 7.33646 2 7.485 2 7.66823L2 8.33177C2 8.515 2.14936 8.66354 2.33361 8.66354H10.6797L11.8333 8.6L10.8606 9.14468L8.07915 11.9175C7.94887 12.0471 7.94887 12.2572 8.07915 12.3867L8.59813 12.9028C8.72842 13.0324 8.93965 13.0324 9.06993 12.9028L14 8L9.06993 3.09717Z" fill="currentColor"/>
      </svg>
    `,
    prevArrow: `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.93007 3.09717C7.06035 2.96761 7.27158 2.96761 7.40187 3.09717L7.92085 3.61329C8.05113 3.74285 8.05113 3.95291 7.92085 4.08248L5.13937 6.85532L4.16667 7.4L5.32031 7.33646H13.6664C13.8506 7.33646 14 7.485 14 7.66823V8.33177C14 8.515 13.8506 8.66354 13.6664 8.66354H5.32031L4.16667 8.6L5.13937 9.14468L7.92085 11.9175C8.05113 12.0471 8.05113 12.2572 7.92085 12.3867L7.40187 12.9028C7.27158 13.0324 7.06035 13.0324 6.93007 12.9028L2 8L6.93007 3.09717Z" fill="currentColor"/>
      </svg>
    `,
  });

  // Subscribe to updates of DatepickerRange
  datepickerRangeRef.value.addEventListener('changeDate', handleDateChange);

  // Set Datepickers styles: fix them position, add styles by our styleguide
  const pickerElements = datepickerRangeRef.value.querySelectorAll('.datepicker');

  pickerElements.forEach((pickerElement) => {
    pickerElement.classList.add('z-[111]');
    pickerElement.classList.add($style.datepicker);
  });

  if (props.modelValue) {
    setInitialValue(props.modelValue);
  }
});
</script>

<style lang="scss" module>
.datepicker {
  position: static !important;
  display: block !important;

  // HACK: We need to define styles via @apply because Datepicker library don't support customization
  :global(.datepicker-picker) {
    box-shadow: none;
  }

  :global(.datepicker-dropdown) {
    @apply z-[110];
    box-shadow: none;
  }

  :global(.datepicker-view) {
    @apply w-[282px];
  }

  :global(.datepicker-footer) {
    display: none;
  }

  :global(.datepicker-controls) {
    @apply h-12 pt-3 flex items-center justify-between;

    :global(button) {
      @apply text-imperium-fg-muted;
    }

    :global(.view-switch) {
      @apply text-xs font-semibold text-imperium-fg-base;
    }
  }

  :global(.days-of-week span) {
    @apply text-xs text-imperium-fg-muted font-semibold py-2 px-1;
  }

  :global(.datepicker-cell) {
    @apply rounded-lg text-xs text-imperium-ds-base-black font-normal py-2 px-3 [&:not(.selected):not(.focused)]:hover:bg-imperium-ds-primary-weak [&:not(.day)]:h-8;

    &:global(.range) {
      @apply rounded-none;
    }

    &:global(.focused) {
      @apply bg-transparent hover:text-imperium-ds-base-black;
    }

    &:global(.selected) {
      @apply bg-imperium-bg-base hover:text-imperium-ds-base-black;
    }
  }
}

.timepicker-hidden {
  :global(.timepicker) {
    @apply hidden;
  }

  :global(.datepicker-picker) {
    @apply pr-0;
  }
}
</style>
