<template>
  <div>
    <img
      v-if="props.img"
      :class="
        avatarClass({
          size: props.size,
          rounded: props.rounded,
        })
      "
      :data-testid="`avatar-${props.id}`"
      :src="props.img"
      class="object-cover"
    />
    <div
      v-else
      :class="
        avatarClass({
          size: props.size,
          rounded: props.rounded,
        })
      "
      :data-testid="`avatar-${props.id}`"
      :style="{ 'background-color': currentColor }"
      class="text-center flex items-center justify-center text-white uppercase"
    >
      <div v-if="props.label && !props.img">
        {{ props.label }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { AVATAR_ROUNDED, SIZES } from '@/types';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    id: string;
    img?: string;
    size?: SIZES;
    rounded?: AVATAR_ROUNDED;
    label?: string;
  }>(),
  {
    size: SIZES.MEDIUM,
    rounded: AVATAR_ROUNDED.FULL,
  },
);

const avatarClass = tv({
  base: '',
  variants: {
    size: {
      [SIZES.LARGE]: 'w-32 h-32 text-5xl',
      [SIZES.MEDIUM]: 'w-16 h-16 text-2xl',
      [SIZES.SMALL]: 'w-8 h-[32px] text-sm',
      [SIZES.XSMALL]: 'w-7 h-7 text-[10px]',
    },
    rounded: {
      [AVATAR_ROUNDED.LARGE]: 'rounded-2xl',
      [AVATAR_ROUNDED.MEDIUM]: 'rounded-lg',
      [AVATAR_ROUNDED.SMALL]: 'rounded',
      [AVATAR_ROUNDED.NONE]: 'rounded-none',
      [AVATAR_ROUNDED.FULL]: 'rounded-full',
    },
  },
});

const colors = [
  '#E91E63',
  '#0A53AE',
  '#9747FF',
  '#57C9BE',
  '#00796B',
  '#2E7D32',
  '#689F38',
  '#FBC02D',
  '#FFA000',
  '#F57C00',
  '#5D4037',
  '#616161',
  '#455A64',
  '#D32F2F',
  '#1976D2',
  '#388E3C',
];

const currentColor = colors[Math.floor(Math.random() * colors.length)];
</script>
