import { ref, computed } from 'vue';
import { DocumentStatus } from '@/types';

const previousDocumentStatus = ref<DocumentStatus | undefined>();
const documentStatus = ref<DocumentStatus>(DocumentStatus.NEW);

const intermediateDocumentStatus = ref<DocumentStatus | undefined>();
const previousIntermediateDocumentStatus = ref<DocumentStatus | undefined>();

export const useDocumentStatus = () => {
  return {
    documentStatus: computed(() => documentStatus.value),
    virtualDocumentStatus: computed(() => intermediateDocumentStatus.value),
    previousDocumentStatus: computed(() => previousDocumentStatus.value),
    virtualPreviousDocumentStatus: computed(() => previousIntermediateDocumentStatus.value),

    virtualUpdate: (newDocumentStatus: DocumentStatus) => {
      const commitData = {
        previousDocumentStatus: documentStatus.value,
        documentStatus: newDocumentStatus,
      };

      const rollbackData = {
        previousDocumentStatus: previousDocumentStatus.value,
        documentStatus: documentStatus.value,
      };

      intermediateDocumentStatus.value = newDocumentStatus;
      previousIntermediateDocumentStatus.value = documentStatus.value;

      return {
        commit: () => {
          previousDocumentStatus.value = commitData.previousDocumentStatus;
          documentStatus.value = commitData.documentStatus;

          intermediateDocumentStatus.value = undefined;
          previousIntermediateDocumentStatus.value = undefined;
        },
        rollback: () => {
          previousDocumentStatus.value = rollbackData.previousDocumentStatus;
          documentStatus.value = rollbackData.documentStatus;

          intermediateDocumentStatus.value = undefined;
          previousIntermediateDocumentStatus.value = undefined;
        },
      };
    },
  };
};
