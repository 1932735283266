import { UAParser } from 'ua-parser-js';
import type { ErrorEvent } from '@sentry/types/types/event';

export const ignoredStatusCodes = [401, 403, 422];

// To filter unwanted error messages that generally is ok
export const ignoredErrorMessages = ['ReportingObserver [deprecation]', 'Request aborted'];

// To filter errors from 3rd party plugins
export const denyUrls = [/extensions\//i, /^chrome:\/\//i, /^chrome-extensions:\/\//i, /^safari-extension:\/\//i];

// To filter errors from unsupported browsers
const supportedBrowsers: Record<string, number> = {
  Chrome: 109,
  Firefox: 54,
  Safari: 10,
  Opera: 38,
};

export const filterOldBrowsers = (event: ErrorEvent | null) => {
  const parser = new UAParser(navigator.userAgent);
  const browser = parser.getBrowser();
  const browserVersion = parseInt(browser.major ?? '');

  if (browser.name && browser.name in supportedBrowsers && browserVersion < supportedBrowsers[browser.name]) {
    return null;
  }
  return event;
};
