<template>
  <tr
    :class="
      tableHeadRowClass({
        size: props.size,
        surface: props.isOnSurface,
        bordered: props.bordered,
        striped: props.striped,
      })
    "
  >
    <slot />
  </tr>
</template>

<script setup lang="ts">
import { TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_STRIPED, SIZES } from '@/types';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    bordered?: TABLE_BORDERED;
    isOnSurface?: TABLE_ON_SURFACE;
    striped?: TABLE_STRIPED;
    isSticky?: boolean;
    size: SIZES;
  }>(),
  {
    bordered: TABLE_BORDERED.NONE,
    isOnSurface: TABLE_ON_SURFACE.DEFAULT,
    striped: TABLE_STRIPED.NONE,
    isSticky: false,
    size: SIZES.MEDIUM,
  },
);

const tableHeadRowClass = tv({
  base: 'table-row',
  variants: {
    size: {
      [SIZES.SMALL]: 'table-row-sm',
      [SIZES.MEDIUM]: 'table-row-md',
      [SIZES.LARGE]: 'table-row-lg',
      [SIZES.XLARGE]: 'table-row-xl',
    },
    bordered: {
      [TABLE_BORDERED.TOP]: 'table-row-bordered-top',
      [TABLE_BORDERED.BOTTOM]: 'table-row-bordered-bottom',
    },
    striped: {
      [TABLE_STRIPED.STRIPED]: 'table-row-striped',
    },
    surface: {
      [TABLE_ON_SURFACE.PRIMARY]: 'table-head-row-card-surface',
    },
  },
  compoundVariants: [
    // Striped Surface
    {
      striped: TABLE_STRIPED.STRIPED,
      surface: TABLE_ON_SURFACE.PRIMARY,
      class: 'table-row-card-surface-striped',
    },
    {
      sticky: true,
      class: 'table-row-sticky',
    },
  ],
});
</script>
