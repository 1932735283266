<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Team</h3>

    <FormSelect
      v-if="documentMetadataWriterSelectView"
      name="writer"
      class="mb-2"
      :is-disabled="!documentMetadataWriterSelectEdit"
      placeholder="Unassigned"
      :model-value="props.writer"
      :attrs="props.writerAttrs"
      :values="writers"
      :size="SIZES.SMALL"
      :variant="VARIANTS.MUTED"
      can-be-cleared
      @update:model-value="(value) => emits('update:writer', value)"
    >
      <template #label>
        <span class="text-xs">Writer</span>
      </template>
    </FormSelect>

    <FormSelect
      v-if="documentMetadataEditorSelectView"
      name="editor"
      class="mb-2"
      :is-disabled="!documentMetadataEditorSelectEdit"
      placeholder="Unassigned"
      :model-value="props.editor"
      :attrs="props.editorAttrs"
      :size="SIZES.SMALL"
      :values="editors"
      :variant="VARIANTS.MUTED"
      can-be-cleared
      @update:model-value="(value) => emits('update:editor', value)"
    >
      <template #label>
        <span class="text-xs">Editor</span>
      </template>
    </FormSelect>

    <FormSelect
      v-if="documentMetadataCopyEditorSelectView"
      name="copyEditor"
      class="mb-2"
      :is-disabled="!documentMetadataCopyEditorSelectEdit"
      placeholder="Unassigned"
      :model-value="props.copyEditor"
      :attrs="props.copyEditorAttrs"
      :values="copyeditors"
      :size="SIZES.SMALL"
      :variant="VARIANTS.MUTED"
      can-be-cleared
      @update:model-value="(value) => emits('update:copyEditor', value)"
    >
      <template #label>
        <span class="text-xs">Copy Editor</span>
      </template>
    </FormSelect>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import FormSelect from '@/components/FormSelect.vue';
import { SIZES, VARIANTS } from '@/types';
import { useFetchUsers } from '@/features/Users/queries';
import { useFetchRoles } from '@/features/Roles/queries';
import { type UsersFetchPayload } from '@/features/Users/types';
import { mapUsersToSelectItemByRole } from '@/features/Users/utils';
import { type SelectItem } from '@/components/FormSelect.vue';
import { ROLES } from '@/types';
import { useUserPermissions } from '@/stores/user.store';

const props = defineProps<{
  writer: number;
  writerAttrs: Record<string, unknown>;
  editor: number;
  editorAttrs: Record<string, unknown>;
  copyEditor: number;
  copyEditorAttrs: Record<string, unknown>;
}>();

const emits = defineEmits<{
  (event: 'update:writer', value: number): void;
  (event: 'update:editor', value: number): void;
  (event: 'update:copyEditor', value: number): void;
}>();

const { data: roles } = useFetchRoles();
const getWriterRoleId = computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_WRITER).id) ?? null;
const getSeniorWriterRoleId =
  computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_SENIOR_WRITER).id) ?? null;
const getEditorRoleId = computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_EDITOR).id) ?? null;
const getCopyEditorRoleId =
  computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_COPY_EDITOR).id) ?? null;
const getEditorInChiefRoleId =
  computed(() => roles?.value?.items?.find((role) => role.slug === ROLES.ROLE_EDITOR_IN_CHIEF).id) ?? null;

const {
  documentMetadataEditorSelectView,
  documentMetadataEditorSelectEdit,
  documentMetadataWriterSelectView,
  documentMetadataWriterSelectEdit,
  documentMetadataCopyEditorSelectView,
  documentMetadataCopyEditorSelectEdit,
} = useUserPermissions();

const fetchUserParams = computed<UsersFetchPayload>(() => ({
  page: 1,
  itemsPerPage: 1000,
  filters: {
    roles: [
      getCopyEditorRoleId.value,
      getWriterRoleId.value,
      getEditorRoleId.value,
      getSeniorWriterRoleId.value,
      getEditorInChiefRoleId.value,
    ],
  },
}));
const { data: users } = useFetchUsers(fetchUserParams);

const writers = computed<SelectItem[]>(() => {
  if (!roles.value?.items?.length || !users.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_WRITER),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_SENIOR_WRITER),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const editors = computed<SelectItem[]>(() => {
  if (!roles.value?.items?.length || !users.value?.items?.length) {
    return [];
  }

  return [
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR),
    ...mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_EDITOR_IN_CHIEF),
  ];
});

const copyeditors = computed<SelectItem[]>(() => {
  if (!roles.value?.items?.length || !users.value?.items?.length) {
    return [];
  }

  return mapUsersToSelectItemByRole(users.value.items, ROLES.ROLE_COPY_EDITOR);
});
</script>
