import type { App } from 'vue';
import type { Router } from 'vue-router';

import * as Sentry from '@sentry/vue';

import { filterOldBrowsers, ignoredErrorMessages, denyUrls } from './ignoreConfig';

import CorrelationService from '@/services/correlation';
import { deploymentEnv, sentryDsn, tracePropagationTargets, tracesSampleRate, release } from '@/config';

export const initSentry = (app: App, router: Router) => {
  Sentry.init({
    app,
    release,
    dsn: sentryDsn,
    environment: deploymentEnv,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
    ],
    ignoreErrors: ignoredErrorMessages,
    denyUrls,
    beforeSend: (event) => filterOldBrowsers(event),
    tracesSampleRate: tracesSampleRate,
    tracePropagationTargets: tracePropagationTargets,
  });

  setTags({
    correlation_id: CorrelationService.getCorrelationId(),
  });
};

export const setTags = (tags: { [key: string]: string }) => {
  Object.entries(tags).forEach(([key, value]) => {
    Sentry.setTag(key, value);
  });
};

export default {
  initSentry,
  setTags,
};
