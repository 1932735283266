export const checkIfNodeIn = (nodeType: string, path: string[] | Object[]): boolean => {
  for (const index in path) {
    const element: number | Object = path[index];

    if (typeof element !== 'object') {
      continue;
    }

    if (element.type?.name === nodeType) {
      return WIDGETS.includes(nodeType);
    }
  }

  return false;
};

// Disables menu item if widget selected
// Prevent from invalid template in CRDT & DB
const WIDGETS = [
  'subscription_form',
  'article_widget',
  'figcaption',
  'figure',
  'img',
  'container',
  'disclaimer',
  'ol',
  'ul',
  'blockquote',
];

// Disables menu item if widget selected for links
const LINKS_WIDGETS = ['subscription_form', 'article_widget', 'img', 'ol', 'ul', 'container', 'disclaimer'];

export const disableMenuItem = (headPath: string[] | Object[], nodeName: string): boolean => {
  const containsWidgetInPath = headPath.some((element) => WIDGETS.includes(element?.type?.name ?? element));
  const containsWidgetInNodeName = nodeName && WIDGETS.includes(nodeName);

  return !containsWidgetInPath && !containsWidgetInNodeName;
};

export const disableMenuLinkItem = (headPath: string[] | Object[], nodeName: string): boolean => {
  const containsWidgetInPath = headPath.some((element) => LINKS_WIDGETS.includes(element?.type?.name ?? element));
  const containsWidgetInNodeName = nodeName && LINKS_WIDGETS.includes(nodeName);

  return !containsWidgetInPath && !containsWidgetInNodeName;
};
