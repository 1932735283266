import { useFetchArticles } from '@/features/Articles/queries';
import { computed } from 'vue';
import { useLanguagesStore } from '@/stores/languages.store';

export const useFilteringArticles = (additionalParams: Record<string, string | number | any> = {}) => {
  const languagesStore = useLanguagesStore();
  const languages = computed(() => languagesStore.state);
  const languageId = computed(() => (languages?.value ? languages?.value![0].id : 0));

  const params = {
    languageId,
    page: 1,
    itemsPerPage: 10,
    ...additionalParams,
  };

  const { data, isLoading, isFetched } = useFetchArticles(params);

  return {
    data,
    isLoading,
    isFetched,
  };
};
