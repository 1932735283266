<template>
  <ModalHolder
    data-testid="dashboard-chief-message-modal"
    :is-visible="props.isVisible"
    @close="emits('close')"
  >
    <template #title>
      {{ t('dashboard.chief-message.modal-title') }}
    </template>

    <form @submit="onSubmit">
      <div class="pb-3">
        <FormTextarea
          v-model="message"
          name="message"
          :placeholder="t('dashboard.chief-message.placeholder').toString()"
          :is-errored="isMessageErrored"
          :attrs="messageAttrs"
          :max="MAX_MESSAGE_LENGTH"
        >
          <template #error>
            {{ errors.message }}
          </template>
        </FormTextarea>
      </div>

      <div class="flex items-center gap-4 justify-end pt-3 pb-1 rounded-b rounded-b-2xl">
        <Button
          data-testid="dashboard-chief-message-cancel-button"
          :visual-type="BUTTON_TYPE.TERTIARY"
          :size="SIZES.MEDIUM"
          @click="emits('cancel')"
        >
          {{ t('common.cancel') }}
        </Button>

        <Button
          data-testid="dashboard-chief-message-update-button"
          type="submit"
          :size="SIZES.MEDIUM"
          :is-loading="isLoading"
        >
          {{ t('dashboard.chief-message.save') }}
        </Button>
      </div>
    </form>
  </ModalHolder>
</template>

<script setup lang="ts">
import zod from 'zod';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { toTypedSchema } from '@vee-validate/zod';

import ModalHolder from '@/components/ModalHolder.vue';
import FormTextarea from '@/components/FormTextarea.vue';
import Button from '@/components/Button.vue';

import { useSimpleAction } from '@/composables';
import { useFormData } from '@/composables/useFormData';

import { BUTTON_TYPE, type EditCoworkerMessagePayload, SIZES } from '@/types';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'confirm'): void;
  (event: 'cancel'): void;
  (event: 'close'): void;
}>();

const MAX_MESSAGE_LENGTH = 300;

const { t } = useI18n();
const { defineField, errors, meta, handleSubmit } = useFormData({
  data: {
    message: '',
  },
  validator: toTypedSchema(
    zod.object({
      message: zod.string().min(1).max(MAX_MESSAGE_LENGTH),
    }),
  ),
});

const [message, messageAttrs] = defineField('message');

const isMessageErrored = computed(() => meta.value.touched && !!errors.value.message);

const onSubmit = handleSubmit((values: EditCoworkerMessagePayload) => action(values));
const { isLoading, action } = useSimpleAction(async () => {
  emits('confirm');
});
</script>
