import ClearFormattingIcon from '@/assets/icons/editor/clear-formatting.svg?raw';

import { markItem } from '@/features/CollaborativeEditor/utils/blocks';
import type { MarkType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { EditorView } from 'prosemirror-view';
import { EditorState, Transaction } from 'prosemirror-state';
import { liftTarget } from 'prosemirror-transform';
import { type ResolvedPos, type NodeType } from 'prosemirror-model';

const MARKS_ALLOWED_TO_CLEAR_FORMATTING = [
  'li',
  'ol',
  'ul',
  'h2',
  'h3',
  'u',
  's',
  'blockquote',
  'container',
  'strong',
  'em',
  'a',
  'highlight',
];
export const buildClearFormatting = (mark: MarkType): InstallableBlock => {
  const clearIconNode = new DOMParser().parseFromString(ClearFormattingIcon, 'text/html').body.firstElementChild;
  const item = markItem(mark, {
    title: 'Toggle clear',
    icon: {
      dom: clearIconNode as Node,
    },
    run(state: EditorState, dispatch: any, editorView: EditorView) {
      const { tr }: { tr: Transaction } = editorView.state;
      const { $from, $to }: { $from: ResolvedPos; $to: ResolvedPos } = tr.selection;

      // traverse all nodes within selection recursively and deal with each
      tr.doc.nodesBetween($from.pos, $to.pos, (node: Node, pos: number) => {
        const formattedNodeType: NodeType = state.schema.nodes[node.type.name];

        if (
          formattedNodeType &&
          (MARKS_ALLOWED_TO_CLEAR_FORMATTING.includes(formattedNodeType.name) ||
            node.marks.some((n) => MARKS_ALLOWED_TO_CLEAR_FORMATTING.includes(n.type.name)))
        ) {
          const fromPos = tr.doc.resolve(tr.mapping.map(pos + 1));
          const toPos = tr.doc.resolve(tr.mapping.map(pos + node.nodeSize - 1));
          const nodeRange = fromPos.blockRange(toPos);

          if (nodeRange) {
            const targetLiftDepth: number | undefined | null = liftTarget(nodeRange);

            if (formattedNodeType.isTextblock) {
              tr.setNodeMarkup(nodeRange.start, state.schema.nodes.paragraph);
              return false;
            } else if (targetLiftDepth || targetLiftDepth === 0) {
              tr.lift(nodeRange, targetLiftDepth);
              return false;
            }
          }

          // remove marks
          tr.removeMark(fromPos.pos - 1, toPos.pos + 1);
        }
      });
      tr.setStoredMarks([]);
      editorView.dispatch(tr);
      return true;
    },
  });

  return {
    key: 'toggleClear',
    item,
  };
};
