import { extractData, http } from '@/http';
import type {
  CategoriesFetchRequest,
  CategoryFetchParam,
  CategoryTranslate,
  CategoryTranslateFormPayload,
  CategoriesFetchResponse,
} from '@/features/Categories/types';

export const fetchCategories = (params: CategoriesFetchRequest): Promise<CategoriesFetchResponse> =>
  http.get<CategoriesFetchResponse>('/category-translates', { params, useAuth: true }).then(extractData);

export const fetchCategory = ({ categoryId }: CategoryFetchParam): Promise<CategoryTranslate> =>
  http
    .get<CategoryTranslate>(`/category-translates/${categoryId}`, {
      useAuth: true,
    })
    .then(extractData);

export const createCategory = (category: CategoryTranslateFormPayload): Promise<CategoryTranslate> =>
  http
    .post<CategoryTranslate>(`/category-translates`, category, {
      useAuth: true,
    })
    .then(extractData);

export const updateCategory = (
  categoryId: string | number,
  category: CategoryTranslateFormPayload,
): Promise<CategoryTranslate> =>
  http
    .patch<CategoryTranslate>(`/category-translates/${categoryId}`, category, {
      useAuth: true,
    })
    .then(extractData);
