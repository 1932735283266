import { defineStore } from 'pinia';
import { ref } from 'vue';

interface User {
  name: string;
  id: number;
}

export const useActiveArticleStore = defineStore('useActiveArticleStore', () => {
  const state = ref<User[]>([]);

  const setUsersStore = (users: User[]): void => {
    const validUsers = users.filter(Boolean);

    state.value = [...new Map(validUsers.map((user) => [user?.id, user])).values()];
  };

  const clearStore = (): void => {
    state.value = [];
  };

  return {
    setUsersStore,
    clearStore,
    state,
  };
});
