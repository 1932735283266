export enum ROLES {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_EDITOR_IN_CHIEF = 'ROLE_EDITOR_IN_CHIEF',
  ROLE_EDITOR = 'ROLE_EDITOR',
  ROLE_WRITER = 'ROLE_WRITER',
  ROLE_SENIOR_WRITER = 'ROLE_SENIOR_WRITER',
  ROLE_COPY_EDITOR = 'ROLE_COPY_EDITOR',
  ROLE_ART_EDITOR = 'ROLE_ART_EDITOR',
  ROLE_ADVERTORIAL = 'ROLE_ADVERTORIAL',
  ROLE_TESTER = 'ROLE_TESTER',
  ROLE_TRANSLATOR = 'ROLE_TRANSLATOR',
  ROLE_EVENT_DIVISION = 'ROLE_EVENT_DIVISION',
  ROLE_CLIENT_SERVICE = 'ROLE_CLIENT_SERVICE',
  ROLE_SEO = 'ROLE_SEO',
}
