<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Taxonomy</h3>

    <FormSelect
      v-if="documentMetadataCategoryView"
      name="category"
      class="mb-2"
      placeholder="Not selected"
      :model-value="props.category"
      :attrs="props.categoryAttrs"
      :is-errored="props.isCategoryErrored"
      :is-disabled="!documentMetadataCategoryEdit"
      is-required
      :size="SIZES.SMALL"
      :values="categories"
      :variant="VARIANTS.MUTED"
      @update:model-value="onCategoryChange"
    >
      <template #label>
        <span class="text-xs">Category</span>
      </template>

      <template #error> Select category </template>
    </FormSelect>

    <FormSelect
      v-if="documentMetadataLabelView"
      name="label"
      class="mb-2"
      placeholder="Not selected"
      :model-value="props.label"
      :attrs="props.labelAttrs"
      :is-errored="props.isLabelErrored"
      :is-disabled="!documentMetadataLabelEdit || isDisabledLabelsInput"
      :size="SIZES.SMALL"
      :values="badges"
      is-required
      :variant="VARIANTS.MUTED"
      @update:model-value="(value) => emits('update:label', value)"
    >
      <template #label>
        <span class="text-xs">Label</span>
      </template>

      <template #error> Select a label to display on the cover </template>
    </FormSelect>

    <TagsMultiDropdown
      v-if="documentMetadataSuperTagsView"
      :is-super="true"
      name="superTags"
      :model-value="props.superTags"
      :is-disabeld="documentMetadataSuperTagsEdit"
      :attrs="props.superTagsAttrs"
      is-required
      :is-errored="props.isSuperTagsErrored"
      :init-values="initSuperTags"
      @update:model-value="
        (value) => {
          selectedSuperTags = value;
          isSelectedSuperTagsChanged = true;
          onTagsAndSuperTagsUpdate(value);
        }
      "
    >
      <template #label>
        <span class="text-xs">Super tags</span>
      </template>

      <template #error> Select super tag </template>
    </TagsMultiDropdown>
    <TagsMultiDropdown
      v-if="documentMetadataTagsView"
      :is-super="false"
      name="tags"
      is-required
      :model-value="props.tags"
      :is-disabeld="documentMetadataTagsEdit"
      :attrs="props.tagsAttrs"
      :is-errored="props.isTagsErrored"
      :init-values="initTags"
      @update:model-value="
        (value) => {
          selectedTags = value;
          isSelectedTagsChanged = true;
          onTagsAndSuperTagsUpdate(value);
        }
      "
    >
      <template #label>
        <span class="text-xs">Tags</span>
      </template>

      <template #error> Select tag </template>
    </TagsMultiDropdown>
  </section>
</template>

<script lang="ts" setup>
import { ref, onMounted, watchEffect, watch, shallowRef, computed } from 'vue';
import FormSelect from '@/components/FormSelect.vue';
import { SIZES, SortStatus, VARIANTS } from '@/types';
import type { ArticleTagsFetchRequest, Tag } from '../types';
import { useUserPermissions } from '@/stores/user.store';
import { useArticleTagsStore } from '@/features/CollaborativeEditor/stores/article-tags.store';
import TagsMultiDropdown from '@/features/ArticleLayout/components/TagsMultiDropdown.vue';
import type { SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import { fetchTags } from '@/features/ArticleLayout/api';
import { useCategories, useBadges } from '@/features/ArticleLayout/composables';
import { debounce } from 'lodash';

const props = defineProps<{
  category: number;
  categoryAttrs: Record<string, unknown>;
  isCategoryErrored: boolean;
  label: number;
  labelAttrs: Record<string, unknown>;
  isLabelErrored: boolean;
  superTags: SearchMultidropdownItem[];
  superTagsAttrs: Record<string, unknown>;
  isSuperTagsErrored: boolean;
  tags: SearchMultidropdownItem[];
  tagsAttrs: Record<string, unknown>;
  isTagsErrored: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:category', value: string): void;
  (event: 'update:label', value: string): void;
  (event: 'update:super-tags', value: string[]): void;
  (event: 'update:tags', value: string[]): void;
}>();

const {
  documentMetadataCategoryView,
  documentMetadataCategoryEdit,
  documentMetadataLabelView,
  documentMetadataLabelEdit,
  documentMetadataSuperTagsView,
  documentMetadataSuperTagsEdit,
  documentMetadataTagsView,
  documentMetadataTagsEdit,
} = useUserPermissions();

const { setTags } = useArticleTagsStore();

const initTags = ref<SearchMultidropdownItem[]>([]);
const initSuperTags = ref<SearchMultidropdownItem[]>([]);

const selectedTags = ref<SearchMultidropdownItem>();
const selectedSuperTags = ref<SearchMultidropdownItem>();

const selectedCategory = ref<{ category?: number }>({ category: props.category ?? undefined });
const { badges, refetch } = useBadges(selectedCategory);

const isSelectedTagsChanged = shallowRef<boolean>(false);
const isSelectedSuperTagsChanged = shallowRef<boolean>(false);
const isDisabledLabelsInput = computed<boolean>(() => !props.category);

watchEffect(() => {
  selectedTags.value = props.tags;
  selectedSuperTags.value = props.superTags;
});

watch(
  () => props.category,
  () => {
    selectedCategory.value.category = props.category;
    refetch(selectedCategory.value.category);
  },
);

watch(
  () => [selectedCategory.value, badges.value],
  (newVal, oldVal) => {
    /**
     * According to Jira-810
     * Users can select any label from the global label list if no labels are assigned to a category.
     * */
    if (selectedCategory.value && !newVal[1].length) {
      refetch(null);
    }
  },
);

const onCategoryChange = (value: number) => {
  selectedCategory.value.category = value;
  emits('update:category', value);
  refetch(value);
};

const onTagsAndSuperTagsUpdate = debounce((): void => {
  if (isSelectedTagsChanged.value) {
    setTags(selectedTags.value);
    emits('update:tags', selectedTags.value);
  }
  if (isSelectedSuperTagsChanged.value) {
    emits('update:super-tags', selectedSuperTags.value);
  }

  isSelectedSuperTagsChanged.value = false;
  isSelectedTagsChanged.value = false;
}, 5000);

onMounted(async () => {
  const tagsPayload: ArticleTagsFetchRequest = {
    languageId: 1,
    'filter[super]': false,
    'order[popular]': SortStatus.ASC,
    pagination: true,
    page: 1,
    itemsPerPage: 10,
  };

  const superTagsPayload: ArticleTagsFetchRequest = {
    languageId: 1,
    'filter[super]': true,
    pagination: false,
  };

  const { items: tagsItems } = await fetchTags(tagsPayload);

  // Init values for tags field
  initTags.value = tagsItems?.map((tagTranslate: Tag) => ({
    id: tagTranslate.tag.id,
    label: tagTranslate.title,
  }));

  // We need to make a pause before fetching tags again for removing conflict between this two requests
  setTimeout(async () => {
    const { items: superTagsItems } = await fetchTags(superTagsPayload);
    // Init values for superTags field

    initSuperTags.value = superTagsItems?.map((tagTranslate: Tag) => ({
      id: tagTranslate.tag.id,
      label: tagTranslate.title,
    }));
  }, 0);

  if (props.tags && props.tags.length) {
    setTags(props.tags);
  }
});

const { categories } = useCategories();
</script>
