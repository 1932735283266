<template>
  <Badge
    :id="idStr"
    :label="badgeLabel"
    :theme="theme"
    :size="SIZES.XSMALL"
  />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import Badge from '@/components/Badge.vue';
import { BADGE_THEMES, SIZES } from '@/types';

interface Props {
  enabled: boolean;
  categoryId: string;
}

const props = withDefaults(defineProps<Props>(), {
  categoryId: null,
  enabled: true,
});

const badgeLabel = computed(() => {
  return props.enabled ? 'Visible' : 'Not Visible';
});

const idStr = computed(() => {
  return `category-bardge-${props.categoryId}`;
});

const theme = computed(() => {
  return props.enabled ? BADGE_THEMES.GREEN : BADGE_THEMES.RED;
});
</script>
