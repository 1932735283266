<template>
  <tr
    :class="
      tableHeadRowClass({
        size: props.size,
        surface: props.isOnSurface,
        bordered: props.bordered,
      })
    "
  >
    <slot />
  </tr>
</template>

<script setup lang="ts">
import { TABLE_BORDERED, TABLE_ON_SURFACE, SIZES } from '@/types';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    bordered?: TABLE_BORDERED;
    isOnSurface?: TABLE_ON_SURFACE;
    size: SIZES;
  }>(),
  {
    bordered: TABLE_BORDERED.NONE,
    isOnSurface: TABLE_ON_SURFACE.DEFAULT,
    size: SIZES.MEDIUM,
  },
);

const tableHeadRowClass = tv({
  base: 'text-imperium-fg-muted font-semibold text-xs',
  variants: {
    size: {
      [SIZES.SMALL]: 'table-head-row-sm',
      [SIZES.MEDIUM]: 'table-head-row-md',
      [SIZES.LARGE]: 'table-head-row-lg',
      [SIZES.XLARGE]: 'table-head-row-xl',
    },
    bordered: {
      [TABLE_BORDERED.TOP]: 'table-head-row-bordered-top',
      [TABLE_BORDERED.BOTTOM]: 'table-head-row-bordered-bottom',
    },
    surface: {
      [TABLE_ON_SURFACE.PRIMARY]: 'table-head-row-card-surface',
    },
  },
});
</script>
