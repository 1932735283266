<template>
  <div
    :data-testid="`badge-${id}`"
    class="inline-flex items-center cursor-pointer"
    :class="
      badgeClass({
        theme: props.theme,
        size: props.size as SIZES,
        rounded: props.rounded,
        isHoverable: props.isHoverable,
        isDisabled: props.isDisabled,
      })
    "
    @click="click"
  >
    <div
      v-if="isLeftIcon"
      class="pl-1 ml-1"
    >
      <slot name="icon" />
    </div>

    <div>
      {{ label }}
    </div>

    <div
      v-if="isRightIcon"
      class="pr-1 mr-1"
    >
      <slot name="iconRight" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { SIZES, BADGE_THEMES, BADGE_ROUNDED, VARIANTS } from '@/types';
import { computed, useSlots } from 'vue';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    label: string;
    id: string;

    theme?: BADGE_THEMES;
    size?: SIZES;
    rounded?: BADGE_ROUNDED;
    isHoverable?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    theme: BADGE_THEMES.DEFAULT,
    size: SIZES.SMALL,
    rounded: BADGE_ROUNDED.FULL,
    isHoverable: false,
    isDisabled: false,
  },
);

const emits = defineEmits<{
  (event: 'click'): void;
}>();

const slots = useSlots();

const isLeftIcon = computed(() => !!slots.icon);
const isRightIcon = computed(() => !!slots.iconRight);

const badgeClass = tv({
  base: 'font-semibold text-center border bg-clip-padding transition-colors',
  variants: {
    size: {
      [SIZES.MEDIUM]: 'text-sm leading-5 px-2.5 py-1.5', // sm in design
      [SIZES.SMALL]: 'text-xs leading-4 px-2 py-1', // xs in design
      [SIZES.XSMALL]: 'text-xs leading-normal px-1 py-0', // 2xs in design
    },
    rounded: {
      [BADGE_ROUNDED.DEFAULT]: 'rounded-sm',
      [BADGE_ROUNDED.FULL]: 'rounded-full',
      [BADGE_ROUNDED.LARGE]: 'rounded',
      [BADGE_ROUNDED.NONE]: 'rounded-none',
    },
  },
  compoundVariants: [
    // DEFAULT
    {
      theme: BADGE_THEMES.DEFAULT,
      isHoverable: false,
      isDisabled: false,
      class: 'bg-imperium-bg-3 border-imperium-border-base text-imperium-fg-base',
    },
    {
      theme: BADGE_THEMES.DEFAULT,
      isHoverable: true,
      isDisabled: false,
      class:
        'bg-imperium-bg-3 border-imperium-border-base text-imperium-fg-base hover:bg-imperium-bg-4 hover:text-imperium-fg-strong',
    },

    // PRIMARY
    {
      theme: BADGE_THEMES.PRIMARY,
      isHoverable: false,
      isDisabled: false,
      class: 'bg-imperium-bg-base border-imperium-ds-primary-strong text-imperium-ds-base-black',
    },
    {
      theme: BADGE_THEMES.PRIMARY,
      isHoverable: true,
      isDisabled: false,
      class:
        'bg-imperium-bg-base border-imperium-ds-primary-strong text-imperium-fg-base-black hover:bg-imperium-ds-primary-strong',
    },

    // RED
    {
      theme: BADGE_THEMES.RED,
      isHoverable: false,
      isDisabled: false,
      class: 'bg-imperium-ds-red-base border-imperium-ds-red-strong text-imperium-ds-base-white',
    },
    {
      theme: BADGE_THEMES.PRIMARY,
      isHoverable: true,
      isDisabled: false,
      class:
        'bg-imperium-ds-red-base border-imperium-ds-red-strong text-imperium-ds-base-white hover:bg-imperium-ds-red-strong',
    },

    // GREEN
    {
      theme: BADGE_THEMES.GREEN,
      isHoverable: false,
      isDisabled: false,
      class: 'bg-imperium-ds-green-base border-imperium-ds-green-strong text-imperium-ds-base-white',
    },
    {
      theme: BADGE_THEMES.PRIMARY,
      isHoverable: true,
      isDisabled: false,
      class:
        'bg-imperium-ds-green-base border-imperium-ds-green-strong text-imperium-ds-base-white hover:bg-imperium-ds-green-strong',
    },

    // DISABLED
    // For now, only 1 color - in design no disabled state for all colors
    {
      isDisabled: true,
      class: 'bg-imperium-bg-2 text-imperium-fg-subtle',
    },
  ],
});

const click = (event: Event) => {
  event.stopPropagation();
  emits('click');
};
</script>
