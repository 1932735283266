import { useQuery } from '@tanstack/vue-query';
import { type Ref } from 'vue';

import { IMAGE_RESOURCE } from '../constants';
import { imageFetch } from '@/features/Media/api';

interface FetchImageQueryPayload {
  imageId: Ref<number | null>;
}

interface FetchImagePayload {
  imageId: number | null;
}

export const useFetchImage = (payload: FetchImageQueryPayload) => {
  const { data, isLoading, isPending, isError, error } = useQuery({
    queryKey: [IMAGE_RESOURCE, payload],
    queryFn: ({ queryKey }) => {
      const payload: FetchImagePayload = queryKey[1] as FetchImagePayload;

      return payload.imageId ? imageFetch(payload.imageId) : {};
    },

    staleTime: Infinity,
    refetchInterval: false,
    retry: 0,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    isPending,
  };
};
