<template>
  <label
    class="inline-flex cursor-pointer"
    :class="{
      'items-center': !isHelp,
      'items-start': isHelp,
    }"
  >
    <input
      type="checkbox"
      :value="modelValue"
      :checked="!!modelValue"
      :disabled="isDisabled"
      v-bind="props.attrs"
      class="text-imperium-ds-primary-base bg-white rounded focus:ring-imperium-ds-primary-strong focus:border-imperium-ds-primary-strong disabled:bg-imperium-bg-3 disabled:border-imperium-border-base"
      :class="{
        [$style.checkbox]: true,
        [$style.checkboxSmall]: props.size === SIZES.SMALL,

        // Sizes
        'w-6 h-6': props.size === SIZES.MEDIUM,
        'w-4 h-4': props.size === SIZES.SMALL,
        'mt-0.5': isHelp,
      }"
      :data-testid="`checkbox-${props.name}`"
      @change="onInputChange"
    />
    <span class="ms-2 text-md text-imperium-fg-base w-full">
      <slot />
      <span
        v-if="isHelp"
        :data-testid="`input-${props.name}-help`"
        class="mt-1 input-meta-text-default input-meta-text-sm input-meta-helper-text"
      >
        <slot name="help" />
      </span>
    </span>
  </label>
</template>

<script lang="ts" setup>
import { SIZES } from '@/types';
import { computed, useSlots } from 'vue';

const slots = useSlots();

const props = withDefaults(
  defineProps<{
    name: string;
    modelValue: boolean;
    isErrored: boolean;
    attrs?: Record<string, unknown>;
    isRequired: boolean;
    isDisabled: boolean;
    size: SIZES;
  }>(),
  {
    size: SIZES.MEDIUM,
    isErrored: false,
    isRequired: false,
    isDisabled: false,
  },
);

const isHelp = computed(() => !!slots.help);

const emits = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const onInputChange = (e: Event) => {
  emits('update:modelValue', (e.target as HTMLInputElement).checked);
};
</script>

<style lang="scss" module>
.checkbox[type='checkbox'] {
  &:not(:checked) {
    @apply border-imperium-border-base;
  }

  &:checked {
    background-image: url('~/src/assets/icons/check-mark.svg');
    background-size: 24px 24px;

    @apply border-imperium-ds-primary-strong;
  }
}

.checkboxSmall[type='checkbox'] {
  &:checked {
    background-size: 16px 16px;
  }
}
</style>
