<template>
  <div>
    <ModalHolder
      :is-visible="props.isVisible"
      :size="SIZES.MEDIUM"
      data-testid="edit-media-modal"
      @close="emits('close')"
    >
      <template #title> Image edit </template>
      <div class="pb-2">
        <UploadImageSetAttributes
          :media-id="props.mediaId"
          :is-editing="true"
          @close="onClose"
        />
      </div>
    </ModalHolder>
  </div>
</template>
<script lang="ts" setup>
import ModalHolder from '@/components/ModalHolder.vue';
import { SIZES } from '@/types';

import UploadImageSetAttributes from '@/features/UploadImage/components/UploadImageSetAttributes.vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    mediaId: number | null;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
}>();

const onClose = () => {
  emits('close');
};
</script>
