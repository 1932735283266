<template>
  <div
    :class="
      wrapperClass({
        size: props.size,
        rounded: props.rounded,
        isDisabled: props.isDisabled,
        color: props.color,
        width: props.width,
      })
    "
    :data-test-id="id"
  >
    <div
      v-for="itemId in props.values"
      :key="itemId"
    >
      <div @click="() => onChangeTab(itemId)">
        <ToggleButton
          v-bind="buttonAttributes"
          :is-active="isTabCurrent(itemId)"
        >
          <slot :name="itemId" />
          <template #leftIcon>
            <slot :name="`${itemId}-leftIcon`" />
          </template>
          <template #rightIcon>
            <slot :name="`${itemId}-rightIcon`" />
          </template>
        </ToggleButton>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { tv } from 'tailwind-variants';
import ToggleButton from '@/components/ToggleButton.vue';

import {
  BUTTON_ON_SURFACE,
  BUTTON_ROUNDED,
  BUTTON_TYPE,
  SEGMENT_CONTROL_TABS_COLOR,
  SEGMENT_CONTROL_TABS_WIDTH,
  SIZES,
} from '@/types';

const props = withDefaults(
  defineProps<{
    id: string;
    values: string[];
    currentTab?: string;
    size?: SIZES;
    rounded?: BUTTON_ROUNDED;
    color?: SEGMENT_CONTROL_TABS_COLOR;
    width?: SEGMENT_CONTROL_TABS_WIDTH;
    onSurface?: BUTTON_ON_SURFACE;
    isDisabled?: boolean;
    buttonVisualType?: BUTTON_TYPE;
  }>(),
  {
    size: SIZES.MEDIUM,
    rounded: BUTTON_ROUNDED.LARGE,
    color: SEGMENT_CONTROL_TABS_COLOR.DEFAULT,
    width: SEGMENT_CONTROL_TABS_WIDTH.CONTENT,
    onSurface: BUTTON_ON_SURFACE.PRIMARY,
    isDisabled: false,
    buttonVisualType: BUTTON_TYPE.PRIMARY,
  },
);

const emits = defineEmits<{
  (event: 'changeTab', id: string): void;
}>();

const currentTab = ref<string>();

watchEffect(
  () => {
    if (props.values?.length) {
      currentTab.value = props.currentTab ? props.currentTab : props.values[0];
    }
  },
  { deep: true },
);

const wrapperClass = tv({
  base: 'flex p-1',
  variants: {
    size: {
      [SIZES.MEDIUM]: '',
    },
    rounded: {
      [BUTTON_ROUNDED.LARGE]: 'button-rounded-lg',
      [BUTTON_ROUNDED.BASE]: 'button-rounded-base',
      [BUTTON_ROUNDED.NONE]: 'button-rounded-none',
      [BUTTON_ROUNDED.FULL]: 'button-rounded-full',
    },
    color: {
      [SEGMENT_CONTROL_TABS_COLOR.DEFAULT]: 'bg-white',
      [SEGMENT_CONTROL_TABS_COLOR.GRAY_3]: 'bg-imperium-bg-3',
      [SEGMENT_CONTROL_TABS_COLOR.GRAY_5]: 'bg-imperium-bg-5',
    },
    width: {
      [SEGMENT_CONTROL_TABS_WIDTH.STRETCH]: 'w-full',
      [SEGMENT_CONTROL_TABS_WIDTH.CONTENT]: 'w-auto',
    },
    // ask for disabled state
    isDisabled: {
      true: '',
    },
  },
});

const buttonAttributes = computed(() => {
  return {
    size: props.size,
    rounded: props.rounded,
    isDisabled: props.isDisabled,
  };
});

const onChangeTab = (tabId: string) => {
  currentTab.value = tabId;
  emits('changeTab', tabId);
};

const isTabCurrent = (id: string): boolean => {
  return currentTab.value === id;
};
</script>
