<template>
  <Toast
    :id="props.id"
    :message="props.message || 'Please try again later'"
    title="Invitation failed"
    :theme="THEMES.DANGER"
  />
</template>
<script setup lang="ts">
import Toast from '@/components/Toast.vue';
import { THEMES } from '@/types';

const props = defineProps<{
  id: string;
  message: string;
}>();
</script>
