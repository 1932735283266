import { MenuItem } from '@/features/CollaborativeEditor/plugins/menu';
import ClosedIcon from '@/assets/icons/closed.svg?raw';

import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { TOGGLE_ATTRIBUTE } from '@/features/CollaborativeEditor/constants';

export const toggleParagraphAttribute = (): InstallableBlock => {
  const item = new MenuItem({
    title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.NON_BREAKABLE),
    render: () => {
      const label = 'Non-breakable';

      const template = `<div id="non-breakable-button">${ClosedIcon}<div>${label}</div></div>`;
      return new DOMParser().parseFromString(template, 'text/html').body.firstElementChild as HTMLElement;
    },
    active(state) {
      const { from } = state.selection;
      const topNode = state.doc.resolve(from).node(1);

      if (topNode) {
        return topNode.attrs[TOGGLE_ATTRIBUTE] === 'true';
      }

      return false;
    },
    enable(state) {
      return !state.selection.empty;
    },
    run(state, dispatch, view) {
      const { selection } = state;
      const { from } = selection;

      let transaction = state.tr;
      const topNode = state.doc.resolve(from).node(1);

      if (topNode) {
        const pos = state.doc.resolve(from).before(1);
        const currentValue = topNode.attrs[TOGGLE_ATTRIBUTE] === 'true' ? null : 'true';
        const newAttrs = {
          ...topNode.attrs,
          [TOGGLE_ATTRIBUTE]: currentValue,
        };

        transaction = transaction.setNodeMarkup(pos, null, newAttrs);
      }

      if (transaction.docChanged) {
        dispatch(transaction);
      }
    },
  });

  return {
    key: 'toggleParagraphAttribute',
    item,
  };
};
