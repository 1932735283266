import type { DocumentPatchPayload } from '@/types';

export interface CreateArticlePayload {
  assignment: string;
  isBreaking: boolean;
  needsOriginalArtwork: boolean;
  categoryId: number;
  editorId: number;
  writerId: number;
}

export type CreateArticleRequest = CreateArticlePayload & {
  languageId: number;
};

export type CreateArticleResponse = {
  id: number;
} & DocumentPatchPayload;

export const enum CoverDeadline {
  '10_MINUTES' = '10m',
  '30_MINUTES' = '30m',
  '24_HOURS' = '24h',
}

export const CoverDeadlineOptions = [
  {
    id: CoverDeadline['10_MINUTES'],
    label: '≈ in 5 – 10 minutes',
  },
  {
    id: CoverDeadline['30_MINUTES'],
    label: '≈ in 30 minutes',
  },
  {
    id: CoverDeadline['24_HOURS'],
    label: 'during 24 hours',
  },
];

export const enum ASSIGN_TO_ME_ENTITY {
  WRITER = 'writer',
  EDITOR = 'editor',
}
