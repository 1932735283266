import { Schema, Node, Mark } from 'prosemirror-model';
import type { Attrs, MarkSpec, NodeSpec, DOMOutputSpec } from 'prosemirror-model';
import { bulletList, orderedList, listItem } from 'prosemirror-schema-list';
import { ImageAttributes, setEditingImageAttributes } from '@/features/CollaborativeEditor/blocks/imagesDataHelper';
import type { ImageAttributesObject, EditorImageType } from '@/features/CollaborativeEditor/blocks/imagesDataHelper';
import { setEditingLinkAttributes } from '@/features/CollaborativeEditor/blocks/link';
import { TOGGLE_ATTRIBUTE } from '@/features/CollaborativeEditor/constants';
import main from '~/.storybook/main';
import { addListenersForLinksFocus } from '@/features/CollaborativeEditor/helpers/linkPreview';

const brDOM: DOMOutputSpec = ['br'];

const addListNode = (obj: { [prop: string]: any }, props: { [prop: string]: any }) => {
  const copy: { [prop: string]: any } = {};

  for (const prop in obj) {
    copy[prop] = obj[prop];
  }

  for (const prop in props) {
    copy[prop] = props[prop];
  }

  copy.attrs = {
    ...copy.attrs,
    [TOGGLE_ATTRIBUTE]: { default: null },
  };

  if (copy.parseDOM) {
    copy.parseDOM = copy.parseDOM.map((rule: any) => ({
      ...rule,
      getAttrs: (dom: HTMLElement) => ({
        ...(rule.getAttrs ? rule.getAttrs(dom) : {}),
        [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
      }),
    }));
  }
  if (copy.toDOM) {
    const originalToDOM = copy.toDOM;
    copy.toDOM = (node: any) => {
      const domSpec = originalToDOM(node);

      if (Array.isArray(domSpec)) {
        if (typeof domSpec[1] === 'number') {
          domSpec.splice(1, 0, { [TOGGLE_ATTRIBUTE]: node.attrs[TOGGLE_ATTRIBUTE] });
        } else if (typeof domSpec[1] === 'object' && domSpec[1] !== null) {
          domSpec[1][TOGGLE_ATTRIBUTE] = node.attrs[TOGGLE_ATTRIBUTE];
        }
      }

      return domSpec;
    };
  }

  return copy;
};

const calcYchangeDomAttrs = (attrs: Attrs, domAttrs: Record<string, unknown> = {}) => {
  domAttrs = Object.assign({}, domAttrs);
  if (attrs.ychange !== null) {
    domAttrs.ychange_user = attrs.ychange.user;
    domAttrs.ychange_state = attrs.ychange.state;
  }
  return domAttrs;
};

const headingSchema = (level: number): NodeSpec => ({
  attrs: {
    ychange: { default: null },
    [TOGGLE_ATTRIBUTE]: { default: null }, // Добавляем TOGGLE_ATTRIBUTE
  },
  content: 'inline*',
  group: 'block',
  defining: true,
  parseDOM: [
    {
      tag: `h${level}`,
      getAttrs(dom: HTMLElement) {
        return {
          ychange: dom.getAttribute('ychange'),
          [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE), // Парсим TOGGLE_ATTRIBUTE
        };
      },
    },
  ],
  toDOM(node) {
    const attrs = { ...node.attrs };
    const domAttrs = calcYchangeDomAttrs(attrs);

    if (attrs[TOGGLE_ATTRIBUTE] !== null) {
      domAttrs[TOGGLE_ATTRIBUTE] = attrs[TOGGLE_ATTRIBUTE];
    }

    return [`h${level}`, domAttrs, 0];
  },
});

export const createImageAttributesObject = (data: Partial<EditorImageType>): ImageAttributesObject => {
  const result: ImageAttributesObject = {};
  for (const attrKey of ImageAttributes) {
    if (data[attrKey] !== undefined && data[attrKey] !== null) {
      result[attrKey] = data[attrKey];
    }
  }

  return result;
};

export const nodes: Record<string, NodeSpec> = {
  // :: NodeSpec The top level document node.
  doc: {
    content: 'block+',
  },
  p: {
    attrs: {
      ychange: { default: null },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'inline*',
    group: 'block',
    parseDOM: [
      {
        tag: 'p',
        getAttrs(dom: HTMLElement) {
          return {
            ychange: dom.getAttribute('ychange'),
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['p', attrs, 0];
    },
  },

  paragraph: {
    attrs: {
      ychange: { default: null },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'inline*',
    group: 'block',
    parseDOM: [{ tag: 'p' }],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['p', attrs, 0];
    },
  },

  advertisement: {
    attrs: {
      ychange: { default: null },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [
      {
        tag: 'div',
        getAttrs(dom: HTMLElement) {
          return {
            class: 'advertisement',
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['div', attrs, 0];
    },
  },
  container: {
    attrs: {
      ychange: { default: null },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: '(block | li)+',
    group: 'block',
    defining: true,
    parseDOM: [
      {
        tag: 'article',
        getAttrs(dom: HTMLElement) {
          return {
            class: 'container container--small',
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };

      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }

      const domAttrs = {
        class: 'container container--small',
        ...(attrs[TOGGLE_ATTRIBUTE] && { [TOGGLE_ATTRIBUTE]: attrs[TOGGLE_ATTRIBUTE] }),
      };

      return ['article', calcYchangeDomAttrs(attrs, domAttrs), 0];
    },
  },

  disclaimer: {
    attrs: {
      ychange: { default: null },
      type: { default: '' },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'inline*',
    group: 'block',
    parseDOM: [
      {
        tag: 'disclaimer',
        getAttrs(dom: HTMLElement) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['disclaimer', attrs, 0];
    },
  },
  subscription_form: {
    attrs: {
      ychange: { default: null },
      type: { default: '' },
      label: { default: '' },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block*',
    atom: true,
    group: 'block',
    selectable: true,
    parseDOM: [
      { tag: 'template[data-type="law_decoded"]' },
      { tag: 'template[data-type="markets_outlook"]' },
      { tag: 'template[data-type="defi_newsletter"]' },
      { tag: 'template[data-type="consulting_newsletter"]' },
      { tag: 'template[data-type="nifty_newsletter"]' },
      { tag: 'template[data-type="crypto_biz"]' },
    ],
    toDOM(node: Node) {
      const domAttrs = {
        ['data-type']: node.attrs.type,
        ['data-name']: 'subscription_form',
        ['data-label']: node.attrs.label,
      };

      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }

      return ['template', calcYchangeDomAttrs(attrs, domAttrs), 0];
    },
  },

  article_widget: {
    attrs: {
      ychange: { default: null },
      id: { default: '' },
      languageId: { default: '' },
      title: { default: '' },
      publishedAt: { default: '' },
      imageUrl: { default: '' },
      author: { default: '' },
    },
    content: 'block+',
    group: 'block',
    draggable: true,
    selectable: true,
    atom: true,
    parseDOM: [{ tag: 'div' }],
    toDOM(node: Node) {
      const dom = document.createElement('article');
      dom.className = 'article-widget-container';
      dom.setAttribute('post_id', node.attrs.id);
      dom.setAttribute('language_id', node.attrs?.languageId ?? 1);

      const image = document.createElement('img');
      image.className = 'article-widget-image';
      image.src = node.attrs.imageUrl;

      const mainSection = document.createElement('div');
      const titleWrapper = document.createElement('div');
      const readAlso = document.createElement('p');
      const articleTitle = document.createElement('h3');
      const metaSection = document.createElement('div');
      const author = document.createElement('p');
      const publishedAt = document.createElement('p');

      mainSection.className = 'article-widget-content';
      titleWrapper.className = 'article-widget-title-wrapper';
      articleTitle.className = 'article-widget-title';
      readAlso.className = 'article-widget-read-also';
      metaSection.className = 'article-widget-meta-section';
      author.className = 'article-widget-meta-author';
      publishedAt.className = 'article-widget-meta-published-at';

      readAlso.textContent = 'Read also';
      articleTitle.textContent = node.attrs.title;
      author.textContent = `by ${node.attrs.author}`;

      const date = new Date(node.attrs.publishedAt);
      const dateFormatted = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }).format(date);

      publishedAt.textContent = dateFormatted;

      titleWrapper.appendChild(readAlso);
      titleWrapper.appendChild(articleTitle);

      metaSection.appendChild(author);
      metaSection.appendChild(publishedAt);

      mainSection.appendChild(titleWrapper);
      mainSection.appendChild(metaSection);

      dom.appendChild(image);
      dom.appendChild(mainSection);

      return dom;
    },
  },

  blockquote: {
    attrs: {
      ychange: { default: null },
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [
      {
        tag: 'blockquote',
        getAttrs(dom: HTMLElement) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['blockquote', attrs, 0];
    },
  },

  h1: headingSchema(1),
  h2: headingSchema(2),
  h3: headingSchema(3),
  h4: headingSchema(4),
  h5: headingSchema(5),
  h6: headingSchema(6),

  code_block: {
    attrs: { ychange: { default: null } },
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    defining: true,
    parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
    toDOM(node: Node) {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['pre', attrs, ['code', 0]];
    },
  },

  ol: addListNode(orderedList, { content: 'li+', group: 'block' }),
  ul: addListNode(bulletList, { content: 'li+', group: 'block' }),
  li: addListNode(listItem, { content: 'p*' }),

  text: {
    group: 'inline',
  },

  img: {
    inline: false,
    attrs: {
      src: {},
      alt: { default: '' },
      name: { default: '' },
      title: { default: '' },
      img_link: { default: '' },
      img_link_title: { default: '' },
      img_link_new_tab: { default: '' },
      img_link_nofollow: { default: '' },
    },
    group: 'block',
    draggable: false,
    parseDOM: [
      {
        tag: 'img[src]',
        getAttrs: (dom) => ({
          src: dom.getAttribute('src'),
          alt: dom.getAttribute('alt'),
          name: dom.getAttribute('name'),
          title: dom.getAttribute('title'),
          img_link: dom.getAttribute('img_link'),
          img_link_title: dom.getAttribute('img_link_title'),
          img_link_new_tab: dom.getAttribute('img_link_new_tab'),
          img_link_nofollow: dom.getAttribute('img_link_nofollow'),
        }),
      },
    ],
    toDOM(node: Node) {
      const attrs = createImageAttributesObject(node.attrs);
      const img = document.createElement('img');

      for (const attr in attrs) {
        if (attrs[attr as keyof typeof attrs]) {
          img.setAttribute(attr, attrs[attr as keyof typeof attrs] as string);
        }
      }

      const dom = document.createElement('div');
      dom.className = 'image-container';
      dom.appendChild(img);

      const editButton = document.createElement('button');
      editButton.className = 'article-image-edit-button';
      editButton.addEventListener('click', (event) => {
        event.stopPropagation();
        setEditingImageAttributes({ ...attrs }, node);
        document.getElementById('edit-image-data-button')?.click();
      });

      dom.appendChild(editButton);

      const captionButton = document.createElement('button');
      captionButton.className = 'article-image-caption-button';
      captionButton.addEventListener('click', (event) => {
        event.stopPropagation();
        setEditingImageAttributes({ ...attrs }, node);
        document.getElementById('edit-caption-image-data-button')?.click();
      });

      dom.appendChild(captionButton);

      return dom;
    },
  },

  figure: {
    group: 'block',
    attrs: {
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: '(img)? (figcaption)?',
    draggable: true,
    parseDOM: [
      {
        tag: 'figure',
        getAttrs(dom: HTMLElement) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM: (node) => {
      const attrs = { ...node.attrs };
      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete attrs[TOGGLE_ATTRIBUTE];
      }
      return ['figure', attrs, 0];
    },
  },

  figcaption: {
    group: 'block',
    content: 'block*',
    parseDOM: [{ tag: 'figcaption' }],
    toDOM: () => ['figcaption', { class: 'figcaption' }, 0],
  },

  hard_break: {
    inline: true,
    group: 'inline',
    selectable: false,
    parseDOM: [{ tag: 'br' }],
    toDOM(node: Node) {
      return brDOM;
    },
  },

  // Embeds
  youtube: {
    attrs: {
      ychange: { default: null },
      src: {},
      embed_id: {},
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block*',
    atom: true,
    isolating: true,
    group: 'block',
    selectable: true,
    code: false,
    defining: false,
    parseDOM: [
      {
        tag: 'iframe',
        getAttrs(dom) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
            src: dom.getAttribute('src'),
            type: 'youtube',
            embed_id: dom.getAttribute('embed_id'),
          };
        },
      },
    ],
    toDOM(node) {
      const attrs = { ...node.attrs };
      const resultAttrs = {
        src: attrs.src,
        frameborder: 0,
        allowfullscreen: true,
        type: 'youtube',
        class: 'xs:min-w-[400px] sm:min-w-[440px] w-full xs:h-[300px] sm:h-[360px]',
        embed_id: attrs.embed_id,
        [TOGGLE_ATTRIBUTE]: attrs[TOGGLE_ATTRIBUTE] || undefined,
      };

      if (resultAttrs[TOGGLE_ATTRIBUTE] === undefined) {
        delete resultAttrs[TOGGLE_ATTRIBUTE];
      }

      return ['iframe', resultAttrs];
    },
  },

  vimeo: {
    attrs: {
      ychange: { default: null },
      src: {},
      embed_id: {},
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block*',
    atom: true,
    isolating: true,
    group: 'block',
    selectable: true,
    code: false,
    defining: false,
    parseDOM: [
      {
        tag: 'iframe',
        getAttrs(dom) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
            src: dom.getAttribute('src'),
            type: 'vimeo',
            embed_id: dom.getAttribute('embed_id'),
          };
        },
      },
    ],
    toDOM(node) {
      const attrs = { ...node.attrs };
      const resultAttrs = {
        src: node.attrs.src,
        frameborder: 0,
        allowfullscreen: true,
        type: 'vimeo',
        class: 'xs:min-w-[400px] sm:min-w-[440px] w-full xs:h-[300px] sm:h-[360px]',
        embed_id: node.attrs.embed_id,
        [TOGGLE_ATTRIBUTE]: true,
      };

      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete resultAttrs[TOGGLE_ATTRIBUTE];
      }
      return ['iframe', resultAttrs];
    },
  },

  instagram: {
    attrs: {
      ychange: { default: null },
      src: {},
      embed_id: {},
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block*',
    atom: true,
    isolating: true,
    group: 'block',
    selectable: true,
    code: false,
    defining: false,
    parseDOM: [
      {
        tag: 'iframe',
        getAttrs(dom) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
            src: dom.getAttribute('src'),
            type: 'instagram',
            embed_id: dom.getAttribute('embed_id'),
          };
        },
      },
    ],
    toDOM(node) {
      const attrs = { ...node.attrs };
      const resultAttrs = {
        allowTransparency: true,
        scrolling: 'no',
        src: node.attrs.src,
        frameborder: 0,
        allowfullscreen: false,
        embed_id: node.attrs.embed_id,
        type: 'instagram',
        class: 'mr-auto xs:min-w-[400px] xs:h-[660px] sm:min-w-[440px] sm:h-[760px] mb-4',
        [TOGGLE_ATTRIBUTE]: true,
      };

      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete resultAttrs[TOGGLE_ATTRIBUTE];
      }
      return ['iframe', resultAttrs];
    },
  },

  twitter: {
    attrs: {
      ychange: { default: null },
      embed_id: {},
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    group: 'block',
    parseDOM: [
      {
        tag: 'div[data-tweet-id]',
        getAttrs(dom) {
          return { [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE), tweetId: dom.getAttribute('data-tweet-id') };
        },
      },
    ],
    toDOM(node) {
      const attrs = { ...node.attrs };
      const div = document.createElement('div');
      div.className = 'w-full m-auto';
      window.twttr.widgets.createTweet(node.attrs.embed_id, div, {
        conversation: 'none',
        cards: 'hidden',
        linkColor: '#cc0000',
        theme: 'light',
      });
      div.className = 'm-auto';
      div.setAttribute('data-tweet-id', node.attrs.embed_id);
      div.setAttribute('embed_id', node.attrs.embed_id);
      if (attrs[TOGGLE_ATTRIBUTE]) {
        div.setAttribute(TOGGLE_ATTRIBUTE, true);
      }
      return div;
    },
  },

  buzzsprout_podcast: {
    attrs: {
      ychange: { default: null },
      src: {},
      embed_id: {},
      [TOGGLE_ATTRIBUTE]: { default: null },
    },
    content: 'block*',
    atom: true,
    isolating: true,
    group: 'block',
    selectable: true,
    code: false,
    defining: false,
    parseDOM: [
      {
        tag: 'iframe',
        getAttrs(dom) {
          return {
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
            src: dom.getAttribute('src'),
            type: 'buzzsprout',
            embed_id: dom.getAttribute('embed_id'),
          };
        },
      },
    ],
    toDOM(node) {
      const attrs = { ...node.attrs };
      const resultAttrs = {
        allowTransparency: true,
        scrolling: 'no',
        src: node.attrs.src,
        frameborder: 0,
        allowfullscreen: false,
        embed_id: node.attrs.embed_id,
        type: 'buzzsprout',
        class: 'm-auto w-full h-[375px]',
        [TOGGLE_ATTRIBUTE]: true,
      };

      if (attrs[TOGGLE_ATTRIBUTE] === null) {
        delete resultAttrs[TOGGLE_ATTRIBUTE];
      }
      return ['iframe', resultAttrs];
    },
  },
};

const emDOM: DOMOutputSpec = ['em', 0];
const strongDOM: DOMOutputSpec = ['strong', 0];
const clearDOM: DOMOutputSpec = ['clear', 0];
const codeDOM: DOMOutputSpec = ['code', 0];

export const marks: Record<string, MarkSpec> = {
  a: {
    attrs: {
      href: {},
      target: { default: null },
      rel: { default: null },
      text: { default: null },
      [TOGGLE_ATTRIBUTE]: { default: null },
      title: { default: null },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a[href]',
        getAttrs(dom) {
          if (typeof dom === 'string') {
            return {};
          }

          return {
            href: dom.getAttribute('href'),
            title: dom.getAttribute('title'),
            [TOGGLE_ATTRIBUTE]: dom.getAttribute(TOGGLE_ATTRIBUTE),
          };
        },
      },
    ],
    toDOM(node: Mark) {
      const isExternalLink = () => {
        return !node.attrs.href.includes('cointelegraph.com');
      };
      const linkElement = document.createElement('a');
      linkElement.setAttribute(
        'title',
        node.attrs.title && node.attrs.title !== 'null' ? node.attrs.title : node.attrs.href,
      );
      linkElement.setAttribute('href', node.attrs.href);
      if (isExternalLink()) {
        linkElement.setAttribute('target', '_blank');
        linkElement.setAttribute('rel', 'nofollow noopener');
      } else if (!isExternalLink() || node.attrs.target || node.attrs.rel) {
        linkElement.setAttribute('target', node.attrs.target);
        linkElement.setAttribute('rel', node.attrs.rel);
      }

      linkElement.innerHTML = node.attrs.text;

      linkElement.addEventListener('click', (event) => {
        event.stopPropagation();
        setEditingLinkAttributes({
          text: node.attrs.text,
          link: node.attrs.href,
          isEdit: true,
          title: node.attrs.title && node.attrs.title !== 'null' ? node.attrs.title : node.attrs.href,
        });
        const editLinkMenuButton = document.querySelector('[title="Insert link"]');
        editLinkMenuButton?.click();
      });

      addListenersForLinksFocus(linkElement);

      return linkElement;
    },
  },

  em: {
    parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
    toDOM(mark: Mark) {
      return emDOM;
    },
  },

  u: {
    parseDOM: [
      {
        tag: 'u',
        getAttrs: () => ({}),
      },
    ],
    toDOM(mark: Mark) {
      const domAttrs = {
        style: 'text-decoration: underline',
      };

      return ['u', domAttrs, 0];
    },
  },
  s: {
    parseDOM: [
      {
        tag: 's',
        getAttrs: () => ({}),
      },
    ],
    toDOM(mark: Mark) {
      const domAttrs = {
        style: 'text-decoration: line-through',
      };

      return ['s', domAttrs, 0];
    },
  },

  clear: {
    toDOM() {
      return clearDOM;
    },
  },
  strong: {
    parseDOM: [
      { tag: 'strong' },
      // This works around a Google Docs misbehavior where
      // pasted content will be inexplicably wrapped in `<b>`
      // tags with a font-weight normal.
      {
        tag: 'b',
        getAttrs: (node: HTMLElement | string) => {
          if (typeof node === 'string') {
            return null;
          }

          return node.style.fontWeight !== 'normal' && null;
        },
      },
      {
        style: 'font-weight',
        getAttrs: (value: HTMLElement | string) => {
          if (typeof value !== 'string') {
            return null;
          }

          return /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null;
        },
      },
    ],
    toDOM() {
      return strongDOM;
    },
  },

  code: {
    parseDOM: [{ tag: 'code' }],
    toDOM() {
      return codeDOM;
    },
  },

  ychange: {
    attrs: {
      user: { default: null },
      state: { default: null },
    },
    inclusive: false,
    parseDOM: [{ tag: 'ychange' }],
    toDOM(node: Mark) {
      return ['ychange', { ychange_user: node.attrs.user, ychange_state: node.attrs.state }, 0];
    },
  },

  highlight: {
    toDOM() {
      return ['span', { class: `highlighted` }, 0];
    },
    parseDOM: [
      {
        tag: 'span.highlighted',
        getAttrs() {
          return {};
        },
      },
    ],
  },
};

export const schema = new Schema({
  nodes,
  marks,
});
