<template>
  <AuthPageWrapper data-testid="auth-forgot-passwd">
    <template #title> Forgot password </template>

    <div class="mb-5 text-imperium-fg-muted text-lg">We will send you a link via email to reset your password.</div>

    <form
      class="flex flex-col grow"
      @submit="onSubmit"
    >
      <FormInput
        v-model="email"
        class="mb-4"
        name="email"
        type="email"
        :is-errored="isEmailErrored"
        :attrs="emailAttrs"
      >
        <template #label> Email address </template>

        <template #prefix>
          <EmailIcon class="absolute z-10 top-2/4 -mt-3 text-imperium-fg-muted" />
        </template>

        <template #error>
          {{ errors.email }}
        </template>
      </FormInput>

      <div class="mt-3 mb-8 flex justify-between items-center">
        <Button
          :visual-type="BUTTON_TYPE.PLAIN"
          data-testid="button-remember"
          :is-link="true"
          to="/auth/login"
        >
          I remember my password
        </Button>
      </div>

      <div class="mt-auto">
        <Button
          type="submit"
          data-testid="button-reset"
          :is-loading="isLoading"
          is-full-width
        >
          Send reset link
        </Button>
      </div>
    </form>
  </AuthPageWrapper>
</template>

<script setup lang="ts">
import zod from 'zod';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { toTypedSchema } from '@vee-validate/zod';

import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import EmailIcon from '@/assets/icons/letter.svg?component';
import { useSimpleAction } from '@/composables';
import type { GenericError } from '@/types/exceptions';
import { ERROR_CODE_TO_MESSAGE } from '../constants';

import AuthPageWrapper from '../components/AuthPageWrapper.vue';
import { useFormData } from '@/composables/useFormData';
import { AuthService } from '../service';
import { type AuthResetPasswordPayload, AUTH_ERROR_CODES } from '../types';
import { BUTTON_TYPE } from '@/types';
import { useErrorHandling } from '@/composables/useErrorHandling';

const router = useRouter();

const { defineField, errors, meta, handleSubmit, setFieldError } = useFormData({
  data: {
    email: '',
  },
  validator: toTypedSchema(
    zod.object({
      email: zod.string().email('Enter a valid email address'),
    }),
  ),
});

const [email, emailAttrs] = defineField('email');

const isEmailErrored = computed(() => meta.value.touched && !!errors.value.email);

const { handleError } = useErrorHandling({
  list: AUTH_ERROR_CODES,
  messages: ERROR_CODE_TO_MESSAGE,
  callback: (message) => setFieldError('email', message),
});

const onSubmit = handleSubmit((values: AuthResetPasswordPayload) => action(values));
const { isLoading, action } = useSimpleAction(async (payload: AuthResetPasswordPayload) => {
  try {
    await AuthService.resetPassword(payload);

    router.replace({ name: 'PasswordResetted' });
  } catch (e: any) {
    handleError(e as GenericError);
  }
});
</script>
