import { ref } from 'vue';
import { defineStore } from 'pinia';
import { type FiltersState, type FiltersValuesState, type FilterValue } from '@/features/Users/types';
import { UserRoles, UserStatuses, UserLanguages } from '@/types';

import { type ComposerTranslation, useI18n } from 'vue-i18n';
import { usePagination } from '@/features/Users/stores/pagination.store';

const getFilterValues = <T extends UserStatuses | UserRoles | UserLanguages>({
  filterList,
  filterName,
  t,
}: {
  filterList: T[];
  filterName: string;
  t: ComposerTranslation;
}): FilterValue<T>[] => {
  if (filterList.filters) {
    return filterList.filters.map((value: T) => ({
      value: value,
      label: t(`user.${filterName}.${value}`),
    }));
  }

  return [];
};

export type State = FiltersState;

export const useUserFilters = defineStore('usersPageFilters', () => {
  const { t } = useI18n();
  const paginationStore = usePagination();

  const state = ref<Partial<State>>({
    filters: {},
    sort: {},
    searchQuery: '',
  });

  const filtersValuesState = ref<FiltersValuesState>({
    roles: getFilterValues<UserRoles>({
      filterName: 'roles',
      filterList: Object.values(UserRoles),
    }),
    statuses: getFilterValues<UserStatuses>({
      filterName: 'statuses',
      filterList: Object.values(UserStatuses),
    }),
    languages: getFilterValues<UserLanguages>({
      filterName: 'languages',
      filterList: Object.values(UserLanguages),
    }),
  });

  const removeAllFiltersFromChips = () => {
    state.value = {
      ...state.value,
      filters: {},
    } as FiltersState;
  };

  const getActiveFiltersForChips = (): { value: string; label: string }[] => {
    const getFilterValueWithFilterName = ({
      filterList,
      filterName,
    }: {
      filterList: string[];
      filterName: string;
    }): FilterValue<string>[] => {
      return filterList.map((value: string) => ({
        value: `${filterName}.${value}`,
        label: t(`user.${filterName}.${value}`),
      }));
    };

    return [
      ...getFilterValueWithFilterName({
        filterName: 'languages',
        filterList: state.value?.filters?.languages || [],
      }),
      ...getFilterValueWithFilterName({
        filterName: 'roles',
        filterList: state.value?.filters?.roles || [],
      }),
      ...getFilterValueWithFilterName({
        filterName: 'statuses',
        filterList: state.value?.filters?.statuses || [],
      }),
    ];
  };

  const setFilters = (filters) => {
    state.value = { ...state.value, filters: { ...state.value.filters, ...filters } } as FiltersState;
  };

  const setSorts = (sort) => {
    state.value = { ...state.value, sort: { ...state.value.sort, ...sort } } as FiltersState;
  };

  const setSearchQuery = (searchQuery) => {
    state.value = { ...state.value, searchQuery };
    paginationStore.setState({ page: 1 });
  };

  return {
    state,
    filtersValuesState,
    setFilters,
    setSorts,
    setSearchQuery,
    getActiveFiltersForChips,
    removeAllFiltersFromChips,
  };
});
