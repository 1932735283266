<template>
  <article
    class="bg-imperium-bg-sub-base shadow-imperium-base rounded-2xl overflow-x-hidden sm:overflow-x-none w-full sm:w-auto"
    data-testid="dashboard-table-scheduled"
  >
    <header class="flex items-center gap-2 px-2 sm:px-4 py-4">
      <StatsIcon :theme="THEMES.WARNING">
        <ClockArrowIcon class="w-5 h-5" />
      </StatsIcon>

      <h3 class="text-gray-900 font-semibold text-md sm:text-xl leading-none">{{ t('dashboard.tables.scheduled') }}</h3>
      <Button
        v-if="props.values?.total"
        :size="SIZES.SMALL"
        :visual-type="BUTTON_TYPE.GHOST"
        class="ml-auto whitespace-nowrap"
        data-testid="dashboard-table-scheduled-button"
        is-link
        to="/articles"
      >
        {{ t('common.view-all', { count: props.values?.total || 0 }) }}
      </Button>
    </header>

    <div
      v-if="props.values?.total"
      class="relative overflow-x-auto"
    >
      <Table
        class="min-w-[580px] sm:min-w-0"
        is-rounded
      >
        <TableHead>
          <TableHeadRow
            :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
            class="min-h-[48px] h-[48px] uppercase"
          >
            <TableHeadCell
              :size="SIZES.LARGE"
              is-uppercase
            >
              {{ t('dashboard.tables.columns.scheduled') }}
            </TableHeadCell>
            <TableHeadCell
              is-full-width
              is-uppercase
              :size="SIZES.LARGE"
            >
              {{ t('dashboard.tables.columns.title') }}
            </TableHeadCell>
            <TableHeadCell
              is-uppercase
              :size="SIZES.LARGE"
            >
              {{ t('dashboard.tables.columns.author') }}
            </TableHeadCell>
            <TableHeadCell
              is-uppercase
              :size="SIZES.LARGE"
            >
              {{ t('dashboard.tables.columns.category') }}
            </TableHeadCell>
            <TableHeadCell
              :size="SIZES.MEDIUM"
              is-uppercase
              :align="TABLE_ALIGN.RIGHT"
            >
              {{ t('dashboard.tables.columns.words') }}
            </TableHeadCell>
          </TableHeadRow>
        </TableHead>

        <TableBody>
          <TableRow
            v-for="(row, index) in props.values?.items"
            :key="`table-06-${index}`"
            :bordered="TABLE_BORDERED.BOTTOM"
            :striped="TABLE_STRIPED.STRIPED"
            class="cursor-pointer"
            @click="emits('redirect', row.id, $event)"
          >
            <TableCell :visual-type="TABLE_VISUAL_TYPE.SUBTLE">
              <template v-if="row.publishedAt">
                <span class="text-imperium-fg-strong">
                  {{ getFormattedDate(row.publishedAt) }}
                </span>
                <br />
                <span class="text-imperium-fg-muted">
                  {{ getFormattedTime(row.publishedAt) }}
                </span>
              </template>
            </TableCell>
            <TableCell>
              <div
                v-if="row.title"
                class="overflow-hidden text-ellipsis whitespace-pre-wrap break-words font-semibold text-imperium-fg-strong"
              >
                {{ row.title }}
              </div>
              <div
                v-else
                class="text-imperium-fg-muted"
              >
                No title
              </div>
            </TableCell>
            <TableCell
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              class="font-semibold text-imperium-fg-strong"
            >
              {{ row.author?.name }}
            </TableCell>
            <TableCell
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              class="text-imperium-fg-base"
            >
              {{ row.category?.title }}
            </TableCell>
            <TableCell
              :visual-type="TABLE_VISUAL_TYPE.SUBTLE"
              :align="TABLE_ALIGN.RIGHT"
              class="text-imperium-fg-base"
            >
              {{ row.words }}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
    <div
      v-else
      class="h-22 flex items-center justify-center mb-4 text-imperium-fg-muted"
    >
      <div v-if="props.isLoading">
        <div class="h-10 w-10">
          <LoaderIcon class="w-10 h-10" />
        </div>
      </div>
      <div v-else>No articles right now</div>
    </div>
  </article>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

import ClockArrowIcon from '@/assets/icons/clock-arrow.svg?component';
import Button from '@/components/Button.vue';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableHeadRow, TableRow } from '@/components/Table';
import {
  BUTTON_TYPE,
  SIZES,
  TABLE_ALIGN,
  TABLE_BORDERED,
  TABLE_ON_SURFACE,
  TABLE_STRIPED,
  TABLE_VISUAL_TYPE,
  THEMES,
} from '@/types';

import LoaderIcon from '@/assets/icons/spinner.svg?component';
import StatsIcon from './StatsIcon.vue';
import type { ArticlesList } from '@/features/Articles/types';
import { getFormattedDate, getFormattedTime } from '@/utils';

const props = defineProps<{
  values: ArticlesList;
  isLoading?: boolean;
}>();

const emits = defineEmits<{
  (event: 'redirect', id: number, pointerEvent: Event): void;
}>();

const { t } = useI18n();
</script>
