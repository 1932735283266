<template>
  <div>
    <RolesForm
      v-if="roleIdFromRoute && role"
      :role="role"
      :permissions-disabled="false"
      :permissions-data="permissions?.items || []"
      :is-permissions-loading="isPermissionsLoading"
      :search="permissionsSearchQuery"
      role-exists
      is-edit
      @save="handleSave"
      @onSearchPermissions="handlePermissionsSearch"
    />
    <RolesForm
      v-else
      :role="null"
      :is-edit="false"
      permissions-disabled
      :permissions-data="permissions?.items || []"
      :is-permissions-loading="isPermissionsLoading"
      :search="permissionsSearchQuery"
      @save="handleSave"
      @onSearchPermissions="handlePermissionsSearch"
    />
  </div>
</template>
<script setup lang="ts">
import RolesForm from '@/features/Roles/components/RoleForm/RolesForm.vue';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useFetchPermissions } from '@/features/Permissions/queries';
import { type Role, type RoleData, type RolesFetchResponse } from '@/features/Roles/types';
import { PERMISSIONS_FILTER_NAME } from '@/features/Permissions/constants';
import { debounce } from 'lodash';
import { useCreateRole } from '@/features/Roles/mutations/useCreateRole';
import { useFetchRole } from '@/features/Roles/queries/useFetchRole';
import { useUpdateRole } from '@/features/Roles/mutations/useUpdateRole';
import { ROLE_FIELD_DESCRIPTION, ROLE_FIELD_NAME } from '@/features/Roles/constants';
import { useToast } from '@/composables';

const route = useRoute();
const router = useRouter();

const roleIdFromRoute = computed(() => route.params.id);

/** Role **/
const rolePayload = computed(() => ({
  id: roleIdFromRoute.value,
}));

const { data: role } = useFetchRole(rolePayload, (result: Role) => {
  if (!result) return {};
});

/** Permissions **/
const permissionsSearchQuery = ref('');

const handlePermissionsSearch = debounce((search: string) => {
  permissionsSearchQuery.value = search;
}, 300);

const permissionsPayload = computed(() => ({
  [PERMISSIONS_FILTER_NAME]: permissionsSearchQuery.value,
}));

const { data: permissions, isLoading: isPermissionsLoading = false } = useFetchPermissions(
  permissionsPayload,
  (result: RolesFetchResponse) => {
    if (!result) {
      return [];
    }
  },
);

const toast = useToast();

/** Role Edit/Creation **/
const createRoleMutation = useCreateRole();
const updateRoelMutation = useUpdateRole();

const handleSave = async (data: RoleData) => {
  try {
    if (roleIdFromRoute.value) {
      await updateRoelMutation.mutateAsync({
        data: {
          name: data[ROLE_FIELD_NAME],
          description: data[ROLE_FIELD_DESCRIPTION],
        },
        id: roleIdFromRoute.value,
      });
      toast.success({
        id: 'ROLE_UPDATE',
        message: 'Role was updated successfully.',
      });
    } else {
      const response = await createRoleMutation.mutateAsync(data);
      role.value = { ...role.value, ...data };
      toast.success({
        id: 'ROLE_CREATE',
        message: 'Role was created successfully.',
      });
      router.push({
        name: 'roles-edit',
        params: {
          id: response[0].id,
        },
      });
    }
  } catch (e) {
    toast.error({
      id: 'ERROR_UPDATE',
      message: e.data.errorMessage,
    });
  }
};
</script>
