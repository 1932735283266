import { useMutation } from '@tanstack/vue-query';
import { updateRole } from '@/features/Roles/api';

export function useUpdateRole() {
  const { data, isPending, isSuccess, mutateAsync } = useMutation({
    mutationFn: async (payload) => {
      return updateRole(payload.id, payload.data);
    },
    gcTime: 0,
  });

  return {
    data,
    mutateAsync,
    isPending,
    isSuccess,
  };
}
