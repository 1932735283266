import ItalicIcon from '@/assets/icons/editor/italic.svg?raw';

import { markItem } from '@/features/CollaborativeEditor/utils/blocks';
import type { MarkType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { getProseMirrorMenuTitle, ProseMirrorMenuLabel } from '@/features/CollaborativeEditor/helpers/menuTooltip';

export const buildEm = (mark: MarkType): InstallableBlock => {
  const italicIconNode = new DOMParser().parseFromString(ItalicIcon, 'text/html').body.firstElementChild;

  const item = markItem(mark, {
    title: getProseMirrorMenuTitle(ProseMirrorMenuLabel.I),
    icon: {
      dom: italicIconNode as Node,
    },
    render: () => {
      const buttonContainer = document.createElement('div');
      buttonContainer.id = 'italic-text-button';
      buttonContainer.className = 'ProseMirror-icon';

      if (italicIconNode) {
        buttonContainer.appendChild(italicIconNode.cloneNode(true));
      }

      return buttonContainer;
    },
  });

  return {
    key: 'toggleEm',
    item,
  };
};
