<template>
  <section class="flex flex-col items-start">
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Article Settings</h3>

    <FormCheckbox
      v-if="documentMetadataShowInMarketsView"
      :is-disabled="!documentMetadataShowInMarketsEdit"
      :model-value="props.isShowInMarkets"
      class="mb-2.5"
      name="show-markets"
      :attrs="isShowInMarketsAttrs"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isShowInMarkets', value)"
    >
      <span class="text-sm">Show in Markets</span>
    </FormCheckbox>

    <FormCheckbox
      v-if="documentMetadataHideFromRssView"
      :is-disabled="!documentMetadataHideFromRssEdit"
      :model-value="props.isExcludeFromRss"
      class="mb-2.5"
      name="exclude-from-rss"
      :attrs="isExcludeFromRssAttrs"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isExcludeFromRss', value)"
    >
      <span class="text-sm">Exclude from all RSS</span>
    </FormCheckbox>

    <FormCheckbox
      :model-value="props.isHideFromHot"
      class="mb-2.5"
      name="hide-from-hot"
      :attrs="isHideFromHotAttrs"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isHideFromHot', value)"
    >
      <span class="text-sm">Hide from Hot Stories</span>
    </FormCheckbox>

    <FormCheckbox
      v-if="documentMetadataHideFromMainPageView"
      :is-disabled="!documentMetadataHideFromMainPageEdit"
      :model-value="props.isHideFromMainPage"
      class="mb-2.5"
      name="hide-from-main-page"
      :attrs="isHideFromMainPageAttrs"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isHideFromMainPage', value)"
    >
      <span class="text-sm">Hide from Main Page</span>
    </FormCheckbox>

    <FormCheckbox
      v-if="documentMetadataOriginContentView"
      :is-disabled="!documentMetadataOriginContentEdit"
      :model-value="props.isOriginalContent"
      class="mb-2.5"
      name="original-content"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isOriginalContent', value)"
    >
      <span class="text-sm">Original Content</span>
    </FormCheckbox>

    <FormCheckbox
      v-if="documentMetadataPromoPostView"
      :is-disabled="!documentMetadataPromoPostEdit"
      :model-value="props.isPromo"
      class="mb-2.5"
      name="promo"
      :attrs="isPromoAttrs"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:isPromo', value)"
    >
      <span class="text-sm">Promo Post</span>
    </FormCheckbox>
    <FormCheckbox
      v-if="documentMetadataRepublishArticleView"
      :is-disabled="!documentMetadataRepublishArticleEdit"
      :model-value="props.canBeRepublished"
      class="mb-2.5"
      name="republish"
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:canBeRepublished', value)"
    >
      <span class="text-sm"> Republished Article</span>
    </FormCheckbox>
  </section>
</template>

<script lang="ts" setup>
import FormCheckbox from '@/components/FormCheckbox.vue';

import { SIZES } from '@/types';
import { useUserPermissions } from '@/stores/user.store';
import { useUserConditions } from '@/composables/useUserConditions';

const props = defineProps<{
  writer: number;
  isShowInMarkets: boolean;
  isShowInMarketsAttrs: Record<string, unknown>;
  isExcludeFromRss: boolean;
  isExcludeFromRssAttrs: Record<string, unknown>;
  isHideFromHot: boolean;
  isHideFromHotAttrs: Record<string, unknown>;
  isHideFromMainPage: boolean;
  isHideFromMainPageAttrs: Record<string, unknown>;
  isPromo: boolean;
  isOriginalContent: boolean;
  isPromoAttrs: Record<string, unknown>;
  canBeRepublished: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:isShowInMarkets', value: boolean): void;
  (event: 'update:isExcludeFromRss', value: boolean): void;
  (event: 'update:isHideFromHot', value: boolean): void;
  (event: 'update:isHideFromMainPage', value: boolean): void;
  (event: 'update:isPromo', value: boolean): void;
  (event: 'update:isOriginalContent', value: boolean): void;
  (event: 'update:canBeRepublished', value: boolean): void;
}>();

const {
  documentMetadataHideFromRssView,
  documentMetadataHideFromRssEdit,
  documentMetadataHideFromMainPageView,
  documentMetadataHideFromMainPageEdit,
  documentMetadataShowInMarketsView,
  documentMetadataShowInMarketsEdit,
  documentMetadataPromoPostView,
  documentMetadataPromoPostEdit,
  documentMetadataRepublishArticleView,
  documentMetadataRepublishArticleEdit,
  documentMetadataOriginContentView,
  documentMetadataOriginContentEdit,
} = useUserPermissions();

const { hideFromMainCondition, showInMarketCondition, promoPostCondition, editNoIndexCondition } = useUserConditions();
</script>
