<template>
  <div>
    <div
      ref="dropdownButton"
      :class="props.classes?.button"
      :style="{ minWidth: width }"
      :data-testid="`dropdown-button-${id}`"
      type="button"
    >
      <slot name="button" />
    </div>

    <slot name="error" />

    <div
      ref="dropdownMenu"
      :data-testid="`dropdown-menu-${id}`"
      :style="{ minWidth: width }"
      class="z-[101] hidden bg-imperium-bg-sub-base rounded-lg shadow"
      :class="props.classes?.dropdown"
    >
      <slot name="menu" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { Dropdown, type DropdownTriggerType } from 'flowbite';
import type { Placement } from '@popperjs/core';

const props = withDefaults(
  defineProps<{
    id: string;
    width?: string;
    placement?: Placement;
    triggerType?: DropdownTriggerType;

    classes?: {
      button?: string;
      dropdown?: string;
    };
  }>(),
  {
    triggerType: 'click',
    width: 'initial',
    placement: 'bottom',
  },
);

const emits = defineEmits<{
  (event: 'open'): void;
  (event: 'close'): void;
}>();

const dropdownButton = ref<HTMLElement | null>(null);
const dropdownMenu = ref<HTMLElement | null>(null);

const dropdown = ref<Dropdown | null>(null);

watchEffect(() => {
  const options = {
    placement: props.placement,
    triggerType: props.triggerType,
    offsetSkidding: 0,
    offsetDistance: 4,
    delay: 0,
    onHide: () => emits('close'),
    onShow: () => emits('open'),
  };

  const instanceOptions = {
    id: 'dropdown-menu-' + props.id,
    override: false,
  };

  dropdown.value = new Dropdown(dropdownMenu.value, dropdownButton.value, options, instanceOptions);
});

defineExpose({
  open: () => dropdown.value?.show(),
  close: () => dropdown.value?.hide(),
});
</script>
