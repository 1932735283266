import { ref, watchEffect, type Ref } from 'vue';
import type { Placement } from '@popperjs/core';
import { Dropdown, type DropdownOptions, type DropdownTriggerType } from 'flowbite';

interface UseDropdownParams {
  dropdownElement: Ref<HTMLElement | null>;
  triggerElement: Ref<HTMLElement | null>;
  id: string;
  options?: DropdownOptions;
}

export const useDropdown = ({
  dropdownElement,
  triggerElement,
  id,
  options = {},
}: UseDropdownParams): Ref<Dropdown> => {
  const dropdown = ref<Dropdown>();
  const placement: Placement = 'bottom';
  const triggerType: DropdownTriggerType = 'click';

  const applyingOptions = {
    placement,
    triggerType,
    offsetDistance: 2,
    delay: 300,
    ignoreClickOutsideClass: false,
    ...options,
  };

  const instanceOptions = {
    id,
    override: false,
  };

  watchEffect(() => {
    if (!dropdownElement.value || !triggerElement.value) return;
    if (dropdown.value) return;

    dropdown.value = new Dropdown(dropdownElement.value, triggerElement.value, applyingOptions, instanceOptions);
  });

  return dropdown as Ref<Dropdown>;
};
