<template>
  <div>
    <div v-if="!props.isLoading">
      <div v-if="!values.youtube && state === State.VIEW">
        <Tooltip
          v-if="state === State.VIEW"
          key="cover-youtube-url-info"
          name="cover-youtube-url-info"
          placement="bottom"
          width="240px"
        >
          <template
            v-if="documentMetadataYoutubeCoverView"
            #content
          >
            <Button
              :color="BUTTON_COLOR.DEFAULT"
              :is-on-surface="BUTTON_ON_SURFACE.DEFAULT"
              :is-disabled="!documentMetadataYoutubeCoverEdit"
              :rounded="BUTTON_ROUNDED.BASE"
              :size="SIZES.SMALL"
              :visual-type="BUTTON_TYPE.TERTIARY"
              class="mt-4"
              @click="onStartEdit"
            >
              <template #leftIcon>
                <PlusIcon class="w-4 h-4 text-imperium-fg-base" />
              </template>
              <template #rightIcon>
                <InfoIcon class="w-4 h-4 text-imperium-fg-base" />
              </template>

              Insert video as cover
            </Button>
          </template>
          <template #tooltip>
            If you insert a video as a cover, you should also include an image to display on the home page, as well as
            on other pages and social networks.
          </template>
        </Tooltip>
      </div>
      <div v-else>
        <form
          class="flex mt-4"
          @submit="onSubmit"
        >
          <FormInput
            v-model="youtube"
            :attrs="youtubeAttrs"
            :is-disabled="state === State.VIEW"
            :is-errored="isYoutubeErrored"
            class="w-full"
            name="youtubeUrl"
            placeholder="Paste the YouTube link"
            type="text"
          >
            <template #label>
              <span class="font-semibold">{{ isMobile ? 'Youtube' : 'Add video from youtube' }}</span>
            </template>
            <template #error>
              <div v-if="state !== State.VIEW">
                {{ errors.youtube }}
              </div>
            </template>
          </FormInput>
          <div>
            <Button
              v-if="state === State.EDIT"
              :size="SIZES.MEDIUM"
              class="ml-2 mt-7"
              type="submit"
            >
              Save
            </Button>
          </div>
          <div>
            <Button
              v-if="state === State.EDIT"
              :color="BUTTON_COLOR.PRIMARY"
              :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
              :size="SIZES.MEDIUM"
              :visual-type="BUTTON_TYPE.SECONDARY"
              class="ml-2 mt-7"
              @click="onCancelEdit"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              v-if="state === State.VIEW"
              :size="SIZES.MEDIUM"
              class="ml-2 mt-7"
              @click="onStartEdit"
            >
              Edit
            </Button>
          </div>
          <div>
            <Button
              v-if="state === State.VIEW"
              :color="BUTTON_COLOR.PRIMARY"
              :is-on-surface="BUTTON_ON_SURFACE.PRIMARY"
              :size="SIZES.MEDIUM"
              :visual-type="BUTTON_TYPE.SECONDARY"
              class="ml-2 mt-7"
              @click="onDelete"
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import zod from 'zod';
import { toTypedSchema } from '@vee-validate/zod';

import { useFormData } from '@/composables';

import FormInput from '@/components/FormInput.vue';
import Button from '@/components/Button.vue';
import Tooltip from '@/components/Tooltip.vue';

import { BUTTON_COLOR, BUTTON_ON_SURFACE, BUTTON_ROUNDED, BUTTON_TYPE, SIZES } from '@/types';

import PlusIcon from '@/assets/icons/plus.svg?component';
import InfoIcon from '@/assets/icons/info.svg?component';
import { useUserPermissions } from '@/stores/user.store';

const props = defineProps<{
  modelValue: string;
  isLoading?: boolean;
}>();

const enum State {
  VIEW = 'view',
  EDIT = 'edit',
}

const emits = defineEmits<{
  (event: 'update:modelValue', youtube: string): void;
}>();

const { documentMetadataYoutubeCoverView, documentMetadataYoutubeCoverEdit } = useUserPermissions();

watch(
  () => [props.modelValue],
  () => setValues({ youtube: props.modelValue || '' }),
);

const state = ref<State>(State.VIEW);
const cachedURL = ref<string>('');

const isMobile = inject<boolean>('isMobile');

const { defineField, errors, meta, values, setValues } = useFormData({
  data: {
    youtube: '',
  },
  validator: toTypedSchema(
    zod.object({
      youtube: zod.string().url('Enter a valid url'),
    }),
  ),
});

const [youtube, youtubeAttrs] = defineField('youtube');

const isYoutubeErrored = computed(() => meta.value.touched && !!errors.value.youtube);

const onSubmit = (event: Event) => {
  event.preventDefault();
  if (values.youtube && !isYoutubeErrored.value) {
    emits('update:modelValue', values.youtube);
    state.value = State.VIEW;
  }
};

const onCancelEdit = () => {
  state.value = State.VIEW;
  if (!cachedURL.value) {
    onDelete();
  } else {
    setValues({ youtube: cachedURL.value });
  }

  emits('update:modelValue', cachedURL.value);
};

const onStartEdit = () => {
  cachedURL.value = values.youtube;
  state.value = State.EDIT;
};

const onDelete = () => {
  setValues({ youtube: '' });
  emits('update:modelValue', '');
};
</script>
