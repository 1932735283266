import { useQuery } from '@tanstack/vue-query';
import { fetchLabels } from '@/features/Labels/api';
import type { ComputedRef } from 'vue';
import type { LabelsFetchRequest } from '@/features/Labels/types';
import { LABELS_RESOURCE } from '@/features/Labels/constants';

export const useFetchLabels = (params: ComputedRef<LabelsFetchRequest>) => {
  const { data, isLoading } = useQuery({
    queryKey: [LABELS_RESOURCE, params],

    queryFn: async ({ queryKey }) => {
      const payload: LabelsFetchRequest = queryKey[1] as LabelsFetchRequest;

      const result = await fetchLabels(payload);

      return result;
    },

    gcTime: 0,
    staleTime: 0,
    refetchInterval: false,
    refetchOnMount: 'always',
  });

  return {
    data,
    isLoading,
  };
};
