<template>
  <div class="flex flex-col px-4 pb-2">
    <div v-if="imageLoadingState === ImageLoadingState.CHOOSE">
      <NavTabs
        :tabs="tabs"
        @choose="onChooseUploadView"
      />
      <div class="pt-4">
        <UploadImageFile
          v-if="uploadingViewType === ImageTabState.UPLOAD"
          @upload="(file: File) => uploadImageAction({ file })"
        />
        <UploadImageUrl
          v-else
          @upload="(url: string) => uploadImageAction({ url })"
        />
      </div>
    </div>
    <div v-if="imageLoadingState === ImageLoadingState.LOADING">
      <div class="h-[156px] flex flex-col items-center justify-center cursor-pointer">
        <LoaderIcon class="h-10 w-10" />
      </div>
    </div>
    <div v-if="imageLoadingState === ImageLoadingState.SET_ATTRIBUTES && mediaId">
      <UploadImageSetAttributes
        :media-id="mediaId"
        @close="onClose"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ImageLoadingState, type NavTab, ImageTabState } from '@/features/UploadImage/types';
import NavTabs from '@/components/NavTabs.vue';
import UploadImageUrl from '@/features/UploadImage/components/UploadImageUrl.vue';
import UploadImageSetAttributes from '@/features/UploadImage/components/UploadImageSetAttributes.vue';
import UploadImageFile from '@/features/UploadImage/components/UploadImageFile.vue';

import LoaderIcon from '@/assets/icons/spinner.svg?component';
import { ref } from 'vue';
import { useToast } from '@/composables/useToast';
import { useSimpleAction } from '@/composables';
import { UploadImageService } from '@/features/UploadImage/service/uploadImage';
import { useMediaList } from '@/features/Media/stores/media.store';

const tabs = ref<NavTab[]>([
  {
    id: ImageTabState.UPLOAD,
    title: 'New image',
  },
  {
    id: ImageTabState.LINK,
    title: 'Insert link',
  },
]);

const props = withDefaults(
  defineProps<{
    mediaId: number;
    imageLoadingState: ImageLoadingState;
  }>(),
  {
    imageLoadingState: ImageLoadingState.CHOOSE,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'update-state', state: ImageLoadingState): void;
  (event: 'update-media-id', mediaId: number | null): void;
}>();

const uploadingViewType = ref<string>();
const mediaStore = useMediaList();

const toast = useToast();

const { action: uploadImageAction } = useSimpleAction(async ({ url, file }: { url?: string; file?: File }) => {
  try {
    emits('update-state', ImageLoadingState.LOADING);
    const postData: { url?: string; file?: File } = {};
    if (url) postData.url = url;
    if (file) postData.file = file;

    const result = await UploadImageService.imageUpload(postData);

    emits('update-media-id', result.id);
    emits('update-state', ImageLoadingState.SET_ATTRIBUTES);

    await mediaStore.fetchMediaList();
  } catch (err: any) {
    emits('update-state', ImageLoadingState.CHOOSE);
    toast.errorTemporary({
      id: 'ERROR_MEDIA_URL',
      message: err.data?.errorMessage || 'Something went wrong. Please try later',
    });
    throw err;
  }
});

const onClose = () => {
  emits('close');
  emits('update-state', ImageLoadingState.CHOOSE);
  emits('update-media-id', null);
};

const onChooseUploadView = (id: string) => {
  uploadingViewType.value = id;
};
</script>
