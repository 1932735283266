<template>
  <div
    :class="{
      'flex items-center py-0.5 rounded cursor-pointer': $slots.label,
    }"
    :data-testid="`radio-button-${props.value}`"
    @click="emits('update:modelValue', props.value)"
  >
    <input
      :data-testid="`radio-button-${value}`"
      type="radio"
      :checked="props.value === props.modelValue"
      :value="props.value"
      :class="$style.radio"
      :name="`radio-button-${props.name ?? props.value}`"
      class="w-4 h-4 text-imperium-ds-primary-base bg-gray-100 border-gray-300 focus:ring-imperium-ds-primary-base"
      @change="emits('update:modelValue', props.value)"
    />
    <label
      v-if="$slots.label"
      :for="`radio-button-${props.value}`"
      class="w-full ms-2 text-sm font-medium text-gray-900 rounded"
    >
      <slot name="label" />
    </label>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  value: string | number;
  modelValue: string | number;
  name?: string;
}>();

const emits = defineEmits<{
  (event: 'update:modelValue', value: string | number): void;
}>();
</script>

<style lang="scss" module>
.radio {
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
  }
}
</style>
