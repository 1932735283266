import axios, { AxiosError, type CancelTokenSource } from 'axios';
import { apiGatewayUrl } from '@/config';
import { HttpException } from '@/exceptions/HttpException';
import { setAuthenticated } from '@/services/auth';

interface Violation {
  propertyPath: string;
  title: string;
}

export interface RequestErrors {
  detail: string;
  title: string;
  violations: Violation[];
}

export const enum HEADERS {
  CONTENT_TYPE = 'content-type',
  USER_LOCALE = 'X-User-Locale',
}

export const http = axios.create({
  baseURL: apiGatewayUrl,
  timeout: 15000,
});

const CancelToken = axios.CancelToken;
const cancelMap = new Map<string, CancelTokenSource>();

http.interceptors.request.use((config) => {
  if (config.cancelToken) {
    return config;
  }

  const uniqueId = `${config.url}-${JSON.stringify(config.params || {})}-${JSON.stringify(config.data || {})}`;
  const source = cancelMap.get(uniqueId);

  if (source) {
    source.cancel(`Cancelled by middleware due to consecutive call on "${config.url}"`);
  }

  if (uniqueId) {
    const tokenSource = CancelToken.source();
    cancelMap.set(uniqueId, tokenSource);
    config.cancelToken = tokenSource.token;
  }

  config.withCredentials = true;

  return config;
});

http.interceptors.response.use(
  (response) => response,
  (res: AxiosError<RequestErrors>) => {
    const { response } = res;

    if (axios.isCancel(res) || !response) {
      return Promise.reject(res);
    }

    const { data, status = 500 } = response;

    // Check for 401 status code
    if (status === 401) {
      setAuthenticated(false);
    }

    if (data?.violations && response.headers[HEADERS.CONTENT_TYPE] === 'application/json') {
      throw new HttpException(data.title, status);
    }

    throw response;
  },
);
