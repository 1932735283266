<template>
  <div>
    <button
      type="button"
      class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
      :aria-controls="`dropdown-${name}`"
      :aria-expanded="isOpen"
      @click="toggleDropdown"
    >
      <component
        :is="props.meta.icon"
        v-if="props.meta?.icon"
        class="w-5 h-5"
      />
      <span class="flex-1 ms-2 whitespace-nowrap">
        {{ props.label }}
      </span>
      <ArrowDown class="w-4 h-4" />
    </button>
    <ul
      v-if="children"
      :id="`dropdown-${name}`"
      role="list"
      :class="{
        hidden: !isOpen,
      }"
    >
      <li
        v-for="{ to, meta: childrenMeta, name: childrenName } in children"
        :key="`children-menu-item-${childrenName}`"
      >
        <SidebarLink
          :to="to"
          :meta="childrenMeta"
          :name="childrenName"
          :label="t(`sidebar.${childrenName}`)"
          class="pl-7"
        />
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, type Component } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ArrowDown } from '@/features/Sidebar/config/icons';
import SidebarLink from '@/features/Sidebar/ui/SidebarLink.vue';

interface Meta {
  icon?: Component;
}

interface Child {
  to: string;
  meta: Meta;
  name: string;
}

interface Props {
  label: string;
  name: string;
  meta: Meta;
  children: Child[];
}

const props = defineProps<Props>();

const route = useRoute();
const { t } = useI18n();

const isOpen = ref<boolean>(route.matched.some(({ name }) => props.name === name));

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const dropdownStateInit = () => {
  if (!route.matched.some(({ name }) => props.name === name)) return;

  isOpen.value = true;
};

onMounted(() => {
  dropdownStateInit();
});
</script>
