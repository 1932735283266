<template>
  <Tooltip
    width="220px"
    :name="'tooltip-status-' + current?.label + props.name"
    placement="bottom"
  >
    <template #content>
      <BadgeWeak
        :id="`${name}-${status}`"
        :label="current?.label"
        data-testid="user-status"
        is-rounded
        v-bind="current?.props"
        :data-tooltip-target="'tooltip-status-' + current?.label"
      >
        <template #icon>
          <Component
            :is="current.icon"
            v-if="current?.icon"
            class="w-5 h-5"
          />
        </template>
      </BadgeWeak>
    </template>
    <template #tooltip>
      <div class="text-sm font-semibold text-center">
        {{ current?.tooltip }}
      </div>
    </template>
  </Tooltip>
</template>

<script setup lang="ts">
import { computed, type ComputedRef } from 'vue';
import CheckMark from '@/assets/icons/check-mark.svg?component';
import Letter from '@/assets/icons/letter.svg?component';
import Warning from '@/assets/icons/exclamation-circle.svg?component';
import Close from '@/assets/icons/close.svg?component';
import { type UserStatus } from '@/features/Users/types';
import { BADGE_THEMES, SIZES, THEMES, UserStatuses } from '@/types';
import Tooltip from '@/components/Tooltip.vue';
import BadgeWeak from '@/components/BadgeWeak.vue';

const usersStatuses: Record<UserStatuses, UserStatus> = {
  active: {
    icon: CheckMark,
    label: 'Active',
    props: {
      theme: BADGE_THEMES.GREEN,
      size: SIZES.SMALL,
    },
    tooltip: 'The user accepted the invitation during 7 days.',
  },
  expired: {
    icon: Warning,
    props: {
      theme: BADGE_THEMES.RED,
      size: SIZES.SMALL,
    },
    label: 'Expired',
    tooltip: "The user didn't accept the invitation. Try inviting the user again or deactivate the invalid account.",
  },
  invited: {
    icon: Letter,
    props: {
      theme: BADGE_THEMES.PRIMARY,
      size: SIZES.SMALL,
    },
    label: 'Invited',
    tooltip: 'The invitation was sent to the user. Expecting their acceptance.',
  },
  deactivated: {
    icon: Close,
    props: {
      theme: BADGE_THEMES.DEFAULT,
      size: SIZES.SMALL,
    },
    label: 'Deactivated',
    tooltip: 'The user account was deactivated, but you can restore it. Click "More" to restore the user.',
  },
};

const props = withDefaults(
  defineProps<{
    name?: string;
    status?: UserStatuses;
  }>(),
  {
    name: '',
    status: UserStatuses.ACTIVE,
  },
);

const current: ComputedRef<UserStatus> = computed<UserStatus>(() => {
  return usersStatuses[props.status];
});
</script>
