import { CookieStoreService } from '@/features/Auth/service';

const AUTH_COOKIE_NAME = 'isAuth';
const TIME_30_DAYS_MS = 1000 * 60 * 60 * 24 * 30;

export const isAuthenticated = (): boolean => {
  return CookieStoreService.get(AUTH_COOKIE_NAME);
};

export const setAuthenticated = (value: boolean = true) => {
  if (value) {
    CookieStoreService.set(AUTH_COOKIE_NAME, true, (new Date().getTime() + TIME_30_DAYS_MS)?.toString());
  } else {
    CookieStoreService.remove(AUTH_COOKIE_NAME);
  }
};
