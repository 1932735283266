<template>
  <div class="flex flex-wrap md:flex-nowrap">
    <FilterButton
      name="role"
      label="User role"
      :attrs="{
        class: 'mb-2 mr-2',
      }"
    >
      <CheckboxListMultiple
        :list="filtersStore.filtersValuesState.roles"
        :value="filtersStore.state?.roles"
        @update="updateFilters({ filterName: 'roles', value: $event })"
      />
    </FilterButton>
    <FilterButton
      name="status"
      label="Status"
      :attrs="{
        class: 'mb-2 mr-2',
      }"
    >
      <CheckboxListMultiple
        :list="filtersStore.filtersValuesState.statuses"
        :value="filtersStore.state?.statuses"
        @update="updateFilters({ filterName: 'statuses', value: $event })"
      />
    </FilterButton>
    <FilterButton
      name="language"
      label="Language access"
      :attrs="{
        class: 'mb-2 mr-2',
      }"
    >
      <div class="space-y-4 flex-col">
        <CheckboxListMultiple
          :list="filtersStore.filtersValuesState.languages"
          :value="filtersStore.state?.languages"
          @update="
            updateFilters >
              {
                filterName: 'languages',
                value: $event,
              }
          "
        />
      </div>
    </FilterButton>
  </div>
</template>
<script setup lang="ts">
import { useUserFilters } from '@/features/Users/stores/filters.store';
import FilterButton from '@/components/Table/Filters/FilterButton.vue';
import CheckboxListMultiple from '@/components/CheckboxListMultiple.vue';

const filtersStore = useUserFilters();

const updateFilters = ({ filterName, value }: { filterName: string; value: string[] }) => {
  filtersStore.setFilters({
    [filterName]: value,
  });
};
</script>
