<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">SEO</h3>

    <FormTextarea
      v-if="documentMetadataSeoKeyWordsView"
      :model-value="keywords"
      name="keywords"
      class="mb-2"
      placeholder="Enter keywords"
      :attrs="keywordsAttrs"
      :is-errored="props.isMetaKeywordsErrored"
      :max="MAX_SEO_KEYWORDS_LENGTH"
      :size="SIZES.SMALL"
      is-required
      :is-disabled="!documentMetadataSeoKeyWordsEdit"
      @update:model-value="(value) => emits('update:keywords', value)"
    >
      <template #label>
        <span class="text-xs">Meta Keywords</span>
      </template>
      <template #error> Enter keywords </template>
    </FormTextarea>

    <FormTextarea
      v-if="documentMetadataSeoDescView"
      :model-value="description"
      name="description"
      class="mb-2"
      placeholder="Write a lead text that will be displayed on search"
      :attrs="descriptionAttrs"
      :max="MAX_SEO_DESCRIPTION_LENGTH"
      :is-disabled="!documentMetadataSeoDescEdit"
      :is-errored="isMetaDescriptionErrored"
      is-required
      :size="SIZES.SMALL"
      @update:model-value="(value) => emits('update:description', value)"
    >
      <template #label>
        <span class="text-xs">Meta Description</span>
      </template>
      <template #error> Fill the description </template>
    </FormTextarea>
  </section>
</template>

<script lang="ts" setup>
import FormInput from '@/components/FormInput.vue';
import FormTextarea from '@/components/FormTextarea.vue';

import { SIZES } from '@/types';
import { MAX_SEO_DESCRIPTION_LENGTH, MAX_SEO_KEYWORDS_LENGTH } from '../constants';
import { useUserPermissions } from '@/stores/user.store';

const props = defineProps<{
  keywords: string;
  keywordsAttrs: Record<string, unknown>;
  description: string;
  descriptionAttrs: Record<string, unknown>;
  isMetaKeywordsErrored: boolean;
  isMetaDescriptionErrored: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:keywords', value: string): void;
  (event: 'update:description', value: string): void;
}>();

const {
  documentMetadataSeoKeyWordsView,
  documentMetadataSeoKeyWordsEdit,
  documentMetadataSeoDescView,
  documentMetadataSeoDescEdit,
} = useUserPermissions();
</script>
