<template>
  <FormInput
    :model-value="props.value"
    :attrs="{
      class: ['w-full'],
    }"
    name="search"
    class="w-full"
    type="text"
    :sizes="SIZES.MEDIUM"
    :placeholder="props.placeholder"
    @update:model-value="debouncedInput"
  >
    <template #prefix>
      <Search />
    </template>
  </FormInput>
</template>
<script lang="ts" setup>
import Search from '@/assets/icons/search.svg?component';
import FormInput from '@/components/FormInput.vue';
import { SIZES } from '@/types';

const props = withDefaults(
  defineProps<{
    placeholder: string;
    value: string;
  }>(),
  {
    placeholder: 'Search',
    value: '',
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string): void;
}>();

let timeoutId: number | ReturnType<typeof setTimeout> | undefined;

const debouncedInput = (value: string) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(() => {
    emits('update:modelValue', value);
  }, 1000) as unknown as number;
};
</script>
