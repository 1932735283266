<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    data-testid="edit-image-data-modal"
    @close="emits('close')"
  >
    <template #title> Image options </template>
    <div class="flex flex-col">
      <form
        class="flex flex-col mt-4"
        @submit="onSubmit"
      >
        <FormTextArea
          v-model="alt"
          :attrs="altAttrs"
          :is-errored="isAltErrored"
          class="w-full mb-4"
          name="imageAlt"
          placeholder="Enter alt text"
        >
          <template #label>
            <span class="font-semibold">Alt text</span>
          </template>
          <template #help>
            Alt text provides a brief description of an image, crucial for accessibility and SEO purposes.
          </template>
          <template #error>
            {{ errors.alt }}
          </template>
        </FormTextArea>
        <FormInput
          v-model="title"
          :attrs="titleAttrs"
          :is-errored="isTitleErrored"
          class="w-full mb-4"
          name="imageTitle"
          placeholder="Enter title text"
          type="text"
        >
          <template #label>
            <span class="font-semibold">Image title</span>
          </template>
          <template #help>
            The title tag provides additional information about the image when a user hovers over it. This can improve
            user experience, which indirectly affects SEO.
          </template>
          <template #error>
            {{ errors.title }}
          </template>
        </FormInput>
        <div class="flex flex-row items-center gap-2">
          <FormInput
            v-model="img_link"
            :is-errored="isLinkErrored"
            :attrs="linkAttrs"
            class="w-full mb-4"
            name="imageLink"
            placeholder="Paste image link"
            type="text"
          >
            <template #label>
              <span class="font-semibold">Link</span>
            </template>
            <template #help> Clicking on the image will navigate to the provided link. </template>
            <template #error>
              {{ errors.img_link }}
            </template>
          </FormInput>
          <div v-if="img_link && !isLinkErrored">
            <Tooltip
              name="tooltip-source-link"
              placement="bottom"
              class="-mt-1.5"
            >
              <template #content>
                <a
                  :href="img_link"
                  target="_blank"
                >
                  <OpenLinkIcon class="w-7 h-7" />
                </a>
              </template>
              <template #tooltip> Redirect to source </template>
            </Tooltip>
          </div>
        </div>
        <FormCheckbox
          v-if="img_link"
          v-model="img_link_new_tab"
          class="mb-4"
          :size="SIZES.SMALL"
          name="link-new-tab"
        >
          <span class="text-sm"> Open link in a new tab</span>
        </FormCheckbox>
        <FormCheckbox
          v-if="img_link"
          v-model="img_link_nofollow"
          class="mb-4"
          :size="SIZES.SMALL"
          name="link-nofollow"
        >
          <div class="text-sm">Nofollow link</div>
          <template #help> This link will be ignored by searching engines </template>
        </FormCheckbox>
        <FormInput
          v-if="img_link"
          v-model="img_link_title"
          :attrs="linkTitleAttrs"
          class="w-full mb-4"
          name="imageLinkTitle"
          placeholder="Paste image link title"
          type="text"
        >
          <template #label>
            <span class="font-semibold">Link title</span>
          </template>
          <template #help>
            The title tag provides additional information about the image when a user hovers over it. This can improve
            user experience, which indirectly affects SEO
          </template>
          <template #error>
            {{ errors.img_link_title }}
          </template>
        </FormInput>

        <Button
          :size="SIZES.MEDIUM"
          class="mt-4"
          is-full-width
          type="submit"
        >
          Save
        </Button>
      </form>
    </div>
  </ModalHolder>
</template>
<script lang="ts" setup>
import { SIZES } from '@/types';
import Button from '@/components/Button.vue';
import FormInput from '@/components/FormInput.vue';
import FormTextArea from '@/components/FormTextarea.vue';
import { useFormData } from '@/composables/useFormData';
import zod from 'zod';
import { computed, watch } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import ModalHolder from '@/components/ModalHolder.vue';
import FormCheckbox from '@/components/FormCheckbox.vue';
import type { EditorImageType } from '@/features/CollaborativeEditor/blocks/imagesDataHelper';
import Tooltip from '@/components/Tooltip.vue';
import OpenLinkIcon from '@/assets/icons/open-link.svg?component';

const MAX_IMAGE_ALT_LENGTH = 500;

const props = withDefaults(
  defineProps<{
    attrs: EditorImageType;
    isVisible?: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'close'): void;
  (event: 'update', value: EditorImageType): void;
}>();

const defaultValues: EditorImageType = {
  alt: '',
  name: '',
  title: '',
  img_link: '',
  img_link_title: '',
  img_link_new_tab: null,
  img_link_nofollow: null,
};

const { defineField, errors, handleSubmit, values, setValues } = useFormData({
  data: defaultValues,
  validator: toTypedSchema(
    zod.object({
      alt: zod.string().max(MAX_IMAGE_ALT_LENGTH, 'Image alt could be less'),
      img_link: zod.union([zod.literal(''), zod.string().trim().url('Enter valid URL.')]),
      img_link_title: zod.string(),
      title: zod.string(),
    }),
  ),
});

watch(
  () => props.attrs,
  (newAttrs) => {
    setValues({
      alt: newAttrs.alt || '',
      name: newAttrs.name || '',
      title: newAttrs.title || '',
      img_link: newAttrs.img_link || '',
      img_link_new_tab: newAttrs.img_link_new_tab || null,
      img_link_title: newAttrs.img_link_title || null,
      img_link_nofollow: newAttrs.img_link_nofollow || null,
    });
  },
  { immediate: true },
);

const [name, nameAttrs] = defineField('name');
const [alt, altAttrs] = defineField('alt');
const [title, titleAttrs] = defineField('title');
const [img_link, linkAttrs] = defineField('img_link');
const [img_link_title, linkTitleAttrs] = defineField('img_link_title');
const [img_link_new_tab] = defineField('img_link_new_tab');
const [img_link_nofollow] = defineField('img_link_nofollow');

const isAltErrored = computed(() => !!errors.value.alt);
const isTitleErrored = computed(() => !!errors.value.title);
const isLinkErrored = computed(() => !!errors.value.img_link);

const onSubmit = (event: Event) => {
  event.preventDefault();

  emits('update', {
    alt: values.alt,
    name: values.name,
    title: values.title,
    img_link: values.img_link,
    img_link_new_tab: values.img_link_new_tab,
    img_link_title: values.img_link_title,
    img_link_nofollow: values.img_link_nofollow,
  });
};
</script>
