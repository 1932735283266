<template>
  <Dropdown
    :id="`filter-${name}`"
    placement="bottom"
    :menu-width="menuWidth"
  >
    <template #button>
      <Button
        type="button"
        v-bind="props.attrs"
        :visual-type="BUTTON_TYPE.GHOST"
      >
        <div class="text-center text-imperium-fg-strong text-base w-max font-semibold">
          {{ label }}
        </div>
        <template #rightIcon>
          <div class="transition-opacity duration-500 ease-in-out">
            <ArrowDown class="w-6 h-6" />
          </div>
        </template>
      </Button>
    </template>
    <template #menu>
      <div
        :data-testid="`filter-menu-${name}`"
        class="py-4 px-3 z-40"
      >
        <slot />
      </div>
    </template>
  </Dropdown>
</template>
<script setup lang="ts">
import Dropdown from '@/components/Dropdown.vue';
import ArrowDown from '@/assets/icons/arrow-down.svg?component';
import Button from '@/components/Button.vue';
import { BUTTON_TYPE } from '@/types';

const props = withDefaults(
  defineProps<{
    name: string;
    label: string;
    menuWidth: string;
    attrs: Record<string, unknown>;
  }>(),
  {
    name: '',
    label: '',
    menuWidth: '220px',
  },
);
</script>
