<template>
  <div
    :data-testid="`art-editor-card-${props.id}`"
    class="max-w-[266px] border border-imperium-border-base bg-imperium-bg-2 rounded-lg"
  >
    <img
      v-if="props.coverArt"
      :src="props.coverArt"
      alt="Article cover art"
      class="max-h-[156px] w-full rounded-t-lg"
    />
    <div class="divide-y divide-imperium-border-weak">
      <router-link
        :to="`/articles/${props.id}`"
        class="p-2 text-imperium-fg-strong font-sm font-semibold line-clamp-5 cursor-pointer"
      >
        {{ props.title || '–' }}
      </router-link>

      <div
        v-if="props.id"
        class="grid grid-cols-12 gap-4 p-2 items-center"
      >
        <div class="col-span-4 text-imperium-fg-muted text-xs uppercase font-semibold">ID</div>
        <div class="col-span-8 justify-self-end text-imperium-fg-strong font-sm font-semibold">
          {{ props.id }}
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 p-2 items-center">
        <div class="col-span-4 text-imperium-fg-muted text-xs uppercase font-semibold">Status</div>
        <div class="col-span-8 justify-self-end">
          <ArticleStatus
            :size="SIZES.XSMALL"
            :status="DocumentStatus[props.status]"
          />
        </div>
      </div>
      <div
        v-if="props.coverDeadline"
        class="grid grid-cols-12 gap-4 p-2 items-center"
      >
        <div
          v-if="!isPublished"
          class="col-span-4 text-imperium-fg-muted text-xs uppercase font-semibold"
        >
          Cover deadline
        </div>
        <div
          v-if="!isPublished"
          class="col-span-8 justify-self-end"
        >
          <div class="flex flex-col items-end text-sm">
            <div>
              {{ getFormattedDate(props.coverDeadline) }}
              <span class="text-imperium-fg-muted">
                {{ getFormattedTime(props.coverDeadline) }}
              </span>
            </div>
            <div class="w-auto">
              <Badge
                v-if="props.isBreakingNews"
                id="breaking"
                :size="SIZES.XSMALL"
                :theme="BADGE_THEMES.RED"
                label="Breaking news"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="props.coverSet"
        class="grid grid-cols-12 gap-4 p-2 items-center"
      >
        <div class="col-span-4 text-imperium-fg-muted text-xs uppercase font-semibold">Cover set</div>
        <div class="col-span-8 justify-self-end text-imperium-fg-strong font-sm font-semibold">
          {{ props.coverSet }}
        </div>
      </div>
      <div
        v-if="props.editor"
        class="grid grid-cols-12 gap-4 p-2 items-center"
      >
        <div class="col-span-4 text-imperium-fg-muted text-xs uppercase font-semibold">Editor</div>
        <div class="col-span-8 justify-self-end text-imperium-fg-strong font-sm font-semibold">
          {{ props.editor }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { BADGE_THEMES, DocumentStatus, SIZES } from '@/types';
import Badge from '@/components/Badge.vue';
import ArticleStatus from '@/features/ArticleLayout/components/ArticleStatus.vue';
import { getFormattedDate, getFormattedTime } from '@/utils';
import { useRouter } from 'vue-router';

const props = defineProps<{
  id: string;
  coverArt: string;
  title: string;
  status: DocumentStatus;
  coverDeadline: string;
  coverSet: string;
  isBreakingNews: boolean;
  editor: string;
  isPublished: boolean;
}>();
</script>
