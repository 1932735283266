<template>
  <section>
    <h3 class="font-semibold text-base text-imperium-fg-base mb-2">Social networks</h3>

    <FormTextarea
      v-if="documentMetadataXLeadTextView"
      :model-value="props.twitterPost"
      name="twitterPost"
      class="mb-1"
      placeholder="Write a lead text that will be displayed on X (Twitter)"
      :attrs="props.twitterPostAttrs"
      :size="SIZES.SMALL"
      is-required
      :max="MAX_TWEET_LENGTH"
      :is-errored="isTwitterPostErrored"
      :is-disabled="!documentMetadataXLeadTextEdit"
      @update:model-value="(value) => emits('update:twitterPost', value)"
    >
      <template #label>
        <span class="text-xs">X post (Twitter)</span>
      </template>

      <template #error> Enter a text for twitter post </template>
    </FormTextarea>

    <FormCheckbox
      v-if="documentMetadataExcludeFromXRSSView"
      :model-value="props.twitterExcludeRss"
      class="mb-2"
      name="exclude-twitter"
      :attrs="props.twitterExcludeRssAttrs"
      :size="SIZES.SMALL"
      :is-disabled="!documentMetadataExcludeFromXRSSEdit"
      @update:model-value="(value) => emits('update:twitterExcludeRss', value)"
    >
      <span class="text-sm">Exclude from X (Twitter) RSS</span>
    </FormCheckbox>

    <FormCheckbox
      v-if="documentMetadataExcludeFromTgChannelsView"
      :model-value="props.twitterExcludeChannels"
      name="exclude-channels"
      :attrs="props.twitterExcludeChannelsAttrs"
      :size="SIZES.SMALL"
      :is-disabled="!documentMetadataExcludeFromTgChannelsEdit"
      @update:model-value="(value) => emits('update:twitterExcludeChannels', value)"
    >
      <span class="text-sm">Exclude from Telegram channels</span>
    </FormCheckbox>
  </section>
</template>

<script lang="ts" setup>
import { SIZES } from '@/types';
import FormCheckbox from '@/components/FormCheckbox.vue';
import FormTextarea from '@/components/FormTextarea.vue';

import { MAX_TWEET_LENGTH } from '../constants';
import { useUserPermissions } from '@/stores/user.store';

const props = defineProps<{
  twitterPost: string;
  twitterPostAttrs: Record<string, unknown>;
  twitterExcludeRss: boolean;
  twitterExcludeRssAttrs: Record<string, unknown>;
  twitterExcludeChannels: boolean;
  twitterExcludeChannelsAttrs: Record<string, unknown>;
  isTwitterPostErrored: boolean;
}>();

const emits = defineEmits<{
  (event: 'update:twitterPost', value: string): void;
  (event: 'update:twitterExcludeRss', value: boolean): void;
  (event: 'update:twitterExcludeChannels', value: boolean): void;
}>();

const {
  documentMetadataXLeadTextView,
  documentMetadataXLeadTextEdit,
  documentMetadataExcludeFromXRSSView,
  documentMetadataExcludeFromXRSSEdit,
  documentMetadataExcludeFromTgChannelsView,
  documentMetadataExcludeFromTgChannelsEdit,
} = useUserPermissions();
</script>
