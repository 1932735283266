import { type GenericError } from '@/types/exceptions';

interface ErrorHandlingParameters {
  list: Record<string, string>;
  messages: Record<string, string>;
  callback: (message: string, code?: string) => void;
}

export const useErrorHandling = ({ list, messages, callback }: ErrorHandlingParameters) => {
  return {
    handleError: (error: GenericError) => {
      const errorData = error?.data;

      if (errorData) {
        const errorCode = (error as GenericError).data.errorCode;
        const errorMessage = (error as GenericError).data.errorMessage;

        const enumeratedCode = list[errorCode];

        if (enumeratedCode && messages[enumeratedCode]) {
          callback(messages[enumeratedCode], enumeratedCode);
          return;
        }

        if (errorMessage) {
          callback(errorMessage, errorCode);
          return;
        }
      }

      callback('Unknown error');
    },
  };
};
