<template>
  <div>
    <RolesFormName
      v-model:name="name"
      v-model:description="description"
      :name-attr="nameAttr"
      :name-errored="nameIsErrored"
      :description-errored="descriptionIsErrored"
      :is-edit="props.isEdit"
      :name="name"
      :errors="errors"
      @onSave="handleSubmit"
      @onCancel="handleCancel"
    />
    <RolesFormPermissions
      :is-disabled="props.permissionsDisabled"
      :permissions="mergedPermissions"
      :is-permissions-loading="props.isPermissionsLoading"
      :search="props.search"
      @onSearchPermissions="emits('onSearchPermissions', $event)"
      @onPermissionUpdate="updateRolePermissions"
    />
  </div>
</template>

<script setup lang="ts">
import RolesFormPermissions from '@/features/Roles/components/RoleForm/RolesFormPermissions.vue';
import RolesFormName from '@/features/Roles/components/RoleForm/RolesFormName.vue';
import type { RoleData } from '@/features/Roles/types';
import { useFormRoleEdit } from '@/features/Roles/composable/useFormRoleEdit';
import { ROLE_FIELD_DESCRIPTION, ROLE_FIELD_NAME, ROLE_FIELD_PERMISSIONS } from '@/features/Roles/constants';
import type { Permission } from '@/features/Permissions/types';
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

interface Props {
  role: RoleData | null;
  isEdit: boolean;
  permissionsData: Permission[];
  isPermissionsLoading: boolean;
  search: string;
  permissionsDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isEdit: false,
});

const emits = defineEmits<{
  save: [role: RoleData];
  onSearchPermissions: [value: string];
}>();

const router = useRouter();
const mergedPermissions = ref<Permission[] | null>(null);
const selectedPermissions = ref<Permission[]>([]);

const { name, nameAttr, description, descriptionAttr, errors, validateForm, nameIsErrored, descriptionIsErrored } =
  useFormRoleEdit(props.role);

const handleSubmit = async () => {
  if (await validateForm()) {
    const updatedRole: RoleData = {
      [ROLE_FIELD_NAME]: name.value,
      [ROLE_FIELD_DESCRIPTION]: description.value,
    };
    emits('save', updatedRole);
  }
};

const updateRolePermissions = (permission: Permission): void => {
  const index = selectedPermissions.value.findIndex((obj) => obj.id === permission.id);

  if (index !== -1) {
    selectedPermissions.value[index] = permission;
  } else {
    selectedPermissions.value.push(permission);
  }
};

const handleCancel = async () => {
  router.back();
};

const mergePermissions = (rolePermissions: Permission[], allPermissions: Permission[]): Permission[] => {
  const mappedRolePermissions = rolePermissions.map((rolePermission) => {
    return {
      fromRole: true,
      ...rolePermission,
    };
  });
  let combinedPermissions = selectedPermissions.value.concat(mappedRolePermissions, allPermissions);

  const permissionMap = new Map<number, Permission>();

  combinedPermissions.forEach((current) => {
    if (!permissionMap.has(current.id)) {
      permissionMap.set(current.id, {
        ...current,
        conditions: current?.conditions ? [...current.conditions] : [],
        edit: current.edit ?? false,
        hide: current.hide ?? !current?.fromRole ?? true,
      });
    }
  });

  return Array.from(permissionMap.values()).sort((a, b) => a.id - b.id);
};

watchEffect(() => {
  if (props.permissionsData) {
    mergedPermissions.value = mergePermissions(props?.role?.permissions ?? [], props.permissionsData);
  }
});
</script>
