import type { SearchMultidropdownItem } from '@/components/FormSearchMultiDropdown.vue';
import type { Tag } from '@/features/ArticleLayout/types';

export const combineTags = ({
  fields,
  values,
}: {
  fields: Record<string, any>;
  values: Record<string, any>;
}): number[] => {
  const combinedTags = [
    ...(fields.tags?.map((item: SearchMultidropdownItem) => item.id) || []),
    ...(fields.superTags?.map((item: SearchMultidropdownItem) => item.id) || []),
    ...(values.tags?.map((item: SearchMultidropdownItem) => item.id) || []),
    ...(values.superTags?.map((item: SearchMultidropdownItem) => item.id) || []),
  ];

  return Array.from(new Set(combinedTags));
};

export const getTags = ({ tags, isSuper }: { tags: Tag[]; isSuper: boolean }) => {
  return tags
    .filter((tag) => tag.super === isSuper)
    .map((tag) => ({
      id: tag.id,
      label: tag.title,
    }));
};
