import BlockquoteIcon from '@/assets/icons/editor/blockquote.svg?raw';

import { wrapItem } from '@/features/CollaborativeEditor/plugins/menu';
import type { NodeType } from 'prosemirror-model';
import type { InstallableBlock } from '@/features/CollaborativeEditor/types';
import { lift, wrapIn } from 'prosemirror-commands';
import { disableMenuItem } from '../utils/state';

export const buildBlockquote = (node: NodeType): InstallableBlock => {
  const blockquoteIconNode = new DOMParser().parseFromString(BlockquoteIcon, 'text/html').body.firstElementChild;
  let isBlockquoteApplied = false;

  const item = wrapItem(node, {
    title: 'Insert quote',
    enable(state) {
      return disableMenuItem(state.selection.$head!.path, state.selection?.node?.type?.name);
    },
    active() {
      return isBlockquoteApplied;
    },
    run(state, dispatch) {
      if (isBlockquoteApplied) {
        lift(state, dispatch);
        isBlockquoteApplied = false;
        return;
      }

      return wrapIn(node, {})(state, dispatch);
    },
    icon: {
      dom: blockquoteIconNode as Node,
    },
  });

  return {
    key: 'wrapBlockQuote',
    item,
  };
};
