<template>
  <div
    class="overflow-y-auto w-[282px] min-w-[282px] bg-white rounded-lg pb-2 px-2 min-h-[100px] art-editor-column__wrapper"
  >
    <!-- Column header -->
    <div class="flex items-center space-x-1 py-3 sticky top-0 z-10 bg-white">
      <div class="min-w-6 min-h-6 px-2 bg-imperium-fg-base text-white flex rounded-full items-center justify-center">
        {{ count }}
      </div>
      <div class="text-imperium-fg-base font-semibold">
        {{ title }}
      </div>
    </div>
    <!-- Items list -->
    <div class="bg-white space-y-2">
      <div
        v-for="article in values"
        :key="`${article.id}_${article.title}`"
      >
        <ArtEditorDashboardCard
          :id="article.id"
          :cover-art="article.coverArt?.url || ''"
          :cover-deadline="article.coverImageDeadLineAt"
          :cover-set="getUserName(article.coverSetBy)"
          :editor="article.editor.name"
          :is-breaking-news="article.isBreaking"
          :is-published="article.status === DocumentStatus.PUBLISHED"
          :status="article.status"
          :title="article.title"
        />
      </div>
    </div>

    <div
      v-if="props.isLoading"
      class="w-full min-h-[60px] flex items-center justify-center"
    >
      <LoaderIcon class="h-10 w-10" />
    </div>

    <div
      v-if="!values.length && !props.isLoading"
      class="flex items-center justify-center min-h-[48px] font-semibold text-imperium-fg-strong"
    >
      No articles
    </div>
    <!-- Column footer -->
    <div />
    <Button
      v-if="isLoadMoreVisible"
      :visual-type="BUTTON_TYPE.TERTIARY"
      is-full-width
      class="mt-2"
      @click="emits('loadMore')"
    >
      Load more
    </Button>
  </div>
</template>
<script lang="ts" setup>
import ArtEditorDashboardCard from '@/features/Dashboard/components/ArtEditorDashboardCard.vue';
import { BUTTON_TYPE, DocumentStatus } from '@/types';
import Button from '@/components/Button.vue';
import { computed, ref } from 'vue';
import { useUsers } from '@/features/Users/composables';
import type { SelectItem } from '@/components/FormSelect.vue';
import LoaderIcon from '@/assets/icons/spinner.svg?component';

const props = withDefaults(
  defineProps<{
    values: any[];
    totalCount: number;
    title: string;
    isLoading?: boolean;
  }>(),
  {
    isLoading: false,
  },
);

const emits = defineEmits<{
  (event: 'loadMore'): void;
}>();

const { users } = useUsers();

const isLoadMoreVisible = computed(() => {
  return props.values.length < props.totalCount && !props.isLoading;
});

const count = computed(() => {
  return props.totalCount;
});
const title = computed(() => {
  return props.title || 'Article title';
});

const getUserName = (id: number) => {
  return users.value?.find((item: SelectItem) => item.id === id)?.label || null;
};
</script>
<style lang="scss" scoped>
.art-editor-column {
  &__wrapper {
    max-height: calc(100vh - 57px);

    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 8px;
      height: 170px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      height: 170px;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-imperium-border-base;
      border-radius: 10px;
      height: 170px;
    }

    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      border-radius: 10px;
      height: 170px;
    }
  }
}
</style>
