<template>
  <FormDatepicker
    :name="props.name"
    :model-value="props.modelValue"
    :placeholder="props.placeholder"
    :is-default-date="props.isDefaultDate"
    :is-disabled="props.isDisabled"
    :is-full-width="props.isFullWidth"
    @update:model-value="(value: string) => emits('update:modelValue', value)"
  />
</template>

<script lang="ts" setup>
import { provide } from 'vue';
// @ts-expect-error No proper types for that
import { Datepicker } from 'vanillajs-datepicker';

import FormDatepicker from './FormDatepicker.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string | null;
    name: string;
    placeholder?: string;
    isDefaultDate?: boolean;
    isFullWidth?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    placeholder: 'Select date',
    isDefaultDate: false,
    isFullWidth: false,
    isDisabled: false,
  },
);

const emits = defineEmits<{
  (event: 'update:modelValue', value: string | null): void;
}>();

// HACK: This library cannot be mocked by vitest, at all. So instead we will provide it to child component,
// and in this way we will have ability to mock `provide` and test it
provide<'Datepicker', typeof Datepicker>('Datepicker', Datepicker);
</script>
