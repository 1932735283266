<template>
  <ModalHolder
    v-if="isVisible"
    data-testid="create-new-article-modal"
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    :type="MODAL_TYPES.POPUP"
    @close="onClose"
  >
    <template #title> {{ modalTitle }} </template>

    <form
      class="py-4"
      @submit="onSubmit"
    >
      <FormInput
        :model-value="permissionSlug"
        placeholder="Enter permission slug"
        is-required
        point-separator
        :is-errored="isSlugErrored"
        @update:model-value="(value) => (permission = value)"
      >
        <template #label> Permission slug </template>
        <template #help> Make this permission easily identifiable. </template>

        <template #error>
          {{ errors.slug }}
        </template>
      </FormInput>

      <div class="flex items-center gap-4 justify-end pt-2 mt-5">
        <Button
          type="submit"
          :size="SIZES.MEDIUM"
          :is-loading="isLoading"
          data-testid="button-cta"
          is-full-width
        >
          {{ buttonTitle }}
        </Button>
      </div>
    </form>
  </ModalHolder>
</template>

<script setup lang="ts">
import { MODAL_TYPES, SIZES } from '@/types';
import ModalHolder from '@/components/ModalHolder.vue';
import FormInput from '@/components/FormInput.vue';
import { computed } from 'vue';
import { useFormData, useSimpleAction, useToast } from '@/composables';
import { toTypedSchema } from '@vee-validate/zod';
import zod from 'zod';
import { CreatePermissionService } from '@/features/Permissions/service/createPermission';
import Button from '@/components/Button.vue';
import CreatePermissionErrorToast from '@/features/Permissions/components/CreatePermissionErrorToast.vue';
import { updatePermission } from '@/features/Permissions/api';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    slug?: string;
    id?: number;
    name?: string;
  }>(),
  {
    isVisible: false,
    slug: '',
  },
);

const emits = defineEmits<{
  (event: 'confirm'): void;
  (event: 'close'): void;
  (event: 'reopen', value: boolean): void;
}>();

const toast = useToast();

const modalTitle = computed(() => (props.id && props.slug ? 'Editing permission' : 'Add a new permission'));
const buttonTitle = computed(() => (props.id && props.slug ? 'Save' : 'Save a new permission'));

const { defineField, errors, values, meta, validate, setErrors, resetForm } = useFormData({
  data: {
    slug: '',
  },
  validator: toTypedSchema(
    zod.object({
      slug: zod.string().min(1),
    }),
  ),
});

const [permission] = defineField('slug');

const permissionSlug = computed<string>(() => props?.slug || permission.value);

const isSlugErrored = computed(() => meta.value.touched && !!errors.value.slug);

const { isLoading, action } = useSimpleAction(async () => {
  try {
    const { valid, errors: validationErrors } = await validate();
    if (!valid) {
      throw validationErrors;
    }
  } catch (errors: any) {
    setErrors(errors as Record<string, string>);
    return;
  }

  try {
    if (props.id && props.slug) {
      await updatePermission({
        id: props.id,
        slug: permission.value,
        name: props.name,
      });
    } else {
      await CreatePermissionService.create({
        slug: permissionSlug.value,
      });
    }

    emits('confirm');
    toast.success({
      id: 'SUCCESS_PERMISSION_CREATION',
      message: `The ${permissionSlug.value} permission was created`,
    });
    resetForm();
  } catch {
    onClose();

    toast.error(
      {
        id: 'ERROR_PERMISSION_CREATION',
        title: 'Permission saving failed.',
        message: 'Try again.',
        onClick: () => {
          onToastClick();
        },
      },
      CreatePermissionErrorToast,
    );
  }
});
const onSubmit = (e: SubmitEvent) => {
  action(values);
  e.preventDefault();
};
const onClose = () => {
  emits('close');
  resetForm();
};

const onToastClick = () => {
  emits('reopen', true);
};
</script>
