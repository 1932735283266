<template>
  <div>
    <CategoriesForm
      v-if="!isLoading"
      :key="`edit-category-${route.params.id}`"
      :data="form"
    />
  </div>
</template>
<script setup lang="ts">
import CategoriesForm from '@/features/Categories/components/CategoriesForm/CategoriesForm.vue';
import { useFetchCategory } from '@/features/Categories/queries/useFetchCategory';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { transformCategoryResponseToForm } from '@/features/Categories/services/form-transformer';

const route = useRoute();

const { data, isLoading } = useFetchCategory({ categoryId: route.params.id }, !!route.params.id);
const form = computed(() => {
  return data.value ? transformCategoryResponseToForm(data.value) : null;
});
</script>
