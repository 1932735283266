import { isRef, watch } from 'vue';
import { createI18n, type I18n, type I18nOptions } from 'vue-i18n';

import en from '~/locales/en.json';

function setupI18n(options: I18nOptions = { locale: 'en' }) {
  const i18n = createI18n(options);
  if (options.locale) {
    setI18nLanguage(i18n, options.locale);

    // Locale translations
    addLocaleMessages(i18n, options.locale, en);
  }
  return i18n;
}

export function setI18nLanguage(i18n: I18n, locale: string) {
  if (isRef(i18n.global.locale)) {
    i18n.global.locale.value = locale;
  }
}

export function addLocaleMessages(i18n: I18n, locale: string, messages: Record<string, any>) {
  i18n.global.mergeLocaleMessage(locale, messages);
}

export const LANGUAGE_STORAGE_KEY = 'lang';
export const i18n = setupI18n({
  legacy: false,
  locale: localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? 'en',
  allowComposition: true,
  fallbackLocale: 'en',
});

export function getLocale(): string {
  // issue with types https://github.com/intlify/vue-i18n-next/issues/1003
  if (isRef(i18n.global.locale)) {
    return i18n.global.locale.value as string;
  }
  return i18n.global.locale;
}

// issue with types https://github.com/intlify/vue-i18n-next/issues/1003
// @ts-expect-error from issue
watch(i18n.global.locale, (locale) => {
  // @ts-expect-error from issue
  localStorage.setItem(LANGUAGE_STORAGE_KEY, locale);
});
