import { useQuery } from '@tanstack/vue-query';
import { fetchDocument } from '../api';
import { type Ref } from 'vue';

import { ARTICLE_DOCUMENT_RESOURCE } from '../constants';

interface FetchDocumentQueryPayload {
  documentId: Ref<number>;
  languageId: Ref<number>;
  enabled: Ref<boolean>;
}

interface FetchDocumentPayload {
  documentId: number;
  languageId: number;
}

export const useFetchDocument = (payload: FetchDocumentQueryPayload) => {
  const { data, isLoading, isSuccess, isPending, isError, error } = useQuery({
    queryKey: [ARTICLE_DOCUMENT_RESOURCE, payload],
    queryFn: ({ queryKey }) => {
      const payload: FetchDocumentPayload = queryKey[1] as FetchDocumentPayload;

      return fetchDocument(payload.documentId, payload.languageId);
    },

    retry: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    staleTime: 0,
    refetchInterval: false,
    gcTime: 0,
    refetchOnMount: 'always',

    enabled: payload.enabled,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    isPending,
    isSuccess,
  };
};
