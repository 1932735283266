import { ref } from 'vue';

export type SimpleAction<T> = (input: T) => Promise<void>;
type ErrorCallback = (e: Error) => void;

export function useSimpleAction<T = void>(action: SimpleAction<T>, callback?: ErrorCallback) {
  const isLoading = ref<boolean>(false);

  async function doAction(input: T) {
    isLoading.value = true;

    try {
      await action(input);
    } catch (e: unknown) {
      if (!callback) {
        throw e;
      }
      callback(e as Error);
    } finally {
      isLoading.value = false;
    }
  }

  return { isLoading, action: doAction };
}
