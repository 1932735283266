<template>
  <div
    v-if="props.filters.length"
    class="flex flex-wrap gap-2"
  >
    <div
      v-for="filter of props.filters"
      :key="filter.value"
    >
      <Tag
        :id="filter.value"
        :label="filter.label"
        @click="emits('remove-item', filter.value)"
      />
    </div>
    <button @click="emits('remove-all')">
      <Tag
        :id="'remove-all'"
        label="Clear all"
      />
    </button>
  </div>
</template>
<script setup lang="ts">
import Tag from '@/components/Tag.vue';

const props = withDefaults(
  defineProps<{
    filters: { value: string; label: string }[];
  }>(),
  {
    filters: () => [],
  },
);

const emits = defineEmits<{
  (event: 'remove-item', value: string): void;
  (event: 'remove-all'): void;
}>();
</script>
