export interface TimepickerItem {
  hours: number;
  minutes: number;
}

export type TimepickerDisplayItem = TimepickerItem & {
  label: string;
};

export const enum DATEPICKER_VIEWS {
  DAYS = 0,
  MONTHS = 1,
  YEARS = 2,
}
