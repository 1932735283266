<template>
  <header
    v-if="isMainLayoutVisible"
    class="fixed top-0 min-h-[57px] z-[100] w-full grid md:grid-flow-col md:auto-cols-[234px_minmax(0,_2fr)] bg-imperium-bg-3 text-xl"
    data-testid="sidebar-holder"
  >
    <div
      class="bg-imperium-bg-4 flex md:justify-between items-center p-2 border-b border-b-imperium-border-weak rounded-se-2xl"
    >
      <div class="flex items-center md:hidden justify-start rtl:justify-end">
        <button
          type="button"
          class="inline-flex items-center ml-2 mr-4 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          :data-testid="isTablet ? (props.isSidebarOpened ? 'sidebar-close-btn' : 'sidebar-open-btn') : undefined"
          @click="emits('sidebar-toggle')"
        >
          <HamburgerIcon
            v-if="!props.isSidebarOpened"
            class="w-6 h-6"
          />
          <CloseIcon
            v-else
            class="w-6 h-6"
          />
        </button>
      </div>

      <RouterLink
        to="/"
        class="flex items-center justify-between space-x-2"
        data-testid="logo"
      >
        <EditorialLogo />

        <span
          v-if="!isMobile"
          class="self-center text-lg text-imperium-fg-strong font-semibold whitespace-nowrap"
        >
          Editorial
        </span>
      </RouterLink>
      <Clocks class="ml-auto md:ml-0" />
      <DashboardTabs
        v-if="isDashboardSwitcherVisible && !isDesktop && !dashboardStore.state.isLoadingDashboardType"
        is-mobile
        class="mr-2"
      ></DashboardTabs>
      <UsersHeaderInfo
        v-if="isArticlePage && !isDesktop"
        class="ml-auto mx-5"
      />
    </div>

    <div
      v-if="isDesktop && !is404page"
      class="flex items-center border-b border-b-imperium-border-weak"
    >
      <Breadcrumbs />
      <UsersHeaderInfo
        v-if="isArticlePage"
        class="mx-5"
      />
      <DashboardTabs
        v-if="isDashboardSwitcherVisible && !dashboardStore.state.isLoadingDashboardType"
        class="mr-2"
      ></DashboardTabs>

      <RegionDropdown
        v-if="isRegionDropdownVisible && isDashboardFull"
        class="ml-auto mr-6 ml-3"
      />
    </div>
  </header>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue';

import EditorialLogo from '@/assets/icons/editorial-logo.svg?component';
import HamburgerIcon from '@/assets/icons/hamburger.svg?component';
import CloseIcon from '@/assets/icons/close.svg?component';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import RegionDropdown from '@/features/RegionDropdown/RegionDropdown.vue';

import { useLayoutAppearance } from '@/composables/useLayoutAppearance';
import { useNotFoundPage } from '@/composables';
import { useRoute } from 'vue-router';
import UsersHeaderInfo from '@/features/Users/components/UserInfo/UsersHeaderInfo.vue';
import { Clocks } from '@/features/Clocks';
import SegmentedControlTabs from '@/components/SegmentedControlTabs.vue';
import DashboardTabs from '@/features/Dashboard/DashboardTabs.vue';
import { useDashboardStore } from '@/features/Dashboard/stores/dashboardStore';

const emits = defineEmits<{
  (event: 'sidebar-toggle'): void;
}>();

const props = withDefaults(
  defineProps<{
    isSidebarOpened?: boolean;
  }>(),
  {
    isSidebarOpened: false,
  },
);

const route = useRoute();
const dashboardStore = useDashboardStore();

const isArticlePage = computed<boolean>(() => route.name === 'article');

const isDashboardFull = inject('isDashboardFull');
const { isRegionDropdownVisible, isMainLayoutVisible, isDashboardSwitcherVisible } = useLayoutAppearance();

const is404page = useNotFoundPage().isNotFoundPage;

const isDesktop = inject('isDesktop');
const isTablet = inject('isTablet');
const isMobile = inject('isMobile');
</script>
