<template>
  <AuthErrorWrapper data-testid="auth-user-blocked">
    <template #icon>
      <LockIcon class="w-[72px] h-[84px]" />
    </template>

    <template #title>
      {{ t('user-blocked.title') }}
    </template>

    {{ t('user-blocked.text') }}

    <template #footer>
      <Button
        to="/auth/login"
        data-testid="button-login"
        class="block"
        is-link
        is-full-width
      >
        {{ t('user-blocked.button') }}
      </Button>
    </template>
  </AuthErrorWrapper>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AuthErrorWrapper from '../components/AuthErrorWrapper.vue';
import LockIcon from '@/assets/icons/lock.svg?component';
import Button from '@/components/Button.vue';

const { t } = useI18n({
  messages: {
    en: {
      'user-blocked': {
        title: 'Account is Temporarily Blocked',
        text: 'For the next 5 minutes your account would be temporarily suspended due to five attempts to sign in with an incorrect password',
        button: 'I understand',
      },
    },
  },
});
</script>
