<template>
  <th
    scope="col"
    :class="
      tableHeadCellClass({
        size: props.size,
        surface: props.isOnSurface,
        padding: props.padding,
        isFullWidth: props.isFullWidth,
        align: props.align,
        verticalAlign: props.verticalAlign,
        sticky: props.isSticky,
        scale: props.scale,
        uppercase: props.isUppercase,
        stickyOffsetSize: props.stickyOffsetSize,
        stickyOffsetScale: props.stickyOffsetScale,
      })
    "
  >
    <slot />
  </th>
</template>

<script setup lang="ts">
import { SIZES, TABLE_ON_SURFACE, TABLE_PADDINGS, TABLE_ALIGN, TABLE_VALIGN, TABLE_SCALE } from '@/types';
import { tv } from 'tailwind-variants';

const props = withDefaults(
  defineProps<{
    padding?: TABLE_PADDINGS;
    isOnSurface?: TABLE_ON_SURFACE;
    size?: SIZES;
    scale?: TABLE_SCALE;
    align?: TABLE_ALIGN;
    verticalAlign?: TABLE_VALIGN;
    isFullWidth?: boolean;
    isSticky?: boolean;
    isUppercase?: boolean;
    stickyOffsetSize?: SIZES | false;
    stickyOffsetScale?: TABLE_SCALE;
  }>(),
  {
    padding: TABLE_PADDINGS.BOTH,
    isOnSurface: TABLE_ON_SURFACE.DEFAULT,
    scale: TABLE_SCALE.NORMAL,
    size: SIZES.MEDIUM,
    isFullWidth: false,
    align: TABLE_ALIGN.LEFT,
    verticalAlign: TABLE_VALIGN.MIDDLE,
    isSticky: false,
    isUppercase: false,
    stickyOffsetSize: false,
    stickyOffsetScale: TABLE_SCALE.NORMAL,
  },
);

const tableHeadCellClass = tv({
  base: 'min-h-8',
  variants: {
    align: {
      [TABLE_ALIGN.LEFT]: 'text-left',
      [TABLE_ALIGN.CENTER]: 'text-center',
      [TABLE_ALIGN.RIGHT]: 'text-right',
    },
    verticalAlign: {
      [TABLE_VALIGN.TOP]: 'table-cell-va-top',
      [TABLE_VALIGN.MIDDLE]: 'table-cell-va-middle',
    },
    surface: {
      [TABLE_ON_SURFACE.PRIMARY]: 'table-head-cell-card-surface',
    },
    padding: {
      [TABLE_PADDINGS.NONE]: 'table-head-cell-padding-none',
      [TABLE_PADDINGS.START]: 'table-head-cell-padding-start',
      [TABLE_PADDINGS.END]: 'table-head-cell-padding-end',
      [TABLE_PADDINGS.BOTH]: 'table-head-cell-padding',
    },
  },
  compoundVariants: [
    {
      isFullWidth: true,
      class: 'w-full',
    },
    {
      uppercase: true,
      class: 'uppercase',
    },
    {
      sticky: true,
      class: 'sticky z-10',
    },

    // Offset scales
    {
      stickyOffsetSize: false,
      stickyOffsetScale: TABLE_SCALE.NORMAL,
      class: 'left-0',
    },
    {
      stickyOffsetSize: SIZES.SMALL,
      stickyOffsetScale: TABLE_SCALE.NORMAL,
      class: 'left-12',
    },
    {
      stickyOffsetSize: SIZES.SMALL,
      stickyOffsetScale: TABLE_SCALE.DOUBLE,
      class: 'left-14',
    },

    {
      size: SIZES.SMALL,
      scale: TABLE_SCALE.NORMAL,
      isFullWidth: false,
      class: 'w-12 min-w-12 max-w-12',
    },
    {
      size: SIZES.SMALL,
      scale: TABLE_SCALE.DOUBLE,
      isFullWidth: false,
      class: 'w-14 min-w-14 max-w-14',
    },
    {
      size: SIZES.SMALL,
      scale: TABLE_SCALE.TRIPLE,
      isFullWidth: false,
      class: 'w-18 min-w-18 max-w-18',
    },
    {
      size: SIZES.SMALL,
      scale: TABLE_SCALE.QUADRUPLE,
      isFullWidth: false,
      class: 'w-22 min-w-22 max-w-22',
    },
    {
      size: SIZES.MEDIUM,
      scale: TABLE_SCALE.NORMAL,
      isFullWidth: false,
      class: 'w-20 min-w-20 max-w-20',
    },
    {
      size: SIZES.MEDIUM,
      scale: TABLE_SCALE.DOUBLE,
      isFullWidth: false,
      class: 'w-24 min-w-24 max-w-24',
    },
    {
      size: SIZES.MEDIUM,
      scale: TABLE_SCALE.TRIPLE,
      isFullWidth: false,
      class: 'w-26 min-w-26 max-w-26',
    },
    {
      size: SIZES.MEDIUM,
      scale: TABLE_SCALE.QUADRUPLE,
      isFullWidth: false,
      class: 'w-28 min-w-28 max-w-28',
    },
    {
      size: SIZES.LARGE,
      scale: TABLE_SCALE.NORMAL,
      isFullWidth: false,
      class: 'w-32 min-w-32 max-w-32',
    },
    {
      size: SIZES.LARGE,
      scale: TABLE_SCALE.DOUBLE,
      isFullWidth: false,
      class: 'w-36 min-w-36 max-w-36',
    },
    {
      size: SIZES.LARGE,
      scale: TABLE_SCALE.TRIPLE,
      isFullWidth: false,
      class: 'w-40 min-w-40 max-w-40',
    },
    {
      size: SIZES.LARGE,
      scale: TABLE_SCALE.QUADRUPLE,
      isFullWidth: false,
      class: 'w-48 min-w-48 max-w-48',
    },
    {
      size: SIZES.XLARGE,
      scale: TABLE_SCALE.NORMAL,
      isFullWidth: false,
      class: 'w-50 min-w-50 max-w-50',
    },
    {
      size: SIZES.XLARGE,
      scale: TABLE_SCALE.DOUBLE,
      isFullWidth: false,
      class: 'w-64 min-w-64 max-w-64',
    },
    {
      size: SIZES.XLARGE,
      scale: TABLE_SCALE.TRIPLE,
      isFullWidth: false,
      class: 'w-72 min-w-72 max-w-72',
    },
    {
      size: SIZES.XLARGE,
      scale: TABLE_SCALE.QUADRUPLE,
      isFullWidth: false,
      class: 'w-90 min-w-90 max-w-90',
    },
  ],
});
</script>
