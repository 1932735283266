import { sseGatewayUrl } from '@/config';
import { onMounted, onUnmounted, type Ref, ref } from 'vue';
import { getFullUrl } from '@/utils/url';
import { fetchMercureToken } from '@/features/ArticleLayout/api';
import { useQueryClient } from '@tanstack/vue-query';
import { ARTICLES_RESOURCE } from '@/features/Articles/constants';
import * as Sentry from '@sentry/browser';

type ArticlesUpdatesArgs = {
  articleIds?: Ref<Set<number>>;
};

const SSE_EVENT_TYPE = 'message';
const ARTICLE_EVENT_UPDATE = 'updated';
const ARTICLE_EVENT_CREATE = 'created';
const ALLOWED_EVENT_TYPES = [ARTICLE_EVENT_UPDATE, ARTICLE_EVENT_CREATE] as const;

type AllowedEventType = (typeof ALLOWED_EVENT_TYPES)[number];

type ArticleUpdateMessage = {
  documentId: number;
  type: AllowedEventType;
};

export const getIds = (...items: { id: number }[]): Set<number> => {
  return new Set(items.map((article) => article.id));
};

const getArticlesUpdateUrl = (languageId: number): string => {
  const url = new URL(getFullUrl(sseGatewayUrl));
  url.searchParams.append('topic', `/v1/document-updates/language/${languageId}`);
  return url.toString();
};

export const useArticlesUpdatesListener = ({ articleIds }: ArticlesUpdatesArgs) => {
  const eventSourceInstance = ref<EventSource | null>(null);
  const queryClient = useQueryClient();
  const allowedEventTypesSet = new Set(ALLOWED_EVENT_TYPES);

  const onMessage = async (event: MessageEvent) => {
    try {
      if (event.type !== SSE_EVENT_TYPE) return;
      const message = JSON.parse(event.data) as ArticleUpdateMessage;

      if (!allowedEventTypesSet.has(message.type)) return;

      if (message.type === ARTICLE_EVENT_UPDATE) {
        if (articleIds?.value && !articleIds.value.has(message.documentId)) return;
        await queryClient.invalidateQueries({ queryKey: [ARTICLES_RESOURCE] });
        return;
      }

      if (message.type === ARTICLE_EVENT_CREATE) {
        await queryClient.invalidateQueries({ queryKey: [ARTICLES_RESOURCE] });
        return;
      }
    } catch (e: unknown) {
      Sentry.captureMessage(String(e));
    }
  };

  const onError = (errorEvent: Event) => {
    // const target = errorEvent.target as EventSource;
    const errorMessage = `${errorEvent}`;
    Sentry.captureMessage(errorMessage);
  };

  const initEventSourceConnection = async () => {
    try {
      const { token } = await fetchMercureToken();

      if (!token) {
        Sentry.captureMessage('Failed to fetch Mercure token');
        return;
      }

      eventSourceInstance.value = new EventSourcePolyfill(getArticlesUpdateUrl(1), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as EventSource;

      eventSourceInstance.value.onmessage = onMessage;
      eventSourceInstance.value.onerror = onError;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  onMounted(initEventSourceConnection);
  onUnmounted(() => {
    if (eventSourceInstance.value) {
      eventSourceInstance.value.close();
      eventSourceInstance.value = null;
    }
  });

  return {
    eventSourceInstance,
  };
};
