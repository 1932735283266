import zod from 'zod';

import {
  MAX_ASSIGNMENT_LENGTH,
  MAX_DEK_LENGTH,
  MAX_SEO_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MAX_TWEET_LENGTH,
} from './constants';
import { DocumentStatus } from '@/types';
import { useDocumentStatus } from './composables';

const tagSchema = zod.object({
  id: zod.number(),
  label: zod.string(),
});

const TEXT_RULES_SET = {
  authorId: zod.number().min(1, 'Select public author'),
  title: zod
    .string({ required_error: 'Enter a title for the article', invalid_type_error: 'Enter a title for the article' })
    .min(1, 'Enter a title for the article'),
  dek: zod
    .string({
      required_error: 'Enter a lead text (Dek) for the article',
      invalid_type_error: 'Enter a lead text (Dek) for the article',
    })
    .max(255, 'Too long Dek')
    .min(1, 'Enter a lead text (Dek) for the article'),

  twitterPost: zod.string(),
  twitterExcludeRss: zod.boolean(),

  categoryId: zod.number().min(1, 'Select a category'),
  badgeId: zod.number().min(1, 'Select a label to display on the cover'),
  tags: zod.array(tagSchema).nonempty('Select tag'),
  superTags: zod.array(tagSchema).nonempty('Select super tag'),

  seoMetaKeyWords: zod.string().min(1),
  seoMetaDescription: zod.string().min(1).max(MAX_SEO_DESCRIPTION_LENGTH),
};

const FULL_RULES_SET = {
  ...TEXT_RULES_SET,
  coverArt: zod.number().min(1, 'Add cover art'),
};

const DOCUMENT_STATUS_TO_RULE_SET = {
  // Current Status of the document
  [DocumentStatus.READY_FOR_ART_EDITOR]: {
    // Previous status of the document
    [DocumentStatus.IN_PROGRESS]: FULL_RULES_SET,
  },

  [DocumentStatus.READY_TO_REVIEW]: {
    [DocumentStatus.READY_FOR_ART_EDITOR]: FULL_RULES_SET,
  },

  [DocumentStatus.READY_TO_PUBLISH]: {
    [DocumentStatus.READY_TO_REVIEW]: FULL_RULES_SET,
  },

  [DocumentStatus.PUBLISHED]: {
    [DocumentStatus.READY_TO_PUBLISH]: FULL_RULES_SET,
    [DocumentStatus.UNPUBLISHED]: FULL_RULES_SET,
  },
};

export const typedSchema = zod
  .object({
    authorId: zod.number().nullable(),
    title: zod
      .string({ required_error: 'Enter a title for the article', invalid_type_error: 'Enter a title for the article' })
      .max(MAX_TITLE_LENGTH, 'Too long title')
      .min(1, 'Enter a title for the article')
      .optional(),
    dek: zod
      .string({
        required_error: 'Enter a lead text (Dek) for the article',
        invalid_type_error: 'Enter a lead text (Dek) for the article',
      })
      .max(MAX_DEK_LENGTH, 'Too long Dek')
      .min(1, 'Enter a lead text (Dek) for the article')
      .optional(),

    // Metadata
    slug: zod.string().min(1, 'Enter article url').nullable(),
    assignment: zod.string().max(MAX_ASSIGNMENT_LENGTH).min(1, 'Fill the assignment'),
    needsOriginalArtwork: zod.boolean(),
    writerId: zod.number().nullable(),
    editorId: zod.number().nullable(),
    copyEditorId: zod.number().nullable(),
    coverImageDeadLineAt: zod.string().nullable(),
    publishedAt: zod.string().nullable().optional(),
    updatedAt: zod.string().nullable().optional(),
    embargoUntil: zod.string().nullable(),

    categoryId: zod.number().nullable(),
    badgeId: zod.number().nullable(),
    superTags: zod.array(tagSchema).optional(),
    tags: zod.array(tagSchema).optional(),

    twitterPost: zod.string().max(MAX_TWEET_LENGTH),
    twitterExcludeRss: zod.boolean(),
    isExcludedFromTelegram: zod.boolean(),
    seoMetaKeyWords: zod.string().nullable(),
    seoMetaDescription: zod.string().max(MAX_SEO_DESCRIPTION_LENGTH).nullable(),

    isBreakingNews: zod.boolean(),
    isShowingInMarkets: zod.boolean(),
    excludeFromAllRss: zod.boolean(),
    hideFromHotStories: zod.boolean(),
    hideFromMainPage: zod.boolean(),
    isPromoPost: zod.boolean(),

    coverArt: zod.number(),
    youtubeUrl: zod.string().nullable(),
  })
  .superRefine((fields, ctx) => {
    const validateTwitterPost = (twitterPost: string, twitterExcludeRss: boolean) => {
      if (twitterExcludeRss && twitterPost.length > MAX_TWEET_LENGTH) {
        throw new Error();
      }

      return true;
    };

    const { virtualDocumentStatus, virtualPreviousDocumentStatus } = useDocumentStatus();

    const rulesets = DOCUMENT_STATUS_TO_RULE_SET[virtualDocumentStatus.value];
    const ruleset = rulesets && rulesets[virtualPreviousDocumentStatus.value];

    if (!ruleset) {
      return;
    }

    Object.keys(ruleset).forEach((rulename) => {
      if (typeof fields[rulename] === 'undefined') {
        return;
      }

      const value = fields[rulename];
      const schema = ruleset[rulename];

      try {
        if (rulename === 'twitterPost') {
          validateTwitterPost(value, fields['twitterExcludeRss']);
        }

        schema.parse(value);
      } catch (e) {
        ctx.addIssue({
          path: [rulename],
          code: 'custom',
        });
      }
    });
  });
