// Prevent link from deleting if last char was deleted
export const deleteLinkHelper = (view, event): boolean => {
  if (event.inputType === 'deleteContentBackward' || event.inputType === 'deleteContentForward') {
    const { state, dispatch } = view;
    const { selection } = state;
    const { $from, $to } = selection;

    const hasLink = $to.nodeBefore.marks.some((mark) => mark.type.name === 'a');
    if (hasLink) {
      event.preventDefault();

      // Delete only text inside a mark
      const tr = state.tr.delete($from.pos - 1, $to.pos);
      dispatch(tr);
      return true;
    }
  }
};
