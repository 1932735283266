<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.SMALL"
    data-testid="unsaved-changes-modal"
    @close="emits('close')"
  >
    <div class="flex flex-col items-center justify-center">
      <ExclamationShapes class="text-imperium-bg-5"></ExclamationShapes>
      <div class="mt-4 text-imperium-fg-base font-normal">Changes not saved. Would you like to save them?</div>
      <div class="flex gap-2 my-4 items-center justify-center">
        <Button
          :color="BUTTON_COLOR.DEFAULT"
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="emits('continue')"
        >
          Don't save changes
        </Button>
        <Button
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.PRIMARY"
          type="submit"
          @click="emits('save')"
        >
          Save changes
        </Button>
      </div>
    </div>
  </ModalHolder>
</template>
<script setup lang="ts">
import ExclamationShapes from '@/assets/icons/exclamation-shapes.svg?component';
import ModalHolder from '@/components/ModalHolder.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES } from '@/types';

import Button from '@/components/Button.vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'save'): void;
  (event: 'continue'): void;
  (event: 'close'): void;
}>();
</script>
