import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useNotFoundPage = () => {
  const route = useRoute();

  return {
    isNotFoundPage: computed(() => route.name === 'notFound'),
  };
};
