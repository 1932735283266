import { useToast } from '@/composables';

const toast = useToast();
export const copyToClipboard = async (text: string): Promise<void> => {
  const formattedText = removeTags(text);
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([formattedText], { type: 'text/html' }),
        'text/plain': new Blob([formattedText], { type: 'text/plain' }),
      }),
    ]);
    toast.success({
      id: 'ARTICLE_TEXT_COPY',
      message: 'Article content was copied to clipboard',
    });
  } catch (err) {
    toast.error({
      id: 'ERROR_COPY',
      message: 'Failed to copy article content',
    });
    throw new Error();
  }
};

const removeTags = (text: string): string => {
  // According to https://jira.cointelegraph.com/browse/CMS-819 not all tags must be copied to clipboard
  // The images URL, subscription box, embeds, related box should not be in the copy.
  const regex = /<\/?(article|template|iframe|figure)[^>]*>([^]*?)<\/\1>/g;
  return text.replace(regex, '');
};
