<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :is-title-visible="false"
    :hard-width="630"
    @close="emits('cancel')"
  >
    <div>
      <FormDatePickerRange
        name="publish"
        :size="SIZES.SMALL"
        :model-value="props.modelValue"
        @update:model-value="onUpdateRangeValues"
      ></FormDatePickerRange>
    </div>
    <div class="flex justify-end gap-2 mt-3">
      <Button
        :visual-type="BUTTON_TYPE.TERTIARY"
        :size="SIZES.SMALL"
        @click="emits('cancel')"
      >
        Cancel
      </Button>
      <Button
        :visual-type="BUTTON_TYPE.PRIMARY"
        :size="SIZES.SMALL"
        :is-disabled="isApplyDisabled"
        @click="onUpdate"
      >
        Apply
      </Button>
    </div>
  </ModalHolder>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';

import Button from '@/components/Button.vue';
import ModalHolder from '@/components/ModalHolder.vue';
import FormDatePickerRange, { type RangeValues } from '@/components/FormDatepicker/FormDatePickerRange.vue';

import { BUTTON_TYPE, SIZES } from '@/types';

const range = ref<RangeValues | null>(null);

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    modelValue?: RangeValues | null;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'cancel'): void;
  (event: 'update:modelValue', value: RangeValues | null): void;
}>();

const isApplyDisabled = computed(() => {
  if (range.value) {
    if (range.value.start && range.value.end) {
      return false;
    }
  }

  return true;
});

const onUpdateRangeValues = (values: RangeValues | null) => {
  range.value = values;
};
const onUpdate = () => {
  emits('update:modelValue', range.value);
};
</script>
