import { ref, watch, computed, type Ref, onMounted, onUnmounted } from 'vue';
import { watchDebounced } from '@vueuse/core';

const TIMEOUT_MS = 250;

export const useScrollEnd = (scrollableArea: HTMLElement | Window = window) => {
  const isScrolling = ref<NodeJS.Timeout>();
  const callbacks = ref<(() => void)[]>([]);
  const scrollSymbol = ref<symbol>(Symbol());

  const scrollListener = () => {
    window.clearTimeout(isScrolling.value);

    isScrolling.value = setTimeout(() => {
      setTimeout(() => {
        scrollSymbol.value = Symbol();
      });
    }, TIMEOUT_MS);
  };

  onMounted(() => {
    scrollableArea.addEventListener('scroll', scrollListener, false);

    watchDebounced(
      scrollSymbol,
      () => {
        callbacks.value.forEach((callback) => callback());
      },
      {
        debounce: TIMEOUT_MS,
      },
    );
  });

  onUnmounted(() => {
    scrollableArea.removeEventListener('scroll', scrollListener);
  });

  return {
    onScrollEnd: (callback: () => void) => {
      callbacks.value.push(callback);
    },
  };
};
