import { Transaction } from 'prosemirror-state';
import { Node as ProseMirrorNode, Schema } from 'prosemirror-model';
import type { EditorImageType } from '@/features/CollaborativeEditor/blocks/imagesDataHelper';

const updateImageInFigure = (
  tr: Transaction,
  dispatch: (tr: Transaction) => void,
  doc: ProseMirrorNode,
  schema: Schema,
  modalData: EditorImageType,
  settings: EditorImageType,
): boolean => {
  const { src } = modalData;
  let modified = false;

  doc.descendants((node: ProseMirrorNode, pos: number) => {
    // TODO: Potential bug. IF we meet same src address of img we will replace attrs for all images
    // which has it
    if (node.type === schema.nodes.img && node.attrs.src === src) {
      const updatedFigureAttrs = {
        ...node.attrs,
        src,
        ...settings,
      };

      tr.setNodeMarkup(pos, null, updatedFigureAttrs);

      modified = true;
      return false; // Stop traversal within this figure node
    }

    return true; // Continue traversing other children
  });

  if (modified && dispatch) {
    dispatch(tr); // Dispatch the transaction to apply the changes to the document
    return true;
  }

  return modified;
};

export default updateImageInFigure;
