<template>
  <div>
    <div class="relative">
      <div
        v-if="isLoading"
        class="max-w-[100vw] min-h-[60vh] flex items-center justify-center"
      >
        <div class="h-10 w-10">
          <LoaderIcon class="w-10 h-10" />
        </div>
      </div>

      <div
        v-else
        class="relative bg-imperium-bg-sub-base"
        :class="{
          'rounded-2xl': !isMobile,
        }"
      >
        <div>
          <Table
            class="max-w-full relative overflow-x-auto"
            :is-rounded="!isMobile"
            is-sticky=""
          >
            <TableHead>
              <TableHeadRow
                :size="SIZES.MEDIUM"
                :bordered="TABLE_BORDERED.BOTTOM"
                :is-on-surface="TABLE_ON_SURFACE.PRIMARY"
              >
                <TableHeadCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :is-sticky="true"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="id"
                    label="Id"
                    class="p-2.5"
                    :value="filtersStore.state.sort.id"
                    @change-sort="updateFilters({ filterName: 'id', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  is-uppercase
                  :is-full-width="!isAditionalColumnsSelected"
                >
                  <SortColumnHeader
                    id="title"
                    label="Article Title"
                    :value="filtersStore.state.sort.title"
                    @change-sort="updateFilters({ filterName: 'title', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.FLAGS)"
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                >
                  Flags
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.STATUS)"
                  :size="SIZES.LARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  is-uppercase
                >
                  Status
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.EMBARGO)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="embargo"
                    label="Embargo"
                    :value="filtersStore.state.sort.embargoUntil"
                    @change-sort="updateFilters({ filterName: 'embargoUntil', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.DEADLINE)"
                  :size="SIZES.LARGE"
                  :scale="TABLE_SCALE.QUADRUPLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="deadline"
                    label="Cover Deadline"
                    :value="filtersStore.state.sort.coverImageDeadLineAt"
                    is-wrap
                    @change-sort="updateFilters({ filterName: 'coverImageDeadLineAt', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.PUBLISHED)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="published"
                    label="Published"
                    :value="filtersStore.state.sort.publishedAt"
                    @change-sort="updateFilters({ filterName: 'publishedAt', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CREATED)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="created"
                    label="Created"
                    :value="filtersStore.state.sort.createdAt"
                    @change-sort="updateFilters({ filterName: 'createdAt', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.UPDATED)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="updated"
                    label="Updated"
                    :value="filtersStore.state.sort.updatedAt"
                    @change-sort="updateFilters({ filterName: 'updatedAt', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.EDITOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Editor
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.WRITER)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Writer
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.COPY_EDITOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Copy Editor
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.AUTHOR)"
                  :size="SIZES.LARGE"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Public Author
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CREATOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Creator
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CATEGORY)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  is-uppercase
                >
                  Category
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.WORDS)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.NORMAL"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="words"
                    label="Words"
                    :value="filtersStore.state.sort.words"
                    @change-sort="updateFilters({ filterName: 'words', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CHARACTERS)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.QUADRUPLE"
                  is-uppercase
                >
                  <SortColumnHeader
                    id="characters"
                    label="chars"
                    :value="filtersStore.state.sort.characters"
                    @change-sort="updateFilters({ filterName: 'characters', value: $event })"
                  />
                </TableHeadCell>

                <TableHeadCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.LANGUAGE)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  Language
                </TableHeadCell>

                <!--                Temporary removed columns-->
                <!--                <TableHeadCell-->
                <!--                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.VIEWS)"-->
                <!--                  :size="SIZES.SMALL"-->
                <!--                  :scale="TABLE_SCALE.TRIPLE"-->
                <!--                  is-uppercase-->
                <!--                >-->
                <!--                  <SortColumnHeader-->
                <!--                    id="views"-->
                <!--                    label="Views"-->
                <!--                    :value="filtersStore.state.sort.views"-->
                <!--                    @change-sort="updateFilters({ filterName: 'views', value: $event })"-->
                <!--                  />-->
                <!--                </TableHeadCell>-->

                <!--                <TableHeadCell-->
                <!--                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.SHARES)"-->
                <!--                  :size="SIZES.SMALL"-->
                <!--                  :scale="TABLE_SCALE.QUADRUPLE"-->
                <!--                  is-uppercase-->
                <!--                >-->
                <!--                  <SortColumnHeader-->
                <!--                    id="shares"-->
                <!--                    label="Shares"-->
                <!--                    :value="filtersStore.state.sort.shares"-->
                <!--                    @change-sort="updateFilters({ filterName: 'shares', value: $event })"-->
                <!--                  />-->
                <!--                </TableHeadCell>-->
              </TableHeadRow>
            </TableHead>

            <TableBody v-if="!!articles?.items.length">
              <TableRow
                v-for="article in articles.items"
                :key="article.id"
                :size="SIZES.MEDIUM"
                :striped="TABLE_STRIPED.STRIPED"
                :bordered="TABLE_BORDERED.BOTTOM"
                class="cursor-pointer"
                @click="emits('redirect', article.id)"
              >
                <TableCell
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  :is-sticky="true"
                  is-uppercase
                >
                  <span class="text-imperium-fg-muted">{{ article.id }}</span>
                </TableCell>

                <TableCell
                  :size="isTablet ? SIZES.LARGE : SIZES.XLARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold"
                  is-full-width
                  :is-sticky="true"
                  :sticky-offset-size="SIZES.SMALL"
                  :sticky-offset-scale="TABLE_SCALE.DOUBLE"
                  is-uppercase
                >
                  <a
                    :href="`${locationOrigin}/articles/${article.id}`"
                    @click.stop="onTitleLinkClick($event, article.id)"
                  >
                    {{ article.title || '—' }}
                  </a>
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.FLAGS)"
                  :size="SIZES.SMALL"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <ArticleFlag
                    :article="article"
                    :with-tooltip="article.needsProofreading"
                    tooltip-label="Needs proofreading"
                  />
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.STATUS)"
                  :size="SIZES.LARGE"
                  :scale="TABLE_SCALE.NORMAL"
                  :padding="TABLE_PADDINGS.NONE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <ArticleStatus
                    :size="SIZES.XSMALL"
                    :status="DocumentStatus[article.status]"
                    :published-at="article.publishedAt"
                  />
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.EMBARGO)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <template v-if="article.embargoUntil">
                    {{ getFormattedDate(article.embargoUntil) }}
                    <br />
                    <span class="text-imperium-fg-muted">
                      {{ getFormattedTime(article.embargoUntil) }}
                    </span>
                  </template>

                  <template v-else> — </template>
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.DEADLINE)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <template v-if="article.coverImageDeadLineAt">
                    {{ getFormattedDate(article.coverImageDeadLineAt) }}
                    <br />
                    <span class="text-imperium-fg-muted">
                      {{ getFormattedTime(article.coverImageDeadLineAt) }}
                    </span>
                  </template>

                  <template v-else> — </template>
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.PUBLISHED)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <template v-if="article.publishedAt">
                    {{ getFormattedDate(article.publishedAt) }}
                    <br />
                    <span class="text-imperium-fg-muted">
                      {{ getFormattedTime(article.publishedAt) }}
                    </span>
                  </template>

                  <template v-else> — </template>
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CREATED)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <template v-if="article.createdAt">
                    {{ getFormattedDate(article.createdAt) }}
                    <br />
                    <span class="text-imperium-fg-muted">
                      {{ getFormattedTime(article.createdAt) }}
                    </span>
                  </template>

                  <template v-else> — </template>
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.UPDATED)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  <template v-if="article.updatedAt">
                    {{ getFormattedDate(article.updatedAt) }}
                    <br />
                    <span class="text-imperium-fg-muted">
                      {{ getFormattedTime(article.updatedAt) }}
                    </span>
                  </template>

                  <template v-else> — </template>
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.EDITOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.editor.name || '—' }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.WRITER)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.writer.name || '—' }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.COPY_EDITOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.copyEditor?.name || '—' }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.AUTHOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.author?.name || '—' }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CREATOR)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.DOUBLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.creator?.name || '—' }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CATEGORY)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.category.title || '—' }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.WORDS)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.NORMAL"
                  :vertical-align="TABLE_VALIGN.TOP"
                  is-uppercase
                >
                  {{ article.words || 0 }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.CHARACTERS)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.QUADRUPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  {{ article.characters || 0 }}
                </TableCell>

                <TableCell
                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.LANGUAGE)"
                  :size="SIZES.MEDIUM"
                  :scale="TABLE_SCALE.TRIPLE"
                  :vertical-align="TABLE_VALIGN.TOP"
                  class="font-semibold overflow-hidden text-ellipsis"
                  is-uppercase
                >
                  —
                </TableCell>

                <!--                <TableCell-->
                <!--                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.VIEWS)"-->
                <!--                  :size="SIZES.SMALL"-->
                <!--                  :scale="TABLE_SCALE.TRIPLE"-->
                <!--                  :vertical-align="TABLE_VALIGN.TOP"-->
                <!--                  class="font-semibold overflow-hidden text-ellipsis"-->
                <!--                  is-uppercase-->
                <!--                >-->
                <!--                  {{ article.views || '—' }}-->
                <!--                </TableCell>-->

                <!--                <TableCell-->
                <!--                  v-if="filtersStore.isColumnSelected(ARTICLE_COLUMNS.SHARES)"-->
                <!--                  :size="SIZES.SMALL"-->
                <!--                  :scale="TABLE_SCALE.QUADRUPLE"-->
                <!--                  :vertical-align="TABLE_VALIGN.TOP"-->
                <!--                  class="font-semibold overflow-hidden text-ellipsis"-->
                <!--                  is-uppercase-->
                <!--                >-->
                <!--                  {{ article.shares || '—' }}-->
                <!--                </TableCell>-->
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          v-if="!paginationStore.state.total"
          class="w-full flex align-middle justify-center items-center min-h-[60vh] max-w-[100vw]"
        >
          <div class="font-semibold text-imperium-fg-strong">No articles found</div>
        </div>

        <div
          v-if="paginationStore.state.total"
          class="py-3 p-4 mt-1"
        >
          <Pagination
            :current-page="paginationStore.state.page || 1"
            :is-mobile="isMobile || isTablet"
            :items-count="paginationStore.state.total || 0"
            :limit="paginationStore.state.limit"
            @change-page="paginationStore.changePage"
            @change-limit="paginationStore.changeLimit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onMounted, computed } from 'vue';

import LoaderIcon from '@/assets/icons/spinner.svg?component';

import { useArticlesFilters } from '@/features/Articles/stores/filters.store';
import { useArticlesPagination } from '@/features/Articles/stores/pagination.store';
import ArticleFlag from '@/features/Articles/components/ArticleFlag.vue';
import Pagination from '@/components/Table/Pagination/Pagination.vue';
import { useFetchArticles } from '../queries';

import { DocumentStatus, TABLE_PADDINGS, TABLE_VALIGN } from '@/types';
import ArticleStatus from '@/features/ArticleLayout/components/ArticleStatus.vue';

import { type ArticlesFetchRequest, type ArticlesFetchResponse } from '../types';
import { ARTICLE_COLUMNS } from '../constants';

import { getFormattedDate, getFormattedTime } from '@/utils';

import { TABLE_BORDERED, TABLE_ON_SURFACE, TABLE_STRIPED, TABLE_SCALE, SIZES, SortStatus } from '@/types';

import { Table, TableBody, TableHead, TableCell, TableHeadCell, TableRow, TableHeadRow } from '@/components/Table';
import SortColumnHeader from '@/components/Table/Filters/SortColumnHeader.vue';
import { getIds, useArticlesUpdatesListener } from '@/composables';

const emits = defineEmits<{
  (event: 'redirect', articleId: number): void;
}>();

const isMobile = inject<boolean>('isMobile');
const isTablet = inject<boolean>('isTablet');

const filtersStore = useArticlesFilters();
const paginationStore = useArticlesPagination();

const locationOrigin = computed(() => {
  return window.location.origin;
});

onMounted(() => {
  filtersStore.setSorts({
    id: SortStatus.DESC,
  });
});

const isAditionalColumnsSelected = computed(() => Object.values(filtersStore.state.enabledColumns).some((val) => val));

const articlesSort = computed(() => {
  const sortFields = filtersStore.state.sort;

  if (!Object.keys(sortFields)[0]) {
    return;
  }

  if (!Object.values(sortFields)[0]) {
    return;
  }

  return {
    value: Object.keys(sortFields)[0],
    direction: Object.values(sortFields)[0],
  };
});

const articlesPayload = computed<ArticlesFetchRequest>(() => {
  const rawFilters = filtersStore.normalizeFilters();
  const payload = {
    languageId: 1,

    page: paginationStore.state.page,
    itemsPerPage: paginationStore.state.limit,

    'filter[title]': filtersStore.state.searchQuery || undefined,

    'filter[status]': rawFilters.status,
    'filter[language]': rawFilters.language,
    'filter[editor]': rawFilters.editor,
    'filter[writer]': rawFilters.writer,
    'filter[author]': rawFilters.author,
    'filter[copyEditor]': rawFilters.copyEditor,
    'filter[creator]': rawFilters.creator,
    'filter[embargoUntil]': rawFilters.embargoedUntil,
    'filter[coverImageDeadLineAt]': rawFilters.deadline,
    'filter[published][from]': rawFilters.publishedFrom,
    'filter[published][to]': rawFilters.publishedTo,
    'filter[created]': rawFilters.createdAt,
    'filter[category]': rawFilters.category,
    'filter[isEmbargoed]': rawFilters.isEmbargoed,
    'filter[breaking]': rawFilters.breaking,
    'filter[needsProofreading]': rawFilters.needsProofreading,
  };

  if (articlesSort.value) {
    payload[`order[${articlesSort.value.value}]`] = articlesSort.value.direction;
  }

  if (rawFilters.publishedFrom) {
    const date = new Date(rawFilters.publishedFrom);

    const timezoneOffset = date.getTimezoneOffset(); // Возвращает смещение относительно UTC в минутах
    date.setTime(date.getTime() - timezoneOffset * 60 * 1000);

    payload['filter[published][from]'] = date.toISOString();
  }
  if (rawFilters.publishedTo) {
    const date = new Date(rawFilters.publishedTo);

    const timezoneOffset = date.getTimezoneOffset(); // Возвращает смещение относительно UTC в минутах
    date.setTime(date.getTime() - timezoneOffset * 60 * 1000);

    payload['filter[published][to]'] = date.toISOString();
  }

  return payload;
});

const { data: articles, isLoading } = useFetchArticles(articlesPayload, (result: ArticlesFetchResponse) => {
  if (!result) {
    return;
  }

  paginationStore.setState({
    page: result.page,
    total: result.total,
  });
});

const updateFilters = ({ filterName, value }: { filterName: string; value: SortStatus }) => {
  filtersStore.setSorts({
    [filterName]: value ? value : SortStatus.ASC,
  });
};

const onTitleLinkClick = (event: PointerEvent, id: number): void => {
  if (!(event.ctrlKey || event.metaKey)) {
    emits('redirect', id);
    event.preventDefault();
  }
};

const allArticleIds = computed(() => {
  return getIds(...(articles?.items || []));
});

useArticlesUpdatesListener({ articleIds: allArticleIds });
</script>
