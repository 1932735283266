<template>
  <ModalHolder
    :is-visible="props.isVisible"
    :size="SIZES.MEDIUM"
    data-testid="user-restore-modal"
    @close="emits('close')"
    @wheel.prevent
  >
    <div class="flex flex-col items-center justify-center">
      <ExclamationShapes class="text-imperium-bg-5" />
      <div class="mt-4 text-imperium-fg-base font-normal text-center">
        Are you sure you want to reset two-factor authentication for
        <b> {{ props.userName }} </b>?
      </div>
      <div class="mt-4">
        <Toast
          id="body-warning-"
          :is-full-width="true"
          :theme="THEMES.WARNING"
          :message="`The next time ${props.userName} logs in, they will be required to complete the 2FA setup process again before
            accessing the CMS.`"
          :is-closable="false"
        />
      </div>
      <div class="flex gap-2 my-4 items-center justify-center">
        <Button
          :color="BUTTON_COLOR.DEFAULT"
          :size="SIZES.MEDIUM"
          data-testid="restore-modal-close-button"
          :visual-type="BUTTON_TYPE.TERTIARY"
          @click="emits('close')"
        >
          Cancel
        </Button>
        <Button
          :is-loading="props.isLoading"
          :size="SIZES.MEDIUM"
          :visual-type="BUTTON_TYPE.PRIMARY"
          type="submit"
          data-testid="restore-modal-restore-button"
          @click="emits('confirm')"
        >
          Yes, reset
        </Button>
      </div>
    </div>
  </ModalHolder>
</template>
<script setup lang="ts">
import ExclamationShapes from '@/assets/icons/exclamation-shapes.svg?component';
import ModalHolder from '@/components/ModalHolder.vue';
import { BUTTON_COLOR, BUTTON_TYPE, SIZES, THEMES } from '@/types';
import Button from '@/components/Button.vue';
import Toast from '@/components/Toast.vue';

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    userName: string;
    isLoading: boolean;
  }>(),
  {
    isVisible: false,
  },
);

const emits = defineEmits<{
  (event: 'confirm'): void;
  (event: 'close'): void;
}>();
</script>
